import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import ErrorLogging from '../../../../../../utils/customHooks/ErrorLogging'
import {
	usePostCustomerAndReturnEmergencyCallingStatusMutation,
	usePostSREEmergencyCallingConfigurationMutation,
} from '../../../../../../services/proxyAPIData'
import { ChangeEvent, useEffect, useState } from 'react'
import {
	showErrorToast,
	showSuccessToast,
	toAlphaString,
	toBeta,
} from '../../../../../../utils/helperFunctions/helperFunctions'
import {
	BetaObject,
	SREEmergencyCalling,
	SREResponseModel,
} from '../../../../../../utils/interfaces/APIModels'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import './SREEmergencyCalling.scss'
import CardPreloader from '../../../../../shared/loading/loadingCard/CardPreloader/CardPreloader'

const SREEmergencyCallingDisplay = () => {
	// Global variables
	const customerInfoForSREToUse = useSelector(
		(state: RootState) => state.RootReducer.customerInfoForSREToUseReducer.value
	)

	// Error logging
	const { addErrorLog } = ErrorLogging()

	// API Calls
	const [postCustomerAndReturnEmergencyCallingStatus] =
		usePostCustomerAndReturnEmergencyCallingStatusMutation()
	const [postSREEmergencyCallingConfiguration] =
		usePostSREEmergencyCallingConfigurationMutation()

	// Flags
	const [emergencyCallingEnabled, setEmergencyCallingEnabled] = useState(false)
	const [isTeamsService, setIsTeamsService] = useState(false)
	const [isStatusRetrieved, setIsStatusRetrieved] = useState(false)
	const [initialCallDone, setInitialCallDone] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		// Make initial call
		const getEmergencyCallingStatus = async () => {
			var isError = false
			var errorMessage = ''

			// Encrypt
			var betaDataObj = await toBeta(customerInfoForSREToUse)
			var betaObj: BetaObject = {
				Content: betaDataObj,
			}

			await postCustomerAndReturnEmergencyCallingStatus(betaObj)
				.unwrap()
				.then(async (responseData) => {
					if ('Content' in responseData) {
						var decryptedResponse = await toAlphaString(
							responseData.Content + ''
						)
						setIsTeamsService(true)
						if (decryptedResponse === 'true') {
							setEmergencyCallingEnabled(true)
						}
						if (decryptedResponse === 'false') {
							setEmergencyCallingEnabled(false)
						}
					}
				})
				.catch(async (error) => {
					if (error) {
						isError = false
						errorMessage = `A problem arose while retrieving the emergency calling status for the customer.`
					}
				})
				.finally(async () => {
					if (isError && errorMessage.length > 0) {
						await addErrorLog(
							'Retrieving Emergency Calling status',
							errorMessage + ''
						)
						showErrorToast(
							'A problem arose while retrieving the emergency calling status.'
						)
					}
				})

			// Initial call done
			setInitialCallDone(true)
		}

		if (customerInfoForSREToUse) {
			if (!isStatusRetrieved) {
				setIsStatusRetrieved(true)
				getEmergencyCallingStatus()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isStatusRetrieved])

	// POST: SRE Emergency update
	const handleEmergencyCallingUpdate = async () => {
		// Start loading
		setLoading(true)

		var isError = false
		var errorMessage = ''

		var enableRequestToUse: SREEmergencyCalling = {
			CustomerID: customerInfoForSREToUse.CustomerID + '',
			EnableEmergencyCalling: emergencyCallingEnabled,
		}

		var betaDataObj = await toBeta(enableRequestToUse)
		var betaObj: BetaObject = {
			Content: betaDataObj,
		}
		await postSREEmergencyCallingConfiguration(betaObj)
			.unwrap()
			.then(async (responseData) => {
				if ('Content' in responseData) {
					// Decrypt response and show success/failure
					var decryptedResponse = await toAlphaString(responseData.Content + '')
					var result = JSON.parse(decryptedResponse) as SREResponseModel

					// Check code
					if (result.ErrorCode === '200') {
						showSuccessToast('The E911 configuration was updated successfully.')
					} else {
						showErrorToast(
							'The update to the E911 configuration was not successful.'
						)
					}
				}
			})
			.catch(async (error) => {
				if (error) {
					isError = false
					errorMessage = `A problem occurred while updating the emergency calling for the customer.`
				}
			})
			.finally(async () => {
				if (isError && errorMessage.length > 0) {
					await addErrorLog('Updating Emergency Calling', errorMessage + '')
					showErrorToast(
						'A problem occurred while updating the status of emergency calling.'
					)
				}
			})

		// End loading
		setLoading(false)
	}

	// Handle enable E911 change
	const handleChangeEmergencyCallingEnabled = (
		e: ChangeEvent<HTMLInputElement>
	) => {
		setEmergencyCallingEnabled(e.target.checked)
	}

	return initialCallDone && isTeamsService ? (
		<>
			<Box className='sre-ec-container'>
				{/* Header */}
				<Box className='sre-ec-header'>
					{/* Icons */}
					<Box className='header-icon'>
						<Box className='icon' sx={{ bgcolor: 'primary.main' }}>
							<img
								src={require('../../../../../../assets/icons/card/E911.png')}
								alt='E911 SRE Emergency Calling'
							/>
						</Box>
					</Box>
					{/* Text */}
					<Box className='header-text'>
						{/* Heading */}
						<Typography component='h2'>Dynamic E911</Typography>
						{/* Description */}
						<Typography component='p'>
							Configure your Teams E911 Call Routing.
						</Typography>
					</Box>
				</Box>
				{/* Enable E911 Calling */}
				<Box className='sre-ec-e911'>
					<FormControlLabel
						control={
							<Checkbox
								sx={{ color: 'text.header' }}
								checked={emergencyCallingEnabled}
								onChange={handleChangeEmergencyCallingEnabled}
							/>
						}
						label='Enable E911?'
						sx={{ color: 'text.header' }}
					/>
				</Box>
				{/* E911 Config button */}
				<Box className='sre-ec-btn'>
					<LoadingButton
						variant='contained'
						loading={loading}
						onClick={handleEmergencyCallingUpdate}>
						Configure E911
					</LoadingButton>
				</Box>
			</Box>
		</>
	) : initialCallDone && !isTeamsService ? (
		<></>
	) : (
		<>
			<Box className='sre-ec-loading'>
				{/* Preloader */}
				<Box className='sre-ec-loader'>
					<CardPreloader autoMargin={false} />
				</Box>
				{/* Typography */}
				<Typography component='p'>Loading information</Typography>
			</Box>
		</>
	)
}

export default SREEmergencyCallingDisplay
