import { useState } from 'react'
import { TeamsUsersResponse } from '../../../../../../utils/interfaces/APIModels'
import { GridColDef, GridRowId } from '@mui/x-data-grid-pro'
import { StyledDataGrid } from '../../../../../shared/datagrid/StyledDataGrid'
import {
	Box,
	Button,
	IconButton,
	Skeleton,
	Tooltip,
	useTheme,
	Modal,
	styled,
} from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import './TUMUserDisplay.scss'
import {
	TUMPagination,
	TUMUpdateStatuses,
} from '../../../../../../utils/enums/enums'
import { showErrorToast } from '../../../../../../utils/helperFunctions/helperFunctions'
import StyledModal from '../../../../../shared/styledComponents/StyledModal/StyledModal'
import TUMEditForm from './TUMEditForm/TUMEditForm'
import { useTUMContext } from '../../../../../../utils/context/TUMContext'
import UseLocalStorage from '../../../../../../utils/customHooks/LocalStorage/UseLocalStorage'
import { TUMUpdateList } from '../../../../../../utils/interfaces/ComponentModels'

// Custom styled box for ellipsis display
const StyledEllipsisBox = styled(Box)({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
})

const TUMUserDisplay = () => {
	// Context
	const {
		customerID,
		currentPageNo,
		isLastPage,
		setRemoveBase,
		setRemoveAddon,
		setRemovePhone,
		setInternationalCalling,
		teamsUserDisplay,
		handlePageNavigation,
	} = useTUMContext()

	// Hooks
	const { data } = UseLocalStorage(`tum-${customerID}`)

	// Edit Row Constants
	const [currentEditRow, setCurrentEditRow] = useState({} as TeamsUsersResponse)

	// Flags
	const [rowsLoading, setRowsLoading] = useState(false)
	const [openModal, setOpenModal] = useState(false)

	// Theme
	const theme = useTheme()

	// Column Definition
	const teamsUserColumns: GridColDef[] = [
		{
			field: 'upn',
			headerName: 'Username',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{rowsLoading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<StyledEllipsisBox>{params.value}</StyledEllipsisBox>
					)}
				</>
			),
		},
		{
			field: 'name',
			headerName: 'Name',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{rowsLoading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<StyledEllipsisBox>{params.value}</StyledEllipsisBox>
					)}
				</>
			),
		},
		{
			field: 'baseLicenseName',
			headerName: 'Base License',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{rowsLoading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<StyledEllipsisBox>{params.value}</StyledEllipsisBox>
					)}
				</>
			),
		},
		{
			field: 'addonLicenseName',
			headerName: 'Add-on License',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{rowsLoading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<StyledEllipsisBox>{params.value}</StyledEllipsisBox>
					)}
				</>
			),
		},
		{
			field: 'phoneNumber',
			headerName: 'Phone Number',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{rowsLoading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<StyledEllipsisBox>{params.value}</StyledEllipsisBox>
					)}
				</>
			),
		},
		{
			field: 'voiceRoutingPolicy',
			headerName: 'Voice Routing Policy',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{rowsLoading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<StyledEllipsisBox>{params.value}</StyledEllipsisBox>
					)}
				</>
			),
		},
		{
			field: 'actions',
			type: 'actions',
			cellClassName: 'actions',
			hideable: false,
			width: 80,
			getActions: ({ id }) => {
				return [
					<>
						<Tooltip
							title={
								isUserBeingConfigured(id)
									? 'This user is currently being configured. Please wait for the configuration to be completed'
									: 'Edit this user'
							}>
							<span>
								<IconButton
									disabled={isUserBeingConfigured(id)}
									onClick={() => handleEditUser(id)}>
									<SettingsOutlinedIcon
										sx={{
											color: theme.palette.primary.main,
										}}
									/>
								</IconButton>
							</span>
						</Tooltip>
					</>,
				]
			},
		},
	]

	// This will save the current row to edit and open the drawer for assignment/unassignment
	const handleEditUser = (id: GridRowId) => {
		// Get the current row and open the drawer
		var currentRow = teamsUserDisplay.find((user) => user.upn + '' === id)

		if (currentRow) {
			// Set current row and open modal
			setCurrentEditRow(currentRow)
			setOpenModal(true)
		}
	}

	// Handle disable of button
	const isUserBeingConfigured = (id: GridRowId) => {
		// Check if user is being updated
		const tumUpdateList = data as TUMUpdateList[]
		if (tumUpdateList && tumUpdateList.length > 0) {
			// Check only pending
			if (
				tumUpdateList.find(
					(u) =>
						u.UPN + '' === id && Number(u.Status) === TUMUpdateStatuses.Pending
				)
			) {
				return true
			}
		}

		return false
	}

	// Handle page nav and loading
	const handleNavigation = async (navType: number) => {
		// Loading
		setRowsLoading(true)

		// Make call
		var success = await handlePageNavigation(navType)

		if (!success) {
			// Show error and set it back to old rows
			showErrorToast(
				`An error occurred when trying to retrieve the ${TUMPagination[
					navType
				].toLowerCase()} page`
			)
		}

		// End loading
		setRowsLoading(false)
	}

	// Handle close drawer
	const handleCloseForm = () => {
		// Reset flags
		setRemoveBase(false)
		setRemoveAddon(false)
		setRemovePhone(false)
		setInternationalCalling(false)
		// Close drawer
		setOpenModal(false)
	}

	return (
		<>
			<Box className='tum-users-container'>
				{/* Filter */}
				{/* Display Table */}
				<StyledDataGrid
					rows={teamsUserDisplay}
					columns={teamsUserColumns}
					editMode='row'
					checkboxSelection={false}
					pagination={false}
					getRowId={(row) => row.upn}
					autoHeight
					rowSelection={false}
					hideFooter
					hideFooterPagination
					hideFooterRowCount
				/>
				{/* Custom Pagination */}
				<Box className='tum-pagination'>
					<Button
						disabled={currentPageNo === 0}
						startIcon={<ArrowBackIcon />}
						variant='contained'
						onClick={() => handleNavigation(TUMPagination.Previous)}>
						Previous
					</Button>
					<Button
						disabled={isLastPage}
						endIcon={<ArrowForwardIcon />}
						variant='contained'
						onClick={() => handleNavigation(TUMPagination.Next)}>
						Next
					</Button>
				</Box>
			</Box>
			{/* Modal Form - Edit and Configure User */}
			<Modal open={openModal} onClose={handleCloseForm}>
				<StyledModal width={620}>
					<TUMEditForm currentUser={currentEditRow} />
				</StyledModal>
			</Modal>
		</>
	)
}

export default TUMUserDisplay
