import React, { useState } from 'react'
import { TabPanelProps } from '../../../../utils/interfaces/ComponentModels'
import { Box, Tab, Tabs, useTheme } from '@mui/material'
import DomainAndTenantsDisplay from './DomainAndTenantsDisplay'
import EmergencyDialPlanDisplay from './EmergencyDialPlanDisplay'
import { TenantConfigurationInfo } from '../../../../utils/interfaces/APIModels'
import { Country } from '../../../../utils/interfaces/DBModels'
import VoiceRoutingDetailsDisplay from './VoiceRoutingDetailsDisplay'

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	)
}

function a11yProps(index: number) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	}
}

const TeamsTabDisplay = ({
	tenantConfigurationInfo,
	countries,
}: {
	tenantConfigurationInfo: TenantConfigurationInfo
	countries: Country[]
}) => {
	const theme = useTheme()
	const [value, setValue] = useState(0)
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}

	return (
		<>
			<div className='customer-overview-tabbed-display'>
				<Tabs value={value} onChange={handleChange} color='text.header'>
					<Tab
						className='map-list-button'
						label='Domains & Tenants'
						iconPosition='start'
						{...a11yProps(0)}
					/>
					<Tab
						className='map-list-button'
						label='Voice Routing Details'
						iconPosition='start'
						{...a11yProps(1)}
					/>
					<Tab
						className='map-list-button'
						label='Emergency Dial Plan'
						iconPosition='start'
						{...a11yProps(2)}
					/>
				</Tabs>
				<TabPanel value={value} index={0} dir={theme.direction}>
					<DomainAndTenantsDisplay
						tenantConfigurationInfo={tenantConfigurationInfo}
					/>
				</TabPanel>
				<TabPanel value={value} index={1} dir={theme.direction}>
					<VoiceRoutingDetailsDisplay
						tenantConfigurationInfo={tenantConfigurationInfo}
					/>
				</TabPanel>
				<TabPanel value={value} index={2} dir={theme.direction}>
					<EmergencyDialPlanDisplay
						countries={countries}
						tenantConfigurationInfo={tenantConfigurationInfo}
					/>
				</TabPanel>
			</div>
		</>
	)
}

export default TeamsTabDisplay
