import { useEffect, useState } from 'react'
import { DDIListDisplay } from '../../../../utils/interfaces/ComponentModels'
import {
	GridColDef,
	GridColumnVisibilityModel,
	GridRenderCellParams,
	GridToolbar,
} from '@mui/x-data-grid-pro'
import CircleIcon from '@mui/icons-material/Circle'
import { DDIStatuses, DDITypes, Roles } from '../../../../utils/enums/enums'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../shared/datagrid/StyledDataGrid'
import CustomCard from '../../../shared/card/CustomCard'
import { DDI } from '../../../../utils/interfaces/DBModels'
import { Box, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { Permissions } from '../../../../utils/enums/enums'

const DDIOverviewDisplay = ({
	ddiList,
	onDeleteDDIs,
	customerHasPOC,
}: {
	ddiList: DDI[]
	onDeleteDDIs: any
	customerHasPOC: boolean
}) => {
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 5,
		page: 0,
	})
	const loggedInRoleAndPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)
	const [ddiRows, setDDIRows] = useState([] as DDIListDisplay[])
	const [columnVisibilityModel, setColumnVisibilityModel] =
		useState<GridColumnVisibilityModel>({} as GridColumnVisibilityModel)

	// helper functions
	function getDDIStatus(ddiStatusID: DDIStatuses): string {
		switch (ddiStatusID) {
			case DDIStatuses.Received:
				return 'Received'
			case DDIStatuses.Allocated:
				return 'Allocated'
			case DDIStatuses.PendingEmergencyAddressData:
				return 'Pending Emergency Address Data'
			case DDIStatuses.Validation:
				return 'Validation'
			case DDIStatuses.FOC:
				return 'FOC'
			case DDIStatuses.Inactive:
				return 'Inactive'
			case DDIStatuses.Active:
				return 'Active'
			case DDIStatuses.Test:
				return 'Test'
			case DDIStatuses.Exported:
				return 'Exported'
			case DDIStatuses.Terminated:
				return 'Terminated'
			default:
				return ''
		}
	}

	function getDDIType(ddiTypeID: DDITypes): string {
		switch (ddiTypeID) {
			case DDITypes.Port:
				return 'Port'
			case DDITypes.Test:
				return 'Test'
			case DDITypes.New:
				return 'New'
			case DDITypes.Resource:
				return 'Resource'
			case DDITypes.User:
				return 'User'
			case DDITypes.PartnerManaged:
				return 'Partner Managed'
			default:
				return ''
		}
	}

	useEffect(() => {
		if (ddiList.length > 0) {
			var _ddiDetails = [] as DDIListDisplay[]
			ddiList.forEach((d) => {
				var _isRangeActive = false
				var _ddiType = ''
				var _ddiStatus = ''
				var _isDDIActive = false

				if (d) {
					if (d?.DDIRange?.IsActive) {
						_isRangeActive = true
					}

					if (d?.IsActive) {
						_isDDIActive = true
					}

					_ddiType = getDDIType(d.DDIRange?.DDITypeID!) + ''
					_ddiStatus = getDDIStatus(d.DDIStatusID!) + ''
				}
				var _ddiObj: DDIListDisplay = {
					ID: d.ID,
					DDI: d.DDI + '',
					IsRangeActive: _isRangeActive,
					DDIType: _ddiType,
					DDIStatus: _ddiStatus,
					IsDDIActive: _isDDIActive,
					DDIRangeID: Number(d.DDIRangeID),
				}
				_ddiDetails.push(_ddiObj)
			})
			setDDIRows(_ddiDetails)

			if (customerHasPOC) {
				setColumnVisibilityModel({
					actions: false,
				})
			}
		} else {
			setDDIRows([] as DDIListDisplay[])
		}
	}, [ddiList])

	const ddiColumns: GridColDef[] = [
		{
			field: 'DDI',
			headerName: 'TN',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'DDIType',
			headerName: 'TN Type',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'DDIStatus',
			headerName: 'TN Status',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'IsRangeActive',
			headerName: 'Is Range Active',
			width: 250,
			hideable: false,
			flex: 1,
			renderCell: (params: GridRenderCellParams<any>) => (
				<>
					<span>{params.value}</span>
					{params.value === true ? (
						<CircleIcon className='circle-icon-status-active' />
					) : (
						<CircleIcon className='circle-icon-status-inactive' />
					)}
				</>
			),
		},
		{
			field: 'IsDDIActive',
			headerName: 'Is TN Active',
			width: 250,
			hideable: false,
			flex: 1,
			renderCell: (params: GridRenderCellParams<any>) => (
				<>
					<span>{params.value}</span>
					{params.value === true ? (
						<CircleIcon className='circle-icon-status-active' />
					) : (
						<CircleIcon className='circle-icon-status-inactive' />
					)}
				</>
			),
		},
	]

	if (
		loggedInRoleAndPermissions.find(
			(x) =>
				Number(x.PermissionID) === Permissions.UpdateDDI &&
				Number(x.RoleID) === Roles.PartnerAdmin
		)
	) {
		ddiColumns.push({
			field: 'actions',
			type: 'actions',
			cellClassName: 'actions',
			headerName: 'Remove Range',
			width: 220,
			editable: false,
			align: 'center',
			hideable: false,
			flex: 1,
			getActions: ({ id }) => {
				return [
					<>
						<Box className='ddi-management-option'>
							<Tooltip title='Remove Range'>
								<IconButton
									onClick={(e: any) => {
										handleDelete(Number(id))
									}}>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						</Box>
					</>,
				]
			},
		})
	}

	const handleDelete = async (ddiId: number) => {
		var ddiToFind = ddiRows.find((x) => Number(x.ID) === ddiId)

		if (ddiToFind) {
			var confirmDelete = window.confirm(
				`Are you sure you want to delete TN: ${ddiToFind?.DDI}? Please note that this will remove all TNs in this range and will affect the customer's service.`
			)
			if (confirmDelete) {
				onDeleteDDIs(ddiToFind.DDIRangeID)
			}
		}
	}

	return (
		<>
			{ddiRows.length > 0 ? (
				<StyledDataGrid
					rows={ddiRows}
					columns={ddiColumns}
					editMode='row'
					checkboxSelection={false}
					pagination
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					pageSizeOptions={[5]}
					columnVisibilityModel={columnVisibilityModel}
					slots={{
						pagination: CustomPagination,
						toolbar: GridToolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.ID}
					autoHeight
					rowSelection={false}
				/>
			) : (
				// No DDI
				<CustomCard
					cardIconSrc='card-error.png'
					cardTitle='No TNs have been found'
					cardDescription='Please note there were no TNs that were found for this customer.'
					isActive
					showButton={false}
				/>
			)}
		</>
	)
}

export default DDIOverviewDisplay
