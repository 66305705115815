import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { OrderSKU } from '../../../../../../utils/interfaces/DBModels'
import './AutoRenewUpdate.scss'

const AutoRenewUpdate = ({
	orderID,
	orderSKUID,
	skuTypeID,
	currentAutoRenewValue,
	updateAutoRenew,
}: {
	orderID: string
	orderSKUID: number
	skuTypeID: string
	currentAutoRenewValue: boolean
	updateAutoRenew: any
}) => {
	// Display constants
	const [autoRenew, setAutoRenew] = useState(currentAutoRenewValue)

	// Flags
	const [loading, setLoading] = useState(false)

	// Handle update of auto renew value
	const handleAutoRenewUpdate = async () => {
		// Start loading
		setLoading(true)

		// Order SKU Update
		var orderSKUObj: OrderSKU = { OrderSKUID: orderSKUID, AutoRenew: autoRenew }

		await updateAutoRenew(orderSKUObj)

		setLoading(false)
	}

	return (
		<>
			<Box className='sku-container'>
				{/* Header */}
				<Box className='sku-header'>
					{/* Text */}
					<Box className='header-text'>
						{/* Heading */}
						<Typography component='h2'>Update Order SKU</Typography>
						{/* Description */}
						<Typography component='p'>
							This action will set the Order SKU to renew automatically
						</Typography>
					</Box>
				</Box>
				{/* Display for Order ID and SKU Type ID */}
				<Box className='sku-order-details'>
					<Box className='sku-text'>
						{/* Order ID */}
						<Typography component='p'>
							<strong>Order ID:</strong> {orderID}
						</Typography>
						{/* SKU Type ID */}
						<Typography component='p'>
							<strong>Order SKU:</strong> {skuTypeID}
						</Typography>
					</Box>
				</Box>
				<Box className='sku-label'>
					<FormControlLabel
						control={
							<Checkbox
								checked={autoRenew}
								onChange={(e) => {
									setAutoRenew(e.target.checked)
								}}
							/>
						}
						label='Enable Auto Renewal?'
						sx={{ color: 'text.header' }}
					/>
				</Box>
				{/* Confirm button */}
				<Box className='sku-btn'>
					<LoadingButton
						variant='contained'
						loading={loading}
						onClick={handleAutoRenewUpdate}>
						Update SKU
					</LoadingButton>
				</Box>
			</Box>
		</>
	)
}

export default AutoRenewUpdate
