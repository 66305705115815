import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { StyledTableCell } from '../StyledTableComponents'

const ServiceManagementRights = () => {
	// Display rows
	const tableDetailDisplay = [
		{
			Name: 'Organization.Read.All',
			Platform: 'Microsoft Graph',
			Type: 'Application',
			Permission: 'Read organization information',
		},
		{
			Name: 'Teams Administrator',
			Platform: 'RBAC',
			Type: 'Role',
			Permission: 'Access to Teams',
		},
	]

	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					{/* Header */}
					<TableHead>
						<TableRow>
							<StyledTableCell>Name</StyledTableCell>
							<StyledTableCell>Platform</StyledTableCell>
							<StyledTableCell>Type</StyledTableCell>
							<StyledTableCell>Permission</StyledTableCell>
						</TableRow>
					</TableHead>
					{/* Body */}
					<TableBody>
						{tableDetailDisplay.map((row, index) => (
							<TableRow key={index}>
								<StyledTableCell>{row.Name}</StyledTableCell>
								<StyledTableCell>{row.Platform}</StyledTableCell>
								<StyledTableCell>{row.Type}</StyledTableCell>
								<StyledTableCell>{row.Permission}</StyledTableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}

export default ServiceManagementRights
