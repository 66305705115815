import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { setPageTitle } from '../../../store/reducers/reducers'
import { useAppDispatch } from '../../../store/hooks'
import { useGetLeadsByPartnerMutation } from '../../../services/proxyAPIData'
import {
	toAlphaString,
	toBetaString,
} from '../../../utils/helperFunctions/helperFunctions'
import Preloader from '../../../components/shared/loading/preloader/Preloader'
import LeadDisplay from '../../../components/distinct/dashboard/LeadManagement/LeadDisplay'
import {
	LeadContactInfoDisplay,
	LeadListDisplay,
} from '../../../utils/interfaces/ComponentModels'
import { Box } from '@mui/material'
import './LeadManagement.scss'
import { CustomerPartners, OCLeadStatus } from '../../../utils/enums/enums'
import { LeadsResponse } from '../../../utils/interfaces/APIModels'

function LeadManagement() {
	// Global variables
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)

	// API call
	const [getLeadsByPartner] = useGetLeadsByPartnerMutation()
	// flags
	const [dataRetrieved, setDataRetrieved] = useState(false)
	const [isPageLoadingDone, setIsPageLoadingDone] = useState(false)

	// variables
	const [partnerLeads, setPartnerLeads] = useState([] as LeadListDisplay[])

	const dispatch = useAppDispatch()

	useEffect(() => {
		// Assign page title
		if (pageTitle !== 'Lead Management') {
			dispatch(setPageTitle('Lead Management'))
		}

		if (!dataRetrieved) {
			getLeads()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataRetrieved, pageTitle, partnerLeads, isPageLoadingDone])
	// get leads
	const getLeads = async () => {
		await getLeadsByPartner(toBetaString(CustomerPartners.Lumen.toString()))
			.unwrap()
			.then(async (data) => {
				if (data?.Content) {
					var alphaText = await toAlphaString(data?.Content)
					var dataResponse = JSON.parse(alphaText) as LeadsResponse[]

					if (dataResponse.length > 0) {
						var _partnerLeadList = [] as LeadListDisplay[]
						for (var i = 0; i < dataResponse.length; i++) {
							if (
								dataResponse[i].Partner ===
								CustomerPartners[CustomerPartners.Lumen]
							) {
								var _leadContactInfoList = [] as LeadContactInfoDisplay[]
								var _leadContactDetails =
									dataResponse[i].LeadContactDetails ?? []
								for (var j = 0; j < _leadContactDetails.length; j++) {
									var _contactInfo: LeadContactInfoDisplay = {
										Email: _leadContactDetails[j].Email ?? '',
										PhoneNumber: _leadContactDetails[j].PhoneNumber ?? '',
										ContactName: _leadContactDetails[j].Name ?? '',
									}
									_leadContactInfoList.push(_contactInfo)
								}
								var _leadObj: LeadListDisplay = {
									LeadID: dataResponse[i].LeadID ?? 0,
									CompanyName: dataResponse[i].CompanyName ?? '',
									DateCreated: dataResponse[i].DateCreated ?? '',
									ExternalSalesAgent: dataResponse[i].ExternalSalesAgent ?? '',
									LeadSize: dataResponse[i].LeadSize ?? '',
									LeadStatus: dataResponse[i].LeadStatus ?? '',
									LeadType: dataResponse[i].LeadType ?? '',
									OCLeadStatusID:
										getOCLeadStatusValue(
											dataResponse[i].OperatorConnectStatus
										) ?? 0,
									OCLeadStatus: (dataResponse[i].OperatorConnectStatus ?? '')
										.replace(/([A-Z])/g, ' $1')
										.trim(),
									Locations: dataResponse[i].Locations ?? '',
									TenantID: dataResponse[i].TenantID ?? '',
									LeadContactInfoDisplay: _leadContactInfoList,
									OCConsentStatus: dataResponse[i].Status ?? '',
									Stage: dataResponse[i].LeadStage ?? '',
								}
								_partnerLeadList.push(_leadObj)
							}
						}
						setPartnerLeads(_partnerLeadList)
						setIsPageLoadingDone(true)
						setDataRetrieved(true)
					}
				}
			})
	}

	function getOCLeadStatusValue(statusString: string): number | undefined {
		const statusEnumValue =
			OCLeadStatus[statusString as keyof typeof OCLeadStatus]
		return statusEnumValue
	}

	return (
		<>
			{isPageLoadingDone ? (
				<Box id='lead-management-page' className='lead-container'>
					{/* display */}
					<LeadDisplay
						getPartnerLeadsList={getLeads}
						partnerLeadList={partnerLeads}
					/>
				</Box>
			) : (
				<Preloader />
			)}
		</>
	)
}

export default LeadManagement
