import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

function LoginRedirect() {
	const { loginWithRedirect } = useAuth0()
	const [searchParams] = useSearchParams()
	const invitationTicketID = searchParams.get('invitation')
	const organizationID = searchParams.get('organization')
	useEffect(() => {
		if (invitationTicketID && organizationID) {
			loginWithRedirect({
				organization: organizationID + '',
				invitation: invitationTicketID + '',
			})
		} else {
			loginWithRedirect()
		}
	}, [invitationTicketID, organizationID])
	return <></>
}

export default LoginRedirect
