import { Box, Typography } from '@mui/material'
import './AppRegistrationRedirect.scss'

const AppRegistrationRedirect = () => {
	return (
		<>
			<Box
				id='app-reg'
				className='landing-container'
				bgcolor='background.default'>
				<Box className='app-reg-content'>
					<img
						src={require('../../assets/icons/card/teamsDR/processComplete.png')}
						alt='Teams Logo for Teams Direct Routing'
					/>
					<Box className='app-reg-text'>
						<Typography component='h2' color='text.primary'>
							Enterprise App added to your Microsoft Tenant
						</Typography>
						<Typography>
							Please <strong>close this page</strong> and go back to the invite
							email to <strong>accept the invite</strong>.
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default AppRegistrationRedirect
