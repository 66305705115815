import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { Auth0Provider } from '@auth0/auth0-react'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
var organizationID = ''
var currentURL = window.location.href
if (currentURL.includes(process.env.REACT_APP_LUMEN_PORTAL_URL + '')) {
	organizationID = process.env.REACT_APP_LUMEN_ORG_ID + ''
} else if (
	currentURL.includes(process.env.REACT_APP_SOFTWARE1_PORTAL_URL + '')
) {
	organizationID = process.env.REACT_APP_SOFTWARE1_ORG_ID + ''
} else if (
	currentURL.includes(process.env.REACT_APP_CONTENTANDCLOUD_PORTAL_URL + '')
) {
	organizationID = process.env.REACT_APP_CONTENTANDCLOUD_ORG_ID + ''
} else if (currentURL.includes(process.env.REACT_APP_SIPCOM_PORTAL_URL + '')) {
	organizationID = process.env.REACT_APP_SIPCOM_ORG_ID + ''
}

root.render(
	<Auth0Provider
		domain='auth.ucplatform.io'
		clientId='OqZ4B8FaBooHSjZeNkxVrX35jw7qOaCG'
		organization='org_xC6QHt7K3JYtA6pw'
		redirectUri={window.location.origin}>
		<Provider store={store}>
			<App />
		</Provider>
	</Auth0Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
