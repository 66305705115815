import { Box, Divider, MenuItem, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { StyledTextBox } from '../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import { StyledSelect } from '../../../shared/styledComponents/StyledSelect/StyledSelect'
import {
	CustomerPartners,
	LeadStatus,
	LeadWeightEnum,
	OCLeadStatus,
} from '../../../../utils/enums/enums'
import { LoadingButton } from '@mui/lab'
import { UpdateLeadRequest } from '../../../../utils/interfaces/APIModels'

const UpdateLead = ({
	leadStage,
	tenantID,
	leadStatusID,
	onUpdate,
}: {
	leadStage: string
	tenantID: string
	leadStatusID: number
	onUpdate: (updateLeadRequestModel: UpdateLeadRequest) => Promise<void>
}) => {
	const [leadStatus, setLeadStatus] = useState(LeadStatus[LeadStatus.Live])
	const [relationshipStatus, setRelationshipStatus] = useState('')
	const [relationshipStatusId, setRelationshipStatusId] = useState(0)
	const [comment, setComment] = useState('')
	const [btnLoading, setBtnLoading] = useState(false)
	const [leadStageId, setLeadStageId] = useState(0)
	const [leadStageValue, setLeadStageValue] = useState('')
	const [initialLeadStageId, setInitialLeadStageId] = useState(0)
	const [initialLeadStageValue, setInitialLeadStageValue] = useState('')
	const [ocLeadStatusOptions, setOCLeadStatusOptions] = useState([
		{ label: 'Customer Contacted', value: OCLeadStatus.CustomerContacted },
		{ label: 'Consent Declined', value: OCLeadStatus.ConsentDeclined },
		{ label: 'Contract Terminated', value: OCLeadStatus.ContractTerminated },
	])
	const [leadWeightOptions, setLeadWeightOptions] = useState([
		{ value: LeadWeightEnum.Pipeline, label: 'Pipeline' },
		{ value: LeadWeightEnum['Best Case'], label: 'Best Case' },
		{ value: LeadWeightEnum.Commit, label: 'Commit' },
		{ value: LeadWeightEnum['Closed Won'], label: 'Closed Won' },
		{ value: LeadWeightEnum['Closed Lost'], label: 'Closed Lost' },
	])

	const handleOCLeadStatusIDChange = (e: { target: any }) => {
		var value = e.target.value
		setRelationshipStatusId(value)
		setRelationshipStatus(OCLeadStatus[value].replace(/\s/g, ''))

		if (
			value === OCLeadStatus.ConsentDeclined ||
			value === OCLeadStatus.ContractTerminated
		) {
			setLeadStatus(LeadStatus[LeadStatus.Dead])

			// only allowed option for consent declined and contract terminated
			setLeadWeightOptions([
				{ value: LeadWeightEnum['Closed Lost'], label: 'Closed Lost' },
			])
			setLeadStageId(LeadWeightEnum['Closed Lost'])
			setLeadStageValue(LeadWeightEnum[LeadWeightEnum['Closed Lost']])
		} else {
			setLeadStatus(LeadStatus[LeadStatus.Live])
			setComment('')
			// reset lead weight options
			setLeadWeightOptions([
				{ value: LeadWeightEnum.Pipeline, label: 'Pipeline' },
				{ value: LeadWeightEnum['Best Case'], label: 'Best Case' },
				{ value: LeadWeightEnum.Commit, label: 'Commit' },
				{ value: LeadWeightEnum['Closed Won'], label: 'Closed Won' },
				{ value: LeadWeightEnum['Closed Lost'], label: 'Closed Lost' },
			])
			setLeadStageId(initialLeadStageId)
			setLeadStageValue(initialLeadStageValue)
		}
	}

	const handleLeadStageIDChange = (e: { target: any }) => {
		var value = e.target.value
		setLeadStageId(value)
		setLeadStageValue(
			leadWeightOptions.find((x) => x.value === value)?.label + ''
		)
	}

	const handleCommentChange = (e: { target: any }) => {
		setComment(e.target.value + '')
	}

	const updateStatus = async () => {
		setBtnLoading(true)
		var updateRequestModel: UpdateLeadRequest = {
			PartnerName: CustomerPartners[CustomerPartners.Lumen],
			TenantID: tenantID,
			Comment: comment,
			RelationshipStatus: relationshipStatus,
			LeadStatus: leadStatus,
			LeadStage: leadStageValue,
		}
		await onUpdate(updateRequestModel)
		setBtnLoading(false)
	}

	useEffect(() => {
		if (leadStage.length > 0) {
			var leadOption = leadWeightOptions.find((x) => x.label === leadStage)

			if (leadOption) {
				setLeadStageId(leadOption.value)
				setLeadStageValue(leadOption.label)

				// set initial lead stage
				setInitialLeadStageId(leadOption.value)
				setInitialLeadStageValue(leadOption.label)
			}
		}
		setRelationshipStatusId(leadStatusID)
		setRelationshipStatus(OCLeadStatus[leadStatusID].replace(/\s/g, ''))

		if (leadStatusID === OCLeadStatus.ConsentAcknowledged) {
			if (
				!ocLeadStatusOptions.find(
					(x) => x.value === OCLeadStatus.ConsentAcknowledged
				)
			) {
				var _ocLeadStatusOptions = [
					{
						label: 'Consent Acknowledged',
						value: OCLeadStatus.ConsentAcknowledged,
					},
					...ocLeadStatusOptions,
				]
				setOCLeadStatusOptions(_ocLeadStatusOptions)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadStatusID, leadStage])

	const canUpdateLead = () => {
		return (
			relationshipStatus.length === 0 ||
			(comment.trim().length === 0 &&
				(relationshipStatus === OCLeadStatus[OCLeadStatus.ConsentDeclined] ||
					relationshipStatus === OCLeadStatus[OCLeadStatus.ContractTerminated]))
		)
	}
	return (
		<>
			<Box className='lead-form-container'>
				{/* Heading */}
				<Box className='lead-form-header'>
					<Typography id='lead-form-header' component='h1'>
						{`Update Lead`}
					</Typography>
				</Box>
				<Divider light />
				{/* Form content */}
				<Box className='lead-form-content'>
					<Box className='lead-form-input'>
						<Typography component='p'>
							Tenant ID
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<StyledTextBox
							disabled
							fullWidth
							type='text'
							placeholder='Tenant ID'
							value={tenantID}
						/>
					</Box>

					<Box className='lead-form-input'>
						<Typography component='p'>
							Lead Stage
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<StyledSelect
							fullWidth
							type='text'
							value={leadStageId}
							placeholder='Lead Stage'
							onChange={handleLeadStageIDChange}>
							{leadWeightOptions.map((option, index) => (
								<MenuItem key={index} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</StyledSelect>
					</Box>

					<Box className='lead-form-input'>
						<Typography component='p'>
							Status
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<StyledSelect
							fullWidth
							type='text'
							value={relationshipStatusId}
							placeholder='OC Lead Status'
							onChange={handleOCLeadStatusIDChange}>
							{ocLeadStatusOptions.map((option, index) => (
								<MenuItem key={index} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</StyledSelect>
					</Box>

					{(relationshipStatus === OCLeadStatus[OCLeadStatus.ConsentDeclined] ||
						relationshipStatus ===
							OCLeadStatus[OCLeadStatus.ContractTerminated]) && (
						<Box className='lead-form-input'>
							<Typography component='p'>
								Comment
								<Typography component='span' className='required'>
									*
								</Typography>
							</Typography>
							<StyledTextBox
								fullWidth
								type='text'
								placeholder='Comment'
								multiline
								maxRows={4}
								inputProps={{ maxLength: 255 }}
								value={comment}
								onChange={(e) => handleCommentChange(e)}
							/>
						</Box>
					)}

					<Box
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<LoadingButton
							loading={btnLoading}
							disabled={canUpdateLead()}
							style={{
								width: '250px',
								marginTop: '1rem',
							}}
							variant='contained'
							onClick={() => {
								updateStatus()
							}}>
							Update Lead
						</LoadingButton>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default UpdateLead
