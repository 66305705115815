import { BaseLicenseResponse } from '../../../../../../../utils/interfaces/APIModels'
import { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid-pro'
import { StyledDataGrid } from '../../../../../../../styles/styledComponents/displays/StyledDataGrid'
import { ModalForm } from '../../../../../../shared/inputs/ModalForm/ModalForm'
import GradingIcon from '@mui/icons-material/Grading'

const TUMBaseLicenseDisplay = ({
	baseLicenses,
	handleCloseModal,
}: {
	baseLicenses: BaseLicenseResponse[]
	handleCloseModal: any
}) => {
	// Display constants
	const [baseLicenseList, setBaseLicenseList] = useState(
		[] as BaseLicenseResponse[]
	)

	// Column Definition
	const baseLicenseColumns: GridColDef[] = [
		{
			field: 'baseLicenseName',
			headerName: 'Base License Name',
			hideable: false,
			flex: 1,
		},
		{
			field: 'numberPurchased',
			headerName: 'Total',
			hideable: false,
			width: 100,
		},
		{
			field: 'numberAssigned',
			headerName: 'Assigned',
			hideable: false,
			width: 100,
		},
		{
			field: 'addonRequired',
			headerName: 'Addon required?',
			hideable: false,
			flex: 1,
			type: 'boolean',
		},
	]

	useEffect(() => {
		// Assign list
		if (baseLicenses) {
			setBaseLicenseList(baseLicenses)
		}
	}, [baseLicenses])

	return (
		<>
			<ModalForm
				icon={<GradingIcon />}
				title='Base Licenses'
				description='View your base licenses list below'
				closeModal={handleCloseModal}>
				{/* Datagrid */}
				<StyledDataGrid
					rows={baseLicenseList}
					columns={baseLicenseColumns}
					editMode='row'
					checkboxSelection={false}
					pagination={false}
					getRowId={(row) => row.baseLicenseSKUID}
					autoHeight
					rowSelection={false}
					hideFooter
					hideFooterPagination
					hideFooterRowCount
				/>
			</ModalForm>
		</>
	)
}

export default TUMBaseLicenseDisplay
