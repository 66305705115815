import { Box, Typography } from '@mui/material'

const TeamsE911Configuration = () => {
	return (
		<>
			<Box className='documentation-info-container'>
				<Typography component='h4'>
					<b>Teams E911 Call Routing Configuration</b>
				</Typography>
				<Typography component='p'>
					Here we explain how to configure dynamic E911 calling for users.
				</Typography>
				<Typography component='p'>
					This process documents how to enable or disable E911 calling for users
					from the “Orders Overview” table on the dashboard. The customers
					displayed on the table are all the customers of that partner. Please
					note that only Partner Admins can manage a user's settings on the
					Orders Overview table, permitted that it is not a trial order - must
					be a Teams or Voice Catch order.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/TeamsE911/Image1.png')}
					alt='Orders Overview page'
					className='landscape'
				/>
				<Typography component='p'>
					Each customer entry has an <i>“Options”</i> cog to the right which
					takes you to the customer overview page. <br />
				</Typography>
				<Typography component='ol'>
					<Typography component='li'> Add Trunk(s)</Typography>
					<Typography component='li'>Add TN Range(s)</Typography>
					<Typography component='li'> E911 Configuration</Typography>
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/TeamsE911/Image2.png')}
					alt='Options Cog E911 Configuration Selection'
					className='landscape'
				/>
				<Typography component='h4'>Step 1</Typography>
				<Typography component='p'>
					If you select to configure E911 calling for a customer, a pop-up
					window will display.
				</Typography>
				<Box className='two-image-layout'>
					<img
						src={require('../../../../../assets/images/documentation/TeamsE911/Image3.png')}
						alt='Select E911 Configuration in Options Cog'
						width='200px'
					/>
					<img
						src={require('../../../../../assets/images/documentation/TeamsE911/Image4.png')}
						alt='Dynamic E911 pop-up window'
						width='400px'
					/>
				</Box>
				<Typography component='h4'>Step 2</Typography>
				<Typography component='p'>
					In the pop-up screen, you can select to enable or disable E911 Call
					Routing by clicking on the switch. To the right of the switch will be
					an indication of the current configuration - whether it is enabled or
					disabled.
				</Typography>
				<Box className='two-image-layout'>
					<img
						src={require('../../../../../assets/images/documentation/TeamsE911/Image7.png')}
						alt='Disabled E911 Configuration'
						width='300px'
					/>
					<img
						src={require('../../../../../assets/images/documentation/TeamsE911/Image5.png')}
						alt='Enabled E911 Configuration'
						width='300px'
					/>
				</Box>
				<Typography component='p'>
					A confirmation message will appear in the top right corner of the
					screen to indicate whether the Teams E911 Call Routing setting has
					been updated successfully for that customer.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/TeamsE911/Image6.png')}
					alt='Success Message of E911 Configuration'
					width='400px'
				/>
				<Typography component='p'>
					You can click anywhere on the screen to exit the pop-up.
				</Typography>
			</Box>
		</>
	)
}

export default TeamsE911Configuration
