import { Box, Typography } from '@mui/material'

const SDAFAQAnswer3 = () => {
	return (
		<>
			<Box className='documentation-answer-container'>
				{/* Question - How many licenses does the service consume? */}
				<Typography component='ol'>
					<Typography component='li'>
						The system is <b>aware</b> of the{' '}
						<b>Telephone Numbers associated</b> with your company's account.{' '}
					</Typography>
					<Typography component='li'>
						Based on the regions these numbers are located, the system will
						<b> automatically configure</b> the Custom Domain and associated
						PSTN Gateways in those regions.
					</Typography>
					<Typography component='li'>
						The table below shows you the <b>breakdown</b> of the available{' '}
						<b>regions</b> and the <b>license</b> breakdown:
					</Typography>
					<Box>
						<img
							src={require('../../../../../assets/images/faqs/RegionLicenseTable.png')}
							alt='table of available regions and licenses'
						/>
					</Box>
					<Typography component='li'>
						If your company is active in only one region, then only 2 licenses
						will be used. This could be a maximum of six licenses if you're
						active in all three regions. In effect, this means that{' '}
						<b>2 licenses</b> are <b>used per region</b>.
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export default SDAFAQAnswer3
