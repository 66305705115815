import { Box, Typography } from '@mui/material'

const SDAFAQAnswer4 = () => {
	return (
		<>
			<Box className='documentation-answer-container'>
				{/* Question - Can the users you created in my Tenant be removed? */}
				<Typography component='ul'>
					<Typography component='li'>
						{' '}
						<b>No</b>, the users that you have created in your Tenant cannot be
						removed.{' '}
					</Typography>
					<Typography component='li'>
						To ensure that your service continues to run smoothly, these users
						have to remain on your tenancy allocated to the custom domains.
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export default SDAFAQAnswer4
