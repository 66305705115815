import { Box, ListItemIcon, MenuItem, Typography } from '@mui/material'
import SearchOffIcon from '@mui/icons-material/SearchOff'

const EmptyOption = ({ header }: { header: string }) => {
	return (
		<MenuItem className='disabled-item'>
			{/* Icon */}
			<ListItemIcon>
				<SearchOffIcon />
			</ListItemIcon>
			{/* No option text and icon */}
			<Box className='option-text'>
				{/* Display name */}
				<Typography
					component='h5'
					sx={{
						wordWrap: 'break-word',
						whiteSpace: 'normal',
					}}>
					{header}
				</Typography>
				{/* Other information */}
				<Box className='option-info'>
					{/* Text */}
					<Typography component='p'>
						No results found or available for configuration. Please refine your
						search or check availability.
					</Typography>
				</Box>
			</Box>
		</MenuItem>
	)
}

export default EmptyOption
