import { useCallback, useEffect, useState } from 'react'
import { LeadListDisplay } from '../../../../utils/interfaces/ComponentModels'
import {
	DataGridProProps,
	GridColDef,
	GridColumnVisibilityModel,
	GridToolbar,
} from '@mui/x-data-grid-pro'
import CustomCard from '../../../shared/card/CustomCard'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../shared/datagrid/StyledDataGrid'
import { Box, IconButton, Modal, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import UpdateLead from './UpdateLead'
import {
	BetaObject,
	UpdateLeadRequest,
} from '../../../../utils/interfaces/APIModels'
import { useUpdateLeadStatusMutation } from '../../../../services/proxyAPIData'
import ErrorLogging from '../../../../utils/customHooks/ErrorLogging'
import {
	showErrorToast,
	showSuccessToast,
	toBeta,
} from '../../../../utils/helperFunctions/helperFunctions'
import { v4 as uuidv4 } from 'uuid'

const modalStyle = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 700,
	bgcolor: '#fff',
	boxShadow: 24,
	p: 4,
	overflow: 'hidden',
	maxHeight: '80%',
}

const LeadDisplay = ({
	getPartnerLeadsList,
	partnerLeadList,
}: {
	getPartnerLeadsList: () => void
	partnerLeadList: LeadListDisplay[]
}) => {
	// Leads
	const [
		postUpdateLeadStatusRequest,
		{ isLoading: postUpdateLeadStatusLoading },
	] = useUpdateLeadStatusMutation()

	// Error handling
	const { addErrorLog } = ErrorLogging()

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0,
	})
	const [columnVisibilityModel, setColumnVisibilityModel] =
		useState<GridColumnVisibilityModel>({} as GridColumnVisibilityModel)
	const [openUpdateLeadModal, setOpenUpdateLeadModal] = useState(false)
	const [gettingLeads, setGettingLeads] = useState(false)
	const [tenantID, setTenantID] = useState('')
	const [leadStage, setLeadStage] = useState('')
	const [leadStatusID, setLeadStatusID] = useState(0)
	useEffect(() => {
		setColumnVisibilityModel({
			LeadStatus: false,
			LeadType: false,
		})
	}, [partnerLeadList, postUpdateLeadStatusLoading])

	const handleOpenUpdateLeadModal = () => {
		setOpenUpdateLeadModal(true)
	}

	const handleCloseUpdateLeadModal = () => {
		setOpenUpdateLeadModal(false)
	}

	const leadColumns: GridColDef[] = [
		{
			field: 'CompanyName',
			headerName: 'Company Name',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'TenantID',
			headerName: 'Tenant ID',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'LeadSize',
			headerName: 'Lead Size',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'LeadStatus',
			headerName: 'Lead Status',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'LeadType',
			headerName: 'Lead Type',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'Stage',
			headerName: 'Stage',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'OCLeadStatus',
			headerName: 'Relationship Status',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'Locations',
			headerName: 'Locations',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'OCConsentStatus',
			headerName: 'Consent Status',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'actions',
			type: 'actions',
			cellClassName: 'actions',
			headerName: 'Update',
			width: 100,
			editable: false,
			align: 'center',
			hideable: false,
			getActions: ({ id }) => {
				return [
					<>
						{isLiveOCLead(partnerLeadList, Number(id)) && (
							<Box className='lead-management-option'>
								<Tooltip title='Update lead'>
									<IconButton
										onClick={(e: any) => {
											const lead = partnerLeadList.find(
												(x) => x.LeadID === Number(id)
											)
											if (lead) {
												setLeadStage(lead.Stage + '')
												setTenantID(lead.TenantID + '')
												setLeadStatusID(lead.OCLeadStatusID ?? 0)
												handleOpenUpdateLeadModal()
											}
										}}>
										<EditIcon />
									</IconButton>
								</Tooltip>
							</Box>
						)}
					</>,
				]
			},
		},
	]

	// build table for contact details
	function ContactInfoContent({ row: rowProp }: { row: LeadListDisplay }) {
		return (
			<Box id='oc-contact-info'>
				<StyledDataGrid
					sx={{
						py: 5,
						height: 1,
						boxSizing: 'border-box',
						width: '90%',
						margin: '0px 50px',
					}}
					initialState={{
						columns: {
							columnVisibilityModel: {
								// Hide OrderSKUID
								OrderSKUID: false,
							},
						},
					}}
					columns={[
						{
							field: 'ContactName',
							headerName: 'Contact Name',
							width: 100,
							hideable: false,
							flex: 1,
						},
						{
							field: 'Email',
							headerName: 'Email',
							width: 100,
							hideable: false,
							flex: 1,
						},
						{
							field: 'PhoneNumber',
							headerName: 'Phone Number',
							width: 100,
							hideable: false,
							flex: 1,
						},
					]}
					rows={rowProp.LeadContactInfoDisplay!}
					getRowId={(row) => uuidv4()}
					autoPageSize={true}
					autoHeight={true}
					hideFooter
				/>
			</Box>
		)
	}

	// Detail panel of main datagrid
	const getDetailPanelContent = useCallback<
		NonNullable<DataGridProProps['getDetailPanelContent']>
		// eslint-disable-next-line react-hooks/exhaustive-deps
	>(({ row }) => <ContactInfoContent row={row} />, [])

	const getDetailPanelHeight = useCallback(() => 'auto', [])

	const updateLeadStatus = async (
		updateLeadRequestModel: UpdateLeadRequest
	) => {
		if (updateLeadRequestModel) {
			var betaObj: BetaObject = {
				Content: await toBeta(updateLeadRequestModel),
			}
			await postUpdateLeadStatusRequest(betaObj)
				.unwrap()
				.then(async () => {
					showSuccessToast('Successfully updated lead status')
					setGettingLeads(true)
					await getPartnerLeadsList()
					setGettingLeads(false)
				})
				.catch(async (error) => {
					if (error) {
						addErrorLog('Update Lead Status', error?.data)
					}
					showErrorToast('Failed to update lead status')
				})
		}
	}

	function isLiveOCLead(partnerLeadList: LeadListDisplay[], id: number) {
		return (
			partnerLeadList
				.find((x) => x.LeadID === Number(id))
				?.OCConsentStatus?.toLowerCase() === 'active' &&
			partnerLeadList
				.find((x) => x.LeadID === Number(id))
				?.LeadStatus?.toLowerCase() === 'live'
		)
	}

	return (
		<>
			{partnerLeadList.length > 0 ? (
				<StyledDataGrid
					rows={partnerLeadList}
					columns={leadColumns}
					editMode='row'
					loading={gettingLeads}
					checkboxSelection={false}
					pagination
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					pageSizeOptions={[5]}
					columnVisibilityModel={columnVisibilityModel}
					slots={{
						pagination: CustomPagination,
						toolbar: GridToolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.LeadID}
					autoHeight
					rowSelection={false}
					getDetailPanelHeight={getDetailPanelHeight}
					getDetailPanelContent={getDetailPanelContent}
				/>
			) : (
				<CustomCard
					cardIconSrc='card-error.png'
					cardTitle='No Leads have been found'
					cardDescription='Please note there were no Leads that were found.'
					isActive
					showButton={false}
				/>
			)}

			<Modal open={openUpdateLeadModal} onClose={handleCloseUpdateLeadModal}>
				<Box sx={modalStyle}>
					<UpdateLead
						leadStage={leadStage}
						leadStatusID={leadStatusID}
						tenantID={tenantID}
						onUpdate={updateLeadStatus}
					/>
				</Box>
			</Modal>
		</>
	)
}

export default LeadDisplay
