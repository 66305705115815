import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import EastOutlinedIcon from '@mui/icons-material/EastOutlined'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined'
import './ConfirmOrder.scss'
import { ChangeEvent, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { StyledTextArea } from '../../../../../shared/styledComponents/StyledTextArea/StyledTextArea'

const ConfirmOrder = ({
	orderID,
	handleConfirmOrder,
}: {
	orderID: number
	handleConfirmOrder: any
}) => {
	// Display constants
	const [manualBilling, setManualBilling] = useState(false)
	const [billingNotes, setBillingNotes] = useState('')

	// Flags
	const [loading, setLoading] = useState(false)

	// Handle manual billing change
	const handleManualBillingChange = (e: ChangeEvent<HTMLInputElement>) => {
		setManualBilling(e.target.checked)
	}

	// API Call - Confirm Order
	const confirmOrder = async () => {
		// Start Loading
		setLoading(true)

		await handleConfirmOrder(orderID, manualBilling, billingNotes.trim())

		// End Loading
		setLoading(true)
	}

	return (
		<>
			<Box className='confirm-order-container'>
				{/* Header */}
				<Box className='confirm-order-header'>
					{/* Icons */}
					<Box className='header-icons'>
						<Box className='icon' sx={{ bgcolor: 'primary.main' }}>
							<AssignmentLateOutlinedIcon />
						</Box>
						<EastOutlinedIcon />
						<Box className='icon' sx={{ bgcolor: 'success.main' }}>
							<AssignmentTurnedInOutlinedIcon />
						</Box>
					</Box>
					{/* Text */}
					<Box className='header-text'>
						{/* Heading */}
						<Typography component='h2'>Confirm Order</Typography>
						{/* Description */}
						<Typography component='p'>
							This action will confirm the order with Sipcom.
						</Typography>
					</Box>
				</Box>
				{/* Manual Billing */}
				<Box className='confirm-order-mb'>
					<FormControlLabel
						control={
							<Checkbox
								sx={{ color: 'text.header' }}
								checked={manualBilling}
								onChange={handleManualBillingChange}
							/>
						}
						label='Enable Manual Billing?'
						sx={{ color: 'text.header' }}
					/>
				</Box>
				{/* Manual Billing Notes */}
				{manualBilling && (
					<Box sx={{ display: 'flex', rowGap: '5px', flexDirection: 'column' }}>
						<Typography component='p'>Billing Notes </Typography>
						<StyledTextArea
							maxRows={10}
							minRows={10}
							value={billingNotes}
							onChange={(e) => {
								e.target.value.length <= 4000 && setBillingNotes(e.target.value)
							}}
							placeholder='Enter manual billing details'
						/>
						<span
							style={{
								fontSize: '12px',
								color: 'grey',
								textAlign: 'right',
							}}>
							({billingNotes.length} / 4000){' '}
						</span>
					</Box>
				)}
				{/* Confirm button */}
				<Box className='confirm-order-btn'>
					<LoadingButton
						disabled={orderID === 0}
						variant='contained'
						loading={loading}
						onClick={confirmOrder}>
						Confirm Order
					</LoadingButton>
				</Box>
			</Box>
		</>
	)
}

export default ConfirmOrder
