import { styled, Select } from '@mui/material'

export const StyledSelect = styled(Select)(({ theme }) => ({
	borderRadius: 0,
	color: theme.palette.mode === 'light' ? '#000' : '#fff',
	'& .MuiOutlinedInput-root': {
		borderRadius: 0,
		color: '#0075C9',
	},
	'& input.MuiOutlinedInput-input': {
		color: '#0075c9',
		backgroundColor: '#fff',
		padding: '10px 8px !important',
	},
	'& .MuiSelect-select': {
		padding: '10px 24px 10px 10px',
	},
	'&.MuiInputBase-root': {
		height: 'auto', // Adjust height if needed
	},
	'&.username-field .Mui-disabled': {
		WebkitTextFillColor: '#fff',
	},
	'& fieldset.MuiOutlinedInput-notchedOutline': {
		borderColor: '#0075C9',
	},
	'& .rmv fieldset.MuiOutlinedInput-notchedOutline': {
		borderColor: '#d32f2f',
	},
}))
