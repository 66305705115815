import { useEffect, useState } from 'react'
import { GridColDef, GridToolbar } from '@mui/x-data-grid-pro'
import { EmergencyDialPlanListDisplay } from '../../../../utils/interfaces/ComponentModels'
import CustomCard from '../../../shared/card/CustomCard'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../shared/datagrid/StyledDataGrid'
import { TenantConfigurationInfo } from '../../../../utils/interfaces/APIModels'
import { Country } from '../../../../utils/interfaces/DBModels'

const EmergencyDialPlanDisplay = ({
	tenantConfigurationInfo,
	countries,
}: {
	tenantConfigurationInfo: TenantConfigurationInfo
	countries: Country[]
}) => {
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 6,
		page: 0,
	})
	const [emergencyDialCodeRows, setEmergencyDialCodeRows] = useState(
		[] as EmergencyDialPlanListDisplay[]
	)

	useEffect(() => {
		if (tenantConfigurationInfo?.Regions) {
			var _emergencyDialCodeDetails = [] as EmergencyDialPlanListDisplay[]

			var regions = tenantConfigurationInfo?.Regions

			var emergencyDialPlanIndex = 0

			regions.forEach((region) => {
				var country = countries.find(
					(x) => x.CountryISO2Code === region?.CountryISO2
				)

				var countryEmergencyCodes = [] as string[]

				if (
					region.EmergencyAmbulanceDialCode &&
					(region.EmergencyAmbulanceDialCode + '').length > 0
				) {
					countryEmergencyCodes.push(region.EmergencyAmbulanceDialCode + '')
				}

				if (
					region.EmergencyFireDialCode &&
					(region.EmergencyFireDialCode + '').length > 0
				) {
					countryEmergencyCodes.push(region.EmergencyFireDialCode + '')
				}

				if (
					region.EmergencyPoliceDialCode &&
					(region.EmergencyPoliceDialCode + '').length > 0
				) {
					countryEmergencyCodes.push(region.EmergencyPoliceDialCode + '')
				}

				emergencyDialPlanIndex++

				var emergencyDialPlanAlreadyAdded = _emergencyDialCodeDetails.find(
					(x) => x?.CountryName + '' === country?.CountryName + ''
				)

				if (!emergencyDialPlanAlreadyAdded) {
					var emergencyDialPlanToAdd: EmergencyDialPlanListDisplay = {
						Index: emergencyDialPlanIndex,
						CountryName: country?.CountryName + '',
						DiallingCode: region?.CountryDiallingCode + '',
						EmergencyDialCodes: countryEmergencyCodes,
					}

					_emergencyDialCodeDetails.push(emergencyDialPlanToAdd)
				}
			})

			setEmergencyDialCodeRows(_emergencyDialCodeDetails)
		}
	}, [tenantConfigurationInfo, countries])

	const emergencyDialPlanColumns: GridColDef[] = [
		{
			field: 'CountryName',
			headerName: 'Country',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'DiallingCode',
			headerName: 'Dialling Code',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'EmergencyDialCodes',
			headerName: 'Emergency Dial Codes',
			width: 220,
			hideable: false,
			flex: 1,
		},
	]

	return (
		<>
			{tenantConfigurationInfo?.Regions &&
			tenantConfigurationInfo?.Regions.length > 0 ? (
				<StyledDataGrid
					rows={emergencyDialCodeRows}
					columns={emergencyDialPlanColumns}
					editMode='row'
					checkboxSelection={false}
					pagination
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					pageSizeOptions={[5]}
					slots={{
						pagination: CustomPagination,
						toolbar: GridToolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.Index}
					rowSelection={false}
				/>
			) : (
				// No emergency dial plans
				<CustomCard
					cardIconSrc='card-error.png'
					cardTitle='No emergency dial plans were discovered'
					cardDescription='Kindly be aware that no emergency dial plans were located for this customer.'
					isActive
					showButton={false}
				/>
			)}
		</>
	)
}

export default EmergencyDialPlanDisplay
