import {
	Alert,
	Box,
	Button,
	FormControl,
	MenuItem,
	Select,
} from '@mui/material'
import { useEffect, useState } from 'react'
import './LicenseSelection.scss'
import { SDALicenseChoice } from '../../../../utils/interfaces/ComponentModels'

const LicenseSelection = ({
	confirmSelection,
	licenseList,
	numRequiredLicenses,
	showLogOutButton,
	onChangeShowLogOutButton,
	onHandleLogOut,
}: {
	confirmSelection: any
	licenseList: SDALicenseChoice[]
	numRequiredLicenses: number
	showLogOutButton: boolean
	onChangeShowLogOutButton: any
	onHandleLogOut: any
}) => {
	const [licenseChoice, setLicenseChoice] = useState(
		licenseList[0]?.MSTeamsLicenseSKUID + ''
	)

	const [errorMessage, setErrorMessage] = useState('')

	const handleLicenseChoiceChange = (e: { target: any }) => {
		onChangeShowLogOutButton(false)
		setErrorMessage('')
		setLicenseChoice(e.target.value)
		handleValidateLicenseChoice(e.target.value)
	}

	function handleValidateLicenseChoice(licenseSkuID: string) {
		var licenseToFind = licenseList.find(
			(x) => x?.MSTeamsLicenseSKUID + '' === licenseSkuID
		)

		if (licenseToFind) {
			if (!licenseToFind.hasValidQuantity) {
				setErrorMessage(
					`The license you have chosen does not have enough available units. You need ${numRequiredLicenses} unit/s. Please buy more and reload the portal to try again.`
				)
				onChangeShowLogOutButton(true)
			}
		}
	}

	useEffect(() => {
		if (licenseList.length > 0) {
			var firstLicenseInList = licenseList[0]
			if (firstLicenseInList) {
				handleValidateLicenseChoice(
					firstLicenseInList?.MSTeamsLicenseSKUID + ''
				)
			}
		} else {
			setErrorMessage(
				'No valid Microsoft licenses found. Please buy valid Microsoft base licenses (E3, E5, Microsoft 365 Business Basic) and reload the portal to try again.'
			)
			onChangeShowLogOutButton(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Box>
				<Box className='form-content'>
					<form className='form'>
						<h1>{`Please choose a License to use (${numRequiredLicenses} required):`}</h1>
						<FormControl fullWidth className='form-input'></FormControl>
						<FormControl fullWidth className='form-input'>
							<label className='form-label'>
								License:
								<Select
									variant='outlined'
									className='form-input-select'
									value={licenseChoice}
									onChange={handleLicenseChoiceChange}>
									{licenseList.map((item) => {
										return (
											<MenuItem
												key={item.MSTeamsLicenseSKUID}
												value={item.MSTeamsLicenseSKUID}>
												{`${item.FriendlyName}, Qty: ${item.NumAvailable}`}
											</MenuItem>
										)
									})}
								</Select>
							</label>
						</FormControl>
						{errorMessage.length > 0 && (
							<Alert
								severity='error'
								sx={{
									width: 600,
									marginBottom: '15px',
									padding: '2px',
								}}>
								{errorMessage}
							</Alert>
						)}
						<Box className='form-button'>
							{!showLogOutButton ? (
								<Button
									disabled={errorMessage.length > 0}
									variant='contained'
									onClick={() => {
										confirmSelection(licenseChoice)
									}}>
									Submit License Choice
								</Button>
							) : (
								<Button onClick={onHandleLogOut} variant='contained'>
									Log Out
								</Button>
							)}
						</Box>
					</form>
				</Box>
			</Box>
		</>
	)
}

export default LicenseSelection
