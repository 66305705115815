import DialpadIcon from '@mui/icons-material/Dialpad'
import { Box, ListItemIcon, MenuItem, Typography } from '@mui/material'
import { TUMPhoneTypes } from '../../../../../../../../../../utils/enums/enums'

const PhoneOption = ({
	numberType,
	displayName,
	handlePhoneSelection,
}: {
	numberType: number
	displayName: string
	handlePhoneSelection: any
}) => {
	return (
		<MenuItem
			className='tum-option'
			onClick={() => handlePhoneSelection(displayName)}>
			{/* Icon */}
			<ListItemIcon>
				<DialpadIcon fontSize='small' />
			</ListItemIcon>
			{/* License Display */}
			<Box className='option-text'>
				{/* Display name */}
				<Typography component='h5'>{displayName}</Typography>
				{/* Other information */}
				<Box className='option-info'>
					{/* Available */}
					<Typography component='p'>
						Type: {TUMPhoneTypes[numberType]}
					</Typography>
				</Box>
			</Box>
		</MenuItem>
	)
}

export default PhoneOption
