import {
	TUMBuildStatuses,
	TUMLoadTypes,
} from '../../../../../utils/enums/enums'
import Hourglass from '../../../../shared/displays/Hourglass/Hourglass'
import { Box, Divider, Typography, useTheme } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import TUMLoginBox from './TUMLoginBox/TUMLoginBox'
import './TUMLoading.scss'
import { TUMLoadingRowDisplay } from '../../../../../utils/interfaces/ComponentModels'
import TUMLoadingRow from './TUMLoadingRow/TUMLoadingRow'
import { useTUMContext } from '../../../../../utils/context/TUMContext'

const TUMLoading = () => {
	// Theme
	const theme = useTheme()

	// Context
	const { loadingStatus, loggedInAccount, handleLogin } = useTUMContext()

	return (
		<Box className='tum-container'>
			<Box className='tum-loading-container'>
				{/* Section 1: Login */}
				<Box className='tum-login-content'>
					{/* Icon */}
					<Box className='icon'>
						<LockPersonIcon sx={{ color: theme.palette.primary.main }} />
					</Box>
					<Box className='desc'>
						<Typography>
							Please choose to login with a valid account containing one of
							these roles:
							<br />
							<strong>Global Administrator, Teams Administrator</strong> or{' '}
							<strong>Teams Communications Administrator</strong>
							<br /> Upon logging in, the procedure will initiate to ensure the
							correct rights are in place. Upon confirmation, it will proceed to
							retrieve and construct this page.
						</Typography>
					</Box>
					{/* Login Button / Account: If logged in, show account */}
					<TUMLoginBox />
				</Box>
				{/* Section 2: Loading rows */}
				<Box className='tum-loading-content'>
					{/* General: All loading rows contain header, description and icon */}
					{/* Description: Change depending on action - 1. Not started (Awaiting) | 2. Loading | 3. Completed step response */}
					{/* Divider */}
					<Divider />
					{/* Rows */}
					{loadingStatus.map((item, index) => {
						return (
							<>
								<TUMLoadingRow
									key={index}
									heading={TUMLoadTypes[item.LoadStatusType]}
									description={item.LoadStatusDesc}
									status={item.LoadStatus}
								/>
								<Divider />
							</>
						)
					})}
				</Box>
			</Box>
		</Box>
	)
}

export default TUMLoading
