import { useState } from 'react'
import { StyledTextBox } from '../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import { Alert, Box, Modal } from '@mui/material'
import LoadingCard from '../../../../shared/loading/loadingCard/LoadingCard'
import { User } from '../../../../../utils/interfaces/APIModels'
import { UserManagementType } from '../../../../../utils/enums/enums'
import {
	showErrorToast,
	showSuccessToast,
} from '../../../../../utils/helperFunctions/helperFunctions'
import { LoadingButton } from '@mui/lab'
import ConfirmDelete from './ConfirmDelete'

function RemoverUsers({
	user,
	apiCall,
}: {
	user: User
	apiCall: (type: Number, id: string) => Promise<string>
}) {
	const [deletingUser, setDeletingUser] = useState(false)
	const [openConfirmModel, setOpenConfirmModel] = useState(false)
	const deleteUser = async () => {
		setDeletingUser(true)
		var alphaText = await apiCall(UserManagementType.DeleteUser, user.userID)
		if (alphaText === '') {
			showSuccessToast('User Deleted.')
		} else {
			showErrorToast(alphaText)
		}
		setDeletingUser(false)
	}

	const handleConfirmDelete = (confirm: string) => {
		setOpenConfirmModel(false)
		deleteUser()
	}

	return (
		<>
			{user.userID ? (
				<Box className='portal-um-form'>
					<StyledTextBox
						fullWidth
						label='User_ID'
						variant='outlined'
						value={user.userID}
						id='outlined-error-helper-text'
					/>
					<StyledTextBox
						fullWidth
						label='Email'
						variant='outlined'
						value={user.email}
						id='outlined-error-helper-text'
					/>
					{user.emailVerified ? (
						<Alert severity='success'>Email verified</Alert>
					) : (
						<Alert severity='error'>Email not verified</Alert>
					)}
					<StyledTextBox
						fullWidth
						label='Fullname'
						variant='outlined'
						value={user.fullName}
						id='outlined-error-helper-text'
					/>
					<StyledTextBox
						fullWidth
						label='Customer ID'
						variant='outlined'
						value={user.customerID}
						id='outlined-error-helper-text'
					/>
					<StyledTextBox
						fullWidth
						label='Number of logins'
						variant='outlined'
						value={user.loginsCount}
						id='outlined-error-helper-text'
					/>

					<LoadingButton
						// disabled={warning === ''}
						loading={deletingUser}
						onClick={() => {
							setOpenConfirmModel(true)
						}}
						variant='contained'
						sx={{ bgcolor: 'red' }}>
						Remove User
					</LoadingButton>
				</Box>
			) : (
				<LoadingCard
					loadingCardTitle='Retrieving data'
					loadingCardDescription='Getting user information'
				/>
			)}
			<Modal
				open={openConfirmModel}
				onClose={() => {
					setOpenConfirmModel(false)
				}}>
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 500,
						overflow: 'hidden',
						bgcolor: '#fff',
						boxShadow: 24,
						maxHeight: '80%',
					}}>
					<ConfirmDelete handleConfirmDelete={handleConfirmDelete} />
				</Box>
			</Modal>
		</>
	)
}

export default RemoverUsers
