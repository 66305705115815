import { TextareaAutosize } from '@mui/base'
import { styled } from '@mui/material'

export const StyledTextArea = styled(TextareaAutosize)(
	({ theme }) => `
  box-sizing: border-box;
  width: 400px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: #0075C9;
  background: ${theme.palette.mode === 'dark' ? '#000' : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? '#000' : '#0075C9'};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? '#000' : '#fff'};

  &:hover {
    border-color: #0075C9;
  }

  &:focus {
    border-color: #0075C9;
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? '#000' : '#fff'};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
)
