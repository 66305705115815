import { Box, Button, Grid, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import {
	MSTeamsUser,
	Service,
	ServiceManagementReport,
} from '../../../../utils/interfaces/DBModels'
import { TeamsDRServiceValueDisplayOptions } from '../../../../utils/enums/enums'
import TeamsServiceSelection from '../TeamsServiceSelection/TeamsServiceSelection'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'

const OverviewDisplay = ({
	ServiceManagementReportList,
	MSTeamsUserList,
	ServiceList
}: {
	ServiceManagementReportList: ServiceManagementReport[]
	MSTeamsUserList: MSTeamsUser[]
	ServiceList: Service[]
}) => {
	const [selectedTeamsServiceID, setSelectedTeamsServiceID] = useState('')
	const [customerHasMultipleTeamsService, setCustomerHasMultipleTeamsService] =
		useState(false)
	const [
		teamsDRServiceValueDisplayOption,
		setTeamsDRServiceValueDisplayOption,
	] = useState(TeamsDRServiceValueDisplayOptions.PartnerServiceID)

	const [enabledTenantUsersCount, setEnabledTenantUsersCount] = useState(0)
	const [haloUsersCount, setHaloUsersCount] = useState(0)
	const [haloCallQueuesCount, setHaloCallQueuesCount] = useState(0)
	const [haloAutoAttendantsCount, setAutoAttendantsCount] = useState(0)
	const [haloTotalEndpointsCount, setHaloTotalEndpointsCount] = useState(0)
	const [selectedService, setSelectedService] = useState('')
	const [selectedServiceHasSMRecords, setSelectedServiceHasSMRecords] =
		useState(false)

	useEffect(() => {
		if (MSTeamsUserList.length > 1) {
			setCustomerHasMultipleTeamsService(true)
			// Determine which value to show the customer to identify their different Teams services.
			// PartnerServiceID is the first preference, then Domain and lastly, the teams service ID.
			var _usePartnerServiceID = true
			var _useDomain = true

			MSTeamsUserList.forEach((x) => {
				if (
					!x?.PartnerServiceID ||
					(x?.PartnerServiceID + '').trim().length === 0
				) {
					_usePartnerServiceID = false
				}

				if (!x?.DomainName || (x?.DomainName + '').trim().length === 0) {
					_useDomain = false
				}
			})

			setTeamsDRServiceValueDisplayOption(
				TeamsDRServiceValueDisplayOptions.PartnerServiceID
			)

			if (!_usePartnerServiceID) {
				if (_useDomain) {
					setTeamsDRServiceValueDisplayOption(
						TeamsDRServiceValueDisplayOptions.DomainName
					)
				} else {
					setTeamsDRServiceValueDisplayOption(
						TeamsDRServiceValueDisplayOptions.ServiceID
					)
				}
			}
		} else {
			setCustomerHasMultipleTeamsService(false)
			setServiceManagementRecordValuesForTeamsService(
				MSTeamsUserList[0]?.ServiceID + ''
			)
		}
	}, [MSTeamsUserList, ServiceManagementReportList])

	const confirmMSTeamsService = async (msTeamsServiceID: string) => {
		var msServiceToUse = MSTeamsUserList.find(
			(x) => x.ServiceID === msTeamsServiceID
		)

		if (msServiceToUse) {
			setSelectedTeamsServiceID(msServiceToUse?.ServiceID + '')

			switch (teamsDRServiceValueDisplayOption) {
				case TeamsDRServiceValueDisplayOptions.PartnerServiceID:
					setSelectedService(msServiceToUse?.PartnerServiceID + '')
					break
				case TeamsDRServiceValueDisplayOptions.DomainName:
					setSelectedService(msServiceToUse?.DomainName + '')
					break
				default:
					setSelectedService(msServiceToUse?.ServiceID + '')
					break
			}
		}
		setServiceManagementRecordValuesForTeamsService(
			msServiceToUse?.ServiceID + ''
		)
	}

	function setServiceManagementRecordValuesForTeamsService(
		teamsServiceID: string
	) {
		setEnabledTenantUsersCount(0)
		setHaloUsersCount(0)
		setHaloCallQueuesCount(0)
		setAutoAttendantsCount(0)
		setHaloTotalEndpointsCount(0)

		var SMReportToFind = ServiceManagementReportList.find(
			(x) =>
				x.ServiceID?.toLowerCase().trim() ===
				teamsServiceID.toLowerCase().trim()
		)

		if (SMReportToFind) {
			if (Number(SMReportToFind?.TenantEnabledUserCount) > 0) {
				setEnabledTenantUsersCount(
					Number(SMReportToFind?.TenantEnabledUserCount)
				)
			}

			var total = 0

			if (Number(SMReportToFind?.HALODRUsers) > 0) {
				total += Number(SMReportToFind?.HALODRUsers)
				setHaloUsersCount(Number(SMReportToFind?.HALODRUsers))
			}

			if (Number(SMReportToFind?.TenantCallQueueCount) > 0) {
				total += Number(SMReportToFind?.TenantCallQueueCount)
				setHaloUsersCount(Number(SMReportToFind?.TenantCallQueueCount))
			}

			if (Number(SMReportToFind?.HALODRAutoAttendants) > 0) {
				total += Number(SMReportToFind?.HALODRAutoAttendants)
				setHaloUsersCount(Number(SMReportToFind?.HALODRAutoAttendants))
			}

			setHaloTotalEndpointsCount(total)
			setSelectedServiceHasSMRecords(true)
		} else {
			setSelectedServiceHasSMRecords(false)
		}
	}

	const resetTeamsServiceSelection = () => {
		setSelectedTeamsServiceID('')
		setSelectedService('')
	}

	return (
		<>
			<Box className='card-container'>
				<Box
					className='card-header flex-box'
					bgcolor='background.superLight'
					borderColor='border.primary'>
					<img
						src={require(`../../../../assets/icons/card/servManagerIcon.png`)}
						alt={'Service manager icon'}
					/>
					<Typography
						component='h2'
						color='text.primary'
						className='card-text dt'>
						Service Configuration
					</Typography>
				</Box>

				{customerHasMultipleTeamsService &&
				selectedTeamsServiceID.length === 0 ? (
					<Box
						className='card-content flex-box'
						sx={{ border: 2, borderColor: 'border.primary' }}>
						<Typography component='h5'>
							Select a Teams Service to proceed with:
						</Typography>
						{/* Form */}
						<TeamsServiceSelection
							confirmSelection={confirmMSTeamsService}
							teamsServiceList={MSTeamsUserList}
							serviceList={ServiceList}
							teamsDRServiceValueDisplayOption={
								teamsDRServiceValueDisplayOption
							}
						/>
					</Box>
				) : (
					<Box
						className='card-content flex-box'
						border={2}
						borderColor='border.primary'
						bgcolor='card.default'>
						{customerHasMultipleTeamsService && (
							<Box>
								<Tooltip title='Select another MS Teams Service'>
									<Button
										sx={{
											textTransform: 'none',
											marginBottom: '10px',
											padding: '5px',
										}}
										startIcon={
											<ChangeCircleIcon
												sx={{
													color: 'text.primary',
												}}
											/>
										}
										onClick={() => {
											resetTeamsServiceSelection()
										}}>
										{`MS Teams Service: ${selectedService}`}
									</Button>
								</Tooltip>
							</Box>
						)}

						{selectedServiceHasSMRecords ? (
							<Grid
								className='grid-content'
								container
								rowSpacing={4}
								columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
								{/* Column 1 */}

								<Grid item xs={1} sx={{ marginRight: '30px' }}>
									<Typography component='h6' className='grid-top-row'>
										MS Teams
									</Typography>
									<img
										style={{
											maxWidth: '100%',
											margin: '0 auto',
										}}
										src={require(`../../../../assets/icons/card/teamsDR/teamsLogo.png`)}
										alt={'Service manager icon'}
										className='grid-bottom-row'
									/>
								</Grid>

								{/* Column 2 */}
								<Grid item xs={1} sx={{ marginRight: '60px' }}>
									<Typography component='h6' className='grid-top-row'>
										Enabled Tenant Users
									</Typography>
									<Typography component='h5' className='grid-bottom-row'>
										{enabledTenantUsersCount}
									</Typography>
								</Grid>

								{/* Column 3 */}
								<Grid item xs={1}>
									<Typography component='h6' className='grid-top-row'>
										HALO Users
									</Typography>
									<Typography component='h2' className='grid-bottom-row'>
										{haloUsersCount}
									</Typography>
								</Grid>

								<Grid item xs={0.5} sx={{ marginTop: '50px' }}>
									<Typography component='h3' className='grid-bottom-row'>
										+
									</Typography>
								</Grid>

								{/* Column 4 */}
								<Grid item xs={1}>
									<Typography component='h6' className='grid-top-row'>
										HALO Call Queues
									</Typography>
									<Typography component='h2' className='grid-bottom-row'>
										{haloCallQueuesCount}
									</Typography>
								</Grid>

								<Grid item xs={0.5} sx={{ marginTop: '50px' }}>
									<Typography component='h3' className='grid-bottom-row'>
										+
									</Typography>
								</Grid>

								{/* Column 5 */}
								<Grid item xs={1}>
									<Typography component='h6' className='grid-top-row'>
										HALO Auto Attendants
									</Typography>
									<Typography component='h2' className='grid-bottom-row'>
										{haloAutoAttendantsCount}
									</Typography>
								</Grid>

								<Grid item xs={0.5} sx={{ marginTop: '50px' }}>
									<Typography component='h3' className='grid-bottom-row'>
										=
									</Typography>
								</Grid>

								{/* Column 6 */}
								<Grid item xs={1}>
									<Typography component='h6' className='grid-top-row'>
										HALO Total Endpoints
									</Typography>
									<Typography component='h2' className='grid-bottom-row'>
										{haloTotalEndpointsCount}
									</Typography>
								</Grid>
							</Grid>
						) : (
							<Grid
								className='grid-content'
								container
								rowSpacing={4}
								columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
								<Grid item xs={1} sx={{ marginRight: '30px' }}>
									<Typography component='h6' className='grid-top-row'>
										MS Teams
									</Typography>
									<img
										style={{
											maxWidth: '100%',
											margin: '0 auto',
										}}
										src={require(`../../../../assets/icons/card/teamsDR/teamsLogo.png`)}
										alt={'Service manager icon'}
										className='grid-bottom-row'
									/>
								</Grid>

								<Grid item xs={1} sx={{ marginRight: '60px' }}>
									<Typography component='h6' className='grid-top-row'>
										Service Configuration
									</Typography>
									<Box sx={{ marginTop: '20px' }}>
										<img
											src={require('../../../../assets/images/dashboard/lumen-service-config-failure-img.png')}
											alt='lumen-teams-service-configuration-failure-img'
										/>
									</Box>
								</Grid>
							</Grid>
						)}
					</Box>
				)}
			</Box>
		</>
	)
}

export default OverviewDisplay
