import { Box, Typography } from '@mui/material'
import PlatformLoginAndAccessControl from './Tables/PlatformLoginAndAccessControl/PlatformLoginAndAccessControl'
import PlatformLoginRights from './Tables/PlatformLoginRights/PlatformLoginRights'
import TenantConfigurationChanges from './Tables/TenantConfigurationChanges/TenantConfigurationChanges'
import TenantConfigurationRights from './Tables/TenantConfigurationRights/TenantConfigurationRights'
import ServiceManagement from './Tables/ServiceManagement/ServiceManagement'
import ServiceManagementRights from './Tables/ServiceManagementRights/ServiceManagementRights'
import TeamsDirectRouting from './Tables/TeamsDirectRouting/TeamsDirectRouting'

const TenantConfiguration = () => {
	return (
		<>
			<Box className='documentation-info-container'>
				{/* Main Heading */}
				<Typography component='h3'>
					Teams Direct Routing - Tenant Configuration
				</Typography>
				{/* Content heading */}
				<Typography component='h4'>Document Definition:</Typography>
				{/* Content */}
				<Typography component='p'>
					This document describes the High-Level architecture and implementation
					provided by the HALO platform in a customer's Microsoft Tenant. This
					implementation facilitates the requirements for Microsoft Teams Direct
					Routing and Service Management using the HALO platform.
				</Typography>
				{/* Content heading */}
				<Typography component='h4'>Tenant Configuration Overview:</Typography>
				{/* Image */}
				<img
					src={require(`../../../../../assets/images/documentation/TeamsOverview/TeamsOverviewDiagramFinal.png`)}
					alt='Diagram about Tenant Configuration'
				/>
				{/* Content heading */}
				<Typography component='h4'>High Level HALO Service Details:</Typography>
				{/* Content */}
				<Typography component='p'>
					The below sections detail out the specific elements implemented in the
					customer's Tenant and the standards they were implemented to:
				</Typography>
				{/* Sub-heading */}
				<Typography component='h5'>Enterprise Applications:</Typography>
				{/* Content */}
				<Typography component='p'>
					Enterprise applications have two access types:
				</Typography>
				{/* List */}
				<Typography component='ul'>
					<Typography component='li'>
						<strong>Delegated:</strong> The customer's global admin must log in
						to allow the application the rights to perform the actions. Actions
						can only happen during the customer's login.
					</Typography>
					<Typography component='li'>
						<strong>Application:</strong> The global admin approves application
						level rights to the customer's Tenant. From this point on the
						application can perform these approved rights whenever required.
					</Typography>
				</Typography>
				{/* Content */}
				<Typography component='p'>
					The HALO Platform uses three different Enterprise Applications to
					control permissions used while performing different actions in the
					platform.
				</Typography>
				{/* Sub-heading */}
				<Typography component='h5'>
					Enterprise Application 1 - Platform Login and access control:
				</Typography>
				{/* Content */}
				<Typography component='p'>
					ucplatform.io uses your own Microsoft Identity to login to the
					platform, the below Enterprise Applications are used to control this
					access:
				</Typography>
				{/* Table */}
				<PlatformLoginAndAccessControl />
				{/* Content */}
				<Typography component='p'>
					<i>Rights:</i>
				</Typography>
				{/* Table */}
				<PlatformLoginRights />
				{/* Sub-heading */}
				<Typography component='h5'>
					Enterprise Application 2 - Tenant Configuration Changes:
				</Typography>
				{/* Content */}
				<Typography component='p'>
					HALO performs all changes in your Tenant using a separate Enterprise
					Application. You are asked to perform a login to this Enterprise
					Application only when these rights are required. The actions performed
					in your Tenant, then utilise the account details you have just logged
					in with, that in most cases require a Global Administration Role.
				</Typography>
				{/* Table */}
				<TenantConfigurationChanges />
				{/* Content */}
				<Typography component='p'>
					<i>Rights:</i>
				</Typography>
				{/* Table */}
				<TenantConfigurationRights />
				{/* Sub-heading */}
				<Typography component='h5'>
					Enterprise Application 3 - Service Management:
				</Typography>
				{/* Content */}
				<Typography component='p'>
					HALO performs continuous checks on your Microsoft Tenant, against the
					Service Profile we have for your Service in our Systems. If your
					Service Profile changes (Add numbers in a new site or region globally)
					or something is removed by mistake in your Tenancy, then Service
					Manager will detect the misconfiguration and inform your technical
					contacts to perform a repair / update in your Microsoft Tenancy using
					ucplatform. The repairs will be fully automated and use Enterprise
					Application 2 listed above and its rights. The Service Manager
					Enterprise Application is only used to Read and will never make
					changes in your Microsoft Tenant
				</Typography>
				{/* Table */}
				<ServiceManagement />
				{/* Content */}
				<Typography component='p'>
					<i>Rights:</i>
				</Typography>
				{/* Table */}
				<ServiceManagementRights />
				{/* Content heading */}
				<Typography component='h4'>Teams Direct Routing:</Typography>
				{/* Content */}
				<Typography component='p'>
					During the Teams Voice configuration process, various elements are
					created in your Microsoft Tenant. HALO automatically picks up what
					your Service Profile is and Configures your Tenant in line with this.
					This will include National and International Call Policies in every
					country you have Telephone Numbers active for this Teams Direct
					Routing Service.
				</Typography>
				{/* Content */}
				<Typography component='p'>
					Below is a Service Elements table that breaks down the standards in
					which we configure settings in your Microsoft Tenant. An LLD will be
					automatically provided to you at the end of the configuration
					processes, that will have Tables detailing out all the exact names of
					the Elements configured in your Microsoft Tenant.
				</Typography>
				{/* Content */}
				<Typography component='p'>
					<i>Service Elements:</i>
				</Typography>
				{/* Table */}
				<TeamsDirectRouting />
				{/* Content heading */}
				<Typography component='h4'>Tenant Configuration process:</Typography>
				{/* Content */}
				<Typography component='p'>
					When following the automated provisioning process for a customer's
					Tenant, a number of actions are performed by the HALO provisioning
					platform. These actions are detailed below:
				</Typography>
				{/* List */}
				<Typography component='ol'>
					{/* Main List Item */}
					<Typography component='li'>
						<strong>
							Approval to implement Teams Direct Routing in the Customers Tenant
						</strong>
						{/* Sub-list */}
						<Typography component='ul'>
							<Typography component='li'>
								Global admin approved the access to the customers Tenant, to
								complete the Teams Direct Routing provisioning process.
							</Typography>
							<Typography component='li'>
								An Enterprise Application is added to the Tenant for
								authentication and access control via permissions embedded in
								the application.
							</Typography>
						</Typography>
					</Typography>
					{/* Main List Item */}
					<Typography component='li'>
						<strong>
							Custom domains added to the customer's Tenant based on PSTN
							Gateway requirements
						</strong>
						{/* Sub-list */}
						<Typography component='ul'>
							<Typography component='li'>
								Based on the countries in the customer's order, two domains are
								configured in each region for high availability purposes. As an
								example, Iif the customer has UK and US presence, then four
								domains will be configured, two for EMEA to provide service to
								the UK and two for AMER to provide services in the US.
							</Typography>
						</Typography>
					</Typography>
					{/* Main List Item */}
					<Typography component='li'>
						<strong>
							Customer selects which licenses are used in their Tenant to
							activate the Custom Domains used as PSTN Gateways.
						</strong>
						{/* Sub-list */}
						<Typography component='ul'>
							<Typography component='li'>
								These choices are made in the HALO provisioning portal, during
								the provisioning process
							</Typography>
						</Typography>
					</Typography>
					{/* Main List Item */}
					<Typography component='li'>
						<strong>
							Domain Users added and licensed to each Custom Domain initializing
							the Microsoft services including PSTN Gateways.
						</strong>
						{/* Sub-list */}
						<Typography component='ul'>
							<Typography component='li'>
								For a Domain to be live and used for Teams Direct Routing, it
								must have an active user and that user must have a valid
								license. As soon as the license is added, the Tenant begins
								automatic configuration of Microsoft Services. This allows the
								domain to be used as a valid PSTN Gateway
							</Typography>
						</Typography>
					</Typography>
					{/* Main List Item */}
					<Typography component='li'>
						<strong>Microsoft Teams is configured for Direct Routing</strong>
						{/* Sub-list */}
						<Typography component='ul'>
							<Typography component='li'>
								Global PSTN Usage updated to add Usage locations for all the
								customer's countries in the order, for domestic dialling,
								international dialling (if selected) and for the default
								emergency dialling policy per country
							</Typography>
							<Typography component='li'>
								Voice Routing policies are configured for each country in the
								order that link to the specific PSTN Usage Location, both
								National and International
							</Typography>
							<Typography component='li'>
								Voice Routes added for each country linking the polices
								togethers and adding the specific dial codes for that country.
								For example, if the customer is in the US the US voice route
								will link any calls for +1 to the AMER PSTN gateways
							</Typography>
						</Typography>
					</Typography>
					{/* Main List Item */}
					<Typography component='li'>
						<strong>
							Emergency Dialling Policies configured based on the countries in
							the customer's order
						</strong>
						{/* Sub-list */}
						<Typography component='ul'>
							<Typography component='li'>
								Country specific emergency dial policies added, linked to
								country specific PSTN gateways.
							</Typography>
							<Typography component='li'>
								Global Emergency policies updated to link country specific
								emergency dial codes to Country specific emergency policies. For
								example in the US 911 is linked directly to the AMER PSTN
								gateways.
							</Typography>
						</Typography>
					</Typography>
					{/* Main List Item */}
					<Typography component='li'>
						<strong>Configure HALO Service Manager</strong>
						{/* Sub-list */}
						<Typography component='ul'>
							<Typography component='li'>
								Enterprise Application added to the domain with basic rights to
								monitor the service configuration and pull back user information
								for User management inside the platform
							</Typography>
							<Typography component='li'>
								The Application must be approved by an Global Administrator to
								be Added
							</Typography>
							<Typography component='li'>
								Service Manager then performs initial health checks to confirm
								your configuration is correct
							</Typography>
						</Typography>
					</Typography>
				</Typography>
				{/* Content */}
				<Typography component='p'>
					After the above actions are completed, the Tenant is ready for Direct
					Routing with Service Management
				</Typography>
			</Box>
		</>
	)
}

export default TenantConfiguration
