import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { StyledTableCell } from '../StyledTableComponents'

const TenantConfigurationChanges = () => {
	// Display rows
	const tableDetailDisplay = [
		{
			Element: 'Name',
			Detail: 'EA-HALO-PlatformAutomation',
		},
		{
			Element: 'Role',
			Detail:
				'All required Tenant changes including base Teams Voice configuration, User Management changes (Telephone Number Assignment and License Assignment)',
		},
		{
			Element: 'Access Type',
			Detail: 'Delegated',
		},
	]

	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					{/* Header */}
					<TableHead>
						<TableRow>
							<StyledTableCell>Element</StyledTableCell>
							<StyledTableCell>Details</StyledTableCell>
						</TableRow>
					</TableHead>
					{/* Body */}
					<TableBody>
						{tableDetailDisplay.map((row, index) => (
							<TableRow key={index}>
								<StyledTableCell>{row.Element}</StyledTableCell>
								<StyledTableCell>{row.Detail}</StyledTableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}

export default TenantConfigurationChanges
