import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './FAQs.scss'
import { FAQDisplay } from '../../../../utils/interfaces/ComponentModels'
import { FAQTypes } from '../../../../utils/enums/enums'
import SDAFAQAnswer1 from './SDAFAQs/Answer1'
import SDAFAQAnswer2 from './SDAFAQs/Answer2'
import SDAFAQAnswer3 from './SDAFAQs/Answer3'
import SDAFAQAnswer4 from './SDAFAQs/Answer4'
import SDAFAQAnswer5 from './SDAFAQs/Answer5'
import ServiceManagerAnswer1 from './ServiceManagerFAQs/Answer1'
import ServiceManagerAnswer2 from './ServiceManagerFAQs/Answer2'
import TeamsUserManagementAnswer1 from './TeamsUserManagementFAQs/Answer1'
import TeamsUserManagementAnswer2 from './TeamsUserManagementFAQs/Answer2'
import TeamsUserManagementAnswer3 from './TeamsUserManagementFAQs/Answer3'
import TeamsUserManagementAnswer4 from './TeamsUserManagementFAQs/Answer4'

const FAQs = () => {
	// Build an array of possible FAQs
	const faqDisplay: FAQDisplay[] = [
		{
			FAQType: FAQTypes['Teams Direct Routing'],
			Question: 'How do I check if I have global admin rights?',
			Answer: <SDAFAQAnswer1 />,
		},
		{
			FAQType: FAQTypes['Teams Direct Routing'],
			Question:
				'What are the licenses used for in the Base Provisioning Process?',
			Answer: <SDAFAQAnswer2 />,
		},
		{
			FAQType: FAQTypes['Teams Direct Routing'],
			Question: 'How many licenses does the service consume?',
			Answer: <SDAFAQAnswer3 />,
		},
		{
			FAQType: FAQTypes['Teams Direct Routing'],
			Question: 'Can the users you created in my Tenant be removed?',
			Answer: <SDAFAQAnswer4 />,
		},
		{
			FAQType: FAQTypes['Teams Direct Routing'],
			Question: 'Can I reuse the licenses from the Base Provisioning Process?',
			Answer: <SDAFAQAnswer5 />,
		},
		{
			FAQType: FAQTypes['Service Manager'],
			Question: 'Will service manager ever change anything in my Tenant?',
			Answer: <ServiceManagerAnswer1 />,
		},
		{
			FAQType: FAQTypes['Service Manager'],
			Question: 'Can I remove the Service Manager Enterprise App?',
			Answer: <ServiceManagerAnswer2 />,
		},
		{
			FAQType: FAQTypes['Teams User Management'],
			Question: 'What rights do I need to perform actions in User Management?',
			Answer: <TeamsUserManagementAnswer1 />,
		},
		{
			FAQType: FAQTypes['Teams User Management'],
			Question: 'Can I add users to my tenant in user management?',
			Answer: <TeamsUserManagementAnswer2 />,
		},
		{
			FAQType: FAQTypes['Teams User Management'],
			Question:
				'Why can I only see some of my phone numbers in user management?',
			Answer: <TeamsUserManagementAnswer3 />,
		},
		{
			FAQType: FAQTypes['Teams User Management'],
			Question:
				'If I replace an old telephone number with a new one, can I go back to the original?',
			Answer: <TeamsUserManagementAnswer4 />,
		},
	]

	// Create my FAQ components for each category
	const faqs = (faqType: number) => (
		<Box className='faq-content'>
			{/* Heading */}
			<Typography color='primary.main' component='h2'>
				{FAQTypes[faqType]}
			</Typography>
			{/* Accordians for the FAQs */}
			{faqDisplay.map(
				(item, index) =>
					item.FAQType === faqType && (
						<Accordion
							key={index}
							sx={{
								border: 1,
								borderColor: 'primary.main',
							}}>
							<AccordionSummary
								className='faq-title'
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel1a-content'>
								{item.Question}
							</AccordionSummary>
							<AccordionDetails>{item.Answer}</AccordionDetails>
						</Accordion>
					)
			)}
		</Box>
	)

	return (
		<>
			<Box className='faq-container'>
				{/* SDA FAQs */}
				{faqs(FAQTypes['Teams Direct Routing'])}
				{/* Service Manager FAQs */}
				{faqs(FAQTypes['Service Manager'])}
				{/* Teams User Management FAQs */}
				{faqs(FAQTypes['Teams User Management'])}
			</Box>
		</>
	)
}

export default FAQs
