import { GridColDef, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../../shared/datagrid/StyledDataGrid'
import { Box, Button, Typography } from '@mui/material'
import { Invitation } from '../../../../../utils/interfaces/APIModels'
import { InvitationListDisplay } from '../../../../../utils/interfaces/ComponentModels'
import LoadingCard from '../../../../shared/loading/loadingCard/LoadingCard'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { Roles } from '../../../../../utils/enums/enums'

function PendingInvites({
	invitationList,
	handleSendInvite,
}: {
	invitationList: Invitation[]
	handleSendInvite: (email: string, customerID: string, role: string) => void
}) {
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 5,
		page: 0,
	})
	const [sendingInvite, setSendingInvite] = useState(false)
	const customerRoleID = process.env.REACT_APP_CUSTOMER_ADMIN_ROLE_ID
	const partnerRoleID = process.env.REACT_APP_PARTNER_ADMIN_ROLE_ID
	const [invitationDisplayList, setInvitationDisplayList] = useState(
		[] as InvitationListDisplay[]
	)

	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	const loggedInUserRole = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)

	const isPartnerAdmin = loggedInUserRole.find(
		(role) => Number(role.RoleID) === Roles.PartnerAdmin
	)
	const isCustomerAdmin = loggedInUserRole.find(
		(role) => Number(role.RoleID) === Roles.CustomerAdmin
	)

	const pendingStatus = 'Pending'

	useEffect(() => {
		if (invitationList.length > 0) {
			var _invitationDisplayList = [] as InvitationListDisplay[]
			var _invitationList = invitationList
			if (isCustomerAdmin) {
				_invitationList = invitationList.filter(
					(i) => i.customerID === loggedInUser.customerID
				)
			}
			_invitationList.forEach((i) => {
				var _email = i.invitedEmail
				var _inviter = i.inviterEmail
				var _invitationDisplayListObj: InvitationListDisplay = {
					InvitationEmail: _email,
					InvitationInviter: _inviter,
					InvitationStatus: i.status,
					InvitationRole:
						i.role === partnerRoleID
							? 'Partner'
							: i.role === customerRoleID
							? 'Customer'
							: 'User has no role',
				}

				if (isPartnerAdmin || (i.role === customerRoleID && isCustomerAdmin)) {
					_invitationDisplayList.push(_invitationDisplayListObj)
				}
			})

			setInvitationDisplayList(_invitationDisplayList)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invitationList])

	const invitationsColoumns: GridColDef[] = [
		{
			field: 'InvitationEmail',
			headerName: 'Email',
			width: 300,
			hideable: false,
		},
		{
			field: 'InvitationStatus',
			headerName: 'Status',
			width: 100,
			hideable: false,
			renderCell: (params) => {
				var pending = params.row.InvitationStatus === pendingStatus
				return (
					<Typography
						sx={{
							color: pending ? 'green' : 'red',
						}}>
						{params.row.InvitationStatus}
					</Typography>
				)
			},
		},
		{
			field: 'InvitationRole',
			headerName: 'Admin',
			width: 150,
			hideable: true,
		},

		{
			field: 'InvitationInviter',
			headerName: 'Inviter',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'actions',
			type: 'actions',
			cellClassName: 'actions',
			headerName: 'Resend Invite',
			width: 220,
			editable: false,
			align: 'center',
			hideable: false,
			flex: 1,
			getActions: ({ id }) => {
				return [
					<>
						<Button
							onClick={async () => {
								var email = id.toString()
								var invite = invitationList.find(
									(i) => i.invitedEmail === email
								)
								setSendingInvite(true)
								await handleSendInvite(
									invite?.invitedEmail + '',
									invite?.customerID + '',
									invite?.role + ''
								)
								setSendingInvite(false)
							}}
							variant='contained'>
							resend Invite
						</Button>
					</>,
				]
			},
		},
	]

	return (
		<>
			{invitationList.length > 0 ? (
				<Box
					sx={{
						backgroundColor: 'white',
						display: 'flex',
						flexDirection: 'column',
						rowGap: '20px',
					}}
					id='list'>
					<Typography component='h1' sx={{ fontSize: '20px' }}>
						List of Pending Invites
					</Typography>
					<StyledDataGrid
						loading={sendingInvite}
						rows={invitationDisplayList}
						columns={invitationsColoumns}
						editMode='row'
						pagination
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						pageSizeOptions={[10]}
						slots={{
							pagination: CustomPagination,
							toolbar: GridToolbar,
						}}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
								quickFilterProps: { debounceMs: 500 },
							},
						}}
						getRowId={(row) => row.InvitationEmail}
						autoHeight
						rowSelection
					/>
				</Box>
			) : (
				<>
					<LoadingCard
						loadingCardDescription='Retrieving pending invites'
						loadingCardTitle='Getting Invites'
					/>
				</>
			)}
		</>
	)
}

export default PendingInvites
