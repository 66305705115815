import PostAndRetrieveDataHook from '../../../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import {
	Customer,
	Order,
	OrderTerm,
} from '../../../../../utils/interfaces/DBModels'
import { CustomerOrderDisplay } from '../../../../../utils/interfaces/ComponentModels'
import {
	FilteredOrderStatus,
	OrderStatuses,
	OrderType,
} from '../../../../../utils/enums/enums'
import { useEffect, useState } from 'react'
import LoadingCard from '../../../../shared/loading/loadingCard/LoadingCard'
import { GridColDef, GridToolbar } from '@mui/x-data-grid-pro'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../../shared/datagrid/StyledDataGrid'
import OrderStatusCardDisplay from '../../OrderManagement/OrderStatusCardDisplay/OrderStatusCardDisplay'

const CustomerOrderOverview = ({ customerID }: { customerID: string }) => {
	// Hooks
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()

	// Display constants
	const [customerOrderDisplay, setCustomerOrderDisplay] = useState(
		[] as CustomerOrderDisplay[]
	)

	// Flags
	const [orderCallMade, setOrderCallMade] = useState(false)
	const [orderCallDone, setOrderCallDone] = useState(false)

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0,
	})

	// Column Definition: Orders
	const customerOrderColumns: GridColDef[] = [
		{
			field: 'ExternalOrderID',
			headerName: 'Order ID',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'BizOrg',
			headerName: 'BizOrg',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'OrderStatus',
			headerName: 'Order Status',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'OrderTerm',
			headerName: 'Order Term',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'OrderType',
			headerName: 'Order Type',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'PurchaseOrder',
			headerName: 'Purchase Order',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'PartnerOrderID',
			headerName: 'Partner Order ID',
			width: 250,
			hideable: false,
			flex: 1,
		},
	]

	useEffect(() => {
		// Make initial call
		if (!orderCallMade) {
			setOrderCallMade(true)
			getOrdersForCustomer()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// GET: Retrieve orders for customer
	const getOrdersForCustomer = async () => {
		// Make call - Filter by Orders containing 'ORD' to filter out the DDI orders
		var dataResponse = await postAndRetrieveDataFromDB(
			'Orders for Customer',
			`Order.OrderTerm.Where(Order.CustomerID = '${customerID}' & Order.ExternalOrderID ~ 'ORD'),Customer.First(Customer.CustomerID = '${customerID}')`,
			false,
			false
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// List to build
			var orderDisplay = [] as CustomerOrderDisplay[]

			// Assign
			var orderResponse = dataResponse.Obj.OrderList as Order[]
			var customerResponse = dataResponse.Obj.Customer as Customer
			if (orderResponse && orderResponse.length > 0) {
				// Loop through response and build display
				for (let o = 0; o < orderResponse.length; o++) {
					// Order term
					var orderTermList = orderResponse[o].OrderTermList as OrderTerm[]
					var orderTerm = ''
					if (orderTermList && orderTermList[0]) {
						// 1 - 1 relationship
						orderTerm = getOrderTerm(orderTermList[0])
					}

					// Push
					orderDisplay.push({
						OrderID: orderResponse[o].OrderID,
						ExternalOrderID: orderResponse[o].ExternalOrderID,
						OrderStatus: getLabelFromStatus(
							orderResponse[o].OrderStatusID + ''
						),
						BizOrg: customerResponse.CustomerPartnerReference,
						OrderTerm: orderTerm,
						OrderType: OrderType[Number(orderResponse[o].OrderTypeID)],
						PartnerOrderID: orderResponse[o].PartnerOrderID,
						PurchaseOrder: orderResponse[o].PurchaseOrder,
					})
				}
			}

			// Set list
			setCustomerOrderDisplay(orderDisplay)
		}

		setOrderCallDone(true)
	}

	// Function to get the human-readable label from an enum value
	const getLabelFromStatus = (status: string): string => {
		return statusLabels[status] || 'Unknown Status'
	}

	// Mapping from enum values to human-readable labels
	const statusLabels: Record<string, string> = {
		[OrderStatuses.AwaitingPartnerApproval]: 'Awaiting Partner Approval',
		[OrderStatuses.PartnerApproved]: 'Partner Approved',
		[OrderStatuses.AwaitingSalesApproval]: 'Awaiting Sales Approval',
		[OrderStatuses.SalesApproved]: 'Sales Approved',
		[OrderStatuses.AwaitingFinanceApproval]: 'Awaiting Finance Approval',
		[OrderStatuses.FinanceApproved]: 'Finance Approved',
		[OrderStatuses.SystemApproved]: 'System Approved',
		[OrderStatuses.InProgress]: 'In Progress',
		[OrderStatuses.Complete]: 'Complete',
		[OrderStatuses.Rejected]: 'Rejected',
	}

	const getOrderTerm = (orderTerm: OrderTerm): string => {
		// Assign
		var years = 0
		var months = 0
		if (orderTerm.Years) {
			years = orderTerm.Years
		}
		if (orderTerm.Months) {
			months = orderTerm.Months
		}

		var results = ''
		var YearsString = ''
		var MonthString = ''

		// Generate term string
		if (years === 1) {
			YearsString = years + ' Year'
		} else if (years > 1) {
			YearsString = years + ' Years'
		}
		if (months === 1) {
			MonthString = months + ' Month'
		} else if (months > 1) {
			MonthString = months + ' Months'
		}
		results = YearsString + ' ' + MonthString

		return results
	}

	return orderCallDone ? (
		<>
			{customerOrderDisplay.length > 0 ? (
				<StyledDataGrid
					rows={customerOrderDisplay}
					columns={customerOrderColumns}
					editMode='row'
					checkboxSelection={false}
					pagination
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					pageSizeOptions={[10]}
					slots={{
						pagination: CustomPagination,
						toolbar: GridToolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.OrderID}
					autoHeight
					rowSelection={false}
				/>
			) : (
				<OrderStatusCardDisplay status={FilteredOrderStatus.NoRecords} />
			)}
		</>
	) : (
		<LoadingCard
			loadingCardTitle='Loading your order information'
			loadingCardDescription='Please wait as we receive your order information'
		/>
	)
}

export default CustomerOrderOverview
