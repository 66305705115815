import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { StyledTableCell } from '../StyledTableComponents'

const TenantConfigurationRights = () => {
	// Display rows
	const tableDetailDisplay = [
		{
			Name: 'offline_access',
			Platform: 'Microsoft Graph',
			Type: 'Delegated',
			Permission: 'Maintain access to data you have given it access to',
		},
		{
			Name: 'openid',
			Platform: 'Microsoft Graph',
			Type: 'Delegated',
			Permission: 'Sign users in',
		},
		{
			Name: 'profile',
			Platform: 'Microsoft Graph',
			Type: 'Delegated',
			Permission: `View users' basic profile`,
		},
		{
			Name: 'User.Read',
			Platform: 'Microsoft Graph',
			Type: 'Delegated',
			Permission: `Sign in and read user profile`,
		},
		{
			Name: 'User.Read.All',
			Platform: 'Microsoft Graph',
			Type: 'Delegated',
			Permission: `Read all users' full profiles`,
		},
		{
			Name: 'User.ReadWrite.All',
			Platform: 'Microsoft Graph',
			Type: 'Delegated',
			Permission: `Read and write all users' full profiles`,
		},
		{
			Name: 'Domain.ReadWrite.All',
			Platform: 'Microsoft Graph',
			Type: 'Delegated',
			Permission: `Read and write domains`,
		},
		{
			Name: 'Organization.Read.All',
			Platform: 'Microsoft Graph',
			Type: 'Delegated',
			Permission: `Read organization information`,
		},
		{
			Name: 'Organization.ReadWrite.All',
			Platform: 'Microsoft Graph',
			Type: 'Delegated',
			Permission: `Read and write organization information`,
		},
		{
			Name: 'Directory.Read.All',
			Platform: 'Microsoft Graph',
			Type: 'Delegated',
			Permission: `Read directory data`,
		},
		{
			Name: 'Directory.ReadWrite.All',
			Platform: 'Microsoft Graph',
			Type: 'Delegated',
			Permission: `Read and write directory data`,
		},
		{
			Name: 'RoleManagement.ReadWrite.Directory',
			Platform: 'Microsoft Graph',
			Type: 'Delegated',
			Permission: `Read and Write Directory RBAC Settings`,
		},
		{
			Name: 'user_impersonation',
			Platform: 'Microsoft Teams',
			Type: 'Delegated',
			Permission: `Access Microsoft Teams and Skype for Business data as the signed in user`,
		},
	]

	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					{/* Header */}
					<TableHead>
						<TableRow>
							<StyledTableCell>Name</StyledTableCell>
							<StyledTableCell>Platform</StyledTableCell>
							<StyledTableCell>Type</StyledTableCell>
							<StyledTableCell>Permission</StyledTableCell>
						</TableRow>
					</TableHead>
					{/* Body */}
					<TableBody>
						{tableDetailDisplay.map((row, index) => (
							<TableRow key={index}>
								<StyledTableCell>{row.Name}</StyledTableCell>
								<StyledTableCell>{row.Platform}</StyledTableCell>
								<StyledTableCell>{row.Type}</StyledTableCell>
								<StyledTableCell>{row.Permission}</StyledTableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}

export default TenantConfigurationRights
