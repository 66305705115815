import { Chip, styled } from '@mui/material'

export const StyledChip = styled(Chip)(({ theme }) => ({
  color: '#0075C9!important',
  backgroundColor: '#E0F3FF!important',
  borderColor: '#0075C9!important',
  fontSize: '16px',
  '&.Mui-disabled': {
    color: '#0075C9!important',
    opacity: '0.6',
  },
}))
