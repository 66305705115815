/* eslint-disable no-loop-func */
import { useEffect } from 'react'
import TUMLoading from '../../../components/distinct/management/TeamsUserManagement/TUMLoading/TUMLoading'
import { TUMBuildStatuses, TUMLoadTypes } from '../../../utils/enums/enums'
import { useTUMContext } from '../../../utils/context/TUMContext'
import TUMDisplay from '../../../components/distinct/management/TeamsUserManagement/TUMDisplay/TUMDisplay'

function TeamsUserManagement() {
	// Context
	const {
		initialGraphToken,
		loadingStatus,
		adminCallDone,
		precheckCallDone,
		retrievalLogicSetup,
		retrievalCallDone,
		validateAdminRole,
		doPrechecks,
		buildRetrievalConstants,
		retrieveTeamsUserMGTInfo,
	} = useTUMContext()

	// Use Effect 1: Build
	useEffect(() => {
		// Process - Confirmed Account needs to be filled in to start this entire process
		if (initialGraphToken.length > 0) {
			// Make calls
			if (
				checkCallStatus(TUMLoadTypes.Admin, TUMBuildStatuses.NotYetStarted) &&
				!adminCallDone
			) {
				validateAdminRole()
			} else if (
				checkCallStatus(TUMLoadTypes.Admin, TUMBuildStatuses.Success) &&
				checkCallStatus(
					TUMLoadTypes.Precheck,
					TUMBuildStatuses.NotYetStarted
				) &&
				!precheckCallDone
			) {
				doPrechecks()
			} else if (
				!retrievalLogicSetup &&
				checkCallStatus(TUMLoadTypes.Admin, TUMBuildStatuses.Success) &&
				checkCallStatus(TUMLoadTypes.Precheck, TUMBuildStatuses.Success) &&
				checkCallStatus(TUMLoadTypes.Retrieval, TUMBuildStatuses.NotYetStarted)
			) {
				buildRetrievalConstants()
			} else if (
				!retrievalCallDone &&
				checkCallStatus(TUMLoadTypes.Admin, TUMBuildStatuses.Success) &&
				checkCallStatus(TUMLoadTypes.Precheck, TUMBuildStatuses.Success) &&
				!checkCallStatus(TUMLoadTypes.Retrieval, TUMBuildStatuses.Failure)
			) {
				retrieveTeamsUserMGTInfo()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		initialGraphToken,
		adminCallDone,
		precheckCallDone,
		retrievalLogicSetup,
		retrievalCallDone,
	])

	// Check call status
	const checkCallStatus = (type: number, statusToCheck: number): boolean => {
		if (
			loadingStatus.find(
				(status) =>
					status.LoadStatusType === type && status.LoadStatus === statusToCheck
			)
		) {
			return true
		}
		return false
	}

	return loadingStatus.every(
		(status) => status.LoadStatus === TUMBuildStatuses.Success
	) ? (
		<TUMDisplay />
	) : (
		<TUMLoading />
	)
}

export default TeamsUserManagement
