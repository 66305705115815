import { Box, Typography } from '@mui/material'

const SDAFAQAnswer1 = () => {
	return (
		<>
			<Box className='documentation-answer-container'>
				{/* Question - How do I check if I have global admin rights?*/}
				<Typography component='ol'>
					<Typography component='li'>
						Sign in to the <a href='https://portal.azure.com/'>Azure portal</a>{' '}
						using a user account in your organization's
						<b> primary</b> tenant.
					</Typography>
					<Typography component='li'>
						Browse to <b>Azure Active Directory </b>
						<a href='https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/RolesAndAdministrators'>
							Roles and administrators.
						</a>
					</Typography>
					<Typography component='li'>
						<b>Select</b> the desired <b>admin</b> role.
					</Typography>
					<Typography component='li'>
						The list of users assigned to that role will be displayed.
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export default SDAFAQAnswer1
