import { Box, Typography } from '@mui/material'

const EmailFAQAnswer1 = () => {
	return (
		<>
			<Box className='documentation-answer-container'>
				{/* Question - What will happen when you accept this invite? */}
				<Typography component='ul'>
					<Typography component='li'>
						When you accept the invitation link below, you will get{' '}
						<b>access</b> to the <b>Lumen UC Platform.</b>
					</Typography>
					<Typography component='li'>
						The first requirement is to <b>add</b> an{' '}
						<b>Enterprise Application</b> to your Microsoft Tenant. This
						Application is used to <b>Authenticate</b> you to the platform using
						your Microsoft Username and Password.
					</Typography>
					<Typography component='li'>
						This application has only <b>one permission</b> to your Microsoft
						Tenant that allows it to read User information required for logon.
						This is used to <b>validate</b> your account.
					</Typography>
					<Typography component='li'>
						To add this Enterprise Application to your Microsoft Tenant, you may
						require a <b>Global Administrator</b> to <b>accept</b> and install
						it.
					</Typography>
					<Typography component='li'>
						You can use a <b>separate account</b> to perform the Application{' '}
						<b>installation</b> during the process and then <b>login</b> for the{' '}
						<b>first time</b> with your <b>standard account</b> to the Platform.
					</Typography>
					{/* insert img here */}
				</Typography>
				<Typography component='h4'>Steps:</Typography>
				<Typography component='ol'>
					<Typography component='li'>
						Press the <b>"Accept"</b> button in this email to start the process.
					</Typography>
					<Typography component='li'>
						<b>Sign in</b> at the Microsoft login prompt as a Global
						Administrator.
					</Typography>
					<Typography component='li'>
						Select the checkbox next to{' '}
						<b>"Consent on behalf of your organization"</b> to approve the
						install of the Enterprise Application, then press the{' '}
						<b>"Accept"</b> button. You will then be <b>redirected</b> to the
						portal.
					</Typography>
					<Typography component='li'>
						Press <b>login</b> and sign in with your <b>standard</b> user
						account.
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export default EmailFAQAnswer1
