import { Box, Button, Divider, MenuItem, Typography } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import {
	OrderFilter,
	SelectOrderFilters,
} from '../../../../../utils/interfaces/ComponentModels'
import { StyledSelect } from '../../../../shared/styledComponents/StyledSelect/StyledSelect'
import { StyledTextBox } from '../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import AddIcon from '@mui/icons-material/Add'
import './OrderFilters.scss'
import { LoadingButton } from '@mui/lab'
import { StyledChip } from '../../../../shared/styledComponents/StyledChip/StyledChip'
import {
	OrderFilterKeyMappings,
	OrderStatuses,
} from '../../../../../utils/enums/enums'

const OrderFilters = ({
	orderFilters,
	handleFilterCall,
}: {
	orderFilters: OrderFilter[]
	handleFilterCall: any
}) => {
	// Fields to filter by
	const orderFilterKeys = [
		'Order ID',
		'Order Status',
		'Partner Order ID',
		'Purchase Order',
		'BizOrg',
		'Customer ID',
		'Customer Name',
	]
	// Display constants
	const [orderFilterList, setOrderFilterList] = useState([] as OrderFilter[])
	const [orderFilterKey, setOrderFilterKey] = useState('')
	const [orderFilterValue, setOrderFilterValue] = useState('')
	const [selectFilterList, setSelectFilterList] = useState(
		[] as SelectOrderFilters[]
	)

	// Flags
	const [filterLoading, setFilterLoading] = useState(false)
	const [clearLoading, setClearLoading] = useState(false)
	const [showSelectFilter, setShowSelectFilter] = useState(false)

	useEffect(() => {
		// Filters
		if (orderFilters) {
			setOrderFilterList(orderFilters)
		}
		// Build select filter list
		setSelectFilterList(createSelectOrderFiltersArray())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderFilters])

	// Handle Functions
	// Handle change of select field for filter key
	const handleFilterKeyChange = (e: { target: any }) => {
		var keyValue = e.target.value
		setOrderFilterKey(keyValue)

		// Check if select needs to be shown
		setShowSelectFilter(handleShowSelectFilter(keyValue))
	}

	// Check if filter is already applied
	const filterApplied = (filterKey: string) => {
		if (orderFilterList.find((o) => o.Key === filterKey)) {
			return true
		} else {
			return false
		}
	}

	// Handle add filter
	const addFilter = () => {
		setOrderFilterList((prev) => [
			...prev,
			{
				Key: orderFilterKey,
				Value: orderFilterValue,
			},
		])
		// Clear the constants
		setOrderFilterKey('')
		setOrderFilterValue('')
	}

	// Handle remove filter
	const removeFilter = async (key: string) => {
		// Filter out the item with the matching key
		const filteredOrderFilterList = orderFilterList.filter(
			(item) => item.Key !== key
		)
		// Update the state with the new list
		setOrderFilterList(filteredOrderFilterList)

		// Make call ONLY if the filter has been applied
		if (orderFilters.find((o) => o.Key === key)) {
			await handleFilterCall(filteredOrderFilterList)
		}
	}

	// Apply filters
	const applyFilters = async () => {
		// Loading
		setFilterLoading(true)

		// Ensure the list has values
		if (orderFilterList.length > 0) {
			await handleFilterCall(orderFilterList)
		}

		// Loading
		setFilterLoading(false)
	}

	// Clear filters
	const clearFilters = async () => {
		// Loading
		setClearLoading(true)

		// Make call
		await handleFilterCall([])

		// Loading
		setClearLoading(false)
	}

	// Check if select filter is needed
	const handleShowSelectFilter = (orderFilterKey: string): boolean => {
		// Return flag
		var requiresSelect = false

		if (orderFilterKey === OrderFilterKeyMappings.OrderStatusID) {
			requiresSelect = true
		}

		return requiresSelect
	}

	// Show the name of the enums to display with spaces
	const formatDisplayName = (key: string): string => {
		// Add a space before each uppercase letter and capitalize the first letter
		return key
			.replace(/([A-Z])/g, ' $1')
			.replace(/^./, (str) => str.toUpperCase())
	}

	// Create filtered array
	const createSelectOrderFiltersArray = (): SelectOrderFilters[] => {
		var _selectOrderFilters = [] as SelectOrderFilters[]

		var orderStatusFilters = Object.keys(OrderStatuses).map((key) => {
			const formattedDisplayName = formatDisplayName(key)
			return {
				FilterCategory: OrderFilterKeyMappings.OrderStatusID,
				FilterID: OrderStatuses[key as keyof typeof OrderStatuses],
				FilterDisplayName: formattedDisplayName.trim(),
			}
		})
		_selectOrderFilters.push(...orderStatusFilters)

		return _selectOrderFilters
	}

	// Handle filter value change
	const handleFilterValueChange = (
		e: ChangeEvent<{ value: unknown }>
	): void => {
		setOrderFilterValue(e.target.value as string)
	}

	return (
		<>
			<Box className='order-filter-container'>
				{/* Filter box */}
				<Box className='order-filter-content'>
					{/* Filter by */}
					<Box className='order-filter'>
						{/* Label */}
						<Typography component='p'>Filter by:</Typography>
						{/* Dropdown */}
						<StyledSelect
							fullWidth
							value={orderFilterKey}
							type='text'
							onChange={handleFilterKeyChange}>
							{orderFilterKeys?.map((item) => {
								return (
									!filterApplied(item) && (
										<MenuItem key={item} value={item}>
											{item}
										</MenuItem>
									)
								)
							})}
						</StyledSelect>
					</Box>
					{/* Value */}
					<Box className='order-filter'>
						{/* Label */}
						<Typography component='p'>Value:</Typography>
						{/* Textfield */}
						<StyledTextBox
							fullWidth
							select={showSelectFilter}
							disabled={orderFilterKey.length === 0}
							value={orderFilterValue}
							onChange={handleFilterValueChange}>
							{showSelectFilter &&
								selectFilterList
									.filter((item) => item.FilterCategory === orderFilterKey)
									.map((item) => (
										<MenuItem key={item.FilterID} value={item.FilterID}>
											{item.FilterDisplayName}
										</MenuItem>
									))}
						</StyledTextBox>
					</Box>
					{/* Add filter button */}
					<Box className='add-filter'>
						<Button
							disabled={orderFilterValue.length === 0}
							startIcon={<AddIcon />}
							variant='outlined'
							onClick={addFilter}>
							Add filter
						</Button>
					</Box>
				</Box>
				{/* Filters applied */}
				{orderFilterList.length > 0 && (
					<Box className='filters-applied'>
						{/* Divider */}
						<Divider />
						{/* Header */}
						<Typography component='h4'>Filters applied:</Typography>
						{/* Chips */}
						<Box className='filter-chips'>
							{orderFilterList.map((item, index) => (
								<StyledChip
									variant='outlined'
									key={index}
									label={`${item.Key}: ${item.Value}`}
									onDelete={() => removeFilter(item.Key)}
								/>
							))}
						</Box>
					</Box>
				)}
				{/* Divider */}
				<Divider />
				{/* Buttons */}
				<Box className='filter-buttons'>
					{/* Clear filters */}
					<LoadingButton
						disabled={orderFilterList.length === 0}
						loading={clearLoading}
						variant='outlined'
						onClick={clearFilters}>
						Clear Filters
					</LoadingButton>
					{/* Apply */}
					<LoadingButton
						disabled={orderFilterList.length === 0}
						loading={filterLoading}
						variant='contained'
						onClick={applyFilters}>
						Apply Filters
					</LoadingButton>
				</Box>
			</Box>
		</>
	)
}

export default OrderFilters
