import { Box, Typography } from '@mui/material'
import './TutorialVideos.scss'
import ReactPlayer from 'react-player'
import { VideoTutorialDisplay } from '../../../../utils/interfaces/ComponentModels'
import { TutorialVideoTypes } from '../../../../utils/enums/enums'

const TutorialVideos = () => {
	// Display constant
	const videoTutorialDisplay: VideoTutorialDisplay[] = [
		{
			VideoTutorialTitle: 'Teams Direct Routing Provisioning - Overview',
			VideoTutorialLink: 'https://vimeo.com/1005453665?share=copy',
			VideoTutorialImageLink: 'TeamsDROverview.png',
			VideoTutorialType:
				TutorialVideoTypes['Teams Direct Routing Provisioning'],
		},
		{
			VideoTutorialTitle: 'Teams Direct Routing Provisioning - Voice Settings',
			VideoTutorialLink: 'https://vimeo.com/872875735/60a3dbb711?share=copy',
			VideoTutorialImageLink: 'TeamsDRVoiceSettings.png',
			VideoTutorialType:
				TutorialVideoTypes['Teams Direct Routing Provisioning'],
		},
		{
			VideoTutorialTitle: 'Teams Direct Routing Provisioning - Entra ID',
			VideoTutorialLink: 'https://vimeo.com/872875243/4c63bb779e?share=copy',
			VideoTutorialImageLink: 'TeamsDRTenantActiveDirectory.png',
			VideoTutorialType:
				TutorialVideoTypes['Teams Direct Routing Provisioning'],
		},
		{
			VideoTutorialTitle:
				'Teams Direct Routing Provisioning - Enterprise Applications',
			VideoTutorialLink: 'https://vimeo.com/1005451826?share=copy',
			VideoTutorialImageLink: 'TeamsDRTenantEnterprise.png',
			VideoTutorialType:
				TutorialVideoTypes['Teams Direct Routing Provisioning'],
		},
		{
			VideoTutorialTitle: 'Teams User Management Overview/Complete',
			VideoTutorialLink: 'https://vimeo.com/857177606/fb3cd644f5?share=copy',
			VideoTutorialImageLink: 'TeamsUserManagementOverviewComplete.png',
			VideoTutorialType: TutorialVideoTypes['Teams User Management'],
		},
		{
			VideoTutorialTitle: 'Teams User Management: Add Phone Number',
			VideoTutorialLink: 'https://vimeo.com/857180742/cc96f800fd?share=copy',
			VideoTutorialImageLink: 'TeamsUserManagementAddPhoneNumber.png',
			VideoTutorialType: TutorialVideoTypes['Teams User Management'],
		},
		{
			VideoTutorialTitle: 'Teams User Management Remove Phone Number',
			VideoTutorialLink: 'https://vimeo.com/857181286/dc288a9f4c?share=copy',
			VideoTutorialImageLink: 'TeamsUserManagementRemovePhoneNumber.png',
			VideoTutorialType: TutorialVideoTypes['Teams User Management'],
		},
		{
			VideoTutorialTitle: 'Teams User Management: Add Base License',
			VideoTutorialLink: 'https://vimeo.com/857178341/aabdb6f048?share=copy',
			VideoTutorialImageLink: 'TeamsUserManagementAddBaseLicense.png',
			VideoTutorialType: TutorialVideoTypes['Teams User Management'],
		},
		{
			VideoTutorialTitle: 'Teams User Management: Remove Base License',
			VideoTutorialLink: 'https://vimeo.com/871004527/28f78aaba0?share=copy',
			VideoTutorialImageLink: 'TeamsUserManagementRemoveBaseLicense.png',
			VideoTutorialType: TutorialVideoTypes['Teams User Management'],
		},
		{
			VideoTutorialTitle:
				'Teams User Management: Add Base License and Phone Number',
			VideoTutorialLink: 'https://vimeo.com/857181685/934993012e?share=copy',
			VideoTutorialImageLink:
				'TeamsUserManagementAddBaseLicenseAndPhoneNumber.png',
			VideoTutorialType: TutorialVideoTypes['Teams User Management'],
		},
		{
			VideoTutorialTitle: 'Teams Admin Center - Account',
			VideoTutorialLink: 'https://vimeo.com/863979040/38243cf1f6?share=copy',
			VideoTutorialImageLink: 'TeamsAdminCentreAccount.png',
			VideoTutorialType: TutorialVideoTypes['Teams Admin Center Guides'],
		},
		{
			VideoTutorialTitle: 'Teams Admin Center - Teams',
			VideoTutorialLink: 'https://vimeo.com/863979988/4b267f59bd?share=copy',
			VideoTutorialImageLink: 'TeamsAdminCentreTeams.png',
			VideoTutorialType: TutorialVideoTypes['Teams Admin Center Guides'],
		},
		{
			VideoTutorialTitle: 'Teams Admin Center - Voice',
			VideoTutorialLink: 'https://vimeo.com/863980244/7467a236c5?share=copy',
			VideoTutorialImageLink: 'TeamsAdminCentreVoice.png',
			VideoTutorialType: TutorialVideoTypes['Teams Admin Center Guides'],
		},
	]

	return (
		<>
			{/*main box  */}
			<Box className='videos-container'>
				{/* Component Title */}
				<Typography component='h2'>Tutorial Videos</Typography>
				{/* Section heading */}
				<Typography component='h3'>
					Teams Direct Routing Provisioning
				</Typography>
				{/* Video Content */}
				<Box className='videos-content'>
					{videoTutorialDisplay.map((item, index) => {
						return (
							item.VideoTutorialType ===
								TutorialVideoTypes['Teams Direct Routing Provisioning'] && (
								<Box key={index} className='video'>
									{/* Video */}
									<ReactPlayer
										url={item.VideoTutorialLink}
										controls={true}
										width='320px'
										height='180px'
										light={require(`../../../../assets/images/tutorialVideoThumbnails/${item.VideoTutorialImageLink}`)}
										playing
									/>
									{/* Title */}
									<Typography component='h4'>
										{item.VideoTutorialTitle}
									</Typography>
								</Box>
							)
						)
					})}
				</Box>
				{/* Section heading */}
				<Typography component='h3'>Teams User Management</Typography>
				{/* Video Content */}
				<Box className='videos-content'>
					{videoTutorialDisplay.map((item, index) => {
						return (
							item.VideoTutorialType ===
								TutorialVideoTypes['Teams User Management'] && (
								<Box key={index} className='video'>
									{/* Video */}
									<ReactPlayer
										url={item.VideoTutorialLink}
										controls={true}
										width='320px'
										height='180px'
										light={require(`../../../../assets/images/tutorialVideoThumbnails/${item.VideoTutorialImageLink}`)}
										playing
									/>
									{/* Title */}
									<Typography component='h4'>
										{item.VideoTutorialTitle}
									</Typography>
								</Box>
							)
						)
					})}
				</Box>
				{/* Section heading */}
				<Typography component='h3'>
					MS Teams Admin Center: User Management
				</Typography>
				{/* Video Content */}
				<Box className='videos-content'>
					{videoTutorialDisplay.map((item, index) => {
						return (
							item.VideoTutorialType ===
								TutorialVideoTypes['Teams Admin Center Guides'] && (
								<Box key={index} className='video'>
									{/* Video */}
									<ReactPlayer
										url={item.VideoTutorialLink}
										controls={true}
										width='320px'
										height='180px'
										light={require(`../../../../assets/images/tutorialVideoThumbnails/${item.VideoTutorialImageLink}`)}
										playing
									/>
									{/* Title */}
									<Typography component='h4'>
										{item.VideoTutorialTitle}
									</Typography>
								</Box>
							)
						)
					})}
				</Box>
			</Box>
		</>
	)
}

export default TutorialVideos
