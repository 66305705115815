import { AlertColor, Box, Button, Typography } from '@mui/material'
import { CardItemOverview } from '../../../utils/interfaces/ComponentModels'
import { StyledAlert } from '../styledComponents/StyledAlert/StyledAlert'
import './CustomCard.scss'
import Preloader from '../loading/preloader/Preloader'
import CustomLoaderWithIcon from '../loading/customLoaderWithIcon/CustomLoaderWithIcon'

const CustomCard = ({
	isActive,
	cardTitle,
	cardDescription,
	cardIconSrc,
	cardBodyIconSrc,
	showButton,
	cardButtonTitle,
	cardButtonAction,
	cardButtonExternalLink,
	showLoading,
	showOverview,
	cardOverview,
	cardOverviewNumber,
	cardMessage,
	cardMessageType,
	isError,
	showCancelButton,
	cancelButtonTitle,
	cancelButtonAction,
	cancelButtonExternalLink,
	showCustomLoaderWithIcon,
	customLoaderWithIconProgress,
	customLoaderWithIcon,
	customLoaderWithIconError,
}: {
	isActive: boolean
	cardTitle: string
	cardDescription?: string
	cardIconSrc?: string
	cardBodyIconSrc?: string
	showButton: boolean
	cardButtonTitle?: string
	cardButtonAction?: any
	cardButtonExternalLink?: string
	showLoading?: boolean
	showOverview?: boolean
	cardOverview?: CardItemOverview[]
	cardOverviewNumber?: string
	cardMessage?: string
	cardMessageType?: AlertColor
	isError?: boolean
	showCancelButton?: boolean
	cancelButtonTitle?: string
	cancelButtonAction?: any
	cancelButtonExternalLink?: string
	customLoaderWithIcon?: any
	customLoaderWithIconProgress?: number
	showCustomLoaderWithIcon?: boolean
	customLoaderWithIconError?: boolean
}) => {
	return (
		<>
			<Box className='card-container'>
				<Box
					className='card-header flex-box'
					bgcolor={isError ? 'error.background' : 'background.superLight'}
					borderRight={2}
					borderLeft={2}
					borderTop={2}
					borderColor={isError ? 'error.main' : 'border.primary'}>
					{cardIconSrc ? (
						<img
							src={require(`../../../assets/icons/card/${cardIconSrc}`)}
							alt={cardTitle}
						/>
					) : null}
					<Typography
						component='h2'
						color={isError ? 'error.color' : 'text.primary'}
						className={isActive ? 'card-text' : 'card-text dt'}>
						{cardTitle}
					</Typography>
				</Box>
				<Box
					className='card-content'
					border={2}
					borderColor={isError ? 'error.main' : 'border.primary'}
					bgcolor='card.default'>
					{cardBodyIconSrc ? (
						<img
							className='card-body-img'
							src={require(`../../../assets/icons/card/${cardBodyIconSrc}`)}
							alt={cardTitle}
						/>
					) : null}

					{cardDescription && cardDescription.length > 0 ? (
						<Typography
							className={showButton ? 'card-text m' : 'card-text'}
							component='p'
							color='text.secondary'>
							{cardDescription}
						</Typography>
					) : null}

					{showLoading ? <Preloader /> : null}

					{showCustomLoaderWithIcon ? (
						<Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
							<CustomLoaderWithIcon
								icon={customLoaderWithIcon}
								progressValue={Number(customLoaderWithIconProgress)}
								error={Boolean(customLoaderWithIconError)}
							/>
						</Box>
					) : null}

					{showOverview ? (
						<Box
							className={
								cardOverviewNumber
									? `grid-box ${cardOverviewNumber} row`
									: 'grid-box row'
							}>
							{cardOverview
								? cardOverview.map((item, index) => (
										<Box className='flex-box col card-overview' key={index}>
											<Typography component='h2' color='text.primary'>
												{item.CardItemOverviewDescription}
											</Typography>
											<Typography component='h5' color='text.primary'>
												{item.CardItemOverviewTitle}
											</Typography>
										</Box>
								  ))
								: null}
						</Box>
					) : null}

					{cardMessage && cardMessageType && cardMessage.length > 0 ? (
						<StyledAlert
							className='alert'
							severity={cardMessageType}
							sx={showButton ? { marginBottom: '15px!important' } : {}}>
							{cardMessage}
						</StyledAlert>
					) : null}

					{showButton && cardButtonTitle ? (
						<Button
							variant='contained'
							disabled={!isActive}
							onClick={cardButtonAction}
							href={cardButtonExternalLink}>
							{cardButtonTitle}
						</Button>
					) : null}

					{showCancelButton && cancelButtonTitle ? (
						<Button
							sx={{ marginTop: '20px' }}
							variant='contained'
							color='error'
							disabled={!isActive}
							onClick={cancelButtonAction}
							href={cancelButtonExternalLink}>
							{cancelButtonTitle}
						</Button>
					) : null}
				</Box>
			</Box>
		</>
	)
}

export default CustomCard
