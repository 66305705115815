import { Box, Typography, useTheme } from "@mui/material";
import "./NoNotificationDisplay.scss";

const NoNotificationDisplay = () => {
  // Theme
  const theme = useTheme();

  return (
    <>
      <Box className="no-notifications-container">
        {/* SVG */}
        <Box className="svg">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 500 500"
            enable-background="new 0 0 500 500"
            xmlSpace="preserve"
          >
            <g id="Background_Simple">
              <g>
                <path
                  fill={theme.palette.primary.main}
                  d="M444.683,134.831c0,0-99.78-100.655-168.925-101.53c-69.145-0.875-246.823,127.788-259.952,168.05
			c-13.129,40.262,73.522,243.322,134.79,274.831c61.268,31.509,305.465-108.532,333.474-151.42
			C512.078,281.875,475.317,183.846,444.683,134.831z"
                />
                <path
                  opacity="0.75"
                  fill="#FFFFFF"
                  d="M444.683,134.831c0,0-99.78-100.655-168.925-101.53
			c-69.145-0.875-246.823,127.788-259.952,168.05c-13.129,40.262,73.522,243.322,134.79,274.831
			c61.268,31.509,305.465-108.532,333.474-151.42C512.078,281.875,475.317,183.846,444.683,134.831z"
                />
              </g>
            </g>
            <g id="Lines">
              <g>
                <line
                  fill="none"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  x1="24.724"
                  y1="174.353"
                  x2="74.481"
                  y2="181.077"
                />
                <line
                  fill="none"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  x1="78.453"
                  y1="105.842"
                  x2="87.929"
                  y2="113.837"
                />
                <line
                  fill="none"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  x1="23.379"
                  y1="59.374"
                  x2="73.052"
                  y2="101.285"
                />
                <line
                  fill="none"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  x1="110.79"
                  y1="41.891"
                  x2="124.238"
                  y2="67.442"
                />
                <line
                  fill="none"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  x1="61.562"
                  y1="275.958"
                  x2="15.31"
                  y2="295.383"
                />
                <line
                  fill="none"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  x1="82.549"
                  y1="267.143"
                  x2="70.101"
                  y2="272.371"
                />
                <line
                  fill="none"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  x1="77.625"
                  y1="365.14"
                  x2="71.119"
                  y2="373.381"
                />
                <line
                  fill="none"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  x1="101.377"
                  y1="335.054"
                  x2="82.496"
                  y2="358.97"
                />
              </g>
            </g>
            <g id="Megaphone">
              <g>
                <ellipse
                  transform="matrix(0.1488 -0.9889 0.9889 0.1488 -66.254 327.2564)"
                  fill={theme.palette.primary.main}
                  stroke="#263238"
                  stroke-miterlimit="10"
                  cx="156.967"
                  cy="202.113"
                  rx="141.348"
                  ry="65.62"
                />

                <ellipse
                  transform="matrix(0.1488 -0.9889 0.9889 0.1488 -69.5604 321.9984)"
                  fill="#BFBFBF"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  cx="152.259"
                  cy="201.405"
                  rx="128.659"
                  ry="56.84"
                />
                <path
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  d="M171.405,74.178c-10.232-1.54-20.757,3.208-30.649,12.779
			c8.388,2.066,19.865,9.232,32.691,29.363c26.267,41.226,33.993,101.66,15.751,134.669c-17.221,31.162-16.866,42.125-72.092,69.254
			c4.759,4.598,10.123,7.502,16.007,8.388c31.042,4.671,64.779-48.503,75.352-118.768S202.447,78.85,171.405,74.178z"
                />
                <g>
                  <path
                    fill={theme.palette.primary.main}
                    stroke="#263238"
                    stroke-miterlimit="10"
                    d="M437.389,189.445
				c-8.802-3.734-100.249-22.076-103.573-22.527c-3.324-0.451-12.387,0.857-12.387,0.857s0.169,0.031,0.47,0.102l15.149,3.288
				c0,0,26.427,14.167,20.898,54.884s-21.856,55.003-46.405,55.055c0,0-2.899-0.222-7.871-0.392c0.154,1.134,1.002,3.38,5.23,4.264
				c5.76,1.205,82.302,7.79,82.302,7.79s28.612,4.308,35.106,0.112c6.494-4.196,27.391-17.861,32.812-48.438
				C464.54,213.866,446.191,193.179,437.389,189.445z"
                  />
                  <path
                    fill="#263238"
                    d="M421.139,185.145c-3.596-0.836-7.611-1.74-11.891-2.685c2.091,0.87,5.3,2.48,6.729,3.197
				c0.71,0.356,1.213,0.607,1.374,0.675c8.52,3.647,26.76,24.791,21.335,55.48c-3.674,20.786-14.81,37.401-33.072,49.366
				c-0.755,0.411-5.688,1.658-8.752,2.326c2.375,0.277,5.307,0.581,8.406,0.815c0.881-0.275,1.571-0.53,1.884-0.735
				c18.974-12.431,30.53-29.682,34.347-51.275C446.641,213.218,431.723,191.879,421.139,185.145z"
                  />
                  <path
                    fill={theme.palette.primary.main}
                    stroke="#263238"
                    stroke-miterlimit="10"
                    d="M462.319,210.968c-3.97-4.168-8.093-6.576-10.951-7.888
				c6.196,9.695,10.844,23.915,7.751,41.362c-3.822,21.557-15.333,34.7-24.069,42.053c1.521,0.471,3.872,0.876,6.477,0.134
				c4.489-1.278,19.94-7.811,26.632-29.288C473.654,239.706,469.4,218.403,462.319,210.968z"
                  />
                </g>
                <path
                  fill="#E0E0E0"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  d="M337.047,171.165l-15.619-3.39
			c-57.01-10.714-89.702-37.206-101.303-48.315c5.877,25.769,6.898,58.077,1.731,92.418c-5.233,34.778-15.919,65.698-29.357,88.689
			c43.18-25.298,119.041-19.463,119.041-19.463c24.549-0.052,40.876-14.337,46.405-55.055S337.047,171.165,337.047,171.165z"
                />
                <path
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  d="M253.84,257.898c-33.833-5.151-32.098-13.827-27.365-40.477
			c3.036-17.093,0.733-35.357-1.309-46.266c-0.137,13.035-1.199,26.704-3.309,40.723c-3.762,25.001-10.352,47.989-18.746,67.49
			c7.126-6.559,13.204-7.628,13.204-7.628l115.723,4.789c5.513-2.911,10.276-7.323,14.235-13.509
			C318.701,262.524,275.745,261.233,253.84,257.898z"
                />
                <path
                  fill="#FFFFFF"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  d="M305.358,283.931c0,0,13.787,15.646,11.829,21.318
			c-1.957,5.671-19.481,17.541-18.77,19.3c0.711,1.759,5.355,4.289,3.006,11.095c-2.349,6.806-6.745,11.196-13.309,24.554
			c-6.564,13.358-7.162,23.014-12.598,26.313c-5.435,3.299-14.792,7.491-14.057,12.578c0.734,5.087,19.185,21.367,23.765,24.363
			c4.58,2.997,14.429,7.422,17.244,0.679c2.815-6.742,1.287-11.225,3.627-14.469c2.34-3.245,25.005-31.99,30.213-44.108
			c5.208-12.117,9.998-31.653,16.176-33.427c6.178-1.773,16.482,1.051,23.408-7.983c6.926-9.034,23.053-35.105,23.053-35.105
			l-62.313-6.324C336.634,282.715,322.859,287.969,305.358,283.931z"
                />
                <path
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  d="M371.706,325.636c-6.212,3.146-13.966,1.495-18.99-5.571
			c-5.025-7.065-30.079-32.607-30.079-32.607l-1.279-2.054c-4.659,0.19-10.117-0.115-16-1.473c0,0,8.841,10.038,11.355,16.948
			c5.11,3.419,11.981,8.984,18.699,17.457c7.794,9.829,10.432,15.342,12.459,18.421c1.464-2.462,2.991-4.162,4.615-4.628
			c6.178-1.773,16.482,1.051,23.408-7.983c0.061-0.08,0.129-0.171,0.191-0.253L371.706,325.636z"
                />
                <g>
                  <path
                    fill="#E0E0E0"
                    stroke="#263238"
                    stroke-miterlimit="10"
                    d="M312.515,288.94c0,0,4.911,7.554,3.757,9.06
				c-1.154,1.506-18.327,16.036-16.778,18.621c1.549,2.585,2.058,4.079,5.058,4.724c3,0.645,4.134,1.036,4.134,1.036
				s11.164-12.258,13.058-17.463c1.894-5.205,1.278-7.664-1.638-10.672C317.19,291.237,312.515,288.94,312.515,288.94z"
                  />
                  <path
                    fill="#263238"
                    d="M315.738,298.564l0.256,0.23c0,0,2.375-2.372,2.621-3.237c0.106-0.372,0.183-1.414,0.238-2.48
				c-2.834-2.415-6.338-4.138-6.338-4.138s4.911,7.554,3.757,9.06C316.184,298.114,315.995,298.311,315.738,298.564z"
                  />
                  <path
                    fill="none"
                    stroke="#263238"
                    stroke-miterlimit="10"
                    d="M300.324,315.914c0,0,3.865-0.117,4.897,0.793
				c1.031,0.91,2.711,4.603,2.711,4.603"
                  />
                  <path
                    fill="#FFFFFF"
                    stroke="#263238"
                    stroke-miterlimit="10"
                    d="M307.442,315.47c0,0,10.09-10.05,12.122-12.726
				c2.031-2.675,1.876-4.365,1.876-4.365"
                  />
                </g>
                <path
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  d="M279.137,396.694c5.853-3.115,10.073-13.402,17.848-29.071
			c5.844-11.778,12.067-23.628,14.796-28.786c0.863-1.632,0.686-3.617-0.453-5.07l-4.604-5.872l-5.158-2.004l-3.149-1.343
			c0.711,1.759,5.355,4.289,3.006,11.095c-2.349,6.806-6.745,11.196-13.309,24.554c-6.564,13.358-7.162,23.014-12.598,26.313
			c-5.435,3.299-14.792,7.491-14.057,12.578c0.734,5.087,19.185,21.367,23.765,24.364c0.484,0.316,1.029,0.649,1.615,0.982
			c-4.121-4.612-16.28-18.454-16.579-21.561C269.901,399.133,273.284,399.809,279.137,396.694z"
                />
                <polygon
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  points="359.087,291.884 356.654,296.834 360.535,300.661 
			366.829,301.296 368.859,296.071 366.501,293.99 367.168,292.321 		"
                />
                <path
                  fill="#E5E5E5"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  d="M208.466,209.863c1.733-11.515,2.732-22.772,3.061-33.571
			c-8.02-0.348-16.04-0.755-24.066-0.929c-6.243-0.135-13.056,0.246-17.23,5.579c-4.81,6.145-7.001,14.447-8.033,22.042
			c-1.135,8.36-1.964,25.253,6.835,30.335c2.248,1.298,26.173,7.802,32.399,9.816C204.332,232.633,206.718,221.484,208.466,209.863z
			"
                />
                <path
                  fill={theme.palette.primary.main}
                  stroke="#263238"
                  stroke-miterlimit="10"
                  d="M182.862,205.296c-1.773,13.059-7.678,23.039-13.189,22.291
			c-5.511-0.748-8.541-11.942-6.768-25.001c1.773-13.059,7.678-23.039,13.189-22.291
			C181.605,181.043,184.635,192.236,182.862,205.296z"
                />
                <path
                  fill="#E5E5E5"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  d="M167.76,229.142c0,0-1.257,7.576,1.185,9.73
			c2.441,2.154,31.149,7.192,31.149,7.192l1.339-2.929l-32.399-9.816l0.639-5.732C169.673,227.586,167.962,225.979,167.76,229.142z"
                />
                <path
                  fill="#E5E5E5"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  d="M183.296,208.695c0,0,4.739,2.011,6.305,2.223
			c1.566,0.213,18.284,2.938,18.284,2.938l0.582-3.994c0,0-18.25-2.657-21.033-3.112c-1.273-0.208-3.169-0.825-3.639-0.913
			C183.794,205.838,183.347,207.497,183.296,208.695z"
                />
                <path
                  fill="#E5E5E5"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  d="M175.63,180.198c0,0,1.212-7.104,3.63-9.089
			c2.417-1.984,4.776-2.325,5.517-2.307c0.741,0.018,26.62,2.871,26.62,2.871l0.131,4.618l-24.066-0.929
			c0,0-6.313-0.528-8.123,0.631c-1.809,1.158-1.903,4.285-1.903,4.285L175.63,180.198z"
                />
                <path
                  fill="#E5E5E5"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  d="M162.469,199.636c0,0-1.058-0.077-1.29,0.159
			c-0.232,0.236-0.499,2.203-0.273,2.501s1.293,0.688,1.293,0.688L162.469,199.636z"
                />
                <path
                  fill="#FFFFFF"
                  d="M152.04,217.402c0,0-25.48,6.385-27.629,9.258c-2.148,2.873-1.219,8.977,1.748,10.434
			c2.967,1.458,27.066-5.115,28.524-8.082S153.578,219.018,152.04,217.402z"
                />
                <path
                  fill="#FFFFFF"
                  d="M119.91,231.324c0,0,3.99,6.871,1.935,9.053c-2.054,2.182-13.929,9.36-15.502,2.818
			C104.77,236.652,116.27,232.236,119.91,231.324z"
                />
                <path
                  fill="#FFFFFF"
                  d="M214.071,258.489c0,0-4.727,15.111-1.675,16.715s67.093-1.59,109.49,3.573
			c0,0,6.817-2.047,11.047-4.742c0,0-59.414-2.421-81.912-3.098c-22.498-0.677-30.284-0.248-32.395-4.399
			C216.514,262.388,217.411,253.593,214.071,258.489z"
                />
              </g>
            </g>
          </svg>
        </Box>
        {/* Text */}
        <Typography component="h3">No new updates</Typography>
        <Typography component="p">
          There are no pending configurations found
        </Typography>
      </Box>
    </>
  );
};

export default NoNotificationDisplay;
