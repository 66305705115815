import {
	BetaAPIMutation,
	BetaObject,
	AddRequest,
} from '../interfaces/APIModels'
import { toast } from 'react-toastify'
import { Log } from '../interfaces/DBModels'
import { v4 as uuidv4 } from 'uuid'

import { SRECustomerServices } from '../interfaces/APIModels'
import { AccountInfo } from '@azure/msal-browser'

export async function toAlphaString(betaText: string) {
	const alpha = process.env.REACT_APP_AES_ALPHA + ''
	const beta = process.env.REACT_APP_AES_BETA + ''
	const alphaMap = new Map<string, string>()

	for (let i = 0; i < beta.length; i++) {
		alphaMap.set(beta[i], alpha[i])
	}
	const alphaTextArray = Array.from(betaText, (betaChar) =>
		alphaMap.has(betaChar) ? alphaMap.get(betaChar)! : betaChar
	)
	const alphaText = alphaTextArray.join('')
	return alphaText
}

export function getEmptyAPIMutationObject() {
	var emptyBetaObj: BetaObject = {
		Content: '',
	}

	var emptyApiMutationObj: BetaAPIMutation = {
		BetaObject: emptyBetaObj,
		QueryParam: '',
	}

	return emptyApiMutationObj
}

export async function GetFullLogErrorMessage(
	error: any,
	pageName: string,
	userID: string,
	userName: string
) {
	var apiError = ''
	if ('data' in error || 'status' in error) {
		if (error.data) {
			apiError += error.data + ' '
		}
		if (error.status) {
			apiError += error.status
		}
	}
	var _errorMessage = `${process.env.REACT_APP_NAME}: ${pageName},  An error occurred`
	if (apiError) {
		_errorMessage = `${process.env.REACT_APP_NAME}: ${pageName}, ${apiError}`
	}

	var addRequestObject = {} as AddRequest
	var AddDataQueryString = 'Params=Log'

	var _log: Log = {
		RequestID: uuidv4(),
		ActionPerformed: `Failed API Request from Web App: ${process.env.REACT_APP_NAME}`,
		ErrorInfo: _errorMessage,
		LogTime: getUTCDateTimeNow(),
	}
	addRequestObject.Obj = { Log: _log }
	addRequestObject.UserID = userID
	addRequestObject.UserName = userName

	var betaDataObj = await toBeta(addRequestObject)

	var betaObj: BetaObject = {
		Content: betaDataObj,
	}

	var betaApiMutation: BetaAPIMutation = {
		BetaObject: betaObj,
		QueryParam: AddDataQueryString,
	}

	return betaApiMutation
}

export function getFormattedDate(): string {
	var today = new Date()
	var _year = today.getUTCFullYear() + ''
	var _month = today.getUTCMonth() + 1 + ''
	var _day = today.getUTCDate() + ''
	var _hours = today.getUTCHours() + ''
	var _minutes = today.getUTCMinutes() + ''
	var _seconds = today.getUTCSeconds() + ''

	if (_month.length < 2) {
		_month = '0' + _month
	}
	if (_day.length < 2) {
		_day = '0' + _day
	}
	if (_hours.length < 2) {
		_hours = '0' + _hours
	}
	if (_minutes.length < 2) {
		_minutes = '0' + _minutes
	}
	if (_seconds.length < 2) {
		_seconds = '0' + _seconds
	}

	var formattedDate = `${_year}-${_month}-${_day}T${_hours}:${_minutes}:${_seconds}`
	return formattedDate
}

export async function toBeta(obj: any) {
	var alphaText = JSON.stringify(obj)
	return toBetaString(alphaText)
}

export function toBetaString(alphaText: string) {
	const alpha = process.env.REACT_APP_AES_ALPHA + ''
	const beta = process.env.REACT_APP_AES_BETA + ''
	const betaMap = new Map<string, string>()

	for (let i = 0; i < alpha.length; i++) {
		betaMap.set(alpha[i], beta[i])
	}

	let betaText = ''
	for (let i = 0; i < alphaText.length; i++) {
		const alphaChar = alphaText[i]
		betaText += betaMap.has(alphaChar) ? betaMap.get(alphaChar)! : alphaChar
	}
	return betaText
}

export function numberToChar(num: number) {
	// 👇️ for Uppercase letters, replace `a` with `A`
	const code = 'A'.charCodeAt(0)
	return String.fromCharCode(code + num)
}

export function showErrorToast(apiErrorMessage: string) {
	toast.error(apiErrorMessage, {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
	})
}

export function showSuccessToast(apiSuccessMessage: string) {
	toast.success(apiSuccessMessage, {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
	})
}

export function showWarningToast(apiSuccessMessage: string) {
	toast.warning(apiSuccessMessage, {
		position: 'top-right',
		autoClose: 2500,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
	})
}

export function showInfoToast(apiInfoMessage: string) {
	toast.info(apiInfoMessage, {
		position: 'top-right',
		autoClose: 2500,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
	})
}

export async function toSREServicesAlpha(betaText: string) {
	var alphaText = await toAlphaString(betaText)

	return JSON.parse(alphaText) as SRECustomerServices
}

export function getUTCDateTimeNow() {
	var today = new Date()
	var utcToday = new Date(
		Date.UTC(
			today.getFullYear(),
			today.getMonth(),
			today.getDate(),
			today.getUTCHours(),
			today.getUTCMinutes(),
			today.getUTCSeconds()
		)
	)
	return utcToday
}

export function hasGraphScopes(scopes: string[]) {
	var scopeFound = false
	for (var i = 0; i < scopes.length; i++) {
		if (scopes[i].toLowerCase().includes('.default')) {
			scopeFound = true
		}
	}
	return scopeFound
}

export function emailValidation(email: string): boolean {
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return regex.test(email)
}

export function GetEmptyAccountInfo(): AccountInfo {
	var accInfo: AccountInfo = {
		environment: '',
		homeAccountId: '',
		localAccountId: '',
		tenantId: '',
		username: '',
		idToken: '',
		name: '',
		nativeAccountId: '',
	}
	return accInfo
}

export function timeout(delay: number) {
	return new Promise((res) => setTimeout(res, delay))
}

// Check if value exists in array
export const existsInArray = <T>(
	array: T[],
	key: keyof T,
	value: any
): boolean => {
	return array.some((arr) => arr[key] === value)
}

export function isValidInteger(value: string) {
	return !(
		(value + '').includes('e') ||
		(value + '').includes('-') ||
		(value + '').includes('+') ||
		(value + '').includes(',') ||
		(value + '').includes('.') ||
		isNaN(Number(value))
	)
}

export function jsonValidator(jsonString: string) {
	try {
		JSON.parse(jsonString)
		return true
	} catch (e) {
		return false
	}
}

// Caching Logic
// Set local storage caching
export const setLocalStorage = (key: string, value: any) => {
	localStorage.setItem(key, JSON.stringify(value))
}

// Get local storage caching
export const getLocalStorage = (key: string) => {
	const data = localStorage.getItem(key)
	return data ? JSON.parse(data) : null
}

// Add to array on local storage caching
export const addToArrayInLocalStorage = (key: string, value: any) => {
	const existingArray = getLocalStorage(key) || []
	existingArray.push(value)
	setLocalStorage(key, existingArray)
}

// Remove from array on local storage caching
export const removeFromArrayInLocalStorage = (
	key: string,
	value: any,
	compareFn: (a: any, b: any) => boolean
) => {
	const existingArray = getLocalStorage(key) || []
	const newArray = existingArray.filter((item: any) => !compareFn(item, value))
	setLocalStorage(key, newArray)
}

// Update existing item in array from local storage caching
// Amend an item in an array on local storage
export const amendArrayItemInLocalStorage = (
	key: string,
	newValue: any,
	compareFn: (a: any, b: any) => boolean
) => {
	const existingArray = getLocalStorage(key) || []
	const updatedArray = existingArray.map((item: any) =>
		compareFn(item, newValue) ? newValue : item
	)
	setLocalStorage(key, updatedArray)
}

// Convert hex to rgba
export function hexToRgba(hex: string, alpha: number): string {
	const hexWithoutHash = hex.startsWith('#') ? hex.slice(1) : hex
	const hexValue = parseInt(hexWithoutHash, 16)
	const r = (hexValue >> 16) & 255
	const g = (hexValue >> 8) & 255
	const b = hexValue & 255
	return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

// Array.find and return the object
export const findArrayValue = <T>(
	array: T[],
	key: keyof T,
	value: any
): T | undefined => {
	return array.find((arr) => arr[key] === value)
}

// Display avatar icon
export function stringAvatar(name: string) {
	return {
		sx: {
			bgcolor: 'primary.main',
			color: 'primary.contrastText',
			fontSize: '20px',
			width: '50px',
			height: '50px',
		},
		children: name[0],
	}
}
