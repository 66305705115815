import { styled, TextField } from '@mui/material'

export const StyledTextBox = styled(TextField)(({ theme }) => ({
	borderRadius: 0,
	color: theme.palette.mode === 'light' ? '#000' : '#fff',
	'& .MuiOutlinedInput-root': {
		borderRadius: 0,
		color: '#0075C9',
	},
	'&.username-field input': {
		fontSize: '14px!important',
		color: '#fff!important',
		backgroundColor: '#0075c9!important',
		textAlign: 'center',
	},
	'& input.MuiInputBase-input': {
		color: '#000!important',
		backgroundColor: '#fff',
		padding: '10px 24px 10px 10px',
	},
	'&.username-field .Mui-disabled': {
		WebkitTextFillColor: '#fff',
	},
	'& fieldset.MuiOutlinedInput-notchedOutline': {
		borderColor: '#0075C9',
	},
	'& .rmv fieldset.MuiOutlinedInput-notchedOutline': {
		borderColor: '#d32f2f',
	},
}))
