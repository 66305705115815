import { Box, Button, IconButton, Modal, Tooltip } from '@mui/material'
import { useState, useEffect } from 'react'
import './PortalUserManagementDisplay.scss'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../shared/datagrid/StyledDataGrid'
import DeleteIcon from '@mui/icons-material/Delete'
import { GridColDef, GridToolbar } from '@mui/x-data-grid-pro'
import {
	BetaObject,
	GetByIDRequestModel,
	Invitation,
	InviteUsersRequestModel,
	Member,
	RolePermissionResponse,
	User,
} from '../../../../utils/interfaces/APIModels'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import {
	emailValidation,
	showErrorToast,
	showSuccessToast,
	toAlphaString,
	toBetaString,
} from '../../../../utils/helperFunctions/helperFunctions'
import {
	useGetUserManagementDataByIDMutation,
	useInviteUsersToPortalMutation,
} from '../../../../services/proxyAPIData'
import { MemberListDisplay } from '../../../../utils/interfaces/ComponentModels'
import RemoveUsers from './Modals/RemoveUsers'
import {
	CustomerPartners,
	UserManagementType,
	UserManagementModals,
	Roles,
} from '../../../../utils/enums/enums'
import PendingInvites from './Modals/PendingInvites'
import InviteMember from './Modals/InviteMember'

function PortalUserManagementDisplay({
	memberList,
	getMembers,
}: {
	memberList: Member[]
	getMembers: () => void
}) {
	//Flags
	const [showScroll, setShowScroll] = useState(false)
	const [openModal, setOpenModal] = useState(false)

	//Proxy Api hooks
	const [apiCallMutation] = useGetUserManagementDataByIDMutation()
	const [inviteUsersToPortalMutation] = useInviteUsersToPortalMutation()

	//Initial States
	const [user, setUser] = useState({} as User)
	const [openedModal, setOpenedModal] = useState(0)
	const [modalWidth, setModalWidth] = useState(500)
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 5,
		page: 0,
	})
	const [invitationList, setInvitationList] = useState([] as Invitation[])
	const [memberDisplayList, setMemberDisplayList] = useState(
		[] as MemberListDisplay[]
	)

	//Roles and Permissions
	const customerRoleID = process.env.REACT_APP_CUSTOMER_ADMIN_ROLE_ID
	const accessToken = useSelector(
		(state: RootState) => state.RootReducer.accessTokenReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const customerID = loggedInUser.customerID
	const partnerName = useSelector(
		(state: RootState) => state.RootReducer.partnerReducer.value
	)
	const loggedInUserRole = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	) as RolePermissionResponse[]
	const isPartnerAdmin = loggedInUserRole[0].RoleID === Roles.PartnerAdmin
	const isCustomerAdmin = loggedInUserRole[0].RoleID === Roles.CustomerAdmin

	//Styling
	const modalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: modalWidth,
		overflowX: `${showScroll ? 'scroll' : 'hidden'}`,
		overflowY: `${showScroll ? 'scroll' : 'hidden'}`,
		bgcolor: '#fff',
		boxShadow: 24,
		p: 4,
		maxHeight: '80%',
	}

	//Functions
	const handleCloseModal = () => {
		setShowScroll(false)
		setModalWidth(500)
		setOpenModal(false)
	}

	const apiCall = async (type: Number, id: string): Promise<string> => {
		var getMembersByOrgIDRequestModel: GetByIDRequestModel = {
			Type: type,
			ID: id,
			AuthorizationToken: accessToken,
		}
		var betaObject: BetaObject = {
			Content: toBetaString(JSON.stringify(getMembersByOrgIDRequestModel)),
		}
		var results = await apiCallMutation(betaObject)
			.unwrap()
			.then(async (usersDataResponse) => {
				if (usersDataResponse && usersDataResponse.Content) {
					var alphaText = await toAlphaString(usersDataResponse.Content + '')
					return alphaText
				}
			})
			.catch((e) => {
				return e.data + ''
			})

		if (type === UserManagementType.DeleteUser) {
			await getMembers()
			handleCloseModal()
		}

		return results ?? ''
	}

	const handleUpdateUser = async (id: string) => {
		setOpenedModal(UserManagementModals.RemoveUserAuth0)
		setOpenModal(true)
		setUser({} as User)
		var alphaText = await apiCall(UserManagementType.User, id)
		var _users = JSON.parse(alphaText ?? '') as User[]
		if (_users.length === 0) {
			showErrorToast('Something went wrong, please try again.')
			handleCloseModal()
			return
		}
		setModalWidth(600)
		setUser(_users[0])
		setShowScroll(true)
	}

	const handleInviteMembers = () => {
		setOpenModal(true)
		setOpenedModal(UserManagementModals.InviteMembers)
	}

	const handleViewPendingInvites = async () => {
		var orgID = process.env.REACT_APP_ORGANIZATION_ID + ''

		setOpenedModal(UserManagementModals.PendingInvites)
		setOpenModal(true)
		setInvitationList([] as Invitation[])

		var alphaText = await apiCall(UserManagementType.PendingInvites, orgID)
		var _invitation = JSON.parse(alphaText ?? '') as Invitation[]
		setInvitationList(_invitation)
		setModalWidth(1000)
		setShowScroll(true)
	}

	const handleSendInvite = async (
		email: string,
		customerID: string,
		role: string
	) => {
		if (!emailValidation(email)) {
			showErrorToast(`Email: ${email} not valid.`)
			return
		}

		var partnerID = 0
		var orgID = ''
		switch (partnerName) {
			case 'lmn':
				partnerID = CustomerPartners.Lumen
				orgID = process.env.REACT_APP_ORGANIZATION_ID ?? ''
				break
		}
		var isRoleCustomerAdmin = role === customerRoleID
		var inviteUsersToPortal: InviteUsersRequestModel = {
			CustomerID: isRoleCustomerAdmin ? customerID : null,
			InvitedEmail: email,
			InviterEmail: loggedInUser.email,
			OrganizationID: orgID,
			PartnerID: partnerID,
			RoleID: role,
		}

		var betaObject: BetaObject = {
			Content: toBetaString(JSON.stringify(inviteUsersToPortal)),
		}

		await inviteUsersToPortalMutation(betaObject)
			.unwrap()
			.then(async (dataResponse) => {
				if (dataResponse && dataResponse.Content) {
					var alphaText = await toAlphaString(dataResponse.Content + '')
					var response = alphaText
					showSuccessToast(response)
				}
			})
	}

	useEffect(() => {
		if (memberList.length > 0) {
			var _memberDisplayList = [] as MemberListDisplay[]

			memberList.forEach((m) => {
				var _userID = m.user_ID ?? ''
				var _name = m.name ?? ''
				var _email = m.email ?? ''
				var _role = m.roles[0].name ?? ''
				var _MemberObj: MemberListDisplay = {
					MemberEmail: _email,
					MemberName: _name,
					MemberRole: _role.replace(/([a-z])([A-Z])/g, '$1 $2'),
					MemberUserID: _userID,
				}

				if (
					isPartnerAdmin ||
					(isCustomerAdmin &&
						_role.includes('Customer') &&
						customerID === m.customerID)
				) {
					// will add dev env variable here as well
					if (!m.email.toLowerCase().endsWith('@sipcom.com')) {
						_memberDisplayList.push(_MemberObj)
					}
				}
			})
			setMemberDisplayList(_memberDisplayList)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memberList])
	const membersColoumns: GridColDef[] = [
		{
			field: 'MemberEmail',
			headerName: 'Email',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'MemberName',
			headerName: 'Name',
			width: 250,
			hideable: false,
			flex: 1,
		},

		{
			field: 'MemberRole',
			headerName: 'Role',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'actions',
			type: 'actions',
			cellClassName: 'actions',
			width: 220,
			editable: false,
			align: 'center',
			hideable: false,
			flex: 1,
			getActions: ({ id }) => {
				return [
					<>
						<Tooltip title='Remove User'>
							<IconButton
								onClick={(e: any) => {
									handleUpdateUser(id + '')
								}}>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</>,
				]
			},
		},
	]

	return (
		<>
			<Box id='list'>
				<Box
					sx={{ display: 'flex', justifyContent: 'flex-end' }}
					className='action-buttons'>
					<Button
						onClick={handleViewPendingInvites}
						className='button'
						variant='contained'>
						Pending Invites
					</Button>
					<Button
						onClick={handleInviteMembers}
						className='button'
						variant='contained'>
						Invite Members
					</Button>
				</Box>
				<StyledDataGrid
					rows={memberDisplayList}
					columns={membersColoumns}
					editMode='row'
					pagination
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					pageSizeOptions={[10]}
					slots={{
						pagination: CustomPagination,
						toolbar: GridToolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.MemberEmail}
					autoHeight
					rowSelection
				/>
			</Box>
			<Modal open={openModal} onClose={handleCloseModal}>
				<Box sx={modalStyle}>
					{openedModal === Number(UserManagementModals.RemoveUserAuth0) && (
						<RemoveUsers user={user} apiCall={apiCall} />
					)}
					{openedModal === Number(UserManagementModals.PendingInvites) && (
						<PendingInvites
							invitationList={invitationList}
							handleSendInvite={handleSendInvite}
						/>
					)}
					{openedModal === Number(UserManagementModals.InviteMembers) && (
						<InviteMember handleSendInvite={handleSendInvite} />
					)}
				</Box>
			</Modal>
		</>
	)
}

export default PortalUserManagementDisplay
