import {
	Box,
	Checkbox,
	CircularProgress,
	Divider,
	FormControlLabel,
	IconButton,
	InputAdornment,
	MenuItem,
	Typography,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import '../FormSections.scss'
import { TeamsUsersResponse } from '../../../../../../../../../utils/interfaces/APIModels'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { StyledTextBox } from '../../../../../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import PhoneOption from './PhoneOption/PhoneOption'
import EmptyOption from '../EmptyOption/EmptyOption'
import ClearIcon from '@mui/icons-material/Clear'
import { useTUMContext } from '../../../../../../../../../utils/context/TUMContext'
import { StyledSelect } from '../../../../../../../../shared/styledComponents/StyledSelect/StyledSelect'

// Handle value change type
type HandleValueChange = (type: keyof TeamsUsersResponse, value: any) => void

const PhoneSection = ({
	user,
	handleValueChange,
}: {
	user: TeamsUsersResponse
	handleValueChange: HandleValueChange
}) => {
	// Context
	const {
		removeBase,
		removeAddon,
		removePhone,
		setRemovePhone,
		internationalCalling,
		setInternationalCalling,
		assignedNumberString,
		teamsPhoneList,
		tumRangeList,
		tumVRPList,
		getDDIRanges,
		buildPhoneAssignmentFromRangeID,
		getDDIsForAssignment,
	} = useTUMContext()

	// Display constants
	const [phoneInput, setPhoneInput] = useState(
		user.phoneNumber ? user.phoneNumber : ''
	)
	const [ddiRangeID, setDDIRangeID] = useState(0)

	// Menu Flags
	const [showPhoneNumbers, setShowPhoneNumbers] = useState(false)
	const containerRef = useRef<HTMLDivElement>(null)

	// Element Flags
	const [rangeCallMade, setRangeCallMade] = useState(false)
	const [loading, setLoading] = useState(false)
	const [phoneError, setPhoneError] = useState('')
	const [isOurDDI, setIsOurDDI] = useState(false)

	// Use Effect 1: Handle click away from element to close autocomplete options
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	// Use Effect 2: Get Range List
	useEffect(() => {
		if (!rangeCallMade) {
			setRangeCallMade(true)
			getDDIRanges()
		} else {
			// Check phone number
			checkPhoneNumber()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rangeCallMade])

	// Check phone number
	const checkPhoneNumber = () => {
		// If number is assigned
		if (user.phoneNumber && user.phoneNumber.length > 0) {
			// Convert current number to int
			var valueToCheck = parseInt(user.phoneNumber, 10)

			// Loop through list
			var rangeToUse = tumRangeList.find((range) => {
				// Convert start and end
				var startRange = parseInt(range.DDIRangeStart + '', 10)
				var endRange = parseInt(range.DDIRangeEnd + '', 10)
				return valueToCheck >= startRange && valueToCheck <= endRange
			})

			// Check if found then assign ddi range ID
			if (rangeToUse && rangeToUse.DDIRangeID) {
				setDDIRangeID(rangeToUse.DDIRangeID)
				setIsOurDDI(true)
			}

			// User International Calling
			if (user.voiceRoutingPolicy) {
				if (
					tumVRPList.find(
						(vrp) => vrp.IntVoiceRoutingPolicy + '' === user.voiceRoutingPolicy
					)
				) {
					setInternationalCalling(true)
				}
			}
		}
	}

	// Close dropdown when clicking outside
	const handleClickOutside = (event: MouseEvent) => {
		if (
			containerRef.current &&
			!containerRef.current.contains(event.target as Node)
		) {
			setShowPhoneNumbers(false)
		}
	}

	// Handle user input
	const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		if (value.length === 0) {
			setPhoneInput(value)
		} else {
			// Validate number
			if (validatePhoneNumber(value)) {
				setPhoneError('')
				setPhoneInput(value)

				// Set loading
				setLoading(true)

				// Filter results
				await getDDIsForAssignment({
					DDIRangeID: ddiRangeID,
					AssignedNumbersFilter: assignedNumberString,
					DDISearchValue: value,
				})

				// End loading
				setLoading(false)
			} else {
				// Helper error
				setPhoneError('Invalid phone number input. Only digits are allowed.')
			}
		}
	}

	// Handle user selection
	const handlePhoneSelection = (phone: string) => {
		// Check if exists
		if (teamsPhoneList.find((p) => p.DDI + '' === phone)) {
			setPhoneError('')
			setPhoneInput(phone)
			handleValueChange('phoneNumber', phone)
			setShowPhoneNumbers(false)
		}
	}

	// Handle range selection
	const handleRangeSelection = async (e: { target: any }) => {
		// Range ID
		var rangeID = Number(e.target.value)
		setDDIRangeID(rangeID)

		// Loading
		setLoading(true)

		// Make call to retrieve latest 10 numbers
		await buildPhoneAssignmentFromRangeID(rangeID)

		// Loading
		setLoading(false)
	}

	// Handle international calling change
	const handleCallingChange = (e: ChangeEvent<HTMLInputElement>) => {
		var checked = e.target.checked
		setInternationalCalling(checked)
		handleValueChange('internationalCalling', checked)
	}

	// Handle Focus Functions
	// Phone
	const handlePhoneFocus = () => {
		setShowPhoneNumbers(true)
	}

	// Handle clear
	const handleClear = () => {
		setPhoneInput('')
		handleValueChange('phoneNumber', '')
		setShowPhoneNumbers(false)
	}

	// Validate phone
	const validatePhoneNumber = (phoneNumber: string): boolean => {
		const phoneRegex = /^\+?[0-9]+$/
		return phoneRegex.test(phoneNumber)
	}

	return (
		<>
			<Box className='tum-form-section-container'>
				{/* Section Header */}
				<Box className='section-header'>
					{/* Label and Information */}
					<Box className='section-label'>
						<Typography component='h5'>Phone Configuration</Typography>
						{/* Extra Information */}
						<IconButton>
							<InfoIcon />
						</IconButton>
					</Box>
				</Box>
				{/* Divider */}
				<Divider />
				{/* Form Elements */}
				{/* 1 - DDI Range Selection */}
				<Box className='section-form'>
					{/* Label */}
					<Box className='tum-form-label'>
						<Typography component='p'>Range Selection:</Typography>
					</Box>
					{/* Autocomplete */}
					<Box className='tum-form-field' ref={containerRef}>
						<StyledSelect
							disabled={removeBase || removeAddon || removePhone}
							id='phone-range-select'
							fullWidth
							type='text'
							autoComplete='off'
							value={ddiRangeID || 0}
							onChange={handleRangeSelection}>
							{tumRangeList.map((item, index) => (
								<MenuItem key={index} value={item.DDIRangeID}>
									{`${item.DDIRangeStart} - ${item.DDIRangeEnd}`}
								</MenuItem>
							))}
						</StyledSelect>
					</Box>
				</Box>
				{/* 2 - Phone Number */}
				{/* Remove */}
				{isOurDDI && (
					<Box className='checkbox'>
						<FormControlLabel
							control={
								<Checkbox
									sx={{ color: 'text.header' }}
									checked={removePhone}
									onChange={(e) => setRemovePhone(e.target.checked)}
								/>
							}
							label='Remove Phone Number'
							sx={{ color: 'text.header' }}
						/>
					</Box>
				)}
				{tumRangeList.length > 0 && ddiRangeID > 0 && (
					<Box className='section-form'>
						{/* Label */}
						<Box className='tum-form-label'>
							<Typography component='p'>Phone Number:</Typography>
						</Box>
						{/* Autocomplete */}
						<Box className='tum-form-field' ref={containerRef}>
							<StyledTextBox
								disabled={
									ddiRangeID === 0 || removeBase || removeAddon || removePhone
								}
								id='phone-autocomplete'
								fullWidth
								type='text'
								value={phoneInput}
								onChange={handleInputChange}
								onFocus={handlePhoneFocus}
								error={phoneError.length > 0}
								helperText={phoneError}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											{phoneInput.length > 0 ? (
												<IconButton
													disabled={removeBase || removeAddon || removePhone}
													onClick={handleClear}>
													<ClearIcon />
												</IconButton>
											) : (
												loading && (
													<IconButton disabled>
														<CircularProgress size={20} />
													</IconButton>
												)
											)}
										</InputAdornment>
									),
								}}
							/>
							{/* Autocomplete options */}
							{showPhoneNumbers && (
								<ul>
									{teamsPhoneList.length > 0 ? (
										teamsPhoneList.map((item, index) => (
											<PhoneOption
												key={index}
												numberType={Number(item.NumberType)}
												displayName={item.DDI + ''}
												handlePhoneSelection={handlePhoneSelection}
											/>
										))
									) : (
										<EmptyOption header='No Telephone Numbers Available' />
									)}
								</ul>
							)}
						</Box>
					</Box>
				)}
				{/* International calling */}
				{phoneInput.length > 0 && (
					<Box className='checkbox'>
						<FormControlLabel
							control={
								<Checkbox
									sx={{ color: 'text.header' }}
									checked={internationalCalling}
									onChange={handleCallingChange}
								/>
							}
							label='Enable International Calling'
							sx={{ color: 'text.header' }}
						/>
					</Box>
				)}
			</Box>
		</>
	)
}

export default PhoneSection
