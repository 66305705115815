import { useEffect, useState } from 'react'
import { SKUPartnerMap, Range } from '../../../utils/interfaces/DBModels'
import CustomCard from '../../../components/shared/card/CustomCard'
import LoadingCard from '../../../components/shared/loading/loadingCard/LoadingCard'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { PartnerCodeToIDMapping } from '../../../utils/enums/enums'
import { setPageTitle } from '../../../store/reducers/reducers'
import { useAppDispatch } from '../../../store/hooks'
import PostAndRetrieveDataHook from '../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import PriceBooksOverview from '../../../components/distinct/dashboard/PriceBooksOverview/PriceBooksOverview'

const PriceBookManagement = () => {
	// Hooks
	const {
		postAndRetrieveDataFromDB,
		postAndRetrieveDataError,
		postAndRetrieveDataLoading,
	} = PostAndRetrieveDataHook()

	// Global variables
	const partnerCode = useSelector(
		(state: RootState) => state.RootReducer.partnerReducer.value
	)
	const [partnerID] = useState(
		PartnerCodeToIDMapping[
			`${partnerCode}` as keyof typeof PartnerCodeToIDMapping
		]
	)

	// Arrays
	const [skuPriceBookList, setSKUPriceBookList] = useState(
		[] as SKUPartnerMap[]
	)
	const [rangeList, setRangeList] = useState([] as Range[])

	// Flags
	const [priceBooksRetrieved, setPriceBooksRetrieved] = useState(false)
	const [apiCallsDone, setApiCallsDone] = useState(false)

	const dispatch = useAppDispatch()

	useEffect(() => {
		var pageTitle = 'Price Books'
		dispatch(setPageTitle(pageTitle))

		if (!postAndRetrieveDataLoading) {
			if (!priceBooksRetrieved) {
				setPriceBooksRetrieved(true)
				getPricebooksForPartner()
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skuPriceBookList, postAndRetrieveDataError, postAndRetrieveDataLoading])

	// Get pricebooks for partner
	const getPricebooksForPartner = async () => {
		// Make call if partner
		if (partnerID > 0) {
			// Make call
			var dataResponse = await postAndRetrieveDataFromDB(
				'Pricebooks for Partner',
				`SKUPartnerMap.PriceBook.Where(SKUPartnerMap.CustomerPartnerID = '${partnerID}'), Range.All()`,
				false,
				false
			)

			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				// *** Get the all relevant lists and build the display array *** //

				//SKUPartnerMap and Pricebooks
				var skuPartnerMapPriceBookList = dataResponse.Obj
					.SKUPartnerMapList as SKUPartnerMap[]

				//sort alphabetically
				skuPartnerMapPriceBookList = skuPartnerMapPriceBookList.sort((a, b) =>
					a.SKUTypeID!.localeCompare(b.SKUTypeID!)
				)

				if (
					skuPartnerMapPriceBookList &&
					skuPartnerMapPriceBookList.length > 0
				) {
					setSKUPriceBookList(skuPartnerMapPriceBookList)
				}

				//Ranges
				var rangeResponseList = dataResponse.Obj.RangeList as Range[]

				if (rangeResponseList && rangeResponseList.length > 0) {
					setRangeList(rangeResponseList)
				}
			} else {
				//if no sku and pricebook data set empty to show no pricebook data display
				setSKUPriceBookList([])
			}
		}
		// Stop loading
		setApiCallsDone(true)
	}

	return (
		<>
			{apiCallsDone && skuPriceBookList.length > 0 ? (
				<PriceBooksOverview
					skuPriceBookList={skuPriceBookList}
					rangeList={rangeList}
				/>
			) : apiCallsDone && skuPriceBookList.length === 0 ? (
				<CustomCard
					cardIconSrc='card-error.png'
					cardTitle='No price books have been found'
					cardDescription='Please note there were no price books that were found with relevant SKU information.'
					isActive
					showButton={false}
				/>
			) : (
				<LoadingCard
					loadingCardTitle='Loading your Price Book information'
					loadingCardDescription='Please wait as we receive your price book information'
				/>
			)}
		</>
	)
}

export default PriceBookManagement
