import { Box, Divider, Tab, Typography, useTheme } from '@mui/material'
import NoNotificationDisplay from '../../../../../shared/displays/NoNotificationDisplay/NoNotificationDisplay'
import { ModalForm } from '../../../../../shared/inputs/ModalForm/ModalForm'
import UpdateIcon from '@mui/icons-material/Update'
import './TUMUpdateHandler.scss'
import { StyledTabs } from '../../../../../../styles/styledComponents/displays/StyledTabs'
import { useEffect, useState } from 'react'
import { StyledTabPanel } from '../../../../../../styles/styledComponents/displays/StyledTabPanel'
import UseLocalStorage from '../../../../../../utils/customHooks/LocalStorage/UseLocalStorage'
import {
	ReturnTypes,
	TUMUpdateStatuses,
} from '../../../../../../utils/enums/enums'
import { TUMUpdateList } from '../../../../../../utils/interfaces/ComponentModels'
import UseCrud from '../../../../../../utils/customHooks/APICalls/UseCrud'
import { DataResponse } from '../../../../../../utils/interfaces/APIModels'
import { TeamsUserManagementResults } from '../../../../../../utils/interfaces/DBModels'
import TUMUpdateRow from './TUMUpdateRow/TUMUpdateRow'

const TUMUpdateHandler = ({
	customerID,
	handleCloseModal,
	triggerSearch,
}: {
	customerID: string
	handleCloseModal: any
	triggerSearch: any
}) => {
	// Hooks
	const { data, amendItem, removeItem } = UseLocalStorage(`tum-${customerID}`)
	const { fetchData } = UseCrud()

	// Theme
	const theme = useTheme()

	// Display constants
	const [tumUpdateDisplay, setTUMUpdateDisplay] = useState(
		[] as TUMUpdateList[]
	)

	// Flags
	const [tabValue, setTabValue] = useState(0)

	// Check for update change
	useEffect(() => {
		// Check for data
		const updateList = data as TUMUpdateList[]
		if (updateList) {
			// Sort the updateList by LogTime
			const sortedUpdateList = [...updateList].sort((a, b) => {
				const dateA = a.LogTime ? new Date(a.LogTime).getTime() : 0
				const dateB = b.LogTime ? new Date(b.LogTime).getTime() : 0
				return dateB - dateA // Sort in descending order (most recent first)
			})
			setTUMUpdateDisplay(sortedUpdateList)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	// Use Effect: Handle configuration check
	useEffect(() => {
		// Check if there are any pending items
		const hasPendingItems = tumUpdateDisplay.filter(
			(item: TUMUpdateList) => Number(item.Status) === TUMUpdateStatuses.Pending
		) as TUMUpdateList[]

		if (hasPendingItems) {
			// Make repeated calls in intervals of 15 seconds
			const interval = setInterval(() => {
				checkForTUMStatus(hasPendingItems)
			}, 5000)

			return () => clearInterval(interval)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tumUpdateDisplay])

	// API Call
	// GET: Check configuration status in DB
	const checkForTUMStatus = async (updateList: TUMUpdateList[]) => {
		// Loop through the update list
		// Repeated calls by use of hook
		for (let status = 0; status < updateList.length; status++) {
			// Hook Call
			var dataResponse = (await fetchData({
				FileAndFunctionName: 'TUMUpdateHandler.tsx: checkForTUMStatus()',
				QueryURL: buildQueryURL(updateList[status]),
				ErrorMessage:
					'An error occurred when checking for a response for the action performed',
				ShowErrorToast: false,
				LogErrorToDB: true,
				ReturnType: ReturnTypes.ObjectOrList,
			})) as DataResponse
			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				// Assign response
				var statusResponse = dataResponse.Obj
					.TeamsUserManagementResults as TeamsUserManagementResults

				if (statusResponse) {
					// Variables
					var jobStatus = 0

					// Check if it is a success or failure
					if (statusResponse.IsSuccess && statusResponse.IsSuccess === true) {
						// Show success
						jobStatus = TUMUpdateStatuses.Success
					} else {
						// Show failure
						jobStatus = TUMUpdateStatuses.Failure
					}

					// Define a compare function
					const compareFn = (a: any, b: any) => a.JobID === b.JobID

					// Create amended item
					const newTumUpdateObj: TUMUpdateList = {
						JobID: updateList[status].JobID,
						UPN: updateList[status].UPN,
						ActionPerformed: updateList[status].ActionPerformed,
						Status: jobStatus,
						Reason: statusResponse.Description + '',
						LogTime: updateList[status].LogTime,
					}
					amendItem(newTumUpdateObj, compareFn)
				}
			}
		}
	}

	// Build query URL
	const buildQueryURL = (params: TUMUpdateList) => {
		// Return built query URL
		return `TeamsUserManagementResults.First(TeamsUserManagementResults.JobID = '${
			params.JobID + ''
		}' & TeamsUserManagementResults.Username = '${
			params.UPN + ''
		}' & TeamsUserManagementResults.CustomerID = '${customerID}' & TeamsUserManagementResults.IsComplete = '1')`
	}

	// Handle Function - Tab change
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue)
	}

	// Handle remove notification of update status
	const handleRemoveUpdateStatus = (item: TUMUpdateList) => {
		removeItem(item, (a, b) => a.JobID === b.JobID)
	}

	// Handle view of customer click
	const handleSearchForUser = (upn: string) => {
		triggerSearch(upn)
	}

	// Get totals
	const getTotals = (type: number) => {
		// Return
		var count = 0
		var total = 0

		// All
		switch (type) {
			// All
			case 0:
				// Get count
				count = tumUpdateDisplay.length

				if (count > 0) {
					total = count
				}

				break
			// Completed
			case 1:
				// Get count
				count = tumUpdateDisplay.filter(
					(update) => Number(update.Status) > TUMUpdateStatuses.Pending
				).length

				if (count > 0) {
					total = count
				}

				break
			// Pending
			case 2:
				// Get count
				count = tumUpdateDisplay.filter(
					(update) => Number(update.Status) === TUMUpdateStatuses.Pending
				).length

				if (count > 0) {
					total = count
				}
				break
		}

		return total
	}

	return (
		<>
			<ModalForm
				icon={<UpdateIcon />}
				title='Update Status'
				description='View your update status for users below'
				closeModal={handleCloseModal}>
				{/* Update display */}
				<Box className='update-handler-container'>
					{/* Notification tab display */}
					{/* Empty display */}
					{tumUpdateDisplay && tumUpdateDisplay.length > 0 ? (
						<Box className='update-tabs'>
							<StyledTabs
								value={tabValue}
								onChange={handleTabChange}
								variant='fullWidth'>
								{/* All */}
								<Tab
									key={0}
									label={
										<Box className='update-tab'>
											{/* Label */}
											<Typography component='span'>All</Typography>
											{/* Number */}
											<Box
												className='total'
												bgcolor={theme.palette.primary.main}>
												<Typography
													component='span'
													sx={{
														color: theme.palette.primary.contrastText,
													}}>
													{getTotals(0)}
												</Typography>
											</Box>
										</Box>
									}
								/>
								{/* Completed */}
								<Tab
									key={1}
									label={
										<Box className='update-tab'>
											{/* Label */}
											<Typography component='span'>Completed</Typography>
											{/* Number */}
											<Box
												className='total'
												bgcolor={theme.palette.primary.main}>
												<Typography
													component='span'
													sx={{
														color: theme.palette.primary.contrastText,
													}}>
													{getTotals(1)}
												</Typography>
											</Box>
										</Box>
									}
								/>
								{/* Pending */}
								<Tab
									key={2}
									label={
										<Box className='update-tab'>
											{/* Label */}
											<Typography component='span'>Pending</Typography>
											{/* Number */}
											<Box
												className='total'
												bgcolor={theme.palette.primary.main}>
												<Typography
													component='span'
													sx={{
														color: theme.palette.primary.contrastText,
													}}>
													{getTotals(2)}
												</Typography>
											</Box>
										</Box>
									}
								/>
							</StyledTabs>
							{/* Tab Panels */}
							{/* ALl */}
							<StyledTabPanel key={0} index={0} value={tabValue}>
								{/* Initial divider */}
								<Divider />
								{/* Map all the notifications */}
								{tumUpdateDisplay && tumUpdateDisplay.length > 0 && (
									<Box>
										{tumUpdateDisplay.map(
											(item: TUMUpdateList, index: number) => (
												<TUMUpdateRow
													key={index}
													row={item}
													handleRemoveUpdateStatus={handleRemoveUpdateStatus}
													handleSearchForUser={handleSearchForUser}
												/>
											)
										)}
									</Box>
								)}
							</StyledTabPanel>
							{/* Completed */}
							<StyledTabPanel key={1} index={1} value={tabValue}>
								{/* Initial divider */}
								<Divider />
								{/* Map all the notifications */}
								{tumUpdateDisplay &&
									tumUpdateDisplay.filter(
										(update) =>
											Number(update.Status) > TUMUpdateStatuses.Pending
									).length > 0 && (
										<Box>
											{tumUpdateDisplay.map(
												(item: TUMUpdateList, index: number) => {
													return (
														Number(item.Status) > TUMUpdateStatuses.Pending && (
															<TUMUpdateRow
																key={index}
																row={item}
																handleRemoveUpdateStatus={
																	handleRemoveUpdateStatus
																}
																handleSearchForUser={handleSearchForUser}
															/>
														)
													)
												}
											)}
										</Box>
									)}
							</StyledTabPanel>
							{/* Pending */}
							<StyledTabPanel key={2} index={2} value={tabValue}>
								{/* Initial divider */}
								<Divider />0{/* Map all the notifications */}
								{tumUpdateDisplay &&
									tumUpdateDisplay.filter(
										(update) =>
											Number(update.Status) === TUMUpdateStatuses.Pending
									).length > 0 && (
										<Box>
											{tumUpdateDisplay.map(
												(item: TUMUpdateList, index: number) => {
													return (
														Number(item.Status) ===
															TUMUpdateStatuses.Pending && (
															<TUMUpdateRow
																key={index}
																row={item}
																handleRemoveUpdateStatus={
																	handleRemoveUpdateStatus
																}
																handleSearchForUser={handleSearchForUser}
															/>
														)
													)
												}
											)}
										</Box>
									)}
							</StyledTabPanel>
						</Box>
					) : (
						<Box className='empty'>
							<NoNotificationDisplay />
						</Box>
					)}
				</Box>
			</ModalForm>
		</>
	)
}

export default TUMUpdateHandler
