import { usePostBetaObjectWithoutRefetchMutation } from '../../../services/proxyAPIData'
import {
	getEmptyAPIMutationObject,
	showErrorToast,
	toBetaString,
} from '../../helperFunctions/helperFunctions'
import { BetaObject, CustomPostHookObj } from '../../interfaces/APIModels'
import ErrorLogging from '../ErrorLogging'

const PostCustomEndpointHook = () => {
	// Proxy call
	const [
		postWithoutRefetch,
		{ error: postCustomEndpointError, isLoading: postCustomEndpointLoading },
	] = usePostBetaObjectWithoutRefetchMutation()

	// Error logging
	const { addErrorLog } = ErrorLogging()

	const postCustomEndpoint = async (customerPostHookObj: CustomPostHookObj) => {
		// Error handling
		var isError = false

		var betaObj: BetaObject = {
			Content: await toBetaString(
				JSON.stringify(customerPostHookObj.RequestObj)
			),
		}

		var apiMutation = getEmptyAPIMutationObject()
		apiMutation.QueryParam = customerPostHookObj.RequestURL
		apiMutation.BetaObject = betaObj

		return await postWithoutRefetch(apiMutation)
			.unwrap()
			.then(async (response) => {
				// Decrypt and return response
				if (response && response.Content) {
					var dataResponse = response.Content

					return dataResponse
				}
			})
			.catch(async (error) => {
				if (error) {
					isError = true
				}
			})
			.finally(() => {
				if (
					isError &&
					customerPostHookObj.ErrorMessage &&
					customerPostHookObj.ErrorMessage.length > 0
				) {
					// Error handling
					if (customerPostHookObj.ShowErrorMessage) {
						showErrorToast(customerPostHookObj.ErrorMessage)
					}
					if (customerPostHookObj.LogErrorToDB && customerPostHookObj.Action) {
						// Log to DB
						addErrorLog(
							customerPostHookObj.Action,
							customerPostHookObj.ErrorMessage
						)
					}
				}
			})
	}

	return {
		postCustomEndpoint,
		postCustomEndpointError,
		postCustomEndpointLoading,
	}
}

export default PostCustomEndpointHook
