import { useState } from "react";
import { usePostBetaObjectAndReturnDataMutation } from "../../../services/proxyAPIData";
import { BetaObject, CustomRequest } from "../../interfaces/APIModels";
import {
  jsonValidator,
  showErrorToast,
  showSuccessToast,
  toAlphaString,
  toBetaString,
} from "../../helperFunctions/helperFunctions";

export default function UseCustomBackendCall() {
  // API Calls
  const [postObjectAndReturnData] = usePostBetaObjectAndReturnDataMutation();

  // Flags
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // GET Call - Function to get data using an ecrypted object
  async function fetchCustomData<T>(
    customRequest: CustomRequest
  ): Promise<void | Awaited<T> | null> {
    // Loading
    setLoading(true);
    // Error handling
    var isError = false;

    //Success handling
    var isMessageFromBackend = false;
    var messageFromBackend = "";
    // Return value
    var returnValue: T | null = null;

    // Encrypt the object
    var betaObject: BetaObject = {
      Content: toBetaString(JSON.stringify(customRequest.QueryObj)),
    };

    return await postObjectAndReturnData({
      url: customRequest.QueryURL,
      dataObject: betaObject,
    })
      .unwrap()
      .then(async (response) => {
        if (response) {
          // Check content and decrypt
          if (response.Content) {
            var results = await toAlphaString(response.Content);
            if (!jsonValidator(results)) {
              returnValue = results as T;
              isMessageFromBackend = true;
              messageFromBackend = returnValue as string;
            } else {
              returnValue = JSON.parse(
                await toAlphaString(response.Content)
              ) as T;
            }

            // Loading and error
            setLoading(false);
            // Return
            return returnValue;
          } else {
            // General response
            returnValue = true as T;
            return returnValue;
          }
        }
      })
      .catch(async (error) => {
        if (error) {
          isError = true;
        }
      })
      .finally(() => {
        if (isError) {
          // Error handling
          if (customRequest.ShowErrorToast) {
            // Show Error Message
            showErrorToast(customRequest.ErrorMessage);
          }
          if (customRequest.LogErrorToDB) {
            // Log Error to DB
          }

          // Loading and error
          setLoading(false);
          setError(true);

          return null;
        }
        if (customRequest.ShowSuccessToast) {
          // SuccessToast
          showSuccessToast(
            isMessageFromBackend
              ? messageFromBackend
              : customRequest.SuccessMessage + ""
          );
        }
      });
  }

  return { fetchCustomData, loading, error };
}
