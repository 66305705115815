import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { setPageTitle } from '../../store/reducers/reducers'
import headerIcon from '../../assets/images/home/lmn/video-tooltips.png'
import { TabPanel } from './TabPanel'
import './Support.scss'
import Documentation from '../../components/distinct/support/Documentation/Documentation'
import FAQs from '../../components/distinct/support/FAQs/FAQs'
import TutorialVideos from '../../components/distinct/support/TutorialVideos/TutorialVideos'

function Support() {
	// Global variables
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)

	//useState
	const [tabValue, setTabValue] = useState(0)

	//handle tab value change
	const handleTabChange = (event: React.SyntheticEvent, newvalue: number) => {
		setTabValue(newvalue)
	}

	// General
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (pageTitle !== 'Knowledge Base') {
			dispatch(setPageTitle('Knowledge Base'))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Box className='support-container'>
				{/* Header */}
				<Box className='support-header' bgcolor='background.superLight'>
					<Box className='flex-box ctr-all'>
						{/* Image */}
						<Box className='support-header-img'>
							<img src={headerIcon} alt='Header logo' />
						</Box>
						{/* title */}
						<Box>
							<Typography component='h1' color='primary.main'>
								Welcome to our Lumen UC Platform Support
							</Typography>
						</Box>
					</Box>
				</Box>

				{/* tab panel - Documents and FAQs */}
				<Box className='support-tab-container'>
					<Tabs value={tabValue} onChange={handleTabChange}>
						<Tab label='Documents' />
						<Tab label='FAQs' />
					</Tabs>

					{/* Documents tab */}
					<TabPanel index={0} value={tabValue}>
						<Documentation />
					</TabPanel>
					<TabPanel index={1} value={tabValue}>
						<FAQs />
					</TabPanel>
				</Box>
				{/* Tutorial videos */}
				<TutorialVideos />
			</Box>
		</>
	)
}

export default Support
