import { useEffect, useState } from 'react'
import CustomerDisplay from '../../../components/distinct/dashboard/CustomerManagement/CustomerDisplay'
import {
	useGetProxyObjectQuery,
	usePostAndRetrieveDataMutation,
} from '../../../services/proxyAPIData'
import { DataResponse } from '../../../utils/interfaces/APIModels'
import {
	showErrorToast,
	toAlphaString,
} from '../../../utils/helperFunctions/helperFunctions'
import { setPageTitle } from '../../../store/reducers/reducers'
import { useAppDispatch } from '../../../store/hooks'
import { Box } from '@mui/material'
import './CustomerManagement.scss'
import Preloader from '../../../components/shared/loading/preloader/Preloader'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { PartnerCodeToIDMapping } from '../../../utils/enums/enums'
import ErrorLogging from '../../../utils/customHooks/ErrorLogging'
import { Customer } from '../../../utils/interfaces/DBModels'

function CustomerManagement() {
	// Need to get from state based on Auth0
	const partnerCode = useSelector(
		(state: RootState) => state.RootReducer.partnerReducer.value
	)

	const [partnerIDtoUse, setPartnerIDtoUse] = useState(
		PartnerCodeToIDMapping[
			`${partnerCode}` as keyof typeof PartnerCodeToIDMapping
		]
	)

	// Error logging
	const { addErrorLog } = ErrorLogging()

	// check if partner exist
	const {
		data: customerPartnerDataResponse,
		isFetching: isFetchingPartner,
		error: customerPartnerError,
		refetch: refetchCustomers,
	} = useGetProxyObjectQuery(
		`CustomerPartner.Exists(CustomerPartner.CustomerPartnerID = '${partnerIDtoUse}')`
	)
	const [isCustomerPartnerRetrieved, setIsCustomerPartnerRetrieved] =
		useState(false)

	// get customers from CDS
	const [
		postAndRetrieveCustomers,
		{ isLoading: postAndRetrieveCustomersLoading },
	] = usePostAndRetrieveDataMutation()

	const [customerList, setCustomerList] = useState({} as Customer[])

	const dispatch = useAppDispatch()

	useEffect(() => {
		var pageTitle = 'Customer Management'
		dispatch(setPageTitle(pageTitle))

		const isPartner = async () => {
			var customerPartnerResponse = {} as DataResponse
			customerPartnerResponse.Obj = {} as boolean
			if (customerPartnerDataResponse?.Content) {
				customerPartnerResponse = JSON.parse(
					await toAlphaString(customerPartnerDataResponse?.Content)
				)

				if (customerPartnerResponse.Obj) {
					getCustomers()
				} else {
					showErrorToast(
						'Partner was not found. Please contact support on this issue'
					)
				}
			}
		}

		const getCustomers = async () => {
			var _customersResponse = {} as DataResponse
			_customersResponse.Obj = {} as Customer[]
			var isError = false
			var toastErrorMessage = ''

			await postAndRetrieveCustomers(
				`Customer.CustomerPartner.Where(Customer.CustomerPartnerID= '${partnerIDtoUse}')`
			)
				.unwrap()
				.then(async (response) => {
					if (response?.Content) {
						_customersResponse = JSON.parse(
							await toAlphaString(response?.Content)
						)
						if (Number(_customersResponse.Count) > 1) {
							var _customerList = _customersResponse.Obj
								.CustomerList as Customer[]
							if (_customerList) {
								setCustomerList(_customerList)
							}
						}
					}
				})
				.catch(async (error) => {
					if (error) {
						isError = true
						toastErrorMessage = 'An error occurred getting customers'
					}
				})
				.finally(async () => {
					if (isError && toastErrorMessage.length > 0) {
						await addErrorLog(
							'Getting customers for partner',
							toastErrorMessage + ' using partnerID: ' + partnerIDtoUse
						)
						showErrorToast(toastErrorMessage)
					}
				})
		}

		if (!isFetchingPartner) {
			if (isCustomerPartnerRetrieved) {
				if (customerPartnerDataResponse?.Content) {
					isPartner()
				} else {
					showErrorToast(
						'Empty response from our server when trying to retrieve customer partner information. Please contact support on this issue'
					)
					return
				}
			} else {
				setIsCustomerPartnerRetrieved(true)
				refetchCustomers()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isFetchingPartner,
		customerPartnerDataResponse,
		customerPartnerError,
		isCustomerPartnerRetrieved,
	])

	return (
		<>
			<Box id='customer-management' className='flex-box col rg'>
				{!postAndRetrieveCustomersLoading && customerList.length > 0 ? (
					<>
						<CustomerDisplay customerList={customerList} />
					</>
				) : (
					<Preloader />
				)}
			</Box>
		</>
	)
}

export default CustomerManagement
