import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { StyledTableCell } from '../StyledTableComponents'

const PlatformLoginRights = () => {
	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					{/* Header */}
					<TableHead>
						<TableRow>
							<StyledTableCell>Name</StyledTableCell>
							<StyledTableCell>Platform</StyledTableCell>
							<StyledTableCell>Type</StyledTableCell>
							<StyledTableCell>Permission</StyledTableCell>
						</TableRow>
					</TableHead>
					{/* Body */}
					<TableBody>
						<TableRow>
							<StyledTableCell>openid</StyledTableCell>
							<StyledTableCell>Microsoft Graph</StyledTableCell>
							<StyledTableCell>Delegated</StyledTableCell>
							<StyledTableCell>Sign users in</StyledTableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}

export default PlatformLoginRights
