import {
	Box,
	Checkbox,
	Divider,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Typography,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import '../FormSections.scss'
import {
	AddonLicenseResponse,
	BaseLicenseResponse,
	TeamsUsersResponse,
} from '../../../../../../../../../utils/interfaces/APIModels'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { TUMLicenseTypes } from '../../../../../../../../../utils/enums/enums'
import LicenseOption from './LicenseOption/LicenseOption'
import EmptyOption from '../EmptyOption/EmptyOption'
import '../FormSections.scss'
import { StyledTextBox } from '../../../../../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import { useTUMContext } from '../../../../../../../../../utils/context/TUMContext'
import ClearIcon from '@mui/icons-material/Clear'

// Handle value change type
type HandleValueChange = (type: keyof TeamsUsersResponse, value: any) => void

const LicenseSection = ({
	user,
	handleValueChange,
}: {
	user: TeamsUsersResponse
	handleValueChange: HandleValueChange
}) => {
	// Context
	const {
		removeBase,
		removeAddon,
		setRemoveBase,
		setRemoveAddon,
		setRemovePhone,
		teamsLicenseDisplay,
	} = useTUMContext()

	// Display constants
	const [baseLicenseInput, setBaseLicenseInput] = useState('')
	const [addonLicenseInput, setAddonLicenseInput] = useState('')
	const [baseLicenseList, setBaseLicenseList] = useState(
		[] as BaseLicenseResponse[]
	)
	const [addonLicenseList, setAddonLicenseList] = useState(
		[] as AddonLicenseResponse[]
	)

	// Flags
	const [addonRequired, setAddonRequired] = useState(false)
	// Menu Flags
	const [showBaseLicenses, setShowBaseLicenses] = useState(false)
	const [showAddonLicenses, setShowAddonLicenses] = useState(false)
	const containerBaseRef = useRef<HTMLDivElement>(null)
	const containerAddonRef = useRef<HTMLDivElement>(null)

	// Use Effect 1: Set the license list and more
	useEffect(() => {
		// Set all the license values
		handleSetVariables()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Use Effect 2: Handle click away to close dropdown
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	// Handle setting all variables
	const handleSetVariables = () => {
		// 1 - Sort both license lists
		const tempBaseLicenses = teamsLicenseDisplay.baseLicenses.sort((a, b) => {
			const availableA = a.numberPurchased - a.numberAssigned
			const availableB = b.numberPurchased - b.numberAssigned
			return availableB - availableA
		})
		const tempAddonLicenses = teamsLicenseDisplay.addonLicenses.sort((a, b) => {
			const availableA = a.numberPurchased - a.numberAssigned
			const availableB = b.numberPurchased - b.numberAssigned
			return availableB - availableA
		})
		// Set new lists in order so the available will show first
		setBaseLicenseList(tempBaseLicenses)
		setAddonLicenseList(tempAddonLicenses)

		// 2 - Check if base assigned then if add-on required
		if (user.baseLicenseName) {
			var baseExists = teamsLicenseDisplay.baseLicenses.find(
				(b) => b.baseLicenseName === user.baseLicenseName
			)
			if (baseExists) {
				// Set base
				setBaseLicenseInput(baseExists.baseLicenseName)
				// Check if addon is required
				setAddonRequired(baseExists.addonRequired)

				// Check add-on
				if (
					baseExists.addonRequired &&
					user.addonLicenseName &&
					!user.addonLicenseName.includes('Incompatible')
				) {
					var addonExists = teamsLicenseDisplay.addonLicenses.find(
						(a) => a.addonLicenseName === user.addonLicenseName
					)
					if (addonExists) {
						// Set add-on
						setAddonLicenseInput(addonExists.addonLicenseName)
					}
				}
			}
		}
	}

	// Close dropdown when clicking outside
	const handleClickOutside = (event: MouseEvent) => {
		// Base
		if (
			containerBaseRef.current &&
			!containerBaseRef.current.contains(event.target as Node)
		) {
			setShowBaseLicenses(false)
		}
		// Add-on
		if (
			containerAddonRef.current &&
			!containerAddonRef.current.contains(event.target as Node)
		) {
			setShowAddonLicenses(false)
		}
	}

	// Handle user input
	const handleInputChange = (
		e: ChangeEvent<HTMLInputElement>,
		licenseType: number
	) => {
		const value = e.target.value
		// Check type
		switch (licenseType) {
			// Base
			case TUMLicenseTypes.Base:
				setBaseLicenseInput(value)

				// Step 1: Filter the licenses
				var filteredBaseLicenses = teamsLicenseDisplay.baseLicenses.filter(
					(b) => b.baseLicenseName.toLowerCase().includes(value.toLowerCase())
				)
				setBaseLicenseList(filteredBaseLicenses)

				// Step 2: Look for if whole input matches the license name in list
				if (
					teamsLicenseDisplay.baseLicenses.find(
						(b) => b.baseLicenseName === value
					)
				) {
					handleLicenseSelection(value, TUMLicenseTypes.Base)
				} else {
					setShowBaseLicenses(true)
				}
				break
			// Add-on 1
			case TUMLicenseTypes.Addon:
				setAddonLicenseInput(value)

				// Step 1: Filter licenses
				var filteredAddonLicenses = teamsLicenseDisplay.addonLicenses.filter(
					(a) => a.addonLicenseName.toLowerCase().includes(value.toLowerCase())
				)
				setAddonLicenseList(filteredAddonLicenses)

				// Step 2: If the whole license string is matched
				if (
					teamsLicenseDisplay.addonLicenses.find(
						(a) => a.addonLicenseName === value
					)
				) {
					handleLicenseSelection(value, TUMLicenseTypes.Addon)
				} else {
					setShowAddonLicenses(true)
				}
				break
		}
	}

	// Handle user selection
	const handleLicenseSelection = (license: string, licenseType: number) => {
		// Once a license is selected, set the value
		if (license) {
			// Check which one
			switch (licenseType) {
				// Base
				case TUMLicenseTypes.Base:
					// Ensure it exists and set the variables
					var baseExists = teamsLicenseDisplay.baseLicenses.find(
						(b) => b.baseLicenseName === license
					)
					if (baseExists) {
						setBaseLicenseInput(license)
						setShowBaseLicenses(false)

						// Check if addon is required
						setAddonRequired(baseExists.addonRequired)

						// Handle value change
						handleValueChange('baseLicenseSKUID', baseExists.baseLicenseSKUID)
						handleValueChange('baseLicenseName', license)
					}
					break
				// Add-on 1
				case TUMLicenseTypes.Addon:
					// Ensure it exists and set the variables
					var addonExists = teamsLicenseDisplay.addonLicenses.find(
						(a) => a.addonLicenseName === license
					)
					if (addonExists) {
						setAddonLicenseInput(license)
						setShowAddonLicenses(false)

						// Handle value change
						handleValueChange(
							'addonLicenseSKUID',
							addonExists.addonLicenseSKUID
						)
						handleValueChange('addonLicenseName', license)
					}
					break
			}
		}
	}

	// Handle remove
	// Base
	const handleRemoveBase = (e: ChangeEvent<HTMLInputElement>) => {
		setRemoveBase(e.target.checked)
		if (user.addonLicenseName) {
			setRemoveAddon(e.target.checked)
		}
		if (user.phoneNumber) {
			setRemovePhone(e.target.checked)
		}
	}
	// Add-on
	const handleRemoveAddon = (e: ChangeEvent<HTMLInputElement>) => {
		setRemoveAddon(e.target.checked)
		if (user.phoneNumber) {
			setRemovePhone(e.target.checked)
		}
	}

	// Handle Focus Functions
	// Base
	const handleBaseFocus = () => {
		setShowBaseLicenses(true)
	}
	// Add-on
	const handleAddonFocus = () => {
		setShowAddonLicenses(true)
	}

	// Handle clear
	// Base
	const handleBaseClear = () => {
		setBaseLicenseInput('')
		handleValueChange('baseLicenseSKUID', '')
		handleValueChange('baseLicenseName', '')
	}
	// Add-on
	const handleAddonClear = () => {
		setAddonLicenseInput('')
		handleValueChange('addonLicenseSKUID', '')
		handleValueChange('addonLicenseName', '')
	}

	return (
		<>
			<Box className='tum-form-section-container'>
				{/* Section Header */}
				<Box className='section-header'>
					{/* Label and Information */}
					<Box className='section-label'>
						<Typography component='h5'>License Configuration</Typography>
						{/* Extra Information */}
						<IconButton>
							<InfoIcon />
						</IconButton>
					</Box>
				</Box>
				{/* Divider */}
				<Divider />
				{/* Form Elements */}
				{/* 1 - Base License */}
				{/* Remove */}
				{user.baseLicenseName && user.baseLicenseName.length > 0 && (
					<Box className='checkbox'>
						<FormControlLabel
							control={
								<Checkbox
									sx={{ color: 'text.header' }}
									checked={removeBase}
									onChange={handleRemoveBase}
								/>
							}
							label='Remove Base License'
							sx={{ color: 'text.header' }}
						/>
					</Box>
				)}
				<Box className='section-form'>
					{/* Label */}
					<Box className='tum-form-label'>
						<Typography component='p'>Base License:</Typography>
					</Box>
					{/* Autocomplete */}
					<Box className='tum-form-field' ref={containerBaseRef}>
						<StyledTextBox
							disabled={removeBase}
							id='base-autocomplete'
							fullWidth
							type='text'
							value={baseLicenseInput}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								handleInputChange(e, TUMLicenseTypes.Base)
							}
							onFocus={handleBaseFocus}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										{baseLicenseInput.length > 0 && (
											<IconButton
												disabled={removeBase}
												onClick={handleBaseClear}>
												<ClearIcon />
											</IconButton>
										)}
									</InputAdornment>
								),
							}}
						/>
						{/* Autocomplete options */}
						{showBaseLicenses && (
							<ul>
								{baseLicenseList.length > 0 ? (
									baseLicenseList.map((item, index) => (
										<LicenseOption
											key={index}
											licenseType={TUMLicenseTypes.Base}
											availableUnits={
												item.numberPurchased - item.numberAssigned
											}
											displayName={item.baseLicenseName}
											handleLicenseSelection={handleLicenseSelection}
											requireAddon={item.addonRequired}
										/>
									))
								) : (
									<EmptyOption header='No Base Licenses Available' />
								)}
							</ul>
						)}
					</Box>
				</Box>
				{/* 2 - Add-on License 1 */}
				{user.addonLicenseName && user.addonLicenseName.length > 0 && (
					<Box className='checkbox'>
						<FormControlLabel
							control={
								<Checkbox
									sx={{ color: 'text.header' }}
									checked={removeAddon}
									onChange={handleRemoveAddon}
								/>
							}
							label='Remove Add-on License'
							sx={{ color: 'text.header' }}
						/>
					</Box>
				)}
				{addonRequired && (
					<Box className='section-form'>
						{/* Label */}
						<Typography component='p'>Add-on License:</Typography>
						{/* Autocomplete */}
						<Box className='tum-form-field' ref={containerAddonRef}>
							<StyledTextBox
								disabled={removeBase || removeAddon}
								id='add-on-autocomplete'
								fullWidth
								type='text'
								value={addonLicenseInput}
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									handleInputChange(e, TUMLicenseTypes.Addon)
								}
								onFocus={handleAddonFocus}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											{addonLicenseInput.length > 0 && (
												<IconButton
													disabled={removeBase || removeAddon}
													onClick={handleAddonClear}>
													<ClearIcon />
												</IconButton>
											)}
										</InputAdornment>
									),
								}}
							/>
							{/* Autocomplete Options */}
							{showAddonLicenses && (
								<ul>
									{/* Options mapping */}
									{addonLicenseList.length > 0 ? (
										addonLicenseList.map((item, index) => (
											<LicenseOption
												key={index}
												licenseType={TUMLicenseTypes.Addon}
												availableUnits={
													item.numberPurchased - item.numberAssigned
												}
												displayName={item.addonLicenseName}
												handleLicenseSelection={handleLicenseSelection}
											/>
										))
									) : (
										<EmptyOption header='No Add-on Licenses Available' />
									)}
								</ul>
							)}
						</Box>
					</Box>
				)}
			</Box>
		</>
	)
}

export default LicenseSection
