import { GridColDef, GridToolbar } from '@mui/x-data-grid-pro'
import { PriceBookDisplay } from '../../../../utils/interfaces/ComponentModels'
import { useState, useEffect } from 'react'
import { GridColumnVisibilityModel } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { StyledDataGrid } from '../../../shared/datagrid/StyledDataGrid'

const PriceBooksOverviewDisplay = ({
	priceBookDisplay,
	showTermColumn,
	showRangeColumn,
}: {
	priceBookDisplay: PriceBookDisplay[]
	showTermColumn: boolean
	showRangeColumn: boolean
}) => {
	const [columnVisibilityModel, setColumnVisibilityModel] =
		useState<GridColumnVisibilityModel>({} as GridColumnVisibilityModel)
	const [priceBookRows, setPriceBookRows] = useState([] as PriceBookDisplay[])

	useEffect(() => {
		// Set the rows
		if (priceBookDisplay) {
			setPriceBookRows(priceBookDisplay)

			setColumnVisibilityModel({
				PriceBookTerm: showTermColumn,
				PriceBookRange: showRangeColumn,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [priceBookDisplay])

	const priceBookColumns: GridColDef[] = [
		{
			field: 'PriceBookTerm',
			headerName: 'Term',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'PriceBookRange',
			headerName: 'Range',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'PriceBookSipcomMRC',
			headerName: 'Sipcom MRC/Unit',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'PriceBookSipcomNRC',
			headerName: 'Sipcom NRC/Unit',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'PriceBookPartnerMRC',
			headerName: 'Partner MRC/Unit',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'PriceBookPartnerNRC',
			headerName: 'Partner NRC/Unit',
			width: 250,
			hideable: false,
			flex: 1,
		},
	]

	return (
		<>
			<Box id='pricebook-list'>
				{priceBookRows.length > 0 ? (
					<StyledDataGrid
						sx={{ maxHeight: '700px' }}
						rows={priceBookRows}
						columns={priceBookColumns}
						editMode='row'
						checkboxSelection={false}
						columnVisibilityModel={columnVisibilityModel}
						slots={{
							toolbar: GridToolbar,
						}}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
								quickFilterProps: { debounceMs: 500 },
							},
						}}
						getRowId={(row) => row.PriceBookID}
						rowSelection={false}
					/>
				) : null}
			</Box>
		</>
	)
}
export default PriceBooksOverviewDisplay
