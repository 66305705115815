import { AccountInfo } from '@azure/msal-browser'
import { RootState } from './../store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {
	CustomerOrderSKUInfo,
	CustomerOrderInfoForTrunks,
	CustomerOrderInfo,
} from '../../utils/interfaces/StateModels'
import { RolePermissionMap } from '../../utils/interfaces/DBModels'
import {
	GetPOCInstancesResponseModel,
	RolePermissionResponse,
	SRECreateIfNotExistRequestModel,
	User,
} from '../../utils/interfaces/APIModels'
import {
	AddTrunkGroupIPObject,
	AddTrunkGroupObject,
} from '../../utils/interfaces/ComponentModels'

// Interfaces
interface PartnerState {
	value: string
}

interface CustomerOrderToUseState {
	value: CustomerOrderInfo
}

interface CustomerOrderSKUToUseState {
	value: CustomerOrderSKUInfo
}

interface CustomerOrderForTrunksToUseState {
	value: CustomerOrderInfoForTrunks
}

interface CustomerInfoForSREToUseState {
	value: SRECreateIfNotExistRequestModel
}

interface GlobalAdminUserState {
	value: string
}

interface TeamsAdminUserState {
	value: string
}

interface TeamsCommunicationsAdminUserState {
	value: string
}

interface ConfirmedAccountState {
	value: AccountInfo
}

interface PageTitleState {
	value: string
}

interface CustomerIDState {
	value: string
}

interface LoggedInUserRolesPermissionsState {
	value: RolePermissionResponse[]
}

interface AccessTokenState {
	value: string
}

interface LoggedInUserState {
	value: User
}

interface InstanceStatusListState {
	value: GetPOCInstancesResponseModel[]
}

interface AddTrunkGroupObjectState {
	value: AddTrunkGroupObject
}

interface AddTrunkGroupIPInfoObjectState {
	value: AddTrunkGroupIPObject
}

// Pass in order SKUID for trunks
interface OrderSKUIDForTrunkState {
	value: number
}

// Pagination for Order MGT
interface OrderFilterState {
	pageNo: number
	pageSize: number
	filters: Record<string, string>
}

// Initial State
const initialPartnerState: PartnerState = {
	value: '',
}

const initialCustomerOrderToUseState: CustomerOrderToUseState = {
	value: {} as CustomerOrderInfo,
}

const initialCustomerOrderSKUToUseState: CustomerOrderSKUToUseState = {
	value: {} as CustomerOrderSKUInfo,
}

const initialCustomerOrderForTrunksToUseState: CustomerOrderForTrunksToUseState =
	{
		value: {} as CustomerOrderInfoForTrunks,
	}

const initialCustomerInfoForSREToUseState: CustomerInfoForSREToUseState = {
	value: {} as SRECreateIfNotExistRequestModel,
}

const initialGlobalAdminUserStateState: GlobalAdminUserState = {
	value: '',
}

const initialTeamsAdminUserState: TeamsAdminUserState = {
	value: '',
}

const initialTeamsCommunicationsAdminUserState: TeamsCommunicationsAdminUserState =
	{
		value: '',
	}

const initialConfirmedAccountState: ConfirmedAccountState = {
	value: {
		environment: '',
		homeAccountId: '',
		localAccountId: '',
		tenantId: '',
		username: '',
		idToken: '',
		name: '',
		nativeAccountId: '',
	} as AccountInfo,
}

const initialPageTitleState: PageTitleState = {
	value: '',
}

const initialCustomerIDState: CustomerIDState = {
	value: '',
}

const initialLoggedInUserRolesPermissionsState: LoggedInUserRolesPermissionsState =
	{
		value: [] as RolePermissionResponse[],
	}

const initialAccessTokenState: AccessTokenState = {
	value: '',
}

const initialLoggedInUserState: LoggedInUserState = {
	value: {} as User,
}

const initialInstanceStatusListState: InstanceStatusListState = {
	value: [] as GetPOCInstancesResponseModel[],
}

const initialAddTrunkGroupObjectInfoState: AddTrunkGroupObjectState = {
	value: {} as AddTrunkGroupObject,
}

const initialAddTrunkGroupIPInfoObjectState: AddTrunkGroupIPInfoObjectState = {
	value: {} as AddTrunkGroupIPObject,
}

const initialOrderSKUIDForTrunkState: OrderSKUIDForTrunkState = {
	value: 0,
}

const initialOrderFilterState: OrderFilterState = {
	pageNo: 1,
	pageSize: 10,
	filters: {},
}

// Slices
export const PartnerSlice = createSlice({
	name: 'partner',
	initialState: initialPartnerState,
	reducers: {
		setPartner: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})

export const CustomerOrderInfoToUseSlice = createSlice({
	name: 'customerOrderInfoToUse',
	initialState: initialCustomerOrderToUseState,
	reducers: {
		setCustomerOrderInfoToUse: (
			state,
			action: PayloadAction<CustomerOrderInfo>
		) => {
			state.value = action.payload
		},
	},
})

export const CustomerOrderSKUInfoToUseSlice = createSlice({
	name: 'customerOrderSKUInfoToUse',
	initialState: initialCustomerOrderSKUToUseState,
	reducers: {
		setCustomerOrderSKUInfoToUse: (
			state,
			action: PayloadAction<CustomerOrderSKUInfo>
		) => {
			state.value = action.payload
		},
	},
})

export const CustomerOrderInfoForTrunksToUseSlice = createSlice({
	name: 'customerOrderInfoForTrunksToUse',
	initialState: initialCustomerOrderForTrunksToUseState,
	reducers: {
		setCustomerOrderInfoForTrunksToUse: (
			state,
			action: PayloadAction<CustomerOrderInfoForTrunks>
		) => {
			state.value = action.payload
		},
	},
})

export const CustomerInfoForSREToUseSlice = createSlice({
	name: 'customerInfoForSREToUse',
	initialState: initialCustomerInfoForSREToUseState,
	reducers: {
		setCustomerInfoForSREToUse: (
			state,
			action: PayloadAction<SRECreateIfNotExistRequestModel>
		) => {
			state.value = action.payload
		},
	},
})

export const GlobalAdminUserSlice = createSlice({
	name: 'globalAdminUser',
	initialState: initialGlobalAdminUserStateState,
	reducers: {
		setGlobalAdminUser: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})

export const TeamsAdminUserSlice = createSlice({
	name: 'teamsAdminUser',
	initialState: initialTeamsAdminUserState,
	reducers: {
		setTeamsAdminUser: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})

export const TeamsCommunicationsAdminUserSlice = createSlice({
	name: 'teamsAdminUser',
	initialState: initialTeamsCommunicationsAdminUserState,
	reducers: {
		setTeamsCommunicationsAdminUser: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})

export const ConfirmedAccountSlice = createSlice({
	name: 'confirmedAccount',
	initialState: initialConfirmedAccountState,
	reducers: {
		setConfirmedAccount: (state, action: PayloadAction<AccountInfo>) => {
			state.value = action.payload
		},
	},
})

export const PageTitleSlice = createSlice({
	name: 'pageTitle',
	initialState: initialPageTitleState,
	reducers: {
		setPageTitle: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})

export const LoggedInUserRolesPermisionsSlice = createSlice({
	name: 'loggedInUserPermissions',
	initialState: initialLoggedInUserRolesPermissionsState,
	reducers: {
		setLoggedInUserRolesPermissions: (
			state,
			action: PayloadAction<RolePermissionMap[]>
		) => {
			state.value = action.payload
		},
	},
})
export const CustomerIDSlice = createSlice({
	name: 'customerID',
	initialState: initialCustomerIDState,
	reducers: {
		setCustomerID: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})

export const AccessTokenSlice = createSlice({
	name: 'accessToken',
	initialState: initialAccessTokenState,
	reducers: {
		setAccessToken: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})

export const LoggedInUserSlice = createSlice({
	name: 'loggedInUser',
	initialState: initialLoggedInUserState,
	reducers: {
		setLoggedInUser: (state, action: PayloadAction<User>) => {
			state.value = action.payload
		},
	},
})

export const InstanceStatusListSlice = createSlice({
	name: 'instanceStatusList',
	initialState: initialInstanceStatusListState,
	reducers: {
		setInstanceStatusList: (
			state,
			action: PayloadAction<GetPOCInstancesResponseModel[]>
		) => {
			state.value = action.payload
		},
	},
})

export const AddTrunkGroupObjectSlice = createSlice({
	name: 'addTrunkGroupObject',
	initialState: initialAddTrunkGroupObjectInfoState,
	reducers: {
		setAddTrunkGroupObject: (
			state,
			action: PayloadAction<AddTrunkGroupObject>
		) => {
			state.value = action.payload
		},
	},
})

export const AddTrunkIPInfoSlice = createSlice({
	name: 'addTrunkIPInfo',
	initialState: initialAddTrunkGroupIPInfoObjectState,
	reducers: {
		setAddTrunkIPInfo: (
			state,
			action: PayloadAction<AddTrunkGroupIPObject>
		) => {
			state.value = action.payload
		},
	},
})

export const OrderSKUIDForTrunkSlice = createSlice({
	name: 'orderSKUIDForTrunk',
	initialState: initialOrderSKUIDForTrunkState,
	reducers: {
		setOrderSKUIDForTrunk: (state, action: PayloadAction<number>) => {
			state.value = action.payload
		},
	},
})

export const OrderFilterSlice = createSlice({
	name: 'orderFilter',
	initialState: initialOrderFilterState,
	reducers: {
		setOrderPageNo: (state, action: PayloadAction<number>) => {
			state.pageNo = action.payload
		},
		setOrderPageSize: (state, action: PayloadAction<number>) => {
			state.pageSize = action.payload
		},
		setOrderFilter: (state, action: PayloadAction<Record<string, string>>) => {
			state.filters = { ...state.filters, ...action.payload }
		},
		removeOrderFilter: (state, action: PayloadAction<string>) => {
			delete state.filters[action.payload]
		},
		resetOrderFilters: (state) => {
			state.filters = {}
		},
	},
})

// Set actions
export const { setPartner } = PartnerSlice.actions
export const { setCustomerOrderInfoToUse } = CustomerOrderInfoToUseSlice.actions
export const { setCustomerOrderSKUInfoToUse } =
	CustomerOrderSKUInfoToUseSlice.actions
export const { setGlobalAdminUser } = GlobalAdminUserSlice.actions
export const { setTeamsAdminUser } = TeamsAdminUserSlice.actions
export const { setTeamsCommunicationsAdminUser } =
	TeamsCommunicationsAdminUserSlice.actions
export const { setConfirmedAccount } = ConfirmedAccountSlice.actions
export const { setPageTitle } = PageTitleSlice.actions
export const { setCustomerID } = CustomerIDSlice.actions
export const { setLoggedInUserRolesPermissions } =
	LoggedInUserRolesPermisionsSlice.actions
export const { setAccessToken } = AccessTokenSlice.actions
export const { setLoggedInUser } = LoggedInUserSlice.actions
export const { setInstanceStatusList } = InstanceStatusListSlice.actions
export const { setAddTrunkGroupObject } = AddTrunkGroupObjectSlice.actions
export const { setAddTrunkIPInfo } = AddTrunkIPInfoSlice.actions
export const { setCustomerOrderInfoForTrunksToUse } =
	CustomerOrderInfoForTrunksToUseSlice.actions
export const { setCustomerInfoForSREToUse } =
	CustomerInfoForSREToUseSlice.actions
export const { setOrderSKUIDForTrunk } = OrderSKUIDForTrunkSlice.actions
export const {
	setOrderPageNo,
	setOrderPageSize,
	setOrderFilter,
	removeOrderFilter,
	resetOrderFilters,
} = OrderFilterSlice.actions

// Get actions
export const getPartner = (state: RootState) =>
	state.RootReducer.partnerReducer.value
export const getCustomerOrderInfoToUse = (state: RootState) =>
	state.RootReducer.customerOrderInfoToUseReducer.value
export const getGlobalAdminUser = (state: RootState) =>
	state.RootReducer.globalAdminUserReducer.value
export const getTeamsAdminUser = (state: RootState) =>
	state.RootReducer.teamsAdminUserReducer.value
export const getTeamsCommunicationsAdminUser = (state: RootState) =>
	state.RootReducer.teamsAdminUserReducer.value
export const getConfirmedAccount = (state: RootState) =>
	state.RootReducer.confirmedAccountReducer.value
export const getPageTitle = (state: RootState) =>
	state.RootReducer.pageTitleReducer.value
export const getCustomerID = (state: RootState) =>
	state.RootReducer.customerIDReducer.value
export const getLoggedInUserRolesPermissions = (state: RootState) =>
	state.RootReducer.loggedInUserRolesPermissionsReducer.value
export const getCustomerOrderInfoForTrunksToUse = (state: RootState) =>
	state.RootReducer.customerOrderInfoForTrunksToUseReducer.value
export const getOrderSKUIDForTrunk = (state: RootState) =>
	state.RootReducer.orderSKUIDForTrunkReducer.value

// Reducers
var partnerReducer = PartnerSlice.reducer
var customerOrderInfoToUseReducer = CustomerOrderInfoToUseSlice.reducer
var customerOrderSKUInfoToUseReducer = CustomerOrderSKUInfoToUseSlice.reducer
var globalAdminUserReducer = GlobalAdminUserSlice.reducer
var teamsAdminUserReducer = TeamsAdminUserSlice.reducer
var teamsCommunicationsAdminUserReducer =
	TeamsCommunicationsAdminUserSlice.reducer
var confirmedAccountReducer = ConfirmedAccountSlice.reducer
var pageTitleReducer = PageTitleSlice.reducer
var customerIDReducer = CustomerIDSlice.reducer
var loggedInUserRolesPermissionsReducer =
	LoggedInUserRolesPermisionsSlice.reducer
var accessTokenReducer = AccessTokenSlice.reducer
var loggedInUserReducer = LoggedInUserSlice.reducer
var instanceStatusListReducer = InstanceStatusListSlice.reducer
var addTrunkGroupObjectReducer = AddTrunkGroupObjectSlice.reducer
var addTrunkGroupIPObjectReducer = AddTrunkIPInfoSlice.reducer
var customerOrderInfoForTrunksToUseReducer =
	CustomerOrderInfoForTrunksToUseSlice.reducer
var customerInfoForSREToUseReducer = CustomerInfoForSREToUseSlice.reducer
var orderSKUIDForTrunkReducer = OrderSKUIDForTrunkSlice.reducer
var orderFilterReducer = OrderFilterSlice.reducer

const rootReducer = combineReducers({
	partnerReducer,
	customerOrderInfoToUseReducer,
	customerOrderSKUInfoToUseReducer,
	pageTitleReducer,
	globalAdminUserReducer,
	teamsAdminUserReducer,
	teamsCommunicationsAdminUserReducer,
	confirmedAccountReducer,
	customerIDReducer,
	loggedInUserRolesPermissionsReducer,
	accessTokenReducer,
	loggedInUserReducer,
	instanceStatusListReducer,
	addTrunkGroupObjectReducer,
	addTrunkGroupIPObjectReducer,
	customerOrderInfoForTrunksToUseReducer,
	customerInfoForSREToUseReducer,
	orderSKUIDForTrunkReducer,
	orderFilterReducer,
})

export default rootReducer
