import { v4 as uuidv4 } from 'uuid'
import {
	getEmptyAPIMutationObject,
	getUTCDateTimeNow,
	toBetaString,
} from '../helperFunctions/helperFunctions'
import { Log } from '../interfaces/DBModels'
import { AddRequest, BetaObject, ErrorLog } from '../interfaces/APIModels'
import { usePostBetaObjectWithoutRefetchMutation } from '../../services/proxyAPIData'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

const ErrorLogging = () => {
	// Global variables
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// Post
	const [postWithoutRefetch] = usePostBetaObjectWithoutRefetchMutation()

	// This function adds an error to the DB. Passes in the action performed and error information
	const addErrorLog = async (actionPerformed: string, errorInfo: string) => {
		// Custom UUID and formatted data
		var requestID = uuidv4()

		var log: Log = {
			RequestID: requestID,
			ActionPerformed: `${process.env.REACT_APP_NAME}: ${actionPerformed}`,
			ErrorInfo: errorInfo,
			LogTime: getUTCDateTimeNow(),
		}

		var errorLog: ErrorLog = {
			Log: log,
		}

		var addLogObj: AddRequest = {
			Obj: errorLog,
			UserID: loggedInUser.userID + '',
			UserName: loggedInUser.email + '',
		}

		var apiMutation = getEmptyAPIMutationObject()
		apiMutation.QueryParam = 'AddV2?Params=Log'
		var betaObj: BetaObject = {
			Content: await toBetaString(JSON.stringify(addLogObj)),
		}

		apiMutation.BetaObject = betaObj

		// post to DB
		await postWithoutRefetch(apiMutation).unwrap().catch()
	}

	return { addErrorLog }
}

export default ErrorLogging
