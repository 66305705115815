import { Alert, styled } from '@mui/material'

export const StyledAlert = styled(Alert)(({ theme }) => ({
  '&.MuiAlert-root.MuiAlert-standardSuccess': {
    backgroundColor: 'rgba(155, 198, 71, 0.2)',
    color: 'rgb(30, 70, 32)',
  },
  '&.MuiAlert-root.MuiAlert-standardError': {
    backgroundColor: 'rgba(211, 47, 47, 0.2)',
    color: 'rgb(95, 33, 32)',
  },
  '&.MuiAlert-root.MuiAlert-standardSuccess .MuiAlert-icon': {
    color: '#2e7d32',
  },
  '&.MuiAlert-root.MuiAlert-standardError .MuiAlert-icon': {
    color: '#d32f2f',
  },
}))
