import {
	gridPageCountSelector,
	gridPageSelector,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid'
import { Theme, styled } from '@mui/material/styles'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import { DataGridPro } from '@mui/x-data-grid-pro'

export function customCheckbox(theme: Theme) {
	return {
		'& .MuiCheckbox-root svg': {
			width: 16,
			height: 16,
			backgroundColor: 'transparent',
			border: `1px solid ${
				theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
			}`,
			borderRadius: 2,
		},
		'& .MuiCheckbox-root svg path': {
			display: 'none',
		},
		'& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
			backgroundColor: '#0075C9',
			borderColor: '#0075C9',
		},
		'& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
			position: 'absolute',
			display: 'table',
			border: '2px solid #fff',
			borderTop: 0,
			borderLeft: 0,
			transform: 'rotate(45deg) translate(-50%,-50%)',
			opacity: 1,
			transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
			content: '""',
			top: '50%',
			left: '39%',
			width: 5.71428571,
			height: 9.14285714,
		},
		'& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after':
			{
				width: 8,
				height: 8,
				backgroundColor: '#0075C9',
				transform: 'none',
				top: '39%',
				border: 0,
			},
	}
}

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
	color: theme.palette.mode === 'light' ? '#000' : '#fff',
	WebkitFontSmoothing: 'auto',
	letterSpacing: 'normal',
	border: 0,
	'& .MuiDataGrid-main': {
		boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
		borderLeft: '1px solid rgba(224, 224, 224, 1)',
		borderTop: '1px solid rgba(224, 224, 224, 1)',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},
	'& .MuiDataGrid-columnsContainer': {
		backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
	},
	'& .MuiDataGrid-iconSeparator': {
		color: 'rgba(224, 224, 224, 1)!important',
	},
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: '#E0F3FF!important',
		color: '#000',
		borderBottomColor: 'rgba(224, 224, 224, 1)',
	},
	'& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
		borderRight: `1px solid rgba(224, 224, 224, 1)`,
	},
	'& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
		borderBottom: `1px solid rgba(224, 224, 224, 1)`,
	},
	'& .MuiDataGrid-cell': {
		backgroundColor: '#fff',
		color: '#000',
	},
	'& .MuiDataGrid-booleanCell': {
		color: 'rgba(0, 0, 0, 0.38)!important',
	},
	'& .MuiPaginationItem-root': {
		borderRadius: 0,
		color: theme.palette.mode === 'light' ? '#000' : '#fff',
	},
	'& .MuiSvgIcon-root': {
		color: theme.palette.mode === 'light' ? '#000' : '#fff',
	},
	'& .actions .MuiSvgIcon-root': {
		color: '#0075C9',
	},
	'& .MuiPaginationItem-root .MuiSvgIcon-root': {
		color: theme.palette.mode === 'light' ? '#000' : '#fff',
	},
	...customCheckbox(theme),
}))

export function CustomPagination() {
	const apiRef = useGridApiContext()
	const page = useGridSelector(apiRef, gridPageSelector)
	const pageCount = useGridSelector(apiRef, gridPageCountSelector)

	return (
		<Pagination
			color='primary'
			variant='outlined'
			shape='rounded'
			page={page + 1}
			count={pageCount}
			// @ts-expect-error
			renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
			onChange={(event: React.ChangeEvent<unknown>, value: number) =>
				apiRef.current.setPage(value - 1)
			}
		/>
	)
}
