import '../../../App.scss'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ComponentType } from 'react'
import { withRoleBasedRedirect } from './RoleBasedRedirect'
import Preloader from '../loading/preloader/Preloader'

interface ProtectedRouteProps {
	component: ComponentType
	permissionID: number
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
	component,
	permissionID,
}) => {
	const Component = withAuthenticationRequired(
		withRoleBasedRedirect(component, { permissionID }),
		{
			onRedirecting: () => {
				return <Preloader />
			},
			returnTo: window.location.pathname,
		}
	)

	return <Component />
}
