import { Box, Typography } from '@mui/material'

const TeamsUserManagementAnswer1 = () => {
	return (
		<>
			<Box className='documentation-answer-container'>
				{/* Question - What rights do I need to perform actions in User Management?*/}
				<Typography component='ul'>
					<Typography component='li'>
						Your account must have an <b>RBAC role</b> of Global Administrator
						or Teams Administrator to <b>perform</b> all the <b>actions</b> in
						User Management.
					</Typography>
					<Typography component='li'>
						See the FAQ on Admin Roles to see the process to check what Roles
						you have assigned.
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export default TeamsUserManagementAnswer1
