import './POC.scss'
import InstancesDisplayList from '../../components/distinct/pocInstances/InstancesDisplay'
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../store/hooks'
import {
	setInstanceStatusList,
	setPageTitle,
} from '../../store/reducers/reducers'
import { InstanceStatuses } from '../../utils/enums/enums'
import Preloader from '../../components/shared/loading/preloader/Preloader'
import {
	GetPOCInstancesResponseModel,
	PostHookObj,
} from '../../utils/interfaces/APIModels'
import { Box, Typography } from '@mui/material'
import CustomCard from '../../components/shared/card/CustomCard'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import PostAndRetrieveDataHook from '../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import { OrderList } from '../../utils/interfaces/ComponentModels'
import PostCustomAPIHook from '../../utils/customHooks/APICalls/PostCustomAPIHook'

function POCInstances() {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const [pocInstances, setPOCInstances] = useState(
		[] as GetPOCInstancesResponseModel[]
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)
	const [dataRetrieved, setDataRetrieved] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [showAddOrderButton, setShowAddOrderButton] = useState(false)

	// Hooks
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()
	const { postCustomAPI } = PostCustomAPIHook()

	useEffect(() => {
		if (pageTitle !== 'Trial Order') {
			dispatch(setPageTitle('Trial Order'))
		}

		if (!dataRetrieved) {
			setDataRetrieved(true)
			getPOCInstances()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getPOCInstances = async () => {
		var postTrialInstancesObj: PostHookObj = {
			Action: `Get Trial Instances`,
			LoggedInUser: loggedInUser,
			RequestURL: 'trial/GetPOCInstances',
			RequestObj: {},
			ShowSuccessMessage: false,
			SuccessMessage: `Retrieved trial instances successfully`,
			ShowErrorMessage: true,
			ErrorMessage: `Unable to get trial instances`,
			LogErrorToDB: true,
		}
		var getTrialInstancesResponse = await postCustomAPI(postTrialInstancesObj)
		if (getTrialInstancesResponse && !getTrialInstancesResponse.isError) {
			var instancesResponse = JSON.parse(
				getTrialInstancesResponse.Content
			) as GetPOCInstancesResponseModel[]

			var _instances = [] as GetPOCInstancesResponseModel[]
			var hasAvaliableInstance = false
			instancesResponse.forEach((instance: GetPOCInstancesResponseModel) => {
				if (instance.POCInstanceStatusID === InstanceStatuses.Available) {
					hasAvaliableInstance = true
				}
				_instances.push(instance)
			})

			//initialize empty array for orderids
			var orderIdToUse = []

			for (var z = 0; z < _instances.length; z++) {
				//only append orderids that are not null
				if (_instances[z].OrderID !== null) {
					orderIdToUse.push(_instances[z].OrderID)
				}
			}
			//send through list of orderids
			if (orderIdToUse.length > 0 && orderIdToUse !== undefined) {
				var dataResponse = await postAndRetrieveDataFromDB(
					'Orders',
					`Order.Customer.Where(Order.OrderID^'${orderIdToUse}')`,
					false,
					false
				)

				if (
					dataResponse &&
					Number(dataResponse.Count) > 0 &&
					dataResponse.Obj
				) {
					var orderList = dataResponse.Obj.OrderList as OrderList[]

					// Find matching order IDs between _instances and orderList
					_instances = _instances.map((x) => {
						var matchingID = orderList.find((y) => x.OrderID === y.OrderID)
						if (matchingID !== undefined) {
							return { ...x, Customer: matchingID.Customer }
						}
						return x
					})
				}
			}
			setShowAddOrderButton(hasAvaliableInstance)
			setPOCInstances(_instances)
			dispatch(setInstanceStatusList(_instances))
		}
		setIsLoading(false)
	}

	return (
		<>
			{!isLoading ? (
				<Box className='poc-container'>
					<Box className='poc-grid-box'>
						<Box>
							<Typography component='h1' color='text.header'>
								Microsoft Teams Instances
							</Typography>
							<Typography
								component='p'
								color='text.header'
								className='instances-p'>
								The table below shows the status of all the existing instances:
							</Typography>
							<InstancesDisplayList instaceList={pocInstances} />
						</Box>
						<Box className='poc-add-order-container'>
							<CustomCard
								isActive={true}
								cardButtonTitle='Add New Order'
								cardButtonAction={() =>
									navigate('/dashboard/trial/add-trial-order')
								}
								cardTitle='Provision New Order'
								cardDescription={
									showAddOrderButton
										? 'Click the button below to provision a new order'
										: 'Cannot provision trial order. All instances are in use.'
								}
								cardIconSrc='card-order.png'
								showButton={showAddOrderButton}
								showLoading={false}
							/>
						</Box>
					</Box>
				</Box>
			) : (
				<Preloader />
			)}
		</>
	)
}

export default POCInstances
