import { useEffect, useState } from 'react'
import { Box, Typography, MenuItem } from '@mui/material'
import { StyledSelect } from '../../../shared/styledComponents/StyledSelect/StyledSelect'
import {
	PriceBook,
	SKUPartnerMap,
	Range,
} from '../../../../utils/interfaces/DBModels'
import { PriceBookDisplay } from '../../../../utils/interfaces/ComponentModels'
import './PriceBooksOverview.scss'
import PriceBooksOverviewDisplay from './PriceBooksOverviewDisplay'

const PriceBooksOverview = ({
	skuPriceBookList,
	rangeList,
}: {
	skuPriceBookList: SKUPartnerMap[]
	rangeList: Range[]
}) => {
	// Variables
	const [filteredSKU, setFilteredSKU] = useState(
		skuPriceBookList[0].SKUTypeID as string
	)

	//display
	const [priceBookDisplay, setPriceBookDisplay] = useState(
		[] as PriceBookDisplay[]
	)
	const [showTermColumn, setShowTermColumn] = useState(false)
	const [showRangeColumn, setShowRangeColumn] = useState(false)

	useEffect(() => {
		// Set the rows
		createPriceBookDisplay(skuPriceBookList[0].SKUTypeID + '')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const createPriceBookDisplay = (inputValue: string) => {
		var filteredPriceBookInfo = skuPriceBookList.filter(
			(e) => e.SKUTypeID === inputValue
		)[0].PriceBookList as PriceBook[]

		if (filteredPriceBookInfo.length > 0) {
			//build display array
			var filteredPriceBookDisplay = [] as PriceBookDisplay[]

			// Loop through pricebooks
			for (let f = 0; f < filteredPriceBookInfo.length; f++) {
				// Push to list
				filteredPriceBookDisplay.push({
					PriceBookID: filteredPriceBookInfo[f].PriceBookID,
					PriceBookTerm:
						Number(filteredPriceBookInfo[f].Term) > 0
							? filteredPriceBookInfo[f].Term + ''
							: '',
					PriceBookRange: getRange(Number(filteredPriceBookInfo[f].RangeID)),
					PriceBookSipcomMRC: filteredPriceBookInfo[f].SipcomMRC,
					PriceBookSipcomNRC: filteredPriceBookInfo[f].SipcomNRC,
					PriceBookPartnerMRC: filteredPriceBookInfo[f].PartnerMRC,
					PriceBookPartnerNRC: filteredPriceBookInfo[f].PartnerNRC,
				})
			}

			//set states for column visibility for term and range , first row of pricebooks enough to confirm

			filteredPriceBookDisplay[0].PriceBookTerm === ''
				? setShowTermColumn(false)
				: setShowTermColumn(true)
			filteredPriceBookDisplay[0].PriceBookRange === ''
				? setShowRangeColumn(false)
				: setShowRangeColumn(true)

			setPriceBookDisplay(filteredPriceBookDisplay)
		}
	}

	//create string range for display
	const getRange = (rangeID: number) => {
		var displayRange = ''
		var range = rangeList.find((el) => el.RangeID === rangeID) as Range
		if (range) {
			displayRange = range.MinSeats + '-' + range.MaxSeats
		}
		return displayRange
	}

	// *** Handle Functions *** //
	const handleSKUTypeFilterChange = (e: { target: any }) => {
		// Set value
		var filterValue = e.target.value + ''
		setFilteredSKU(filterValue)

		//get pricebook list for selected sku
		createPriceBookDisplay(filterValue)
	}

	return (
		<Box className='pricebook-overview-container'>
			{/* SKU Type filter */}
			<Box className='pricebook-overview-filters'>
				<Typography component='h4'>View SKU pricing</Typography>
				<Typography className='pricebook-sku-heading' component='p'>
					SKU:
				</Typography>
				<StyledSelect
					variant='outlined'
					value={filteredSKU}
					onChange={handleSKUTypeFilterChange}>
					{skuPriceBookList.map((item, index) => (
						<MenuItem key={index} value={item.SKUTypeID}>
							{item.SKUTypeID}
						</MenuItem>
					))}
				</StyledSelect>
			</Box>
			<Box id='pricebooks'>
				<PriceBooksOverviewDisplay
					priceBookDisplay={priceBookDisplay}
					showTermColumn={showTermColumn}
					showRangeColumn={showRangeColumn}
				/>
			</Box>
		</Box>
	)
}

export default PriceBooksOverview
