import { usePostAndRetrieveDataMutation } from '../../../services/proxyAPIData'
import {
  showErrorToast,
  toAlphaString,
} from '../../helperFunctions/helperFunctions'
import { DataResponse } from '../../interfaces/APIModels'
import ErrorLogging from '../ErrorLogging'

const PostAndRetrieveDataHook = () => {
  // Proxy call
  const [
    postAndRetrieveData,
    { error: postAndRetrieveDataError, isLoading: postAndRetrieveDataLoading },
  ] = usePostAndRetrieveDataMutation()

  // Error logging
  const { addErrorLog } = ErrorLogging()

  const postAndRetrieveDataFromDB = async (
    dataRetrieved: string,
    requestURL: string,
    ifErrorShowErrorToast: boolean,
    logErrorToDB: boolean
  ) => {
    // Error handling
    var isError = false
    var errorMessage = ''

    var dataResponse = {} as DataResponse

    return await postAndRetrieveData(requestURL)
      .unwrap()
      .then(async (response) => {
        if (response) {
          if (response.Content) {
            // Decrypt response
            dataResponse = JSON.parse(await toAlphaString(response?.Content))
            return dataResponse
          }
        }
      })
      .catch(async (error) => {
        if (error) {
          isError = true
          errorMessage = `An error occurred when returning ${dataRetrieved}`
        }
      })
      .finally(() => {
        if (isError && errorMessage.length > 0) {
          // Error handling
          if (ifErrorShowErrorToast) {
            showErrorToast(errorMessage)
          }
          if (logErrorToDB) {
            // Log to DB
            addErrorLog(requestURL, errorMessage)
          }
        }
      })
  }

  return {
    postAndRetrieveDataFromDB,
    postAndRetrieveDataError,
    postAndRetrieveDataLoading,
  }
}

export default PostAndRetrieveDataHook
