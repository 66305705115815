import { Alert, Box, Divider, Tooltip, Typography } from '@mui/material'
import { StyledTextBox } from '../../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { OrderSKUTerm } from '../../../../../../utils/interfaces/DBModels'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import InfoIcon from '@mui/icons-material/Info'
import './OrderSKUTermUpdate.scss'

const OrderSKUTermUpdate = ({
	orderID,
	orderSKUID,
	skuTypeID,
	currentTermValue,
	getSKUTermID,
	updateSKUTerm,
}: {
	orderID: string
	orderSKUID: number
	skuTypeID: string
	currentTermValue: number
	getSKUTermID: any
	updateSKUTerm: any
}) => {
	// Display constants
	const [term, setTerm] = useState<number>(currentTermValue)
	const [errorMessage, setErrorMessage] = useState('')

	// Flags
	const [loading, setLoading] = useState(false)

	// Handle functions
	// Handle update of term value
	const handleUpdateSKUTerm = async () => {
		// Start loading
		setLoading(true)

		// Order SKU Term Update
		var orderSKUTermObj = {} as OrderSKUTerm

		// First get ID
		var requestURL = 'UpdateV2?Params=OrderSKUTerm'
		var orderSKUTermID: number = await getSKUTermID(orderSKUID)
		if (orderSKUTermID === 0) {
			// Does not exist, change to insert
			requestURL = 'AddV2?Params=OrderSKUTerm'
		} else {
			orderSKUTermObj.OrderSKUTermID = orderSKUTermID
		}

		// Build relevant object
		orderSKUTermObj.OrderSKUID = orderSKUID
		orderSKUTermObj.Years = calculateYearsAndMonths(term).years
		orderSKUTermObj.Months = calculateYearsAndMonths(term).remainingMonths

		// Send object to be updated
		await updateSKUTerm(orderSKUTermObj, requestURL)

		// End loading
		setLoading(false)
	}

	// Handle change of term value
	const handleTermChange = (e: { target: any }) => {
		var newValue = e.target.value
		if (newValue && (newValue <= 0 || newValue >= 36)) {
			setErrorMessage('Please enter a term between 1 and 36 months (3 years)')
			return
		}
		setErrorMessage('')
		setTerm(newValue)
	}

	// Calculate months and years for term
	const calculateYearsAndMonths = (
		months: number
	): { years: number; remainingMonths: number } => {
		// Get the year and remaining months
		const monthsInYear = 12
		const years = Math.floor(months / monthsInYear)
		const remainingMonths = months % monthsInYear

		return { years, remainingMonths }
	}

	return (
		<>
			<Box className='sku-term-container'>
				{/* Header */}
				<Box className='sku-term-header'>
					{/* Icon */}
					<Box className='header-icon'>
						<Box className='icon'>
							<CalendarMonthIcon />
						</Box>
					</Box>
					{/* Text */}
					<Box className='header-text'>
						{/* Heading */}
						<Typography component='h2'>Update Order SKU Term</Typography>
						{/* Description */}
						<Typography component='p'>
							Manage and update your term for this SKU.
						</Typography>
					</Box>
				</Box>
				{/* Display for Order ID and SKU Type ID */}
				<Box className='sku-term-order-details'>
					<Box className='sku-text'>
						{/* Order ID */}
						<Typography component='p'>
							<strong>Order ID:</strong> {orderID}
						</Typography>
						{/* SKU Type ID */}
						<Typography component='p'>
							<strong>Order SKU:</strong> {skuTypeID}
						</Typography>
					</Box>
				</Box>
				{/* Divider */}
				<Divider />
				{/* Term Update */}
				<Box className='sku-term-form'>
					{/* Label */}
					<Box className='sku-term-label'>
						<Typography component='p'>Term (in months)</Typography>
						<Tooltip title='Please enter a term between 1 and 36 months (3 years)'>
							<InfoIcon />
						</Tooltip>
					</Box>
					{/* Textfield */}
					<StyledTextBox
						fullWidth
						onChange={handleTermChange}
						type='number'
						value={term}
					/>
				</Box>
				{/* Alert */}
				{errorMessage.length > 0 && (
					<Alert severity='error'>{errorMessage}</Alert>
				)}
				{/* Confirm button */}
				<Box className='sku-term-btn'>
					<LoadingButton
						disabled={term <= 0}
						variant='contained'
						loading={loading}
						onClick={handleUpdateSKUTerm}>
						Update SKU Term
					</LoadingButton>
				</Box>
			</Box>
		</>
	)
}

export default OrderSKUTermUpdate
