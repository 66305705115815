import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	PaperProps,
	Tooltip,
	Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import CircleIcon from '@mui/icons-material/Circle'
import { VideoTooltipsDisplay } from '../../../../utils/interfaces/ComponentModels'
import './VideoTooltips.scss'
import Draggable from 'react-draggable'
import { useState } from 'react'
import ReactPlayer from 'react-player'
import CloseIcon from '@mui/icons-material/Close'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'

const PaperComponent = (props: PaperProps) => {
	return (
		<Draggable
			handle='#draggable-dialog-title'
			cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	)
}

const VideoToolTips = () => {
	// Global variables
	const partner = useSelector(
		(state: RootState) => state.RootReducer.partnerReducer.value
	)

	// General variables
	const [openVideoDialog, setOpenVideoDialog] = useState(false)
	const [videoDialogCurrentIndex, setVideoDialogCurrentIndex] = useState(0)

	// Array for the tooltips with video links
	const videoTooltips: VideoTooltipsDisplay[] = [
		{
			VideoTooltipTitle:
				'Teams Direct Routing Provisioning and Service Manager Process - Overview',
			VideoTooltipLink: 'https://vimeo.com/857176042/20f569763a?share=copy',
		},
		{
			VideoTooltipTitle: 'Teams Direct Routing Provisioning - Voice Settings',
			VideoTooltipLink: 'https://vimeo.com/872875735/60a3dbb711?share=copy',
		},
		{
			VideoTooltipTitle: 'Teams Direct Routing Provisioning - Entra ID',
			VideoTooltipLink: 'https://vimeo.com/872875243/4c63bb779e?share=copy',
		},
		{
			VideoTooltipTitle:
				'Teams Direct Routing Provisioning - Enterprise Applications',
			VideoTooltipLink: 'https://vimeo.com/872876270/9aaae91d12?share=copy',
		},
		{
			VideoTooltipTitle: 'Teams User Management - Overall',
			VideoTooltipLink: 'https://vimeo.com/857177606/fb3cd644f5?share=copy',
		},
	]

	const handleOpenVideoPopup = (index: number) => {
		// Set all the variables
		setVideoDialogCurrentIndex(index)
		setOpenVideoDialog(true)
	}

	const handleCloseVideoPopup = (event: any, reason: any) => {
		if (reason !== 'backdropClick') {
			setOpenVideoDialog(false)
		}
	}

	return (
		<>
			<Box className='video-tooltips-container' bgcolor='background.superLight'>
				{/* Display the list of video tool tips */}
				<Box className='grid-box two'>
					{/* Video links */}
					<Box>
						<Typography component='h4'>
							Let us help you get up and running with Lumen Services.
						</Typography>
						{/* The list of links */}
						<List dense>
							{videoTooltips.map((item, index) => (
								<ListItemButton
									key={index}
									className='video-link'
									onClick={() => handleOpenVideoPopup(index)}>
									<ListItemIcon className='li-icon-container'>
										<CircleIcon
											sx={{ color: 'text.primary' }}
											className='li-icon'
										/>
									</ListItemIcon>
									<ListItemText
										className='li-text'
										sx={{ color: 'text.primary' }}
										primary={item.VideoTooltipTitle}
									/>
								</ListItemButton>
							))}
						</List>
					</Box>
					{/* Image */}
					<Box className='flex-box ctr-all video-dialog-img'>
						<img
							src={require(`../../../../assets/images/home/${partner}/video-tooltips.png`)}
							alt='Video Tooltips'
						/>
					</Box>
				</Box>
			</Box>
			{/* Dialog */}
			<Dialog
				hideBackdrop
				disableEnforceFocus
				disableEscapeKeyDown
				disableScrollLock
				fullWidth
				open={openVideoDialog}
				onClose={(event, reason) => {
					handleCloseVideoPopup(event, reason)
				}}
				PaperComponent={PaperComponent}>
				<>
					<Box className='flex-box sb'>
						<DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
							{videoTooltips[videoDialogCurrentIndex].VideoTooltipTitle}
						</DialogTitle>
						<Box className='close-icon'>
							<Tooltip title='Close Video Player'>
								<IconButton onClick={() => handleCloseVideoPopup('', '')}>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
					<DialogContent>
						{videoTooltips[videoDialogCurrentIndex].VideoTooltipLink.length >
						3 ? (
							<ReactPlayer
								url={videoTooltips[videoDialogCurrentIndex].VideoTooltipLink}
								controls={true}
								width='100%'
								light
								playing
							/>
						) : (
							<>
								<Box className='no-video-container flex-box ctr-all'>
									<VideocamOffIcon />
									<Typography component='h3'>
										Please note there is no video for this at this moment.
										Please check again later.
									</Typography>
								</Box>
							</>
						)}
					</DialogContent>
				</>
			</Dialog>
		</>
	)
}

export default VideoToolTips
