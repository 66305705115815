import { Box, Typography } from '@mui/material'

const SDAFAQAnswer5 = () => {
	return (
		<>
			<Box className='documentation-answer-container'>
				{/* Question - Can I reuse the licenses from the Base Provisioning Process? */}
				<Typography component='ul'>
					<Typography component='li'>
						<b>No</b>, these licenses must remain assigned to the service users
						in the Custom Domains.
					</Typography>
					<Typography component='li'>
						This is to allow the PSTN gateways to continue working.
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export default SDAFAQAnswer5
