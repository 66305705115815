import { useCustomAPICallMutation } from '../../../services/proxyAPIData'
import {
	getEmptyAPIMutationObject,
	showErrorToast,
	showSuccessToast,
	toAlphaString,
	toBetaString,
} from '../../helperFunctions/helperFunctions'
import {
	BetaObject,
	CustomAPIResponse,
	PostHookObj,
} from '../../interfaces/APIModels'
import ErrorLogging from '../ErrorLogging'

const PostCustomAPIHook = () => {
	// Proxy call
	const [
		callCustomAPI,
		{ error: postCustomAPIError, isLoading: postCustomAPILoading },
	] = useCustomAPICallMutation()

	// Error logging
	const { addErrorLog } = ErrorLogging()

	const postCustomAPI = async (postHookObj: PostHookObj) => {
		// Error handling
		var customApiResponse = {} as CustomAPIResponse

		var betaObj: BetaObject = {
			Content: toBetaString(JSON.stringify(postHookObj.RequestObj)),
		}

		var apiMutation = getEmptyAPIMutationObject()
		apiMutation.QueryParam = postHookObj.RequestURL
		apiMutation.BetaObject = betaObj

		return await callCustomAPI(apiMutation)
			.unwrap()
			.then(async (response) => {
				if (response) {
					if (postHookObj.ShowSuccessMessage) {
						showSuccessToast(postHookObj.SuccessMessage + '')
					}
					if (response?.Content) {
						// Decrypt response
						customApiResponse.Content = await toAlphaString(response?.Content)
						customApiResponse.isError = false
						return customApiResponse
					}
				}
			})
			.catch(async (error) => {
				if (error) {
					customApiResponse.Content = JSON.stringify(error)
					customApiResponse.isError = true
					return customApiResponse
				}
			})
			.finally(async () => {
				if (
					customApiResponse.isError &&
					postHookObj.ErrorMessage &&
					postHookObj.ErrorMessage.length > 0
				) {
					// Error handling
					if (postHookObj.ShowErrorMessage) {
						showErrorToast(postHookObj.ErrorMessage)
					}
					if (postHookObj.LogErrorToDB && postHookObj.Action) {
						// Log to DB
						await addErrorLog(
							postHookObj.Action,
							`${postHookObj.ErrorMessage}: ${customApiResponse.Content}`
						)
					}
				}
			})
	}

	return {
		postCustomAPI,
		postCustomAPIError,
		postCustomAPILoading,
	}
}

export default PostCustomAPIHook
