import { createTheme, PaletteMode } from '@mui/material'

// General theme
const getGeneralTheme = (mode: PaletteMode) => ({
	palette: {
		mode,
		primary: {
			...(mode === 'light'
				? {
						main: '#00A0DD',
				  }
				: {
						main: '#083176',
				  }),
		},
		background: {
			...(mode === 'light'
				? {
						default: '#fff',
				  }
				: {
						default: '#2e2e2e',
				  }),
			light: '#F6FAFD',
		},
		text: {
			primary: '#0075C9',
			secondary: '#000',
		},
	},
})

const getLumenTheme = (mode: PaletteMode) => ({
	palette: {
		mode,
		primary: {
			main: '#0075C9',
		},
		secondary: {
			main: '#E0F3FF',
		},
		background: {
			...(mode === 'light'
				? {
						default: '#fff',
				  }
				: {
						default: '#2e2e2e',
				  }),
			light: '#F6FAFD',
			dark: '#000',
			wht: '#fff',
			superLight: '#E0F3FF',
		},
		text: {
			primary: '#0075C9',
			secondary: '#000',
			error: '#d32f2f',
			warning: '#ed6c02',
			light: '#fff',
			...(mode === 'light'
				? {
						header: '#000',
				  }
				: {
						header: '#fff',
				  }),
		},
		border: {
			primary: '#00A0DD',
		},
		header: {
			...(mode === 'light'
				? {
						default: '#1F75B9',
				  }
				: {
						default: '#000',
				  }),
		},
		fixedElement: {
			...(mode === 'light'
				? {
						default: '#fff',
				  }
				: {
						default: '#000',
				  }),
		},
		error: {
			main: '#d32f2f',
			background: 'rgba(211, 47, 47, 0.2)',
			color: 'rgb(95, 33, 32)',
		},
		card: {
			...(mode === 'light'
				? {
						default: '#fff',
				  }
				: {
						default: '#e0e0e0',
				  }),
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						backgroundColor: '#0075C9',
						color: '#fff',
					},
				},
			},
		},
	},
})

export const getThemeByURL = (mode: PaletteMode) => {
	var themeToUse = null

	switch (window.location.origin) {
		case process.env.REACT_APP_LUMEN_PORTAL_URL: {
			themeToUse = createTheme(getLumenTheme(mode))
			// Pass the partner abr through the site
			break
		}

		default: {
			themeToUse = createTheme(getLumenTheme(mode))
			break
		}
	}

	return themeToUse
}
