import { Box, Typography } from '@mui/material'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import { FilteredOrderStatus } from '../../../../../utils/enums/enums'
import './OrderStatusCardDisplay.scss'

const OrderStatusCardDisplay = ({ status }: { status: number }) => {
	return (
		<Box className='order-status-display-container'>
			{/* Content */}
			<Box className='order-status-display-content'>
				{/* Icon Display */}
				<Box className='order-status-icon'>
					{status === FilteredOrderStatus.NoRecords ? (
						<TroubleshootIcon color='primary' />
					) : (
						<SearchOffIcon color='error' />
					)}
				</Box>
				{/* Text */}
				<Box className='order-status-text'>
					{/* Heading */}
					<Typography component='h4'>
						{status === FilteredOrderStatus.Failure
							? 'Retrieval Failure'
							: 'No Records Found'}
					</Typography>
					{/* Paragraph */}
					<Typography component='p'>
						{status === FilteredOrderStatus.Failure
							? 'An error occurred when trying to retrieve orders from the server'
							: 'No records found. Please adjust your filters or try again later.'}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}

export default OrderStatusCardDisplay
