import { Box } from '@mui/material'
import './CardPreloader.scss'

const CardPreloader = ({ autoMargin }: { autoMargin: boolean }) => {
	return (
		<Box
			sx={{
				marginTop: `${autoMargin ? 'auto' : '0'}`,
				marginBottom: `${autoMargin ? 'auto' : '0'}`,
			}}
			className='card-preloader'
		/>
	)
}

export default CardPreloader
