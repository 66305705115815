import {
	AlertColor,
	Box,
	Button,
	LinearProgress,
	Typography,
} from '@mui/material'
import { StyledAlert } from '../styledComponents/StyledAlert/StyledAlert'
import './SDACard.scss'
import Preloader from '../loading/preloader/Preloader'

const CustomCard = ({
	isActive,
	cardTitle,
	cardDescription,
	cardBodyIconSrc,
	showButton,
	cardButtonTitle,
	cardButtonAction,
	cardButtonExternalLink,
	showLoading,
	cardMessage,
	cardMessageType,
	progress,
}: {
	isActive: boolean
	cardTitle: string
	cardDescription?: string
	cardBodyIconSrc?: string
	showButton: boolean
	cardButtonTitle?: string
	cardButtonAction?: any
	cardButtonExternalLink?: string
	showLoading?: boolean
	cardMessage?: string
	cardMessageType?: AlertColor
	progress?: number
}) => {
	return (
		<>
			<Box className='sda-card-container'>
				<Box className='sda-card-content' bgcolor='card.default'>
					{cardBodyIconSrc ? (
						<img
							className='sda-card-body-img'
							src={require(`../../../assets/icons/card/${cardBodyIconSrc}`)}
							alt={cardTitle}
						/>
					) : null}

					{cardDescription && cardDescription.length > 0 ? (
						<Typography
							className={showButton ? 'card-text m' : 'card-text'}
							component='p'
							color='text.secondary'>
							{cardDescription}
						</Typography>
					) : null}

					<Box sx={{ marginTop: '-10px', marginBottom: '-40px' }}>
						{showLoading ? <Preloader /> : null}
					</Box>

					{progress && progress > 0 ? (
						<Box
							sx={{
								marginTop: '55px',
								marginBottom: '10px',
								padding: '10px',
								width: '500px',
							}}>
							<LinearProgress variant='determinate' value={progress} />
						</Box>
					) : null}

					{cardMessage && cardMessageType && cardMessage.length > 0 ? (
						<StyledAlert
							className='alert'
							severity={cardMessageType}
							sx={
								showButton
									? {
											marginBottom: '15px!important',
											lineHeight: 2,
											padding: '2px',
									  }
									: { lineHeight: 2, padding: '2px' }
							}>
							{cardMessage}
						</StyledAlert>
					) : null}

					{showButton && cardButtonTitle ? (
						<Button
							sx={
								cardDescription && cardDescription.length > 0
									? { marginTop: '40px' }
									: {}
							}
							variant='contained'
							disabled={!isActive}
							onClick={cardButtonAction}
							href={cardButtonExternalLink}>
							{cardButtonTitle}
						</Button>
					) : null}
				</Box>
			</Box>
		</>
	)
}

export default CustomCard
