import { useMsal } from '@azure/msal-react'
import { Box, Button, Typography } from '@mui/material'
import './ConfirmMSALAccountCard.scss'
import CustomCard from '../card/CustomCard'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import {
	setConfirmedAccount,
	setGlobalAdminUser,
} from '../../../store/reducers/reducers'
import { GetEmptyAccountInfo } from '../../../utils/helperFunctions/helperFunctions'

const ConfirmMSALAccountCard = ({
	displayWithoutCard,
}: {
	displayWithoutCard?: boolean
}) => {
	// MSAL
	const dispatch = useAppDispatch()
	const { instance, accounts } = useMsal()
	// Constant
	const [cardDescription, setCardDescription] = useState('')

	useEffect(() => {
		if (accounts[0]) {
			if (accounts[0]?.username) {
				setCardDescription(
					`Would you like to proceed with logged-in account: ${accounts[0].username}?`
				)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accounts])

	const handleLogout = async () => {
		await instance
			.logoutRedirect({
				onRedirectNavigate: () => {
					// Return false to stop navigation after local logout
					return false
				},
			})
			.then(() => {
				dispatch(setConfirmedAccount(GetEmptyAccountInfo()))
				dispatch(setGlobalAdminUser(''))
			})
	}

	return (
		<>
			{displayWithoutCard ? (
				<>
					<Box sx={{ padding: '10px', margin: '10px' }} className='flex-box'>
						<img
							src={require(`../../../assets/icons/card/card-lock.png`)}
							alt='Confirm Account'
						/>
						<Typography fontSize={17} sx={{ marginTop: '15px' }}>
							{cardDescription}
						</Typography>
					</Box>

					<Box className='teamsDRProceedButton'>
						<Button
							variant='contained'
							onClick={() => {
								dispatch(setConfirmedAccount(accounts[0]))
							}}
							sx={{ marginBottom: '10px', width: '250px' }}>
							Proceed
						</Button>
					</Box>
				</>
			) : (
				<>
					<Box className='login-box'>
						<CustomCard
							cardTitle='Proceed with this account?'
							cardDescription={cardDescription}
							showButton={true}
							isActive={true}
							cardIconSrc='card-lock.png'
							cardButtonTitle='Proceed'
							cardButtonAction={() => {
								dispatch(setConfirmedAccount(accounts[0]))
							}}
							showCancelButton={true}
							cancelButtonTitle='Log Out'
							cancelButtonAction={handleLogout}
						/>
					</Box>
				</>
			)}
		</>
	)
}
export default ConfirmMSALAccountCard
