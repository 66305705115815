import { Box, Typography } from '@mui/material'

const ServiceManagerAnswer1 = () => {
	return (
		<>
			<Box className='documentation-answer-container'>
				{/* Question - Will service manager ever change anything in my Tenant? */}
				<Typography component='ul'>
					<Typography component='li'>
						Service Manager is a <b>reporting</b> tool only.
					</Typography>
					<Typography component='li'>
						It is used to check your configuration and report back on its{' '}
						<b>state</b> and <b>usage.</b>
					</Typography>
					<Typography component='li'>
						If any <b>changes</b> are needed to your service, you will be asked
						to perform those actions with <b>Delegated Rights</b> from a{' '}
						<b>Global Administrator.</b>
					</Typography>
					<Typography component='li'>
						Service Manager will <b>never initiate</b> changes <b>itself</b>.
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export default ServiceManagerAnswer1
