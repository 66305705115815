import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@mui/material'
import { useAppDispatch } from '../../../store/hooks'
import { setConfirmedAccount } from '../../../store/reducers/reducers'
import { GetEmptyAccountInfo } from '../../../utils/helperFunctions/helperFunctions'

const LoginButton = () => {
	const dispatch = useAppDispatch()
	const { loginWithRedirect } = useAuth0()

	const handleLogin = () => {
		dispatch(setConfirmedAccount(GetEmptyAccountInfo()))
		loginWithRedirect()
	}

	return (
		<Button variant='contained' className='login-button' onClick={handleLogin}>
			Login
		</Button>
	)
}

export default LoginButton
