import {
	usePostBetaObjectWithoutRefetchMutation,
	usePublishServiceBusMessageMutation,
} from '../../../services/proxyAPIData'
import {
	getEmptyAPIMutationObject,
	showErrorToast,
	showSuccessToast,
	toBetaString,
} from '../../helperFunctions/helperFunctions'
import {
	AddRequest,
	BetaObject,
	PostHookObj,
	PublishServiceBusMessageModel,
} from '../../interfaces/APIModels'
import ErrorLogging from '../ErrorLogging'

const PostUpdateDataHook = () => {
	// Proxy call
	const [
		postWithoutRefetch,
		{ error: apiPostWithoutRefetchError, isLoading: postWithoutRefetchLoading },
	] = usePostBetaObjectWithoutRefetchMutation()
	const [publishServiceBusMessage] = usePublishServiceBusMessageMutation()

	// Error logging
	const { addErrorLog } = ErrorLogging()

	const postUpdateDataWithoutRefetch = async (postHookObj: PostHookObj) => {
		// Error handling
		var isError = false

		var requestBody: AddRequest = {
			Obj: postHookObj.RequestObj,
			UserID: postHookObj.LoggedInUser.userID + '',
			UserName: postHookObj.LoggedInUser.email + '',
		}

		var betaObj: BetaObject = {
			Content: toBetaString(JSON.stringify(requestBody)),
		}

		var apiMutation = getEmptyAPIMutationObject()
		apiMutation.QueryParam = postHookObj.RequestURL
		apiMutation.BetaObject = betaObj

		return await postWithoutRefetch(apiMutation)
			.unwrap()
			.then(async () => {
				if (postHookObj.ShowSuccessMessage) {
					showSuccessToast(postHookObj.SuccessMessage + '')
					// Publish Order Status Updated Message
					if (
						postHookObj.RequestObj.Order &&
						postHookObj.RequestObj.Order.OrderStatusID
					) {
						// Publish ServiceBus message
						var publishServiecBusMessageObj: PublishServiceBusMessageModel = {
							Message: `{OrderID: ${postHookObj.RequestObj.Order.OrderID}}`,
							QueueOrTopicName:
								process.env.REACT_APP_ORDER_STATUS_UPDATE_TOPIC_NAME + '',
							ConnectionStringSettingName:
								process.env.REACT_APP_SERVICE_BUS_CONNECTION_STRING_SETTING +
								'',
						}
						var betaObj: BetaObject = {
							Content: toBetaString(
								JSON.stringify(publishServiecBusMessageObj)
							),
						}
						await publishServiceBusMessage(betaObj)
							.unwrap()
							.then()
							.catch(async (error) => {
								if (error) {
									var errorMessage =
										`An error occurred when publishing order status update message. Request body: ` +
										JSON.stringify(publishServiecBusMessageObj) +
										` Error: ` +
										error?.data
									addErrorLog('Publish Service Bus Message:', errorMessage)
								}
							})
					}
				}
			})
			.catch(async (error) => {
				if (error) {
					isError = true
				}
			})
			.finally(() => {
				if (
					isError &&
					postHookObj.ErrorMessage &&
					postHookObj.ErrorMessage.length > 0
				) {
					// Error handling
					if (postHookObj.ShowErrorMessage) {
						showErrorToast(postHookObj.ErrorMessage)
					}
					if (postHookObj.LogErrorToDB && postHookObj.Action) {
						// Log to DB
						addErrorLog(postHookObj.Action, postHookObj.ErrorMessage)
					}
				}
			})
	}

	return {
		postUpdateDataWithoutRefetch,
		apiPostWithoutRefetchError,
		postWithoutRefetchLoading,
	}
}

export default PostUpdateDataHook
