import { Box, Button, FormControl, MenuItem, Select } from '@mui/material'
import { useState } from 'react'
import { MSTeamsUser, Service } from '../../../../utils/interfaces/DBModels'
import './TeamsServiceSelection.scss'
import { TeamsDRServiceValueDisplayOptions } from '../../../../utils/enums/enums'

const TeamsServiceSelection = ({
	confirmSelection,
	teamsServiceList,
	teamsDRServiceValueDisplayOption,
	serviceList
}: {
	confirmSelection: any
	teamsServiceList: MSTeamsUser[]
	teamsDRServiceValueDisplayOption: TeamsDRServiceValueDisplayOptions
	serviceList: Service[]
}) => {
	const [serviceChoice, setServiceChoice] = useState(
		teamsServiceList[0].ServiceID
	)

	const handleLicenseChoiceChange = (e: { target: any }) => {
		setServiceChoice(e.target.value)
	}

	function getServiceDisplayName(msTeamsService: MSTeamsUser) {
	var serviceDisplayName = '';

	switch(teamsDRServiceValueDisplayOption)
	{
		case TeamsDRServiceValueDisplayOptions.PartnerServiceID: 
		var matchingService = serviceList.find(x => x.ServiceID?.toLowerCase().trim() === msTeamsService.ServiceID?.toLowerCase().trim())
		if(matchingService)
		{
			serviceDisplayName = matchingService.PartnerServiceReference + '';
		}
		break;

		case TeamsDRServiceValueDisplayOptions.DomainName: 
			serviceDisplayName = msTeamsService.DomainName + '';
		break;

		default: 
			serviceDisplayName = msTeamsService.ServiceID + '';
		break;
	}
		
	return serviceDisplayName
	}

	return (
		<>
			<Box className='ts-form-content'>
				<form className='ts-form'>
					<FormControl fullWidth className='ts-form-input'></FormControl>
					<FormControl fullWidth className='ts-form-input'>
						<label className='ts-form-label'>
							Service:
							<Select
								variant='outlined'
								className='ts-form-input-select'
								value={serviceChoice}
								onChange={handleLicenseChoiceChange}>
								{teamsServiceList.map((item) => {
									return (
										<MenuItem key={item.ServiceID} value={item.ServiceID}>
											{getServiceDisplayName(item)}
										</MenuItem>
									)
								})}
							</Select>
						</label>
					</FormControl>
					<Box className='ts-form-button'>
						<Button
							variant='contained'
							onClick={() => {
								confirmSelection(serviceChoice)
							}}>
							Submit Service Choice
						</Button>
					</Box>
				</form>
			</Box>
		</>
	)
}

export default TeamsServiceSelection
