import { Box, Typography } from '@mui/material'

const TeamsUserManagementAnswer2 = () => {
	return (
		<>
			<Box className='documentation-answer-container'>
				{/* Question - Can I add users to my tenant in user management?*/}
				<Typography component='ul'>
					<Typography component='li'>
						<b>No</b>, User Management allows you to maintain the Voice and
						Licenses settings of existing Users.
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export default TeamsUserManagementAnswer2
