import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { setPageTitle } from '../../../store/reducers/reducers'
import { useAppDispatch } from '../../../store/hooks'
import { useNavigate, useParams } from 'react-router'
import {
	showErrorToast,
	toAlphaString,
	toBetaString,
} from '../../../utils/helperFunctions/helperFunctions'
import TeamsTabDisplay from '../../../components/distinct/dashboard/TeamsOverview/TeamsTabDisplay'
import Preloader from '../../../components/shared/loading/preloader/Preloader'
import './TeamsOverview.scss'
import { useGetTenantProvisioningConfigMutation } from '../../../services/proxyAPIData'
import { TenantConfigurationInfo } from '../../../utils/interfaces/APIModels'
import PostAndRetrieveDataHook from '../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import { Country } from '../../../utils/interfaces/DBModels'

function TeamsOverview() {
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)

	//API calls
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()

	const [getTenantConfigInfo] = useGetTenantProvisioningConfigMutation()

	// Params
	const { customerID } = useParams()
	const [dataRetrieved, setDataRetrieved] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [tenantConfigInfo, setTenantConfigInfo] = useState(
		{} as TenantConfigurationInfo
	)
	const [countries, setCountries] = useState([] as Country[])

	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	useEffect(() => {
		//Set Page Title
		if (pageTitle !== 'Teams Overview') {
			dispatch(setPageTitle('Teams Overview'))
		}
		if (!dataRetrieved) {
			setDataRetrieved(true)
			if (customerID && !isLoading) {
				setIsLoading(true)
				GetTeamsConfigurationDetailsForCustomer()
			} else {
				showErrorToast('Unable to get selected Customer')
				navigate('/')
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataRetrieved, isLoading])

	const GetTeamsConfigurationDetailsForCustomer = async () => {
		var encCustomer = await toBetaString(customerID + '')

		var coutriesDataResponse = await postAndRetrieveDataFromDB(
			'Countries',
			'Country.All()',
			false,
			false
		)

		if (
			coutriesDataResponse &&
			Number(coutriesDataResponse.Count) > 0 &&
			coutriesDataResponse.Obj
		) {
			// Orders
			var _countries = coutriesDataResponse.Obj.CountryList as Country[]
			setCountries(_countries)
		}

		await getTenantConfigInfo(encCustomer)
			.unwrap()
			.then(async (tenantConfigDataResponse) => {
				if (tenantConfigDataResponse.Content) {
					var response = await toAlphaString(tenantConfigDataResponse.Content)

					var _tenantConfigInfo = JSON.parse(
						response
					) as TenantConfigurationInfo

					if (_tenantConfigInfo) {
						setTenantConfigInfo(_tenantConfigInfo)
					}
				}
			})
			.catch(() => {
				showErrorToast('Unable to get User Tenant Configuration.')
			})

		setIsLoading(false)
	}

	return (
		<>
			<Box id='teams-overview' className='flex-box col rg'>
				{dataRetrieved && !isLoading ? (
					<>
						<Box>
							{/* System diagram */}
							<Box className='teams-overview-heading'>
								<Typography component='h2' color='text.header'>
									SYSTEM DIAGRAM
								</Typography>
								<a
									href={`${process.env.REACT_APP_TEAMS_CONFIGURATION_DIAGRAM_URL}`}
									target='_blank'
									rel='noreferrer'
									download='TeamsOverviewDiagram.pdf'>
									<img
										src={require(`../../../assets/icons/teamsOverview/pdf-download.png`)}
										alt={'Diagram pdf download button'}
									/>
								</a>
							</Box>
							<Box className='teams-overview-img'>
								<img
									src={require(`../../../assets/images/documentation/TeamsOverview/TeamsOverviewDiagramFinal.png`)}
									alt={'Teams overview diagram'}
								/>
							</Box>
						</Box>

						<Box>
							{/* Tenant Configuration */}
							<Typography component='h2' color='text.header'>
								TENANT CONFIGURATION
							</Typography>

							{countries.length > 0 &&
								tenantConfigInfo.Domains &&
								tenantConfigInfo.Regions && (
									<TeamsTabDisplay
										countries={countries}
										tenantConfigurationInfo={tenantConfigInfo}
									/>
								)}
						</Box>
					</>
				) : (
					<Preloader />
				)}
			</Box>
		</>
	)
}

export default TeamsOverview
