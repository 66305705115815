import './SDAProvisioning.scss'
import { useEffect, useRef, useState } from 'react'
import Card from '../../../../components/shared/card/SDACard'
import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux'
import {
	useGetMSLicensesForHaloUsersMutation,
	useGetProxyObjectQuery,
	useGetServiceManagementReportAndStatusForCustomerMutation,
	usePostAndRetrieveDataMutation,
	usePostBetaObjectWithoutRefetchMutation,
	usePublishServiceBusMessageMutation,
	useStartSDAConfigurationMutation,
} from '../../../../services/proxyAPIData'
import {
	UserAdminPermissionsEnums,
	Endpoints,
	ProvisioningSteps,
	SDASteps,
	SDAStatus,
} from '../../../../utils/enums/enums'
import { Alert, AlertTitle, Box, Button, Chip, Stack } from '@mui/material'
import {
	BetaObject,
	CheckUserAdminPermissionsRequest,
	GetLicensesResponse,
	JobLogListResponse,
	MSRequest,
	MSResponse,
	MSTeamsLicenseSKUListResponse,
	TenantConfigurationInfo,
	GetHaloUserLicensesRequestModel,
	TeamsDRDomainInfo,
	SDAConfigurationRequest,
	PostHookObj,
	TeamsDRCountryInfo,
	PublishServiceBusMessageModel,
} from '../../../../utils/interfaces/APIModels'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import LicenseSelection from '../../../../components/distinct/dashboard/TeamsDR/LicenseSelection'
import Typography from '@mui/material/Typography'
import { useAppDispatch } from '../../../../store/hooks'
import { DataResponse } from '../../../../utils/interfaces/APIModels'
import {
	GetEmptyAccountInfo,
	getEmptyAPIMutationObject,
	hasGraphScopes,
	timeout,
	toAlphaString,
	toBetaString,
} from '../../../../utils/helperFunctions/helperFunctions'
import {
	setConfirmedAccount,
	setPageTitle,
} from '../../../../store/reducers/reducers'
import LoginCard from '../../../../components/shared/login/LoginCard'
import {
	JobLog,
	MSTeamsLicenseSKU,
} from '../../../../utils/interfaces/DBModels'
import {
	SDALicenseChoice,
	SDAOrderSKUInfo,
} from '../../../../utils/interfaces/ComponentModels'
import ConfirmMSALAccountCard from '../../../../components/shared/confirmMSALAccount/ConfirmMSALAccountCard'
import { GraphScopes, TeamsScopes } from '../../../../utils/constants/constants'
import Preloader from '../../../../components/shared/loading/preloader/Preloader'
import PostUpdateDataHook from '../../../../utils/customHooks/APICalls/PostUpdateDataHook'
import Prechecks from '../../../../utils/customHooks/Prechecks/Prechecks'
import ErrorLogging from '../../../../utils/customHooks/ErrorLogging'

const SDAProvisioning = ({
	_tenantProvisioningConfig,
	isReprovisioning,
	sdaOrderSKUInfo,
	customerID,
	teamsServiceID,
	smToken,
	reloadDashboard,
}: {
	_tenantProvisioningConfig: TenantConfigurationInfo
	isReprovisioning: boolean
	sdaOrderSKUInfo: SDAOrderSKUInfo
	customerID: string
	teamsServiceID: string
	smToken: string
	reloadDashboard: any
}) => {
	const dispatch = useAppDispatch()
	//msal
	const isAuthenticated = useIsAuthenticated()
	const { instance } = useMsal()

	//Global State Variables
	const confirmedAccount = useSelector(
		(state: RootState) => state.RootReducer.confirmedAccountReducer.value
	)
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	//Component State Variables
	const [provisioningStepProgress, setProvisioningStepProgress] = useState(0)
	const [activeStep, setActiveStep] = useState(
		SDASteps.GlobalAdminAndHaloUsersLicenseCheck
	)
	const [preChecksDone, setPreChecksDone] = useState(false)
	const [showLicencesLogOutButton, setShowLicencesLogOutButton] =
		useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [licenseList, setLicenseList] = useState([] as SDALicenseChoice[])
	const [makeRepeatedAPICalls, setMakeRepeatedAPICalls] = useState(false)
	const [configurationDescription, setConfigurationDescription] = useState('')
	const globalAdminGraphToken = useRef('')
	const globalAdminTeamsToken = useRef('')
	const [tenantProvisioningConfig, setTenantProvisioningConfig] = useState(
		_tenantProvisioningConfig
	)
	const [numLicensesRequired, setNumLicensesRequired] = useState(0)

	// API Calls
	const { data: getLicensesData, isFetching: isFetchingLicenses } =
		useGetProxyObjectQuery('MSTeamsLicenseSKU.All()')

	const {
		data: encJobLogData,
		isFetching: isFetchingJobLogData,
		isUninitialized,
		refetch: refetchJobLogData,
	} = useGetProxyObjectQuery(
		`JobLog.ProvisioningSteps.Where(JobLog.OrderSKUID = '${sdaOrderSKUInfo?.OrderSKUID}')`,
		{
			skip: !makeRepeatedAPICalls,
		}
	)

	const [postAndRetrieve, { isLoading: isLoadingPostAndRetrieve }] =
		usePostAndRetrieveDataMutation()

	const [startSDAProvisioning] = useStartSDAConfigurationMutation()

	const { postUpdateDataWithoutRefetch } = PostUpdateDataHook()

	const [
		postDataWithoutRefetch,
		{ isLoading: isLoadingPostDataWithoutRefetch },
	] = usePostBetaObjectWithoutRefetchMutation()

	const [getMSLicensesForHaloUsers] = useGetMSLicensesForHaloUsersMutation()

	const { getSDAOrderSKU } = Prechecks(customerID)

	const { addErrorLog } = ErrorLogging()

	const [runSMChecksForCustomer] =
		useGetServiceManagementReportAndStatusForCustomerMutation()

	const [publishServiceBusMessage] = usePublishServiceBusMessageMutation()

	useEffect(() => {
		//Set Page Title
		if (pageTitle !== 'Teams Direct Routing') {
			dispatch(setPageTitle('Teams Direct Routing'))
		}

		//only do things if no data is being fetched
		if (
			!isLoadingPostAndRetrieve &&
			!isLoadingPostDataWithoutRefetch &&
			!isFetchingLicenses &&
			!isFetchingJobLogData &&
			errorMessage.length < 1
		) {
			//Check if user confirmed their MSAL Account to proceed with
			if (isAuthenticated && confirmedAccount.username.length > 0) {
				if (!preChecksDone) {
					//do global admin checks if logged in user has not been verified to have the Global Admin role
					setPreChecksDone(true)
					CheckGlobalAdminRole()
				} else {
					//Logged in user has a valid teams order and they have the global admin role.
					//Checks and actions after reading from JobLog Table
					if (
						makeRepeatedAPICalls &&
						encJobLogData?.Content &&
						activeStep < SDASteps.SDAConfigurationComplete
					) {
						CheckJobLogData()
					}
				}
			}
		}

		const interval = setInterval(() => {
			if (makeRepeatedAPICalls && !isFetchingJobLogData && preChecksDone) {
				if (!isUninitialized) {
					refetchJobLogData()
				}
			}
		}, 5000)
		return () => clearInterval(interval)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		preChecksDone,
		confirmedAccount,
		encJobLogData,
		isFetchingJobLogData,
		activeStep,
		isLoadingPostAndRetrieve,
		isFetchingLicenses,
		isUninitialized,
		isAuthenticated,
	])

	//Function that makes API call to check User Licenses using Graph API endpoint
	const CheckForAvailableLicenses = async (domainsWithoutLicenses: number) => {
		var _errorMessage = ''
		//Get new graph token if necessary
		await instance
			.acquireTokenSilent({
				scopes: GraphScopes,
				account: confirmedAccount,
			})
			.then(async (response) => {
				if (hasGraphScopes(response.scopes)) {
					//Make API call to check for licenses once token is refreshed
					var getLicensesReq: MSRequest = {
						Endpoint: Endpoints.GET_LICENSES,
						token: response.accessToken,
					}

					var apiMutation = getEmptyAPIMutationObject()
					apiMutation.QueryParam = 'MakeMSCall'
					var betaObj: BetaObject = {
						Content: await toBetaString(JSON.stringify(getLicensesReq)),
					}
					apiMutation.BetaObject = betaObj

					await postDataWithoutRefetch(apiMutation)
						.unwrap()
						.then(async (msResponseFromEndpoint) => {
							if (msResponseFromEndpoint) {
								var alphaText = await toAlphaString(
									msResponseFromEndpoint?.Content + ''
								)
								if (alphaText.length > 0) {
									var msResponse = JSON.parse(alphaText) as MSResponse
									var getLicensesResponse = JSON.parse(
										msResponse.MSResponseObject
									) as GetLicensesResponse
									if (msResponse) {
										if (getLicensesData?.Content) {
											if (msResponseFromEndpoint) {
												var _licenseListDataResponse = JSON.parse(
													await toAlphaString(getLicensesData?.Content + '')
												) as DataResponse
												var _licenseList =
													_licenseListDataResponse.Obj as MSTeamsLicenseSKUListResponse
												if (getLicensesResponse.value) {
													if (
														getLicensesResponse.value.length > 0 &&
														_licenseList.MSTeamsLicenseSKUList.length > 0
													) {
														if (getLicensesResponse.value.length > 0) {
															var _availableMSTeamsLicenseSKUList =
																[] as MSTeamsLicenseSKU[]
															getLicensesResponse.value.forEach((userSKU) => {
																var matchingMSTeamsLicenseSKU =
																	_licenseList.MSTeamsLicenseSKUList.find(
																		(x) =>
																			(x.MSTeamsLicenseSKUID + '')
																				.trim()
																				.toLowerCase() ===
																			(userSKU.skuId + '').trim().toLowerCase()
																	)
																//check if license is valid for Teams voice and it must NOT be an add-on license
																if (
																	matchingMSTeamsLicenseSKU?.DomainConfiguration ===
																	true
																) {
																	//check if license has 2 or more available units
																	var _sdaLicenseChoice = {} as SDALicenseChoice

																	var consumedUnits = 0
																	var prepaidUnits = 0

																	if (
																		userSKU?.consumedUnits &&
																		Number(userSKU.consumedUnits) > 0
																	) {
																		consumedUnits = Number(
																			userSKU.consumedUnits
																		)
																	}

																	if (
																		userSKU?.prepaidUnits?.enabled &&
																		Number(userSKU.prepaidUnits.enabled) > 0
																	) {
																		prepaidUnits = Number(
																			userSKU.prepaidUnits.enabled
																		)
																	}

																	var freeLicenses =
																		prepaidUnits - consumedUnits

																	_sdaLicenseChoice = matchingMSTeamsLicenseSKU
																	_sdaLicenseChoice.NumAvailable = freeLicenses

																	if (freeLicenses >= domainsWithoutLicenses) {
																		_sdaLicenseChoice.hasValidQuantity = true
																	} else {
																		_sdaLicenseChoice.hasValidQuantity = false
																	}

																	_availableMSTeamsLicenseSKUList.push(
																		_sdaLicenseChoice
																	)
																}
															})

															setLicenseList(_availableMSTeamsLicenseSKUList)

															if (_availableMSTeamsLicenseSKUList.length > 0) {
																setActiveStep(SDASteps.LicenseSelection)
															} else {
																_errorMessage = `No valid Microsoft Licenses were found. You need at least ${domainsWithoutLicenses} available Microsoft base licenses (E3, E5, Microsoft 365 Business Basic) to continue this process. Please log out, assign new compatible licenses and try again`
																setErrorMessage(_errorMessage)
																setMakeRepeatedAPICalls(false)
															}
														}
													}
												}
											}
										}
									}
								}
							}
						})
						.catch(async (error) => {
							await addErrorLog(
								`SDA Lumen Portal (${customerID}) - CheckForAvailableLicenses`,
								_errorMessage +
									`${JSON.stringify(sdaOrderSKUInfo)}: ${JSON.stringify(error)}`
							)
							setErrorMessage(
								'Unable to get available Licenses for logged-in user, please Log out and try again in a few minutes'
							)
							setMakeRepeatedAPICalls(false)
						})
				} else {
					setErrorMessage(
						'Invalid Token retrieved from Microsoft Login. Please log out and try again.'
					)
					setMakeRepeatedAPICalls(false)
				}
			})
	}

	const CheckJobLogForOrderSKUProgress = async () => {
		var errorMessage = ''
		var errorMessageText = 'Error checking for ongoing Teams DR operation'

		await postAndRetrieve(
			`JobLog.Where(JobLog.OrderSKUID = '${sdaOrderSKUInfo?.OrderSKUID}')`
		)
			.unwrap()
			.then(async (response) => {
				if (response) {
					if (response.Content) {
						var alphaText = await toAlphaString(response.Content + '')
						if (alphaText.length > 0) {
							var dataResponse = JSON.parse(alphaText) as DataResponse
							if (dataResponse) {
								var jobLogList = dataResponse?.Obj?.JobLogList as JobLog[]
								if (jobLogList) {
									if (jobLogList.length > 0) {
										//SDA in progress - show progress
										showInitialProvisioningProgress()
									} else {
										if (isReprovisioning) {
											//ignore SKU status
											CheckForValidTeamsOrderAndExistingHaloUserLicenses()
										} else {
											var teamsDROrderSKUInfo = await getSDAOrderSKU(
												teamsServiceID
											)

											switch (teamsDROrderSKUInfo.SDAStatus) {
												// No Teams Order Found
												case SDAStatus.NoTeamsOrderSKUFound:
													errorMessage =
														'You do not have a configured Teams Order yet.'
													break
												// Not ready for SDA
												case SDAStatus.NotReadyForSDA:
													errorMessage =
														'Your Teams order is not ready for the Teams Direct Routing process yet. Please try again later.'
													break
												// Ready for SDA
												case SDAStatus.ReadyForSDA:
													CheckForValidTeamsOrderAndExistingHaloUserLicenses()
													break
												// SDA completed
												case SDAStatus.SDACompleted:
													setMakeRepeatedAPICalls(false)
													await cleanUpJobLog()
													await runSMReportAndStatusForCustomer()
													await publishToSKUManagementTopic()
													await timeout(3000)
													setActiveStep(SDASteps.SDAConfigurationComplete)
													break
											}
										}
									}
								} else {
									errorMessage = errorMessageText
								}
							}
						}
					} else {
						errorMessage = errorMessageText
					}
				} else {
					errorMessage = errorMessageText
				}
			})
			.catch(async (error) => {
				await addErrorLog(
					`SDA Lumen Portal (${customerID}) - CheckJobLogForOrderSKUProgress`,
					errorMessage +
						`${JSON.stringify(sdaOrderSKUInfo)}: ${JSON.stringify(error)}`
				)
			})
			.finally(() => {
				if (errorMessage.length > 0) {
					showError(errorMessage)
				}
			})
	}

	//Function to check for Teams SDA Order for this customer
	const CheckForValidTeamsOrderAndExistingHaloUserLicenses = async () => {
		await instance
			.acquireTokenSilent({
				scopes: GraphScopes,
				account: confirmedAccount,
			})
			.then(async (response) => {
				var getHaloUserLicensesRequest: GetHaloUserLicensesRequestModel = {
					Domains: _tenantProvisioningConfig.Domains,
					Token: response.accessToken,
				}

				var encGetHaloUserLicensesRequest: BetaObject = {
					Content: toBetaString(JSON.stringify(getHaloUserLicensesRequest)),
				}

				await getMSLicensesForHaloUsers(encGetHaloUserLicensesRequest)
					.unwrap()
					.then(async (encDomains) => {
						if (encDomains && encDomains.Content) {
							var domains = JSON.parse(
								await toAlphaString(encDomains?.Content + '')
							) as TeamsDRDomainInfo[]

							if (domains) {
								var _tenantConfig = tenantProvisioningConfig
								var domainsForSelectedTeamsService = [] as TeamsDRDomainInfo[]

								domains.forEach((x) => {
									if (
										(x.ServiceID + '').toLowerCase().trim() ===
										teamsServiceID.toLowerCase().trim()
									) {
										domainsForSelectedTeamsService.push(x)
									}
								})

								_tenantConfig.Domains = domainsForSelectedTeamsService

								setTenantProvisioningConfig(_tenantConfig)

								if (
									_tenantConfig?.Domains &&
									_tenantConfig.Domains.length > 0
								) {
									var domainsWithoutLicensesCount = 0

									_tenantConfig.Domains.forEach((x) => {
										if ((x?.TenantUserLicense + '').length === 0) {
											domainsWithoutLicensesCount++
										}
									})

									if (domainsWithoutLicensesCount === 0) {
										await confirmLicenseChoice('')
									} else {
										setNumLicensesRequired(domainsWithoutLicensesCount)
										await CheckForAvailableLicenses(domainsWithoutLicensesCount)
									}
								}
							}
						}
					})
			})
			.catch(async (error) => {
				await addErrorLog(
					`SDA Lumen Portal (${customerID}) - CheckForValidTeamsOrderAndExistingHaloUserLicenses`,
					`${JSON.stringify(sdaOrderSKUInfo)}: ${JSON.stringify(error)}`
				)
				setErrorMessage(
					'Unable to get available licenses for logged-in user, please log out and try again in a few minutes'
				)
				return
			})
	}

	//These Pre-checks are to ensure that the logged-in user has the Global Admin Role in their azure tenancy and that they have at least 2 free licenses
	const CheckGlobalAdminRole = async () => {
		await timeout(20000)
		var errorMessage = ''
		var errorMessageInfo = ''
		//Get graph token
		await instance
			.acquireTokenSilent({
				scopes: GraphScopes,
				account: confirmedAccount,
			})
			.then(async (graphTokenResponse) => {
				if (graphTokenResponse) {
					//Get TeamsToken
					await instance
						.acquireTokenSilent({
							scopes: TeamsScopes,
							account: confirmedAccount,
						})
						.then(async (teamsTokenResponse) => {
							globalAdminGraphToken.current = graphTokenResponse.accessToken
							globalAdminTeamsToken.current = teamsTokenResponse.accessToken

							var checkForAdminPermissionsRequest: CheckUserAdminPermissionsRequest =
								{
									AdminPermissionType: Number(
										UserAdminPermissionsEnums.GlobalAdmin
									),
									Token: graphTokenResponse.accessToken,
								}

							var apiMutation = getEmptyAPIMutationObject()
							apiMutation.QueryParam = 'CheckUserAdminPermissions'
							var betaObj: BetaObject = {
								Content: await toBetaString(
									JSON.stringify(checkForAdminPermissionsRequest)
								),
							}
							apiMutation.BetaObject = betaObj

							await postDataWithoutRefetch(apiMutation)
								.unwrap()
								.then(() => {
									CheckJobLogForOrderSKUProgress()
								})
						})
						.catch(() => {
							setPreChecksDone(false)
							errorMessage =
								'An error occurred when trying to get the teams Token'
						})
				}
			})
			.catch((error) => {
				errorMessageInfo = JSON.stringify(error)
				setPreChecksDone(false)
				errorMessage = 'An error occurred when trying to get the graph Token'
				if ('data' in error) {
					errorMessage =
						'An error occurred when trying to get the graph Token: ' +
						error.data
					if (
						error.data.includes('DR members call') ||
						error.data.includes('No DR Members were found') ||
						error.data.includes(
							'No values were returned from DR Members call'
						) ||
						error.data.includes('No Global Administrator value')
					) {
						errorMessage =
							'Invalid Account Role. You require the Global Administrator role to proceed.'
					}
				}
				if (
					(error + '')
						.toLowerCase()
						.includes(
							'the user or administrator has not consented to use the application'
						) ||
					(error + '')
						.toLowerCase()
						.includes(
							'me call (https://graph.microsoft.com/v1.0/me) was not successful'
						)
				) {
					errorMessage =
						'The user or administrator has not consented to use this application. Please click the log out button below and then return to this page after a few minutes. You will then be prompted to consent to the application again.'
				}
			})
			.finally(async () => {
				if (errorMessage.length > 0) {
					if (
						!errorMessage.includes('User cancelled the flow') &&
						!errorMessage.includes(
							'The user or administrator has not consented to use this application'
						)
					) {
						await addErrorLog(
							`SDA Lumen Portal (${customerID}) - CheckGlobalAdminRole`,
							errorMessage +
								`${JSON.stringify(sdaOrderSKUInfo)}: ${JSON.stringify(
									errorMessage
								)} ${errorMessageInfo}`
						)
						setErrorMessage(
							'Invalid Account Role. Please make sure you are logging in with an account that has the Global Administrator role. Please log out, change your role and try again.'
						)
					} else {
						setErrorMessage(errorMessage)
					}
					setMakeRepeatedAPICalls(false)
				}
			})
	}

	//Function to Check Job Log Table tp know what to do next in SDA/Teams DR process
	const CheckJobLogData = async () => {
		if (encJobLogData?.Content) {
			var alphaText = await toAlphaString(encJobLogData.Content + '')

			if (alphaText.length > 0) {
				var jobLogAPIResponse = JSON.parse(alphaText) as DataResponse

				if (jobLogAPIResponse?.Obj) {
					// Get Job log list for this order ID
					var _jobLogListResponse = jobLogAPIResponse.Obj as JobLogListResponse
					if (_jobLogListResponse?.JobLogList) {
						if (_jobLogListResponse.JobLogList.length > 0) {
							var jobLogList = _jobLogListResponse.JobLogList

							if (jobLogList.length > 0) {
								var jobLogHighestProvisioningStepID = Math.max(
									...jobLogList.map((x) => Number(x.ProvisionStepID))
								)

								if (jobLogHighestProvisioningStepID > 0) {
									var jobLogToFind = jobLogList.find(
										(x) =>
											Number(x.ProvisionStepID) ===
											jobLogHighestProvisioningStepID
									)

									if (
										jobLogToFind?.ProvisionStepID &&
										jobLogToFind?.ProvisioningSteps
									) {
										if (
											Number(jobLogToFind?.ProvisionStepID) ===
												Number(ProvisioningSteps.TeamsDRConfigurationError) ||
											Number(jobLogToFind?.ProvisionStepID) ===
												Number(ProvisioningSteps.ServiceManagerError)
										) {
											//Stop SDA process and show which step it failed on if there is an error
											setErrorMessage(
												`${jobLogToFind?.ProvisioningSteps?.Description}`
											)
											setMakeRepeatedAPICalls(false)
										} else {
											switch (jobLogToFind.ProvisionStepID) {
												case Number(ProvisioningSteps.ProvisioningStarted):
													setProvisioningStepProgress(15)
													break
												case Number(ProvisioningSteps.CreatingDomains):
													setProvisioningStepProgress(25)
													break
												case Number(ProvisioningSteps.VerifyingDomains):
													setProvisioningStepProgress(35)
													break
												case Number(
													ProvisioningSteps.ConfiguringServiceAccounts
												):
													setProvisioningStepProgress(45)
													break
												case Number(ProvisioningSteps.ConfiguringTeamsDRVoice):
													setProvisioningStepProgress(55)
													setActiveStep(SDASteps.TeamsDRVoiceConfiguration)
													break
												case Number(
													ProvisioningSteps.TeamsDRVoiceConfigurationComplete
												):
													setProvisioningStepProgress(65)
													break
												case Number(
													ProvisioningSteps.StartingServiceManagerConfiguration
												):
													setProvisioningStepProgress(80)
													setActiveStep(SDASteps.ServiceManagerConfiguration)
													break
												case Number(
													ProvisioningSteps.AddingTeamsAdminRoleToSMApp
												):
													setProvisioningStepProgress(90)
													break
												case Number(
													ProvisioningSteps.ServiceManagerConfigurationComplete
												):
													//stop refetching data from the job log table, clean up job log table and change SDA steps to last step
													setMakeRepeatedAPICalls(false)
													await cleanUpJobLog()
													await runSMReportAndStatusForCustomer()
													if (!isReprovisioning) {
														//set orderSKU to complete,
														await publishToSKUManagementTopic()
														await timeout(3000)
													}
													setActiveStep(SDASteps.SDAConfigurationComplete)
													break
											}
											setConfigurationDescription(
												`${jobLogToFind?.ProvisioningSteps?.Description}`
											)
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	const publishToSKUManagementTopic = async () => {
		// Publish ServiceBus message
		var publishServiceBusMessageObj: PublishServiceBusMessageModel = {
			Message: `{"actionLogID" : ${sdaOrderSKUInfo.ActionLogID}, "isSuccess": true}`,
			QueueOrTopicName: process.env.REACT_APP_ACTION_COMPLETED_TOPIC_NAME + '',
			ConnectionStringSettingName:
				process.env.REACT_APP_SERVICE_BUS_CONNECTION_STRING_SETTING + '',
		}

		var betaObj: BetaObject = {
			Content: await toBetaString(JSON.stringify(publishServiceBusMessageObj)),
		}
		await publishServiceBusMessage(betaObj)
			.unwrap()
			.then()
			.catch(async (error) => {
				if (error) {
					var errorMessage =
						`An error occurred when publishing order status update message (Lumen Portal - SDAProvisioning) ${JSON.stringify(
							sdaOrderSKUInfo
						)}. Request body: ` +
						JSON.stringify(publishServiceBusMessageObj) +
						` Error: ` +
						error?.data
					addErrorLog('Publish Service Bus Message:', errorMessage)
				}
			})
	}

	const cleanUpJobLog = async () => {
		var _jobLogUpdateObj = {
			JobLog: {
				OrderSKUID: sdaOrderSKUInfo.OrderSKUID,
			},
		}

		var postHookObj: PostHookObj = {
			Action: `Update SDA Job Log`,
			LoggedInUser: loggedInUser,
			RequestURL: `DeleteV2?Params=JobLog`,
			RequestObj: _jobLogUpdateObj,
			ShowSuccessMessage: false,
			ShowErrorMessage: false,
			LogErrorToDB: true,
		}

		await postUpdateDataWithoutRefetch(postHookObj).catch(async (error) => {
			await addErrorLog(
				`SDA Lumen Portal (${customerID}) - cleanUpJobLog`,
				`unable to clean up joblog - ${JSON.stringify(
					sdaOrderSKUInfo
				)}: ${JSON.stringify(error)}`
			)
		})
	}

	const addSDAStartedInJobLog = async () => {
		var jobLogToAdd: JobLog = {
			JobID: `SDAStart-${sdaOrderSKUInfo.OrderSKUID}`,
			Description: 'SDA Started form Lumen Portal',
			OrderSKUID: sdaOrderSKUInfo.OrderSKUID,
			ProvisionStepID: ProvisioningSteps.ProvisioningStarted,
			Status: '',
		}

		var _jobLogAddObj = {
			JobLog: jobLogToAdd,
		}

		var postHookObj: PostHookObj = {
			Action: `Add SDA Started to Job Log`,
			LoggedInUser: loggedInUser,
			RequestURL: `AddV2?Params=JobLog`,
			RequestObj: _jobLogAddObj,
			ShowSuccessMessage: false,
			ShowErrorMessage: false,
			LogErrorToDB: true,
		}

		await postUpdateDataWithoutRefetch(postHookObj).catch(async (error) => {
			await addErrorLog(
				`SDA Lumen Portal (${customerID}) - addSDAStartedInJobLog`,
				`${JSON.stringify(sdaOrderSKUInfo)}: ${JSON.stringify(error)}`
			)
		})
	}

	const runSMReportAndStatusForCustomer = async () => {
		await runSMChecksForCustomer(toBetaString(customerID + '')).unwrap()
	}

	const showError = (errorMessage: string) => {
		//showErrorToast(errorMessage)
		setErrorMessage(errorMessage)
		setMakeRepeatedAPICalls(false)
	}

	//Once the user chooses a license to use, make API call to Start SDA process
	const confirmLicenseChoice = async (licenseChoice: string) => {
		showInitialProvisioningProgress()

		var MSTeamsLicenseToFind = licenseList.find(
			(x) =>
				x.MSTeamsLicenseSKUID?.toLowerCase().trim() ===
				licenseChoice.toLowerCase().trim()
		)

		var domains = tenantProvisioningConfig.Domains as TeamsDRDomainInfo[]
		var regions = tenantProvisioningConfig.Regions as TeamsDRCountryInfo[]

		for (var domainIndex = 0; domainIndex < domains.length; domainIndex++) {
			if (domains[domainIndex]) {
				if (domains[domainIndex]?.TenantUserLicense === '') {
					domains[domainIndex].TenantUserLicense =
						MSTeamsLicenseToFind?.MSTeamsLicenseSKUID
				}
			}
		}

		var filteredRegionsForSelectedService = regions.filter(
			(x) =>
				x.ServiceID?.toLowerCase().trim() ===
				teamsServiceID.toLowerCase().trim()
		)

		var _tenantProvisioningConfig = tenantProvisioningConfig
		_tenantProvisioningConfig.Domains = domains
		_tenantProvisioningConfig.Regions = filteredRegionsForSelectedService
		setTenantProvisioningConfig(_tenantProvisioningConfig)

		var startSDARequest: SDAConfigurationRequest = {
			CustomerID: customerID,
			GraphToken: globalAdminGraphToken.current,
			MSTeamsID: teamsServiceID,
			OrderSKUID: sdaOrderSKUInfo?.OrderSKUID + '',
			SMToken: smToken,
			TeamsToken: globalAdminTeamsToken.current,
			Domains: _tenantProvisioningConfig.Domains,
			Regions: _tenantProvisioningConfig.Regions,
		}

		var apiMutation = getEmptyAPIMutationObject()
		apiMutation.QueryParam = 'StartSDAConfiguration'

		var betaObj: BetaObject = {
			Content: await toBetaString(JSON.stringify(startSDARequest)),
		}
		apiMutation.BetaObject = betaObj

		await startSDAProvisioning(apiMutation)
			.unwrap()
			.then(async () => {
				await addSDAStartedInJobLog()
			})
			.catch(async (error) => {
				await addErrorLog(
					`SDA Lumen Portal (${customerID}) - startSDAProvisioning`,
					`${JSON.stringify(sdaOrderSKUInfo)}: ${JSON.stringify(error)}`
				)
				setErrorMessage(
					'There was an error starting the Teams Direct Routing Provisioning'
				)
			})
	}

	function showInitialProvisioningProgress() {
		setActiveStep(SDASteps.CreatingAndVerifyingDomains)
		setConfigurationDescription('Teams Direct Routing Provisioning Started')
		setProvisioningStepProgress(10)
		setMakeRepeatedAPICalls(true)
	}

	const handleLogout = async () => {
		await instance
			.logoutRedirect({
				onRedirectNavigate: () => {
					// Return false to stop navigation after local logout
					return false
				},
			})
			.then(() => {
				dispatch(setConfirmedAccount(GetEmptyAccountInfo()))
				reloadDashboard(false)
				setErrorMessage('')
				setMakeRepeatedAPICalls(false)
			})
	}

	function toggleLicencesLogoutButton(showLogoutButton: boolean) {
		setShowLicencesLogOutButton(showLogoutButton)
	}

	return (
		<>
			{errorMessage.length > 0 ? (
				<>
					<Box className='error-container'>
						<Alert severity='error'>
							<AlertTitle sx={{ fontSize: '18px', paddingBottom: '10px' }}>
								There was an error encountered during the provisioning process.
								Please contact support and ensure that you log out. Error
								Details:
							</AlertTitle>
							<Typography variant='subtitle1' gutterBottom>
								{errorMessage}
							</Typography>
						</Alert>
						<Box className='error-container-button'>
							<Button onClick={handleLogout} variant='contained'>
								Log Out
							</Button>
						</Box>
					</Box>
				</>
			) : isAuthenticated && confirmedAccount.username.trim().length > 0 ? (
				<Box>
					<Box className='order-and-user-info'>
						<Stack>
							{
								<Chip
									variant='outlined'
									label={
										confirmedAccount.username.length > 0 && isAuthenticated
											? `User: ${confirmedAccount?.username}  -  Order: ${sdaOrderSKUInfo?.ExternalOrderID}.`
											: `User is not logged in  -  Order: ${sdaOrderSKUInfo?.ExternalOrderID}.`
									}
									clickable={false}
								/>
							}
						</Stack>
					</Box>
					<Box>
						<Box>
							{activeStep === SDASteps.GlobalAdminAndHaloUsersLicenseCheck && (
								<Card
									isActive={true}
									cardTitle=''
									cardDescription='Please wait a few minutes while we check for your Microsoft Teams order and your logged-in account role'
									cardBodyIconSrc={'teamsDR/userPermissions.png'}
									cardButtonAction={null}
									cardButtonTitle=''
									showButton={false}
									cardButtonExternalLink={''}
									showLoading={true}
								/>
							)}
							{activeStep === SDASteps.LicenseSelection && (
								<LicenseSelection
									confirmSelection={confirmLicenseChoice}
									licenseList={licenseList}
									numRequiredLicenses={numLicensesRequired}
									showLogOutButton={showLicencesLogOutButton}
									onChangeShowLogOutButton={toggleLicencesLogoutButton}
									onHandleLogOut={handleLogout}
								/>
							)}
							{activeStep === SDASteps.CreatingAndVerifyingDomains && (
								<Card
									isActive={true}
									cardBodyIconSrc={'teamsDR/haloIcon.png'}
									cardButtonAction={null}
									cardButtonTitle=''
									cardMessage='It may take up to 30 minutes to configure your service. You may leave the page.'
									cardMessageType='info'
									showButton={false}
									cardButtonExternalLink={''}
									cardTitle='Configuring your service'
									cardDescription={configurationDescription}
									progress={provisioningStepProgress}
								/>
							)}
							{activeStep === SDASteps.TeamsDRVoiceConfiguration && (
								<Card
									isActive={true}
									cardBodyIconSrc={'teamsDR/teamsLogo.png'}
									cardButtonAction={null}
									cardMessage='It may take up to 20 minutes to configure your Teams Desired Voice state. You may leave the page.'
									cardMessageType='info'
									cardButtonTitle=''
									showButton={false}
									cardButtonExternalLink={''}
									cardTitle='Configuring Teams Voice Desired State'
									cardDescription={configurationDescription}
									progress={provisioningStepProgress}
								/>
							)}
							{activeStep === SDASteps.ServiceManagerConfiguration && (
								<Card
									isActive={true}
									cardBodyIconSrc={'teamsDR/haloIcon.png'}
									cardButtonAction={null}
									cardMessage='It may take up to 10 minutes to complete Service Manager configuration. You may leave the page.'
									cardMessageType='info'
									cardButtonTitle=''
									showButton={false}
									cardButtonExternalLink={''}
									cardTitle='Configuring Service Manager'
									cardDescription={configurationDescription}
									progress={provisioningStepProgress}
								/>
							)}
							{activeStep === SDASteps.SDAConfigurationComplete && (
								<Card
									isActive={true}
									cardBodyIconSrc={'teamsDR/processComplete.png'}
									cardButtonTitle='Proceed'
									showButton={true}
									cardButtonAction={() => {
										reloadDashboard(true)
									}}
									cardTitle='Provisioning Complete'
									cardDescription={
										isReprovisioning
											? 'Provisioning has completed successfully.'
											: 'Provisioning has completed successfully. You will receive an email report shortly.'
									}
								/>
							)}
						</Box>
					</Box>
				</Box>
			) : isAuthenticated && confirmedAccount.username.trim().length === 0 ? (
				<>
					<ConfirmMSALAccountCard displayWithoutCard={true} />
					<Box className='centerBox'>
						<Button onClick={handleLogout} variant='contained'>
							Log Out
						</Button>
					</Box>
				</>
			) : !isAuthenticated ? (
				<LoginCard
					roleName={'Global Administrator'}
					displayWithoutCard={true}
				/>
			) : (
				<Box>
					<Typography
						component='h2'
						style={{
							fontSize: '15px',
						}}>
						Loading...
					</Typography>
					<Preloader />
				</Box>
			)}
		</>
	)
}

export default SDAProvisioning
