import { GridColDef, GridToolbar } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { DomainAndTenantListDisplay } from '../../../../utils/interfaces/ComponentModels'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../shared/datagrid/StyledDataGrid'
import CustomCard from '../../../shared/card/CustomCard'
import {
	TeamsDRDomainInfo,
	TenantConfigurationInfo,
} from '../../../../utils/interfaces/APIModels'

const DomainAndTenantsDisplay = ({
	tenantConfigurationInfo,
}: {
	tenantConfigurationInfo: TenantConfigurationInfo
}) => {
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 6,
		page: 0,
	})
	const [tenantUsersRows, setTenantUsersRows] = useState(
		[] as DomainAndTenantListDisplay[]
	)

	useEffect(() => {
		if (
			tenantConfigurationInfo?.Domains &&
			tenantConfigurationInfo?.Domains.length > 0
		) {
			var _tenantUserDetails = [] as DomainAndTenantListDisplay[]

			var tenantDomains =
				tenantConfigurationInfo?.Domains as TeamsDRDomainInfo[]

			tenantDomains.forEach((domain) => {
				var _tenantUserObj: DomainAndTenantListDisplay = {
					Domain: domain?.Domain + '',
					TenantUser: domain?.TenantUserName + '',
				}
				_tenantUserDetails.push(_tenantUserObj)
			})

			setTenantUsersRows(_tenantUserDetails)
		}
	}, [tenantConfigurationInfo])

	const domainAndTenantUserColumns: GridColDef[] = [
		{
			field: 'Domain',
			headerName: 'Domain',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'TenantUser',
			headerName: 'Tenant User',
			width: 220,
			hideable: false,
			flex: 1,
		},
	]

	return (
		<>
			{tenantConfigurationInfo?.Domains &&
			tenantConfigurationInfo?.Domains.length > 0 ? (
				<StyledDataGrid
					rows={tenantUsersRows}
					columns={domainAndTenantUserColumns}
					editMode='row'
					checkboxSelection={false}
					pagination
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					pageSizeOptions={[5]}
					slots={{
						pagination: CustomPagination,
						toolbar: GridToolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.Domain}
					rowSelection={false}
				/>
			) : (
				// No Domains
				<CustomCard
					cardIconSrc='card-error.png'
					cardTitle='No domains were discovered'
					cardDescription='Kindly be aware that no domains were located for this customer.'
					isActive
					showButton={false}
				/>
			)}
		</>
	)
}

export default DomainAndTenantsDisplay
