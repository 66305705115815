import { Box, Typography } from '@mui/material'
import './Footer.scss'

const Footer = () => {
  return (
    <>
      <Box
        id='footer'
        className='footer-container'
        component='footer'
        bgcolor='fixedElement.default'
        sx={{ borderTop: 2, borderTopColor: 'border.primary' }}>
        <Typography component='p' color='text.header'>
          © Copyright {new Date().getFullYear()}. All rights reserved.
        </Typography>
      </Box>
    </>
  )
}

export default Footer
