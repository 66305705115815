import './ErrorPage.scss'
import { Box, Typography } from '@mui/material'

function ErrorPage() {
	return (
		<Box className='error-page'>
			<Box className='error-message'>
				<Box>
					<Typography component='h1' color='primary.main'>
						Oops....
					</Typography>
				</Box>
				<Box>
					<Typography component='h3' color='primary.main'>
						Something went wrong!
					</Typography>
				</Box>
				<Box>
					<Typography component='h6'>
						There was an error getting data from the server. Please ensure that
						you are logged in with the correct email address or check your
						conection and try again
					</Typography>
				</Box>
			</Box>
			<Box className='error-img'>
				<img
					src={require('../../../assets/icons/error/404 Not Found.png')}
					alt='Error'
				/>
			</Box>
		</Box>
	)
}

export default ErrorPage
