import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import PostAndRetrieveDataHook from '../../../../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import { Alert, Box, Divider, Typography } from '@mui/material'
import { StyledTextBox } from '../../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import { ContactDetail } from '../../../../../../utils/interfaces/DBModels'
import {
	emailValidation,
	showErrorToast,
	showSuccessToast,
	toAlphaString,
	toBetaString,
} from '../../../../../../utils/helperFunctions/helperFunctions'
import { useResendInviteMutation } from '../../../../../../services/proxyAPIData'
import {
	BetaObject,
	InvitationResendRequest,
} from '../../../../../../utils/interfaces/APIModels'
import { LoadingButton } from '@mui/lab'
import { ContactDetailType } from '../../../../../../utils/enums/enums'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import './ResendAuth0Invites.scss'
import CardPreloader from '../../../../../shared/loading/loadingCard/CardPreloader/CardPreloader'

function ResendAuth0Invites() {
	// Global variables
	const customerOrderInfoToUse = useSelector(
		(state: RootState) => state.RootReducer.customerOrderInfoToUseReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// Hooks
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()

	// Resend invites call
	const [resendInvite] = useResendInviteMutation()

	// Flags
	const [apiCallsDone, setAPICallsDone] = useState(false)

	// Component Constans
	const [updatingPrimary, setUpdatingPrimary] = useState(false)
	const [updatingTechnical, setUpdatingTechnical] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [disableBtn, setDisableBtnLoading] = useState(false)
	const [primaryContactEmail, setCustomerPrimaryEmail] = useState('')
	const [primaryInfo, setPrimaryInfo] = useState<ContactDetail>()
	const [technicalInfo, setTechnicalInfo] = useState<ContactDetail>()
	const [technicalContactEmail, setTechnicalEmail] = useState('')

	useEffect(() => {
		if (!apiCallsDone) getCustomerDetails()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiCallsDone])

	// GET: Customer details
	const getCustomerDetails = async () => {
		setAPICallsDone(false)
		var dataResponse = await postAndRetrieveDataFromDB(
			'get Customer Details',
			`ContactDetail.ContactDetailMap.Where(ContactDetailMap.CustomerID = 
			'${customerOrderInfoToUse.CustomerID}'))`,
			false,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var customerDetails = dataResponse.Obj
				.ContactDetailList as ContactDetail[]
			if (customerDetails) {
				var _primaryInfo = customerDetails[0]
				var _technicalInfo = customerDetails[1]
				setCustomerPrimaryEmail(_primaryInfo?.ContactPersonEmail ?? '')
				setTechnicalEmail(_technicalInfo?.ContactPersonEmail ?? '')
				setPrimaryInfo(_primaryInfo)
				setTechnicalInfo(_technicalInfo)
			}
		}
		setAPICallsDone(true)
	}

	// POST: Update invite
	const onUpdate = async (type: string) => {
		setErrorMessage('')
		setDisableBtnLoading(true)
		var OldEmail = ''
		var NewEmail = ''
		var contactDetailID = 0
		switch (type) {
			case ContactDetailType.Billing:
				if (!emailValidation(primaryContactEmail)) {
					setErrorMessage('invalid primary email address')
					return
				}
				setUpdatingPrimary(true)
				OldEmail = primaryInfo?.ContactPersonEmail ?? ''
				NewEmail = primaryContactEmail
				contactDetailID = Number(primaryInfo?.ContactDetailID)
				break
			case ContactDetailType.Engineering:
				if (!emailValidation(technicalContactEmail)) {
					setErrorMessage('invalid technical email address')
					return
				}
				setUpdatingTechnical(true)
				OldEmail = technicalInfo?.ContactPersonEmail ?? ''
				NewEmail = technicalContactEmail
				contactDetailID = Number(primaryInfo?.ContactDetailID)
				break
		}
		var resendInviteRequest: InvitationResendRequest = {
			ContactDetailID: contactDetailID,
			OrderID: customerOrderInfoToUse.OrderID,
			CustomerID: customerOrderInfoToUse.CustomerID,
			OldEmail: OldEmail,
			NewEmail: NewEmail,
			InviterEmail: loggedInUser.email,
			ContactDetailType: type,
		}
		var resendInvitationBetaMutation: BetaObject = {
			Content: await toBetaString(JSON.stringify(resendInviteRequest)),
		}
		await resendInvite(resendInvitationBetaMutation)
			.unwrap()
			.then(async (res) => {
				if (res.Content) {
					var response = await toAlphaString(res.Content)
					showSuccessToast('Invite sent successfully')
					if (response.includes('resent')) {
						await getCustomerDetails()
					}
				}
			})
			.catch(async (e) => {
				showErrorToast('Failed to send invite.')
			})
		setDisableBtnLoading(false)
		setUpdatingPrimary(false)
		setUpdatingTechnical(false)
	}

	// Handle change of value
	const onChangeStringValue = async (val: string, field: string) => {
		switch (field) {
			case 'customerPrimaryEmail':
				setCustomerPrimaryEmail(val)
				break
			case 'customerTechnicalEmail':
				setTechnicalEmail(val)
				break
		}
	}

	return apiCallsDone ? (
		<>
			<Box className='resend-container'>
				{/* Header */}
				<Box className='resend-header'>
					{/* Icon */}
					<Box className='header-icon'>
						<Box className='icon'>
							<GroupAddIcon />
						</Box>
					</Box>
					{/* Text */}
					<Box className='header-text'>
						{/* Heading */}
						<Typography component='h2'>Resend Invites</Typography>
						{/* Description */}
						<Typography component='p'>
							Resend invites for Portal access to your primary or technical
							contact.
						</Typography>
					</Box>
				</Box>
				{/* Form */}
				<Box className='resend-form-content'>
					{/* Error */}
					{errorMessage !== '' && (
						<Alert severity='error'>{errorMessage}</Alert>
					)}
					{/* Section 1 - Primary Contact */}
					<Box className='form-section'>
						{/* Divider Header */}
						<Divider>Primary Contact</Divider>
						{/* Textfield */}
						<StyledTextBox
							fullWidth
							label='Customer Primary Contact Email'
							variant='outlined'
							value={primaryContactEmail}
							onChange={(e) =>
								onChangeStringValue(e.target.value, 'customerPrimaryEmail')
							}
							required
						/>
						{/* Button */}
						<Box className='resend-btn'>
							<LoadingButton
								loading={updatingPrimary}
								disabled={disableBtn}
								variant='contained'
								onClick={() => {
									onUpdate(ContactDetailType.Billing)
								}}>
								Resend invite
							</LoadingButton>
						</Box>
					</Box>
					{/* Section 2 - Technical Contact */}
					<Box className='form-section'>
						{/* Divider Header */}
						<Divider>Technical Contact</Divider>
						{/* Textfield */}
						<StyledTextBox
							fullWidth
							label='Customer Technical Contact Email'
							variant='outlined'
							value={technicalContactEmail}
							onChange={(e) =>
								onChangeStringValue(e.target.value, 'customerTechnicalEmail')
							}
							required
						/>
						{/* Button */}
						<Box className='resend-btn'>
							<LoadingButton
								disabled={disableBtn}
								loading={updatingTechnical}
								variant='contained'
								onClick={() => {
									onUpdate(ContactDetailType.Engineering)
								}}>
								Resend invite
							</LoadingButton>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	) : (
		<>
			<Box className='resend-loading'>
				{/* Preloader */}
				<Box className='resend-loader'>
					<CardPreloader autoMargin={false} />
				</Box>
				{/* Typography */}
				<Typography component='p'>
					Loading and validating information for invites
				</Typography>
			</Box>
		</>
	)
}

export default ResendAuth0Invites
