import { Box, Typography } from '@mui/material'
import './ViewManualBillingNotes.scss'
import { StyledTextArea } from '../../../../../shared/styledComponents/StyledTextArea/StyledTextArea'

const ViewManualBillingNotes = ({
	manualBillingNotes,
}: {
	manualBillingNotes: string
}) => {
	return (
		<>
			<Box className='view-manual-billing-notes-container'>
				{/* Header */}
				<Box className='view-manual-billing-notes-header'>
					{/* Text */}
					<Box className='header-text'>
						{/* Heading */}
						<Typography component='h2'>Manual Billing Notes</Typography>
						{/* Description */}
						<Typography component='p'>
							Custom billing information for order
						</Typography>
					</Box>
				</Box>
				{/* Manual Billing Notes */}
				{manualBillingNotes && (
					<Box sx={{ display: 'flex', rowGap: '5px', flexDirection: 'column' }}>
						<Typography component='p'>Billing Notes </Typography>
						<StyledTextArea
							maxRows={10}
							minRows={10}
							value={manualBillingNotes}
							disabled
						/>
					</Box>
				)}
			</Box>
		</>
	)
}

export default ViewManualBillingNotes
