import { useState } from 'react'
import {
	BetaObject,
	DataResponse,
	GetRequest,
	PostRequest,
	PublishServiceBusMessageModel,
} from '../../interfaces/APIModels'
import {
	usePostAndRetrieveDataMutation,
	usePostBetaObjectWithoutRefetchMutation,
	usePublishServiceBusMessageMutation,
} from '../../../services/proxyAPIData'
import { ReturnTypes } from '../../enums/enums'
import {
	getEmptyAPIMutationObject,
	showErrorToast,
	showSuccessToast,
	toAlphaString,
	toBetaString,
} from '../../helperFunctions/helperFunctions'
import { ErrorHandling } from '../ErrorHandling/ErrorHandling'

export default function UseCrud() {
	// Proxy calls
	const [postAndRetrieveData] = usePostAndRetrieveDataMutation()
	const [postWithoutRefetch] = usePostBetaObjectWithoutRefetchMutation()
	const [publishServiceBusMessage] = usePublishServiceBusMessageMutation()

	// Error Logging
	const addErrorLog = ErrorHandling()

	// General
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	// ** API Calls - All the functions to make GET and POST Calls ** //

	// GET Call - Function to get data using a query URL
	async function fetchData(
		getRequest: GetRequest
	): Promise<string | void | Awaited<DataResponse> | null> {
		// Loading
		setLoading(true)

		// Error handling
		var isError = false

		// Return value
		var returnValue: string | DataResponse | null = null

		return await postAndRetrieveData(getRequest.QueryURL)
			.unwrap()
			.then(async (response) => {
				if (response) {
					// Cast it to the type you want - return type
					switch (getRequest.ReturnType) {
						// Object or list
						case ReturnTypes.ObjectOrList:
							var responseObj = response as BetaObject

							// Check content and decrypt
							if (responseObj && responseObj.Content) {
								// Decrypt the value
								returnValue = JSON.parse(
									await toAlphaString(responseObj.Content)
								) as DataResponse
							}

							break
						// String
						case ReturnTypes.String:
							returnValue = response as string
							break
					}
				}

				// Loading and error
				setLoading(false)

				// Return
				return returnValue
			})
			.catch(async (error) => {
				if (error) {
					isError = true
				}
			})
			.finally(async () => {
				if (isError) {
					// Error handling
					if (getRequest.ShowErrorToast) {
						// Show Error Message
					}
					if (getRequest.LogErrorToDB) {
						// Log Error to DB
						await addErrorLog({
							ActionPerformed: getRequest.FileAndFunctionName,
							ErrorInfo: getRequest.ErrorMessage,
						})
					}

					// Loading and error
					setLoading(false)
					setError(true)
				}
			})
	}

	// POST Call - Function to post an object or string for data to be added, deleted or updated
	async function modifyData(
		postRequest: PostRequest
	): Promise<void | boolean | null> {
		// Loading
		setLoading(true)

		// Error handling
		var isError = false

		// Generate the request body
		var requestBody = {
			Obj: postRequest.QueryObj,
			UserID: postRequest.UserName,
			UserName: postRequest.UserName,
		}

		// Encrypt
		var betaObj: BetaObject = {
			Content: await toBetaString(JSON.stringify(requestBody)),
		}

		// Get latest empty mutation object and send request to be modified
		var apiMutation = getEmptyAPIMutationObject()
		apiMutation.QueryParam = postRequest.QueryURL
		apiMutation.BetaObject = betaObj

		return await postWithoutRefetch(apiMutation)
			.unwrap()
			.then(async () => {
				// Display to user
				if (postRequest.ShowSuccessMessage) {
					// Show success message
					showSuccessToast(postRequest.SuccessMessage + '')
				}
				// Check if Order Status is being updated
				var requestObj = postRequest.QueryObj
				if (requestObj.Order && requestObj.Order.OrderStatusID) {
					// Publish ServiceBus message
					var publishServiceBusMessageObj: PublishServiceBusMessageModel = {
						Message: `{OrderID: ${requestObj.Order.OrderID}}`,
						QueueOrTopicName:
							process.env.REACT_APP_ORDER_STATUS_UPDATE_TOPIC_NAME + '',
						ConnectionStringSettingName:
							process.env.REACT_APP_SERVICE_BUS_CONNECTION_STRING_SETTING + '',
					}
					var betaObj: BetaObject = {
						Content: await toBetaString(
							JSON.stringify(publishServiceBusMessageObj)
						),
					}
					await publishServiceBusMessage(betaObj)
						.unwrap()
						.then()
						.catch(async (error) => {
							if (error) {
								isError = true
							}
						})
				}
				// Return true to say the data has been modified
				return true
			})
			.catch(async (error) => {
				if (error) {
					isError = true
				}
			})
			.finally(async () => {
				if (isError) {
					// Error handling
					if (postRequest.ShowErrorMessage) {
						// Show Error Message
						showErrorToast(postRequest.ErrorMessage)
					}
					if (postRequest.LogErrorToDB) {
						// Log Error to DB
						await addErrorLog({
							ActionPerformed: postRequest.FileAndFunctionName,
							ErrorInfo: postRequest.ErrorMessage,
						})
					}

					// Loading and error
					setLoading(false)
					setError(true)

					return false
				}
			})
	}

	return { fetchData, modifyData, loading, error }
}
