import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'
import ExtensionIcon from '@mui/icons-material/Extension'
import { Box, ListItemIcon, MenuItem, Typography } from '@mui/material'
import { TUMLicenseTypes } from '../../../../../../../../../../utils/enums/enums'
import CircleIcon from '@mui/icons-material/Circle'

const LicenseOption = ({
	licenseType,
	displayName,
	availableUnits,
	handleLicenseSelection,
	requireAddon,
}: {
	licenseType: number
	displayName: string
	availableUnits: number
	handleLicenseSelection: any
	requireAddon?: boolean
}) => {
	return (
		<MenuItem
			className={
				availableUnits === 0 ? 'tum-option disabled-item' : 'tum-option'
			}
			onClick={() => handleLicenseSelection(displayName, licenseType)}>
			{/* Icon */}
			<ListItemIcon>
				{/* Based on license type */}
				{licenseType === TUMLicenseTypes.Base ? (
					<WorkspacePremiumIcon fontSize='small' />
				) : (
					<ExtensionIcon fontSize='small' />
				)}
			</ListItemIcon>
			{/* License Display */}
			<Box className='option-text'>
				{/* Display name */}
				<Typography
					component='h5'
					sx={{
						wordWrap: 'break-word',
						whiteSpace: 'normal',
					}}>
					{displayName}
				</Typography>
				{/* Other information */}
				<Box className='option-info'>
					{/* Available */}
					<Typography component='p'>Available: {availableUnits}</Typography>
					{/* Icon */}
					<CircleIcon />
					{/* Available */}
					{requireAddon && (
						<Typography component='p'>
							Requires Add-on <span>*</span>
						</Typography>
					)}
				</Box>
			</Box>
		</MenuItem>
	)
}

export default LicenseOption
