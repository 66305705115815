import { Box, IconButton, Modal, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import './AddNewRange.scss'
import CloseIcon from '@mui/icons-material/Close'
import { StyledTextBox } from '../../../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import { isValidInteger } from '../../../../../../../utils/helperFunctions/helperFunctions'

const AddNewRange = ({
	newRangesList,
	setNewRangesList,
	handleClose,
	setRangeDataForRow,
	CountryDiallingCodeLength,
}: {
	newRangesList: any
	setNewRangesList: any
	handleClose: any
	setRangeDataForRow: any
	CountryDiallingCodeLength: number
}) => {
	const modalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 500,
		bgcolor: '#fff',
		boxShadow: 24,
		p: 4,
		overflow: 'auto',
		maxHeight: '80%',
	}

	const errorModalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 300,
		bgcolor: '#fff',
		boxShadow: 24,
		p: 4,
		overflow: 'auto',
		maxHeight: '80%',
		textAlign: 'center',
	}

	//constants
	const [ddiRangeStart, setRangeStart] = useState('')
	const [ddiRangeEnd, setRangeEnd] = useState('')
	const [isOpen, setIsOpen] = useState(false)
	const ddiMaxLength = 15 - CountryDiallingCodeLength
	const ddiMinLength = 6

	//errors
	const [ddiRangeStartError, setRangeStartError] = useState(false)
	const [ddiRangeEndError, setRangeEndError] = useState(false)
	const [rangeStartAndEndError, setRangeStartAndEndError] = useState(false)
	const [rangeStartTouched, setRangeStartTouched] = useState<boolean>(false)
	const [rangeEndTouched, setRangeEndTouched] = useState<boolean>(false)

	//valdiation errs
	const [hasRangeValidationErr, setHasRangeValidationErr] = useState('')

	//handle functions for range start and range end
	const handleRangeStart = (e: { target: any }) => {
		var rangeEntered = e.target.value.trim()
		setRangeStart(rangeEntered)

		//validation
		if (
			!isValidInteger(rangeEntered) ||
			rangeEntered.length > ddiMaxLength ||
			rangeEntered.length < ddiMinLength
		) {
			//throw err
			setRangeStartError(true)
			setRangeStartTouched(true)
		} else if (ddiRangeEnd ? rangeEntered.length !== ddiRangeEnd.length : '') {
			setRangeStartError(true)
			setRangeStartAndEndError(true)
		} else {
			setRangeStartError(false)
			if (rangeStartAndEndError && !ddiRangeEnd.startsWith('+')) {
				setRangeEndError(false)
				setRangeStartAndEndError(false)
			}
		}

		if (!rangeEntered || rangeEntered === '') {
			setRangeStartTouched(false)
		}
	}

	const handleRangeEnd = (e: { target: any }) => {
		var rangeEntered = e.target.value.trim()
		setRangeEnd(rangeEntered)

		if (
			!isValidInteger(rangeEntered) ||
			rangeEntered.length > ddiMaxLength ||
			rangeEntered.length < ddiMinLength
		) {
			//throw err
			setRangeEndError(true)
			setRangeEndTouched(true)
		} else if (
			ddiRangeStart ? rangeEntered.length !== ddiRangeStart.length : ''
		) {
			setRangeEndError(true)
			setRangeStartAndEndError(true)
		} else {
			setRangeEndError(false)
			if (rangeStartAndEndError && !ddiRangeStart.startsWith('+')) {
				setRangeStartError(false)
				setRangeStartAndEndError(false)
			}
		}

		if (!rangeEntered || rangeEntered === '') {
			setRangeEndTouched(false)
		}
	}

	const validationCheck = () => {
		setHasRangeValidationErr('')
		var copyOfLatestRangesAdded = [...newRangesList]

		//if there existing ranges in the table
		if (copyOfLatestRangesAdded.length > 0) {
			var overlappingRangesInTable = copyOfLatestRangesAdded.filter(
				(x) =>
					(ddiRangeStart >= x.ddiRangeStart && ddiRangeEnd <= x.ddiRangeEnd) ||
					(ddiRangeStart >= x.ddiRangeStart &&
						ddiRangeStart <= x.ddiRangeEnd) ||
					(ddiRangeEnd >= x.ddiRangeStart && ddiRangeEnd <= x.ddiRangeEnd) ||
					(ddiRangeStart < x.ddiRangeStart && ddiRangeEnd > x.ddiRangeEnd)
			)
			if (ddiRangeStart > ddiRangeEnd || overlappingRangesInTable.length > 0) {
				//show err modal
				setIsOpen(true)
				setHasRangeValidationErr(
					`Please verify that the range values are unique. 
					The Range Start should be less than the Range End, 
					and these specific ranges should not have been previously included in the table.`
				)
			} else {
				addRangeToTable()
			}
		} else {
			//validation for first range being added
			if (ddiRangeStart > ddiRangeEnd) {
				//show err modal
				setIsOpen(true)
				setHasRangeValidationErr(
					`Please verify that the range values are unique. 
					The Range Start should be less than the Range End, 
					and these specific ranges should not have been previously included in the table.`
				)
				return
			} else {
				addRangeToTable()
			}
		}
	}

	const addRangeToTable = () => {
		//get updated list of all the ranges currently added inside the table
		var copyOfLatestRangesAdded = [...newRangesList]

		//check if its the first range being added, if so create an empty object
		if (copyOfLatestRangesAdded.length === 0) {
			//give the index a default value of 0
			var index = 0
			copyOfLatestRangesAdded[index] = {}

			copyOfLatestRangesAdded[index].ID = index
			copyOfLatestRangesAdded[index].ddiRangeStart = ddiRangeStart
			copyOfLatestRangesAdded[index].ddiRangeEnd = ddiRangeEnd

			handleClose()

			setNewRangesList(copyOfLatestRangesAdded)
			setRangeDataForRow(copyOfLatestRangesAdded)
		} else {
			//get the last index value in the array and add 1 to it
			var lastElement =
				copyOfLatestRangesAdded[copyOfLatestRangesAdded.length - 1]
			var getLastIndexAdded = lastElement ? lastElement.ID : 0

			copyOfLatestRangesAdded.push({
				ID: getLastIndexAdded + 1,
				ddiRangeStart: ddiRangeStart,
				ddiRangeEnd: ddiRangeEnd,
			})
			handleClose()

			setNewRangesList(copyOfLatestRangesAdded)
			setRangeDataForRow(copyOfLatestRangesAdded)
		}
	}

	const handleCloseErrorModal = () => {
		setIsOpen(false)
	}

	const disableAddNewRange = () => {
		if (
			ddiRangeStart.trim() === '' ||
			ddiRangeEnd.trim() === '' ||
			ddiRangeEndError ||
			ddiRangeStartError
		) {
			return true
		} else {
			return false
		}
	}

	return (
		<>
			<Box sx={modalStyle}>
				<Box className='range-textboxes'>
					<Box>
						<Typography>Range Start:</Typography>
						<Box>
							<StyledTextBox
								sx={{ width: '200px' }}
								required
								value={ddiRangeStart}
								error={
									rangeStartTouched &&
									(!isValidInteger(ddiRangeStart) || ddiRangeStartError)
								}
								helperText={
									rangeStartTouched && !isValidInteger(ddiRangeStart)
										? 'Only numbers are allowed for range start.'
										: rangeStartTouched && ddiRangeStartError
										? `TN range start must be 6 to ${ddiMaxLength} digits (excl. dialing code), same length as range end, and no spaces.`
										: ''
								}
								type='text'
								onChange={handleRangeStart}
							/>
						</Box>
					</Box>
					<Box>
						<Typography>Range End:</Typography>
						<Box>
							<StyledTextBox
								sx={{ width: '200px' }}
								required
								value={ddiRangeEnd}
								error={
									rangeEndTouched &&
									(!isValidInteger(ddiRangeEnd) || ddiRangeEndError)
								}
								helperText={
									rangeStartTouched && !isValidInteger(ddiRangeEnd)
										? 'Only numbers are allowed for range end.'
										: rangeEndTouched && ddiRangeEndError
										? `TN range end must be 6 to ${ddiMaxLength} digits (excluding dialing code), same length as range start, and no spaces.`
										: ''
								}
								type='text'
								onChange={handleRangeEnd}
							/>
						</Box>
					</Box>
				</Box>

				<Box className='add-new-range-btn'>
					<LoadingButton
						onClick={validationCheck}
						disabled={disableAddNewRange()}>
						Add new range
					</LoadingButton>
				</Box>

				{/* err modal */}
				<Modal
					open={isOpen}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'>
					<Box sx={errorModalStyle}>
						<IconButton
							id='basic-button'
							sx={{ position: 'absolute', top: 8, right: 8 }}
							onClick={() => {
								handleCloseErrorModal()
							}}>
							<CloseIcon />
						</IconButton>

						<Typography id='modal-modal-title' variant='h6' component='h3'>
							Validation Error
						</Typography>
						<Typography id='modal-modal-description'>
							{hasRangeValidationErr}
						</Typography>
					</Box>
				</Modal>
			</Box>
		</>
	)
}

export default AddNewRange
