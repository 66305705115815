import { Box, Typography } from '@mui/material'
import './LoadingCard.scss'
import { useState } from 'react'
import CardPreloader from './CardPreloader/CardPreloader'

const LoadingCard = ({
	loadingCardTitle,
	loadingCardDescription,
}: {
	loadingCardTitle: string
	loadingCardDescription: string
}) => {
	const [showDelayedMessage, setShowDelayedMessage] = useState(false)
	//If any Api call takes longer than 7 seconds then alert the user that they might have slow internet
	setTimeout(() => {
		setShowDelayedMessage(true)
	}, 7000)

	return (
		<>
			<Box className='loading-box' bgcolor='background.wht'>
				<Box className='lb'>
					{/* Preloader */}
					<CardPreloader autoMargin={showDelayedMessage} />
					{/* Preloader Text */}
					<Box>
						<Typography component='h3'>{loadingCardTitle}</Typography>
						<Typography component='p'>{loadingCardDescription}</Typography>
						{showDelayedMessage && (
							<>
								<Typography color={'red'}>
									Sorry, this is taking longer than usual.
								</Typography>
								<Typography color={'red'}>
									Please be patient while we process your request.
								</Typography>
							</>
						)}
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default LoadingCard
