import { useMsal } from '@azure/msal-react'
import { Box, Button, Typography } from '@mui/material'
import './LoginCard.scss'
import CustomCard from '../card/CustomCard'
import { useEffect, useState } from 'react'
import { GraphScopes } from '../../../utils/constants/constants'
import { showErrorToast } from '../../../utils/helperFunctions/helperFunctions'

const LoginCard = ({
	roleName,
	displayWithoutCard,
}: {
	roleName?: string
	displayWithoutCard?: boolean
}) => {
	// MSAL
	const { instance } = useMsal()
	// Constant
	const [cardDescription, setCardDescription] = useState('')

	useEffect(() => {
		if (roleName) {
			setCardDescription(
				`You need to login with Microsoft as a ${roleName} to view these actions.`
			)
		} else {
			setCardDescription(
				`You need to login with Microsoft to view these actions.`
			)
		}
	}, [roleName])

	const handleLogin = async () => {
		const logInRequest = {
			scopes: GraphScopes,
			prompt: 'select_account',
		}

		await instance.loginPopup(logInRequest).catch(() => {
			showErrorToast('Login cancelled')
		})
	}

	return (
		<>
			{displayWithoutCard ? (
				<>
					<Box sx={{ padding: '10px', margin: '10px' }} className='flex-box'>
						<img
							src={require(`../../../assets/icons/card/card-lock.png`)}
							alt='Login Required'
						/>
						<Typography
							className={'card-text'}
							fontSize={17}
							sx={{ marginTop: '15px' }}>
							{cardDescription}
						</Typography>
					</Box>

					<Box className='teamsDRloginButton'>
						<Button
							variant='contained'
							onClick={handleLogin}
							sx={{ marginBottom: '10px', width: '250px' }}>
							Login with Microsoft
						</Button>
					</Box>
				</>
			) : (
				<Box className='login-box'>
					<CustomCard
						cardTitle='Login Required'
						cardDescription={cardDescription}
						showButton={true}
						isActive={true}
						cardIconSrc='card-lock.png'
						cardButtonTitle='Login with Microsoft'
						cardButtonAction={handleLogin}
					/>
				</Box>
			)}
		</>
	)
}

export default LoginCard
