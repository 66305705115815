import { Box, Typography } from '@mui/material'

const TeamsUserManagementAnswer3 = () => {
	return (
		<>
			<Box className='documentation-answer-container'>
				{/* Question - Why can I only see some of my phone numbers in user management?*/}
				<Typography component='ul'>
					<Typography component='li'>
						You will <b>only</b> be able to assign <b>Telephone Numbers</b> in
						User Management that we have <b>registered</b> against your Service.
					</Typography>
					<Typography component='li'>
						If numbers associated with your service are missing, then please
						contact your
						<b> Account Manager</b> to raise the issue.
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export default TeamsUserManagementAnswer3
