import { TableCell, styled, tableCellClasses } from '@mui/material'

// Styled Header
export const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#E7E6E6',
		color: '#000',
		fontSize: 14,
		fontWeight: '700',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		color: '#000',
	},
}))
