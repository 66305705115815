import { Box, Typography } from '@mui/material'

const addTrunksDocumentation = () => {
	return (
		<>
			<Box className='documentation-info-container'>
				<Typography component='h4'>Add Trunk(s) to Users</Typography>
				<Typography component='p'>
					Here we will explain how to add Trunk(s) to orders. This can be
					accessed via the dashboard on the Orders Overview table.
				</Typography>
				<Typography component='p'>
					This process documents how to add Trunk(s) from the Orders Overview
					table on the dashboard.
				</Typography>
				<Typography component='p'>
					Each order entry has an "Options" cog to the right which you can
					select to perform three <br />
					actions for that customer: <br />
				</Typography>
				<Typography component='ol'>
					<Typography component='li'>add a Trunk(s)</Typography>
					<Typography component='li'>add a TN Range(s)</Typography>
					<Typography component='li'>E911 Configuration</Typography>
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/Trunks/Image1.png')}
					alt='Orders Overview page'
					className='landscape'
				/>
				<br />
				<img
					src={require('../../../../../assets/images/documentation/Trunks/Image3.png')}
					alt='Options Cog Add Trunk Selection'
					className='portrait'
					width='200px'
				/>
				<Typography component='p'>
					If you select to add a new Trunk to an order, you will be redirected
					to another page in which you can enter the Trunk information.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/Trunks/New Add Trunks Page.png')}
					alt='Add Trunks Page'
					className='landscape'
				/>
				<Typography component='p'>
					First, you have to select a region(s) for the Trunk IPs. There are
					three different regions you can select from, namely:
				</Typography>
				<Typography component='ol'>
					<Typography component='li'>AMER</Typography>
					<Typography component='li'>APAC</Typography>
					<Typography component='li'>EMEA</Typography>
				</Typography>
				<Typography component='p'>
					Here you can add or remove a trunk(s).
				</Typography>
				<Typography component='p'>
					To add a trunk, select a region(s) by ticking the circle at the top
					right corner of a region card.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/Trunks/AMER 1.png')}
					alt='AMER card'
					className='landscape'
					width='450px'
				/>
				<Typography component='p'>
					Enter the "Carrier Description" for the IP addresses in that region.
					Each carrier description for each region has to be unique. There is a
					tooltip explaining how to define the carrier description.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/Trunks/AMER 2.png')}
					alt='AMER card'
					className='landscape'
					width='450px'
				/>
				<Typography component='p'>
					Now enter the IP address for that region in the "Trunk IP" field below
					the carrier description. For each region you can add a maximum of four
					Trunk IPs.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/Trunks/AMER Region Entered.png')}
					alt='AMER card'
					className='landscape'
					width='450px'
				/>
				<Typography component='p'>
					For the IP address(es) to be added, you can either click "Enter" or "+
					Trunk IP" to the right of the field.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/Trunks/AMER Region Entered.png')}
					alt='AMER card'
					className='landscape'
					width='450px'
				/>
				<Typography component='p'>
					Please note that you have to enter a valid IP address. It will then
					validate the IP address to make sure that the IP is in the correct
					format and that it does not already exist. If the IP address format is
					incorrect, or it already exists, a message in red will appear below
					the problematic field to indicate what can be done to fix it.
				</Typography>
				<Box className='two-image-layout'>
					<img
						src={require('../../../../../assets/images/documentation/Trunks/IP Already Exists.png')}
						alt='IP already exists error'
						width='300px'
					/>
					<img
						src={require('../../../../../assets/images/documentation/Trunks/IP not in the correct format.png')}
						alt='Trunk IP not in the correct format error'
						width='300px'
					/>
				</Box>
				<Typography component='p'>
					You can remove an IP address from the list by clicking on the "x" next
					to the added IP address.
				</Typography>
				<Box className='two-image-layout'>
					<img
						src={require('../../../../../assets/images/documentation/Trunks/AMER Region Entered.png')}
						alt='AMER card'
						width='300px'
					/>
					<img
						src={require('../../../../../assets/images/documentation/Trunks/removeIPAddress.png')}
						alt='AMER card'
					/>
				</Box>
				<Typography component='p'>
					Only once an IP address(es) has been added to all the selected regions
					will the button to "Add Trunk(s)" be enabled.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/Trunks/DisabledAddTrunks.png')}
					alt='AMER card'
					className='landscape'
				/>
				<Typography component='p'>
					You can proceed by clicking the "Add Trunk(s)" after you have entered
					all the necessary details. In summary, you must have:
					<ol>
						<li>added valid IP addresses for all your Trunk IPs</li>
						<li>select the regions for each IP address entered; and</li>
						<li>given unique carrier descriptions to the latter.</li>
					</ol>
				</Typography>
				<Typography component='p'>
					The button will appear to load and a confirmation message will pop-up
					at the top right corner of the screen to indicate whether the trunk(s)
					have been added successfully.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/Trunks/AddTrunksEnabled.png')}
					alt='Add/Loading trunks button'
					className='landscape'
				/>
				<img
					src={require('../../../../../assets/images/documentation/Trunks/Loading Button.png')}
					alt='Loading/delete button'
					className='landscape'
				/>
				<img
					src={require('../../../../../assets/images/documentation/Trunks/Configured Trunk Region Message.png')}
					alt='Trunks confguration success message'
					width='300px'
				/>
				<Typography component='p'>
					The page will reload to retrieve the latest data.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/Trunks/Adding new region.png')}
					alt='AMER/APAC/EMEA cards'
					className='landscape'
				/>
				<Typography component='p'>
					You can delete a trunk by clicking on the "bin" icon in the top right
					corner of the card. It will load and update the information
					accordingly. A confirmation message will appear in the top right
					corner of the screen indicating whether the trunk(s) for that region
					have been deleted successfully.
				</Typography>
				<Box className='two-image-layout'>
					<img
						src={require('../../../../../assets/images/documentation/Trunks/Deleting Region Message.png')}
						alt='AMER card'
						className='document-images'
					/>
					<img
						src={require('../../../../../assets/images/documentation/Trunks/binIcon.png')}
						alt='AMER card'
						className='document-images'
						width='300px'
					/>
				</Box>
				<Typography component='p'>
					To view the data in a table format, you can select the icon in the
					right corner of the customer's name card which will redirect you to
					the "Customer Overview" page.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/Trunks/lastImg.png')}
					alt='Add trunk(s) page'
					className='landscape'
				/>
			</Box>
		</>
	)
}

export default addTrunksDocumentation
