import { Box, Typography } from '@mui/material'

const ServiceManagerAnswer2 = () => {
	return (
		<>
			<Box className='documentation-answer-container'>
				{/* Question - Can I remove the Service Manager Enterprise App?*/}
				<Typography component='ul'>
					<Typography component='li'>
						<b>No</b>, the Enterprise Application is how Service Manager
						accesses your Tenant to perform these checks.
					</Typography>
					<Typography component='li'>
						Without the Application it will stop much of the functionality of
						the service.
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export default ServiceManagerAnswer2
