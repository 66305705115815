import { Box, Typography } from '@mui/material'

const TeamsUserManagementAnswer4 = () => {
	return (
		<>
			<Box className='documentation-answer-container'>
				{/* Question - If I replace an old telephone number with a new one, can I go back to the original?*/}
				<Typography component='ul'>
					<Typography component='li'>
						The system <b>does not keep track</b> of the numbers previously
						allocated to a user.
					</Typography>
					<Typography component='li'>
						The system is doing a <b>real time look-up</b> of the data in your
						Microsoft Tenant.
					</Typography>
					<Typography component='li'>
						If you <b>change</b> a user's <b>number</b> to one of the numbers{' '}
						<b>allocated</b> to this service, removing a number from a service
						we don't manage, you will <b>not</b> be able to <b>reassign </b>
						that old number using our tools.
					</Typography>
					<Typography component='li'>
						To reassign a number from another service, please go into the{' '}
						<b>Teams Admin Center</b> and perform the change to the user's
						profile.
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export default TeamsUserManagementAnswer4
