import {
	Box,
	Divider,
	IconButton,
	Modal,
	Tooltip,
	Typography,
	styled,
	useTheme,
} from '@mui/material'
import { TeamsUserManagementLicenseResponse } from '../../../../../../utils/interfaces/APIModels'
import CallMadeIcon from '@mui/icons-material/CallMade'
import './TUMOverview.scss'
import StyledModal from '../../../../../shared/styledComponents/StyledModal/StyledModal'
import { useState } from 'react'
import { TUMOverviewDisplayOptions } from '../../../../../../utils/enums/enums'
import TUMBaseLicenseDisplay from './TUMBaseLicenseDisplay/TUMBaseLicenseDisplay'
import TUMAddonLicenseDisplay from './TUMAddonLicenseDisplay/TUMAddonLicenseDisplay'
import TUMDDIOverview from './TUMDDIOverview/TUMDDIOverview'

// Create a custom-styled IconButton
const CustomIconButton = styled(IconButton)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.main, // Set background color
	borderRadius: '10px',
	border: `1px solid ${theme.palette.primary.main}`, // Set border color
	'& .MuiSvgIcon-root': {
		color: theme.palette.secondary.contrastText, // Set icon color
		fontSize: '18px',
	},
}))

const TUMOverview = ({
	customerID,
	licenseDisplay,
}: {
	customerID: string
	licenseDisplay: TeamsUserManagementLicenseResponse
}) => {
	// Flags
	const [openModal, setOpenModal] = useState(false)
	const [displayType, setDisplayType] = useState(0)

	// Theme
	const theme = useTheme()

	// Handle opening of modals
	const handleOpenModal = (type: number) => {
		setDisplayType(type)
		setOpenModal(true)
	}

	// Handle closing of modals
	const handleCloseModal = () => {
		setDisplayType(0)
		setOpenModal(false)
	}

	return (
		<>
			{/* Main display */}
			<Box
				className='tum-overview-container'
				bgcolor={theme.palette.background.paper}>
				{/* Base License */}
				<Box className='overview'>
					{/* Header */}
					<Box className='ovw-header'>
						{/* Text */}
						<Typography component='h4'>Base Licenses Overview</Typography>
						{/* Button */}
						<Tooltip title='View your base licenses list'>
							<CustomIconButton
								onClick={() =>
									handleOpenModal(TUMOverviewDisplayOptions.BaseLicenseDisplay)
								}>
								<CallMadeIcon />
							</CustomIconButton>
						</Tooltip>
					</Box>
					{/* Divider */}
					<Divider />
					{/* Overview Values */}
					<Box className='overview-values'>
						{/* Total Purchased  */}
						<Box className='ovw'>
							<Typography component='h2'>
								{licenseDisplay.totalBasePurchased}
							</Typography>
							<Typography component='p'>Total</Typography>
						</Box>
						{/* Divider */}
						<Divider orientation='vertical' />
						{/* Total Purchased  */}
						<Box className='ovw'>
							<Typography component='h2'>
								{licenseDisplay.totalBaseAssigned}
							</Typography>
							<Typography component='p'>Assigned</Typography>
						</Box>
					</Box>
				</Box>
				{/* Divider */}
				<Divider orientation='vertical' />
				{/* Add-on License */}
				<Box className='overview'>
					{/* Header */}
					<Box className='ovw-header'>
						{/* Text */}
						<Typography component='h4'>Add-on Licenses Overview</Typography>
						{/* Button */}
						<Tooltip title='View your add-on licenses list'>
							<CustomIconButton
								onClick={() =>
									handleOpenModal(TUMOverviewDisplayOptions.AddonLicenseDisplay)
								}>
								<CallMadeIcon />
							</CustomIconButton>
						</Tooltip>
					</Box>
					{/* Divider */}
					<Divider />
					{/* Overview Values */}
					<Box className='overview-values'>
						{/* Total Purchased  */}
						<Box className='ovw'>
							<Typography component='h2'>
								{licenseDisplay.totalAddonPurchased}
							</Typography>
							<Typography component='p'>Total</Typography>
						</Box>
						{/* Divider */}
						<Divider orientation='vertical' />
						{/* Total Purchased  */}
						<Box className='ovw'>
							<Typography component='h2'>
								{licenseDisplay.totalAddonAssigned}
							</Typography>
							<Typography component='p'>Assigned</Typography>
						</Box>
					</Box>
				</Box>
				{/* Divider */}
				<Divider orientation='vertical' />
				{/* Phone Numbers */}
				<Box className='overview'>
					{/* Header */}
					<Box className='ovw-header'>
						{/* Text */}
						<Typography component='h4'>Telephone Numbers Overview</Typography>
						{/* Button */}
						<Tooltip title='View your telephone number ranges list'>
							<CustomIconButton
								onClick={() =>
									handleOpenModal(TUMOverviewDisplayOptions.DDIRangeDisplay)
								}>
								<CallMadeIcon />
							</CustomIconButton>
						</Tooltip>
					</Box>
					{/* Divider */}
					<Divider />
					{/* Overview Values */}
					<Box className='overview-text'>
						{/* Total Purchased  */}
						<Typography>
							Click on the button to view the list of phone number ranges that
							you own. This will help when typing to assign a phone number for a
							user below.
						</Typography>
					</Box>
				</Box>
			</Box>
			{/* Modals */}
			<Modal open={openModal}>
				<StyledModal width={700}>
					{displayType === TUMOverviewDisplayOptions.BaseLicenseDisplay ? (
						// Base License Display Modal
						<TUMBaseLicenseDisplay
							baseLicenses={licenseDisplay.baseLicenses}
							handleCloseModal={handleCloseModal}
						/>
					) : displayType === TUMOverviewDisplayOptions.AddonLicenseDisplay ? (
						// Add-on License Display Modal
						<TUMAddonLicenseDisplay
							addonLicenses={licenseDisplay.addonLicenses}
							handleCloseModal={handleCloseModal}
						/>
					) : (
						displayType === TUMOverviewDisplayOptions.DDIRangeDisplay && (
							// DDI Ranges Display Modal
							<TUMDDIOverview
								customerID={customerID}
								handleCloseModal={handleCloseModal}
							/>
						)
					)}
				</StyledModal>
			</Modal>
		</>
	)
}

export default TUMOverview
