import { Avatar, Box, Typography } from '@mui/material'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import './TUMEditForm.scss'
import { useState } from 'react'
import {
	TeamsUserManagementConfigurationRequest,
	TeamsUsersResponse,
} from '../../../../../../../utils/interfaces/APIModels'
import { stringAvatar } from '../../../../../../../utils/helperFunctions/helperFunctions'
import LicenseSection from './FormSections/LicenseSection/LicenseSection'
import PhoneSection from './FormSections/PhoneSection/PhoneSection'
import { LoadingButton } from '@mui/lab'
import { useTUMContext } from '../../../../../../../utils/context/TUMContext'
import { v4 as uuidv4 } from 'uuid'
import {
	TUMConfigurationTypes,
	TUMPhoneNumberTypes,
} from '../../../../../../../utils/enums/enums'

const TUMEditForm = ({ currentUser }: { currentUser: TeamsUsersResponse }) => {
	// Context
	const {
		teamsLicenseDisplay,
		msTeamsLicenseSKUList,
		tumVRPList,
		removeAddon,
		removeBase,
		removePhone,
		getDDIServiceTypeID,
		validateDDI,
		configureTeamsUser,
	} = useTUMContext()

	// User Information
	const [user] = useState<TeamsUsersResponse>(currentUser)
	const [updatedUser, setUpdatedUser] =
		useState<TeamsUsersResponse>(currentUser)

	// Flags
	const [btnLoading, setBtnLoading] = useState(false)

	// Handle value change
	const handleValueChange = (
		property: keyof TeamsUsersResponse,
		value: any
	) => {
		// Set updated user
		setUpdatedUser((prev) => ({
			...prev,
			[property]: value,
		}))
	}

	// Handle compare array for change
	const handleCompareArrays = (): boolean => {
		// Check if something has changed and return flag
		var hasChanged = false

		// Convert arrays to strings to compare
		const currentUserString = JSON.stringify(user)
		const updatedUserString = JSON.stringify(updatedUser)

		if (currentUserString !== updatedUserString) {
			hasChanged = true
		}

		return hasChanged
	}

	// Handle disable button
	const handleDisableButton = (): boolean => {
		// Check for changes
		const hasSomethingChanged =
			handleCompareArrays() || removeBase || removeAddon || removePhone
		return !hasSomethingChanged
	}

	// Handle show phone number
	const showPhoneNumberAssignment = () => {
		// Check if user has the the correct license
		if (updatedUser.baseLicenseSKUID) {
			// Check list
			if (
				msTeamsLicenseSKUList.find(
					(base) =>
						base.MSTeamsLicenseSKUID + '' === updatedUser.baseLicenseSKUID &&
						base.EnterpriseVoiceIncluded === true
				)
			) {
				return true
			} else {
				// Check add-on
				if (updatedUser.addonLicenseSKUID) {
					// Check list
					if (
						msTeamsLicenseSKUList.find(
							(base) =>
								base.MSTeamsLicenseSKUID + '' === updatedUser.addonLicenseSKUID
						)
					) {
						return true
					}
				}
			}
		}

		return false
	}

	// Handle button click for configuration
	const handleButtonClick = async () => {
		// Start loading
		setBtnLoading(true)

		// Check what has been changed and build configuration list
		var configurationList = [] as TeamsUserManagementConfigurationRequest[]

		// 1 - Check if something is to be removed
		if (removeBase || removeAddon || removePhone) {
			// Hierachy on actions - Phone Number > Add-on License > Base License
			// Phone Number - Add to list
			if (removePhone) {
				// Get the number type
				var serviceTypeID = await getDDIServiceTypeID(
					updatedUser.phoneNumber + ''
				)

				configurationList.push({
					JobID: uuidv4(),
					ActionTypeID: TUMConfigurationTypes['Remove TN'],
					UPN: updatedUser.upn,
					LineURI: updatedUser.phoneNumber,
					VoiceRoutingPolicy: updatedUser.voiceRoutingPolicy,
					ServiceTypeID: serviceTypeID,
				})
			}

			// Add-on License - Add to list
			if (removeAddon) {
				configurationList.push({
					JobID: uuidv4(),
					ActionTypeID: TUMConfigurationTypes['Remove License'],
					UPN: updatedUser.upn,
					ServiceTypeID: TUMPhoneNumberTypes.TDR,
					SKUs: [
						{
							SKUID: updatedUser.addonLicenseSKUID,
						},
					],
				})
			}

			// Base License - Add to list
			if (removeBase) {
				configurationList.push({
					JobID: uuidv4(),
					ActionTypeID: TUMConfigurationTypes['Remove License'],
					UPN: updatedUser.upn,
					ServiceTypeID: TUMPhoneNumberTypes.TDR,
					SKUs: [
						{
							SKUID: updatedUser.baseLicenseSKUID,
						},
					],
				})
			}
		}

		// 2 - Check if something needs to be assigned
		if (
			// Base License
			(updatedUser.baseLicenseName &&
				updatedUser.baseLicenseName + '' !== user.baseLicenseName) ||
			// Add-on License
			(updatedUser.addonLicenseName &&
				updatedUser.addonLicenseName + '' !== user.addonLicenseName) ||
			// Phone Number
			(updatedUser.phoneNumber &&
				updatedUser.phoneNumber + '' !== user.phoneNumber) ||
			// International calling (VRP)
			updatedUser.internationalCalling !== user.internationalCalling
		) {
		}
		// Hierachy on actions - Base License > Add-on License > Phone Number
		// Base License
		if (
			updatedUser.baseLicenseName &&
			updatedUser.baseLicenseName + '' !== user.baseLicenseName
		) {
			// Get license
			var base = teamsLicenseDisplay.baseLicenses.find(
				(base) => base.baseLicenseName === updatedUser.baseLicenseName
			)

			if (base) {
				configurationList.push({
					JobID: uuidv4(),
					ActionTypeID: TUMConfigurationTypes['Add License'],
					UPN: updatedUser.upn,
					ServiceTypeID: TUMPhoneNumberTypes.TDR,
					SKUs: [
						{
							SKUID: base.baseLicenseSKUID,
						},
					],
				})
			}
		}

		// Add-on License
		if (
			updatedUser.addonLicenseName &&
			updatedUser.addonLicenseName + '' !== user.addonLicenseName
		) {
			// Get license
			var addon = teamsLicenseDisplay.addonLicenses.find(
				(add) => add.addonLicenseName === updatedUser.addonLicenseName
			)

			if (addon) {
				configurationList.push({
					JobID: uuidv4(),
					ActionTypeID: TUMConfigurationTypes['Add License'],
					UPN: updatedUser.upn,
					ServiceTypeID: TUMPhoneNumberTypes.TDR,
					SKUs: [
						{
							SKUID: addon.addonLicenseSKUID,
						},
					],
				})
			}
		}

		// Phone Number OR International Calling change
		if (
			// Phone Number
			(updatedUser.phoneNumber &&
				updatedUser.phoneNumber + '' !== user.phoneNumber) ||
			// International calling (VRP)
			updatedUser.internationalCalling !== user.internationalCalling
		) {
			// Get phone
			var filter = user.phoneNumber + ''
			if (updatedUser.phoneNumber && updatedUser.phoneNumber.length > 0) {
				filter = updatedUser.phoneNumber
			}
			var phone = await validateDDI(filter)

			if (phone && phone.ddi && phone.ddi.length > 0) {
				// Get VRP
				var vrp = tumVRPList.find(
					(vrp) => vrp.CountryISO2Code + '' === phone?.countryISO2Code + ''
				)

				// Check whether national or international calling
				var vrpToUse = ''
				if (vrp) {
					if (updatedUser.internationalCalling) {
						vrpToUse = vrp.IntVoiceRoutingPolicy + ''
					} else {
						vrpToUse = vrp.DomVoiceRoutingPolicy + ''
					}
				}

				configurationList.push({
					JobID: uuidv4(),
					ActionTypeID: TUMConfigurationTypes['Add TN'],
					UPN: updatedUser.upn,
					LineURI: phone.ddi,
					ISO: phone.countryISO2Code,
					VoiceRoutingPolicy: vrpToUse,
					ServiceTypeID: phone.serviceTypeID,
				})
			}
		}

		// Send configuration list to the runbook
		await configureTeamsUser(configurationList)

		// End loading
		setBtnLoading(false)
	}

	return (
		<>
			<Box className='tum-form-container'>
				{/* Header */}
				<Box className='tum-form-header'>
					{/* Icon */}
					<Box className='form-icon'>
						<Box className='icon'>
							<ContactPhoneIcon />
						</Box>
					</Box>
					{/* Text */}
					<Box className='form-text'>
						<Typography component='h2'>Manage Teams User</Typography>
						<Typography component='p'>
							Choose to assign/unassign license(s) and/or a phone number to this
							user
						</Typography>
					</Box>
				</Box>
				{/* User Information */}
				<Box className='tum-form-user-info'>
					{/* Avatar */}
					<Avatar className='avatar' {...stringAvatar(user.name + '')} />
					{/* Username and Email */}
					<Box className='user-info'>
						<Typography component='h5'>{user.name}</Typography>
						<Typography component='p'>{user.upn}</Typography>
					</Box>
				</Box>
				{/* Form Content */}
				<Box className='tum-form-content'>
					{/* Form Item 1 - License Section */}
					<LicenseSection
						user={currentUser}
						handleValueChange={handleValueChange}
					/>
					{/* Form Item 2 - Phone Section */}
					{showPhoneNumberAssignment() && (
						<PhoneSection
							user={currentUser}
							handleValueChange={handleValueChange}
						/>
					)}
				</Box>
				{/* Form Action */}
				<Box className='tum-form-btn'>
					<LoadingButton
						variant='contained'
						disabled={handleDisableButton()}
						loading={btnLoading}
						onClick={handleButtonClick}>
						Configure User
					</LoadingButton>
				</Box>
			</Box>
		</>
	)
}

export default TUMEditForm
