import {
	Box,
	Collapse,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material'
import './Documentation.scss'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { DocumentationTypes, Roles } from '../../../../utils/enums/enums'
import AddTrunks from './AddTrunks/AddTrunks'
import TeamsE911Configuration from './TeamsE911/TeamsE911'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import TenantConfiguration from './TenantConfiguration/TenantConfiguration'
import AddTNDocumentation from './AddTN/AddTN'

const Documentation = () => {
	const loggedInRoleAndPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)

	// Flags
	const [documentationType, setDocumentationType] = useState(0)
	const [openOrderDocs, setOpenOrderDocs] = useState(true)
	const [isPartnerAdmin, setIsPartnerAdmin] = useState(false)
	const [isCustomerAdmin, setIsCustomerAdmin] = useState(false)

	useEffect(() => {
		// Check if user is partner or customer admin
		// Partner Admin
		if (
			loggedInRoleAndPermissions.find(
				(rolePermission) => Number(rolePermission.RoleID) === Roles.PartnerAdmin
			)
		) {
			setIsPartnerAdmin(true)
		}
		// Customer Admin
		if (
			loggedInRoleAndPermissions.find(
				(rolePermission) =>
					Number(rolePermission.RoleID) === Roles.CustomerAdmin
			)
		) {
			setIsCustomerAdmin(true)
		}
	}, [loggedInRoleAndPermissions])

	// Handle Functions
	// Handle open nested list for Order Documentation
	const handleOpenOrderDocsClick = () => {
		setOpenOrderDocs(!openOrderDocs)
	}

	// Handle open documentation
	const handleDocumentationView = (documentationType: number) => {
		setDocumentationType(documentationType)
	}

	return (
		<>
			<Box className='documentation-container'>
				{/* Content */}
				<Box className='documentation-content'>
					{/* List Menu - To manage the change in documentation view */}
					<Box>
						<List>
							<ListItemButton onClick={handleOpenOrderDocsClick}>
								<ListItemIcon>
									<img
										src={require('../../../../assets/icons/support/documentation-icon.png')}
										alt='Order Update Process Icon'
									/>
								</ListItemIcon>
								<ListItemText primary='Order Update Process' />
								{openOrderDocs ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
							<Collapse in={openOrderDocs} timeout='auto' unmountOnExit>
								<List component='div' disablePadding>
									{isPartnerAdmin && (
										<ListItemButton
											sx={{ pl: 4 }}
											onClick={() =>
												handleDocumentationView(DocumentationTypes.AddDID)
											}>
											<ListItemIcon></ListItemIcon>
											<ListItemText primary='Add TN' />
										</ListItemButton>
									)}

									{isPartnerAdmin && (
										<ListItemButton
											sx={{ pl: 4 }}
											onClick={() =>
												handleDocumentationView(DocumentationTypes.AddTrunks)
											}>
											<ListItemIcon></ListItemIcon>
											<ListItemText primary='Add Trunks' />
										</ListItemButton>
									)}
									{isPartnerAdmin && (
										<ListItemButton
											sx={{ pl: 4 }}
											onClick={() =>
												handleDocumentationView(
													DocumentationTypes.TeamsE911CallRoutingConfiguration
												)
											}>
											<ListItemIcon></ListItemIcon>
											<ListItemText primary='Teams E911 Call Routing Configuration' />
										</ListItemButton>
									)}
									{isCustomerAdmin && (
										<ListItemButton
											sx={{ pl: 4 }}
											onClick={() =>
												handleDocumentationView(
													DocumentationTypes.TenantConfiguration
												)
											}>
											<ListItemIcon></ListItemIcon>
											<ListItemText primary='Teams Direct Routing - Tenant Configuration' />
										</ListItemButton>
									)}
								</List>
							</Collapse>
						</List>
					</Box>
					{/* Documentation box */}
					<Box
						className='documentation-box'
						border={1}
						borderColor='primary.main'>
						{documentationType > 0 ? (
							<Box className='documentation'>
								{/* Conditional logic to display data based on the list item click */}
								{/* Conditional logic based on user role */}
								{/* Add trunks  */}
								{documentationType === DocumentationTypes.AddTrunks &&
									isPartnerAdmin && <AddTrunks />}
								{/* E911 Configuration */}
								{documentationType ===
									DocumentationTypes.TeamsE911CallRoutingConfiguration &&
									isPartnerAdmin && <TeamsE911Configuration />}
								{/* DID information */}
								{documentationType === DocumentationTypes.AddDID &&
									isPartnerAdmin && <AddTNDocumentation />}
								{/* Teams Direct Routing - Tenant Configuration */}
								{documentationType === DocumentationTypes.TenantConfiguration &&
									isCustomerAdmin && <TenantConfiguration />}
							</Box>
						) : (
							// Empty display
							<Box className='empty-documentation-display'>
								<Box className='empty-display'>
									<img
										src={require('../../../../assets/icons/support/empty-display-icon.png')}
										alt='Empty Documentation Display'
									/>
									<Typography component='h2' color='primary.main'>
										Select a document to view
									</Typography>
								</Box>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default Documentation
