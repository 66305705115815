import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material'
import {
	TUMBuildStatuses,
	TUMLoadTypes,
} from '../../../../../../utils/enums/enums'
import MicrosoftIcon from '@mui/icons-material/Microsoft'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import ShieldIcon from '@mui/icons-material/Shield'
import GppBadIcon from '@mui/icons-material/GppBad'
import './TUMLoginBox.scss'
import { useTUMContext } from '../../../../../../utils/context/TUMContext'

const TUMLoginBox = () => {
	// Theme
	const theme = useTheme()

	// Context
	const { loadingStatus, loggedInAccount, handleLogin } = useTUMContext()
	const adminStatus = loadingStatus.find(
		(status) => status.LoadStatusType === TUMLoadTypes.Admin
	)

	return (
		<>
			<Box className='tum-login-box'>
				{/* Section 1: Account Details */}
				<Box
					className='acc-details'
					border='1px solid'
					borderColor={theme.palette.primary.main}>
					{/* Icons: Plain Shield (Primary colour - if not logged in), Green Verified User once admin check is passed, Red Bad Icon if not valid account */}
					{adminStatus?.LoadStatus === TUMBuildStatuses.Success ? (
						<Tooltip title='Login to verify account'>
							<VerifiedUserIcon sx={{ color: theme.palette.success.main }} />
						</Tooltip>
					) : adminStatus?.LoadStatus === TUMBuildStatuses.Failure ? (
						<Tooltip title='Login to verify account'>
							<GppBadIcon sx={{ color: theme.palette.error.main }} />
						</Tooltip>
					) : (
						<Tooltip title='Login to verify account'>
							<ShieldIcon sx={{ color: theme.palette.primary.main }} />
						</Tooltip>
					)}
					{/* Account Chosen */}
					<Box className='acc-text'>
						<Typography component='h4'>Account</Typography>
						{loggedInAccount.username && loggedInAccount.username.length > 0 ? (
							<Typography component='p'>{loggedInAccount.username}</Typography>
						) : (
							<Typography component='p'>{'<No account logged in>'}</Typography>
						)}
					</Box>
				</Box>
				{/* Login Options */}
				{!loggedInAccount.username && (
					<Box className='login'>
						<Typography component='p'>
							Please login to start the process <strong>below</strong> and view
							this page.
						</Typography>
						{/* Button - Handle Login */}
						<Button
							startIcon={
								<MicrosoftIcon
									sx={{
										color: theme.palette.primary.contrastText,
									}}
								/>
							}
							sx={{ margin: 2.5 }}
							variant='contained'
							onClick={handleLogin}>
							Login with Microsoft
						</Button>
					</Box>
				)}
			</Box>
		</>
	)
}

export default TUMLoginBox
