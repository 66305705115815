import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface StyledModalProps {
	width: number
	children: ReactNode
}

export const modalStyle = (width: number) => ({
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: width,
	bgcolor: '#fff',
	boxShadow: 24,
	p: 4,
	overflow: 'auto',
	maxHeight: '85%',
})

const StyledModal: React.FC<StyledModalProps> = ({ width, children }) => {
	return (
		<Box sx={modalStyle(width)} className='modal'>
			{children}
		</Box>
	)
}

export default StyledModal
