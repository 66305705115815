import { ComponentType, FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import {
	getEmptyAPIMutationObject,
	GetFullLogErrorMessage,
} from '../../../utils/helperFunctions/helperFunctions'
import { RootState } from '../../../store/store'
import { BetaAPIMutation } from '../../../utils/interfaces/APIModels'
import { usePostBetaObjectWithoutRefetchMutation } from '../../../services/proxyAPIData'

export interface WithRoleBasedRedirectOptions {
	permissionID: number
}
export const withRoleBasedRedirect =
	<P extends object>(
		Component: ComponentType<P>,
		options: WithRoleBasedRedirectOptions
	): FC<P> =>
	(props: P): JSX.Element => {
		const pageName = 'RoleBasedRedirect'
		const [isAuthorized, setIsAuthorized] = useState(false)
		const [postWithoutRefetch] = usePostBetaObjectWithoutRefetchMutation()
		const navigate = useNavigate()
		const loggedInUserRolesPermissions = useSelector(
			(state: RootState) =>
				state.RootReducer.loggedInUserRolesPermissionsReducer.value
		)
		const loggedInUser = useSelector(
			(state: RootState) => state.RootReducer.loggedInUserReducer.value
		)

		const LogErrorMessage = async (error: any) => {
			var _log = getEmptyAPIMutationObject()
			_log = await GetFullLogErrorMessage(
				error,
				pageName,
				loggedInUser.userID,
				loggedInUser.fullName
			)
			const betaApiMutation: BetaAPIMutation = _log
			await postWithoutRefetch(betaApiMutation).unwrap()
		}

		useEffect(() => {
			async function checkRoles(permissionID: number) {
				const isAuthorized = loggedInUserRolesPermissions.find(
					(perm) => Number(perm.PermissionID) === permissionID
				)
				if (!isAuthorized) {
					LogErrorMessage(new Error('Unauthorised route'))

					navigate('/Error')
				} else {
					setIsAuthorized(true)
				}
			}
			if (loggedInUserRolesPermissions.length === 0) {
				navigate('/')
			} else {
				checkRoles(options.permissionID)
			}
		})

		return isAuthorized ? <Component {...props} /> : <div></div>
	}
