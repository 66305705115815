import {
	CustomPostHookObj,
	MSLineURIAndRoutingPlanUsers,
	MSRequest,
	MSResponse,
	TenantConfigurationInfo,
	UserDDIs,
	UserLicenseListResponse,
	UserManagementLicenseResponse,
	UserManagementResponseList,
} from './../../interfaces/APIModels'
import {
	MSTeamsLicenseSKU,
	OrderSKUProductMap,
	Service,
	ServiceManagementStatus,
} from './../../interfaces/DBModels'
import {
	Endpoints,
	ProductActionStatus,
	SDAStatus,
	ServiceManagementStatusCheck,
	TokenType,
	OrderType,
	ServiceTypes,
} from '../../enums/enums'
import {
	MicrosoftLicenseOverview,
	MicrosoftLineURIOverview,
	SDAOrderSKUInfo,
	ServiceManagementCheck,
	TeamsServicesAndCustomerServices,
	UserManagementAddonLicenseDisplay,
	UserManagementBaseLicenseDisplay,
	UserManagementLicenseOverview,
} from '../../interfaces/ComponentModels'
import { MSTeamsUser } from '../../interfaces/DBModels'
import PostAndRetrieveDataHook from '../APICalls/PostAndRetrieveDataHook'
import { ServiceManagementReport } from './../../interfaces/DBModels'
import { TokenRequest } from '../../interfaces/APIModels'
import PostCustomEndpointHook from '../APICalls/PostCustomEndpointHook'
import { toAlphaString } from '../../helperFunctions/helperFunctions'

// Hook call to manage the prechecks for dashboard, teams user management and SDA
const Prechecks = (customerID: string) => {
	// Hooks
	const {
		postAndRetrieveDataFromDB,
		postAndRetrieveDataError,
		postAndRetrieveDataLoading,
	} = PostAndRetrieveDataHook()
	const {
		postCustomEndpoint,
		postCustomEndpointError,
		postCustomEndpointLoading,
	} = PostCustomEndpointHook()

	// ******************************* API Calls ******************************* //

	// GET: Check for teams service
	// Return - msTeamsUser: MSTeamsUser
	const getTeamsServicesAndCustomerServices = async () => {
		// Return obj

		var teamsServicesAndCustomerServices =
			{} as TeamsServicesAndCustomerServices

		if (!postAndRetrieveDataLoading && !postAndRetrieveDataError) {
			// Make call
			var dataResponse = await postAndRetrieveDataFromDB(
				'MS Teams',
				`MSTeamsUser.Where(MSTeamsUser.CustomerID = '${customerID}'),Service.Where(Service.ServiceID ~ '${customerID}' & Service.ServiceTypeID = '${ServiceTypes.MSTeamsDirectRouting}')`,
				false,
				true
			)
			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				var msTeamsResponseList = dataResponse?.Obj
					?.MSTeamsUserList as MSTeamsUser[]

				var servicesResponseList = dataResponse.Obj.ServiceList as Service[]

				// Check response
				if (msTeamsResponseList && msTeamsResponseList.length > 0) {
					teamsServicesAndCustomerServices.TeamsServices = msTeamsResponseList
				}
				if (servicesResponseList && servicesResponseList.length > 0) {
					teamsServicesAndCustomerServices.Services = servicesResponseList
				}
			}
		}

		return teamsServicesAndCustomerServices
	}

	// GET: Check for teams order and validate the status
	const getSDAOrderSKU = async (msTeamsServiceID: string) => {
		var teamsOrderStatus = SDAStatus.NoTeamsOrderSKUFound
		var orderSKUID = 0
		var orderID = 0
		var externalOrderID = ''
		var orderSKUProductMapID = 0
		var actionLogID = 0

		var matchingOrderSKUProductMapResponse = await postAndRetrieveDataFromDB(
			'OrderSKUProductMap',
			`OrderSKUProductMap.First(OrderSKUProductMap.ServiceID = '${msTeamsServiceID}')`,
			false,
			true
		)

		if (
			matchingOrderSKUProductMapResponse &&
			Number(matchingOrderSKUProductMapResponse.Count) > 0 &&
			matchingOrderSKUProductMapResponse.Obj
		) {
			var teamsOrderSKUProductMap = matchingOrderSKUProductMapResponse.Obj
				.OrderSKUProductMap as OrderSKUProductMap
			if (teamsOrderSKUProductMap && teamsOrderSKUProductMap.OrderSKUID) {
				var orderSKUProductMapForSDAResponse = await postAndRetrieveDataFromDB(
					'OrderSKUProductMapList',
					`OrderSKUProductMap.OrderSKU.Order.First(OrderSKUProductMap.OrderSKUID = '${teamsOrderSKUProductMap.OrderSKUID}' & OrderSKUProductMap.ProductID = 'SDA' & Order.OrderTypeID = '${OrderType.New}'), ActionLog.First(ActionLog.ProductID = 'SDA' & ActionLog.OrderSKUID = '${teamsOrderSKUProductMap.OrderSKUID}')`,
					false,
					true
				)
				if (
					orderSKUProductMapForSDAResponse &&
					Number(orderSKUProductMapForSDAResponse.Count) > 0 &&
					orderSKUProductMapForSDAResponse.Obj
				) {
					externalOrderID =
						orderSKUProductMapForSDAResponse.Obj?.OrderSKUProductMap?.OrderSKU
							?.Order?.ExternalOrderID + ''

					orderID = Number(
						orderSKUProductMapForSDAResponse.Obj?.OrderSKUProductMap?.OrderSKU
							?.Order?.OrderID
					)

					actionLogID = Number(
						orderSKUProductMapForSDAResponse.Obj?.ActionLog?.ActionLogID
					)

					var teamsOrderSKUProductMapForSDA = orderSKUProductMapForSDAResponse
						.Obj.OrderSKUProductMap as OrderSKUProductMap

					if (
						teamsOrderSKUProductMapForSDA &&
						teamsOrderSKUProductMapForSDA.ProductActionStatusID
					) {
						orderSKUID = Number(teamsOrderSKUProductMapForSDA.OrderSKUID)
						orderSKUProductMapID = Number(
							teamsOrderSKUProductMapForSDA.OrderSKUProductMapID
						)

						switch (teamsOrderSKUProductMapForSDA.ProductActionStatusID) {
							case ProductActionStatus[ProductActionStatus['NOT-START']]:
								teamsOrderStatus = SDAStatus.NotReadyForSDA
								break
							case ProductActionStatus[ProductActionStatus['IN-PROG']]:
								teamsOrderStatus = SDAStatus.ReadyForSDA
								break
							case ProductActionStatus[ProductActionStatus.COMP]:
								teamsOrderStatus = SDAStatus.SDACompleted
								break
						}
					}
				}
			}
		}

		var sdaOrderSKUInfo: SDAOrderSKUInfo = {
			OrderSKUID: orderSKUID,
			SDAStatus: teamsOrderStatus,
			ExternalOrderID: externalOrderID,
			OrderID: orderID,
			OrderSKUProductMapID: orderSKUProductMapID,
			ActionLogID: actionLogID,
		}

		return sdaOrderSKUInfo
	}

	// GET: Check the Service Management Status and Report
	// Return - serviceManagementCheck: ServiceManagementCheck
	const getServiceManagementStatusAndReport = async () => {
		// Return variables
		var serviceManagementCheck: ServiceManagementCheck = {
			ServiceManagementStatus: 0,
			SMReport: {} as ServiceManagementReport,
		}

		if (!postAndRetrieveDataLoading && !postAndRetrieveDataError) {
			// Make status call
			var smStatusDataResponse = await postAndRetrieveDataFromDB(
				'Service Management Status',
				`ServiceManagementStatus.First(ServiceManagementStatus.CustomerID = '${customerID}')`,
				false,
				true
			)
			if (
				smStatusDataResponse &&
				Number(smStatusDataResponse.Count) > 0 &&
				smStatusDataResponse.Obj
			) {
				var smStatusResponse = smStatusDataResponse.Obj
					.ServiceManagementStatus as ServiceManagementStatus

				if (smStatusResponse && smStatusResponse.JobStatus + '' === 'Success') {
					serviceManagementCheck.ServiceManagementStatus =
						ServiceManagementStatusCheck.ServiceManagementSuccess

					// Make report call
					var smReportDataResponse = await postAndRetrieveDataFromDB(
						'Service Management Report',
						`ServiceManagementReport.Where(ServiceManagementReport.CustomerID = '${customerID}').Pagination(NumberOfRows = '1' & PageNumber = '1' & Column = 'ServiceManagementReport.LogTime' & SortType = 'DESC')`,
						false,
						true
					)
					if (
						smReportDataResponse &&
						Number(smReportDataResponse.Count) > 0 &&
						smReportDataResponse.Obj
					) {
						var smReportResponse = smReportDataResponse.Obj
							.ServiceManagementReportList as ServiceManagementReport[]

						if (smReportResponse && smReportResponse.length > 0) {
							serviceManagementCheck.SMReport = smReportResponse[0]
						}
					}
				} else {
					if (
						smStatusResponse &&
						(smStatusResponse.JobStatus + '').includes('Fail')
					) {
						if (
							smStatusResponse.Description + '' !==
							'Unable to connect to Tenant through Teams Module'
						) {
							serviceManagementCheck.ServiceManagementStatus =
								ServiceManagementStatusCheck.ServiceManagementFailure
						}
					}
				}
			}
		}

		return serviceManagementCheck
	}

	// GET: Return graph token, then use it to return the licenses from Microsoft and DB and validate
	// Return - licenseOverview: MicrosoftLicenseOverview
	const getUserLicenses = async (msTeamsUser: MSTeamsUser) => {
		// Return variables
		var licenseOverview = {} as MicrosoftLicenseOverview

		// Variables
		var graphToken = ''

		// Get graph token from tenant ID
		if (msTeamsUser.TenantID && msTeamsUser.TenantID.length > 0) {
			// Make call
			if (!postCustomEndpointLoading && !postCustomEndpointError) {
				// Request Model
				var tokenRequest: TokenRequest = {
					TokenType: TokenType.GraphToken,
					TenantID: msTeamsUser.TenantID + '',
				}

				var customPostHookObj: CustomPostHookObj = {
					Action: 'Graph Token',
					RequestURL: 'GetTokenForTeamsUserManagement',
					RequestObj: tokenRequest,
					ShowSuccessMessage: false,
					ShowErrorMessage: false,
					ErrorMessage: 'An error occurred when retrieving the graph token',
					LogErrorToDB: true,
				}

				var customPostResponse = await postCustomEndpoint(customPostHookObj)

				if (customPostResponse) {
					var graphTokenResponse = JSON.parse(
						await toAlphaString(customPostResponse)
					)
					if (graphTokenResponse) {
						graphToken = graphTokenResponse
					}
				}
			}
		}

		// Use graph token to make call to get user licenses from microsoft
		if (graphToken.length > 0) {
			// Request Obj
			var getLicenseObj: MSRequest = {
				token: graphToken,
				Endpoint: Endpoints.GET_LICENSES,
			}

			var msLicensesHookObj: CustomPostHookObj = {
				Action: 'Microsoft Licenses',
				RequestURL: 'MakeMSCall',
				RequestObj: getLicenseObj,
				ShowSuccessMessage: false,
				ShowErrorMessage: false,
				ErrorMessage:
					'An error occurred when retrieving the licenses from microsoft',
				LogErrorToDB: true,
			}

			var msLicensesDataResponse = await postCustomEndpoint(msLicensesHookObj)

			if (msLicensesDataResponse) {
				// Decrypt to string
				var msLicensesResponseString = await toAlphaString(
					msLicensesDataResponse
				)

				if (msLicensesResponseString && msLicensesResponseString.length > 0) {
					// Convert string to JSON
					var msLicensesResponse = JSON.parse(
						msLicensesResponseString
					) as MSResponse

					if (msLicensesResponse) {
						var msLicenseInfo = JSON.parse(
							msLicensesResponse.MSResponseObject
						) as UserManagementLicenseResponse

						var msUserLicenseList =
							msLicenseInfo.value as UserLicenseListResponse[]

						if (msUserLicenseList && msUserLicenseList.length > 0) {
							// If all good, make call to DB to retrieve MS License SKUs and validate accordingly
							if (!postAndRetrieveDataLoading && !postAndRetrieveDataError) {
								// Make call
								var dataResponse = await postAndRetrieveDataFromDB(
									'MS Teams License SKUs',
									`MSTeamsLicenseSKU.All()`,
									false,
									true
								)
								if (
									dataResponse &&
									Number(dataResponse.Count) > 0 &&
									dataResponse.Obj
								) {
									var msTeamsLicenseSKUResponse = dataResponse.Obj
										.MSTeamsLicenseSKUList as MSTeamsLicenseSKU[]

									// Check response
									if (
										msTeamsLicenseSKUResponse &&
										msTeamsLicenseSKUResponse.length > 0
									) {
										// Validate
										var licenseOverviewResponse = validateLicenses(
											msUserLicenseList,
											msTeamsLicenseSKUResponse
										)

										if (licenseOverviewResponse) {
											licenseOverview = licenseOverviewResponse
										}
									}
								}
							}
						}
					}
				}
			}
		}

		return licenseOverview
	}

	// GET: Return the teams token, then use it to return the line URI and routing plan details
	// Return - lineURIOverview: MicrosoftLineURIOverview
	const getLineURIAndRoutingPlanDetails = async (
		msTeamsUser: MSTeamsUser,
		userDDIAssignmentList: UserDDIs[],
		tenantConfigurationInfo: TenantConfigurationInfo
	) => {
		// Return variable
		var lineURIOverview = {} as MicrosoftLineURIOverview

		// Variables
		var teamsToken = ''

		// Get graph token using the MS Teams Tenant ID
		if (msTeamsUser.TenantID && msTeamsUser.TenantID.length > 0) {
			// Make call
			if (!postCustomEndpointLoading && !postCustomEndpointError) {
				// Request Model
				var tokenRequest: TokenRequest = {
					TokenType: TokenType.TeamsToken,
					TenantID: msTeamsUser.TenantID + '',
				}

				var customPostHookObj: CustomPostHookObj = {
					Action: 'Teams Token',
					RequestURL: 'GetTokenForTeamsUserManagement',
					RequestObj: tokenRequest,
					ShowSuccessMessage: false,
					ShowErrorMessage: false,
					ErrorMessage: 'An error occurred when retrieving the graph token',
					LogErrorToDB: true,
				}

				var customPostResponse = await postCustomEndpoint(customPostHookObj)

				if (customPostResponse) {
					var teamsTokenResponse = JSON.parse(
						await toAlphaString(customPostResponse)
					)
					if (teamsTokenResponse) {
						teamsToken = teamsTokenResponse
					}
				}
			}
		}

		if (teamsToken.length > 0) {
			// Get the line URI and Routing Plan from microsoft
			// Request Obj
			var getLineURIAndRoutingPlanObj = {
				TeamsToken: teamsToken,
			}

			var msLineURIHookObj: CustomPostHookObj = {
				Action: 'Microsoft Line URI and Routing Plans',
				RequestURL: 'GetTeamsLineURIAndVRP',
				RequestObj: getLineURIAndRoutingPlanObj,
				ShowSuccessMessage: false,
				ShowErrorMessage: false,
				ErrorMessage:
					'An error occurred when retrieving the line URI and routing plans from Microsoft',
				LogErrorToDB: true,
			}

			var msLineURIDataResponse = await postCustomEndpoint(msLineURIHookObj)

			if (msLineURIDataResponse) {
				// Decrypt to object list
				var msLineURIResponseList = JSON.parse(
					await toAlphaString(msLineURIDataResponse)
				) as MSLineURIAndRoutingPlanUsers[]

				if (msLineURIResponseList && msLineURIResponseList.length > 0) {
					// Assign the lists
					lineURIOverview.LineURIAndRoutingPlanList = msLineURIResponseList

					// Validate the VRP and DDIs
					lineURIOverview.HasVRPIssue = validateDDIsAndVRP(
						msLineURIResponseList,
						userDDIAssignmentList,
						tenantConfigurationInfo
					)
				}
			}
		}

		return lineURIOverview
	}

	// GET: Return the teams users list from Microsoft
	// Return - msUserList: UserManagementResponseList[]
	const getUserManagementDetails = async (msTeamsUser: MSTeamsUser) => {
		// Return variables
		var msUserList = [] as UserManagementResponseList[]

		// Get the graph token first
		var graphToken = ''

		// Get graph token using the MS Teams Tenant ID
		if (msTeamsUser.TenantID && msTeamsUser.TenantID.length > 0) {
			// Make call
			if (!postCustomEndpointLoading && !postCustomEndpointError) {
				// Request Model
				var tokenRequest: TokenRequest = {
					TokenType: TokenType.GraphToken,
					TenantID: msTeamsUser.TenantID + '',
				}

				var customPostHookObj: CustomPostHookObj = {
					Action: 'Teams Token',
					RequestURL: 'GetTokenForTeamsUserManagement',
					RequestObj: tokenRequest,
					ShowSuccessMessage: false,
					ShowErrorMessage: false,
					ErrorMessage: 'An error occurred when retrieving the graph token',
					LogErrorToDB: true,
				}

				var customPostResponse = await postCustomEndpoint(customPostHookObj)

				if (customPostResponse) {
					var graphTokenResponse = JSON.parse(
						await toAlphaString(customPostResponse)
					)
					if (graphTokenResponse) {
						graphToken = graphTokenResponse
					}
				}
			}
		}

		// Use graph token to make call
		if (graphToken && graphToken.length > 0) {
			// Request Obj
			var getUsersObj = {
				GraphToken: graphToken,
			}

			var msUsersHookObj: CustomPostHookObj = {
				Action: 'Get Microsoft Teams Users',
				RequestURL: 'GetTeamsUsers',
				RequestObj: getUsersObj,
				ShowSuccessMessage: false,
				ShowErrorMessage: false,
				ErrorMessage:
					'An error occurred when retrieving your teams users from microsoft',
				LogErrorToDB: true,
			}

			var msUsersDataResponse = await postCustomEndpoint(msUsersHookObj)

			if (msUsersDataResponse) {
				// Decrypt to object list
				msUserList = JSON.parse(
					await toAlphaString(msUsersDataResponse)
				) as UserManagementResponseList[]
			}
		}

		return msUserList
	}

	// ******************************* END API Calls ******************************* //

	// ******************************* Validate Functions ******************************* //

	// Validate licenses to the CDS one, remove the ones we do not use and make an overview
	const validateLicenses = (
		userLicenseList: UserLicenseListResponse[],
		msTeamsLicenseSKUList: MSTeamsLicenseSKU[]
	) => {
		// Return variables
		var baseLicenseList = [] as UserManagementBaseLicenseDisplay[]
		var addonLicenseList = [] as UserManagementAddonLicenseDisplay[]
		var totalUsed = 0
		var totalPurchased = 0
		var totalAvailable = 0

		// Loop through user licenses and only assign the ones that match the MS License SKUs
		for (var l = 0; l < userLicenseList.length; l++) {
			var addonRequired = false

			// Check if it exists in CDS
			if (userLicenseList[l].skuId) {
				var existsInCDS = msTeamsLicenseSKUList.find(
					// eslint-disable-next-line no-loop-func
					(sku) =>
						sku.MSTeamsLicenseSKUID + '' === userLicenseList[l].skuId + ''
				)

				if (existsInCDS) {
					// If addon, add to addon license display else add to base license display
					if (existsInCDS.IsAddon === false) {
						var baseLicenseName = ''
						var numberBaseLicenseAvailable = 0

						baseLicenseName = existsInCDS.FriendlyName + ''
						if (existsInCDS.EnterpriseVoiceIncluded === false) {
							addonRequired = true
						}

						var numberBaseLicensePurchased = Number(
							userLicenseList[l].prepaidUnits?.enabled
						)
						totalPurchased += numberBaseLicensePurchased

						if (Number(userLicenseList[l].consumedUnits) >= 0) {
							numberBaseLicenseAvailable =
								numberBaseLicensePurchased -
								Number(userLicenseList[l].consumedUnits)
							totalAvailable += numberBaseLicenseAvailable
							totalUsed += Number(userLicenseList[l].consumedUnits)
						}

						// Push to base list
						baseLicenseList.push({
							SKUID: userLicenseList[l].skuId + '',
							BaseLicenseName: baseLicenseName + '',
							NumberPurchased: numberBaseLicensePurchased,
							NumberAvailable: numberBaseLicenseAvailable,
							AddonRequired: addonRequired,
						})
					} else {
						if (existsInCDS.IsAddon === true) {
							var numberAddonLicenseAvailable = 0
							var addonLicenseName = existsInCDS.FriendlyName + ''
							var numberAddonLicensePurchased = Number(
								userLicenseList[l].prepaidUnits?.enabled
							)
							totalPurchased += numberAddonLicensePurchased

							if (Number(userLicenseList[l].consumedUnits) >= 0) {
								numberAddonLicenseAvailable =
									numberAddonLicensePurchased -
									Number(userLicenseList[l].consumedUnits)
								totalAvailable += numberAddonLicenseAvailable
								totalUsed += Number(userLicenseList[l].consumedUnits)
							}

							// Push to list
							addonLicenseList.push({
								SKUID: userLicenseList[l].skuId + '',
								AddonLicenseName: addonLicenseName + '',
								NumberPurchased: numberAddonLicensePurchased,
								NumberAvailable: numberAddonLicenseAvailable,
							})
						}
					}
				}
			}
		}

		// Build the return object
		var licenseOverviewObj: UserManagementLicenseOverview = {
			TotalPurchasedLicenses: totalPurchased,
			TotalAvailableLicenses: totalAvailable,
			TotalUsedLicenses: totalUsed,
		}
		var microsoftLicenseOverview: MicrosoftLicenseOverview = {
			LicenseOverview: licenseOverviewObj,
			BaseLicenseList: baseLicenseList,
			AddonLicenseList: addonLicenseList,
			MSTeamsLicenseSKUList: msTeamsLicenseSKUList,
		}

		return microsoftLicenseOverview
	}

	// Validate the DDIs and VRP
	const validateDDIsAndVRP = (
		msLineURIAndRoutingPlanList: MSLineURIAndRoutingPlanUsers[],
		userDDIAssignmentList: UserDDIs[],
		TenantConfigurationInfo: TenantConfigurationInfo
	) => {
		// Return variable
		var hasVRPIssue = false

		// Check for our VRP
		for (var i = 0; i < msLineURIAndRoutingPlanList.length; i++) {
			if (
				TenantConfigurationInfo?.Regions &&
				TenantConfigurationInfo.Regions.length > 0
			) {
				var isOurVRP = TenantConfigurationInfo?.Regions.find(
					// eslint-disable-next-line no-loop-func
					(region) =>
						region.DomVoiceRoutingPolicy + '' ===
							msLineURIAndRoutingPlanList[i].onlineVoiceRoutingPolicy + '' ||
						region.IntVoiceRoutingPolicy + '' ===
							msLineURIAndRoutingPlanList[i].onlineVoiceRoutingPolicy + '' ||
						region.EmergencyVoiceRoutingPolicy + '' ===
							msLineURIAndRoutingPlanList[i].onlineVoiceRoutingPolicy + ''
				)

				if (isOurVRP) {
					// Check if number belongs to us
					var isOurDDI = userDDIAssignmentList.find(
						// eslint-disable-next-line no-loop-func
						(ddi) =>
							ddi.DDI + '' === msLineURIAndRoutingPlanList[i].lineUri + ''
					)
					if (!isOurDDI) {
						hasVRPIssue = true
					}
				}
			}
		}

		// Return
		return hasVRPIssue
	}

	// ******************************* END Validate Functions ******************************* //

	// Return
	return {
		getTeamsServicesAndCustomerServices,
		getSDAOrderSKU,
		getServiceManagementStatusAndReport,
		getUserLicenses,
		getLineURIAndRoutingPlanDetails,
		getUserManagementDetails,
	}
}

export default Prechecks
