import { Box } from '@mui/material'

// This is the tab styling

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && <Box className='tab-container'>{children}</Box>}
        </div>
    )
}
