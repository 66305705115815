import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import './InstancesDisplay.scss'
import { GetPOCInstancesResponseModel } from '../../../utils/interfaces/APIModels'
import { InstanceStatuses } from '../../../utils/enums/enums'
import { StyledDataGrid } from '../../shared/datagrid/StyledDataGrid'

const InstancesDisplayList = ({
	instaceList,
}: {
	instaceList: GetPOCInstancesResponseModel[]
}) => {
	const [rows, setRows] = useState([] as GetPOCInstancesResponseModel[])
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 5,
		page: 0,
	})

	const columns: GridColDef[] = [
		{
			field: 'POCInstanceName',
			headerName: 'Instance Name',
			width: 200,
			hideable: false,
			flex: 2,
		},
		{
			field: 'POCInstanceStatusID',
			headerName: 'Status',
			width: 150,
			hideable: false,
			flex: 1,
			valueGetter: (params: GridRenderCellParams<any>) => {
				switch (Number(params.value)) {
					case InstanceStatuses.Available:
						return 'Available'
					case InstanceStatuses.InUse:
						return 'In Use'
				}
			},
		},
		{
			field: 'StartDate',
			headerName: 'Start Date',
			width: 250,
			hideable: false,
			flex: 1,
			valueGetter: (params: GridRenderCellParams<any>) =>
				params.value ? new Date(params.value).toLocaleDateString('en-US') : '',
		},
		{
			field: 'EndDate',
			headerName: 'End Date',
			width: 250,
			hideable: false,
			flex: 1,
			valueGetter: (params: GridRenderCellParams<any>) =>
				params.value ? new Date(params.value).toLocaleDateString('en-US') : '',
		},
		{
			field: 'CustomerName',
			headerName: 'Customer Name',
			width: 250,
			hideable: false,
			flex: 2,
			valueGetter: (params: GridRenderCellParams<any>) => {
				if (params.row.OrderID !== null) {
					return params.row.Customer.CustomerName
				}
			},
		},
	]

	useEffect(() => {
		if (instaceList.length > 0) {
			setRows(instaceList)
		}
	}, [instaceList])

	return (
		<>
			{rows.length > 0 ? (
				<div className='instances-container'>
					<StyledDataGrid
						className='instances-table'
						columns={columns}
						rows={rows}
						pagination={true}
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						checkboxSelection={false}
						getRowId={(row) => row.POCInstanceID}
						autoHeight={true}
					/>
				</div>
			) : null}
		</>
	)
}

export default InstancesDisplayList
