import { Box, Button, Typography } from '@mui/material'
import react, { useState } from 'react'
import { StyledTextBox } from '../../../../shared/styledComponents/StyledTextBox/StyledTextBox'

function ConfirmDelete({
	handleConfirmDelete,
}: {
	handleConfirmDelete: (confirm: string) => void
}) {
	const [confirmMsg, setConfirmMsg] = useState('')
	return (
		<>
			{/* I stole this style I will edit soon */}
			<Box className='poc-container'>
				<Box className='poc-form-content'>
					<Box className='poc-form'>
						<Typography>
							Before proceeding with deleting the user, please confirm that you
							want to permanently delete them. This action cannot be undone once
							completed.
						</Typography>
						<StyledTextBox
							onChange={(e) => {
								setConfirmMsg(e.target.value)
							}}
							fullWidth
							helperText='Type confirm to proceed.'
							variant='outlined'
							value={confirmMsg}
							id='outlined-error-helper-text'
						/>
						<Button
							disabled={confirmMsg !== 'confirm'}
							onClick={() => handleConfirmDelete(confirmMsg)}
							variant='contained'>
							Confirm
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default ConfirmDelete
