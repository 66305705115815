import { useEffect, useState } from 'react'
import { GridColDef, GridToolbar } from '@mui/x-data-grid-pro'
import { VoiceRoutingListDisplay } from '../../../../utils/interfaces/ComponentModels'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../shared/datagrid/StyledDataGrid'
import CustomCard from '../../../shared/card/CustomCard'
import {
	TeamsDRCountryInfo,
	TenantConfigurationInfo,
} from '../../../../utils/interfaces/APIModels'

const VoiceRoutingDetailsDisplay = ({
	tenantConfigurationInfo,
}: {
	tenantConfigurationInfo: TenantConfigurationInfo
}) => {
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 5,
		page: 0,
	})

	const [voiceRouteRows, setVoiceRouteRows] = useState(
		[] as VoiceRoutingListDisplay[]
	)

	useEffect(() => {
		if (
			tenantConfigurationInfo?.Regions &&
			tenantConfigurationInfo?.Regions.length > 0
		) {
			var tenantRegions =
				tenantConfigurationInfo?.Regions as TeamsDRCountryInfo[]
			var _voiceRouteDetails = [] as VoiceRoutingListDisplay[]

			tenantRegions.forEach((tenantRegion) => {
				if (tenantRegion) {
					var domVoiceRouteObj: VoiceRoutingListDisplay = {
						VoiceRoutingPolicy: tenantRegion.DomVoiceRoutingPolicy,
						VoiceRoute: tenantRegion.DomVoiceRoute + '',
					}

					var intVoiceRouteObj: VoiceRoutingListDisplay = {
						VoiceRoutingPolicy: tenantRegion.IntVoiceRoutingPolicy,
						VoiceRoute: tenantRegion.IntVoiceRoute + '',
					}

					var _emergencyVoiceRouteObj: VoiceRoutingListDisplay = {
						VoiceRoutingPolicy: tenantRegion.EmergencyVoiceRoutingPolicy,
						VoiceRoute: tenantRegion.EmergencyVoiceRoute + '',
					}

					var domVRPAlreadyAdded = _voiceRouteDetails.find(
						(x) =>
							x?.VoiceRoutingPolicy + '' ===
							tenantRegion?.DomVoiceRoutingPolicy + ''
					)

					if (!domVRPAlreadyAdded) {
						_voiceRouteDetails.push(domVoiceRouteObj)
					}

					var intVRPAlreadyAdded = _voiceRouteDetails.find(
						(x) =>
							x?.VoiceRoutingPolicy + '' ===
							tenantRegion?.IntVoiceRoutingPolicy + ''
					)

					if (!intVRPAlreadyAdded) {
						_voiceRouteDetails.push(intVoiceRouteObj)
					}

					var emergVRPAlreadyAdded = _voiceRouteDetails.find(
						(x) =>
							x?.VoiceRoutingPolicy + '' ===
							tenantRegion?.EmergencyVoiceRoutingPolicy + ''
					)

					if (!emergVRPAlreadyAdded) {
						_voiceRouteDetails.push(_emergencyVoiceRouteObj)
					}
				}
			})

			setVoiceRouteRows(_voiceRouteDetails)
		}
	}, [tenantConfigurationInfo])

	const ordersColumns: GridColDef[] = [
		{
			field: 'VoiceRoutingPolicy',
			cellClassName: 'VoiceRoutingPolicy',
			headerName: 'Voice Routing Policy',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'VoiceRoute',
			cellClassName: 'VoiceRoute',
			headerName: 'Voice Route',
			width: 220,
			hideable: false,
			flex: 1,
		},
	]

	return (
		<>
			{tenantConfigurationInfo?.Regions &&
			tenantConfigurationInfo?.Regions.length > 0 ? (
				<StyledDataGrid
					rows={voiceRouteRows}
					columns={ordersColumns}
					editMode='row'
					checkboxSelection={false}
					pagination
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					pageSizeOptions={[5]}
					slots={{
						pagination: CustomPagination,
						toolbar: GridToolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.VoiceRoutingPolicy}
					autoHeight={voiceRouteRows.length > 0}
					rowSelection={false}
				/>
			) : (
				// No routing and PSTN details
				<CustomCard
					cardIconSrc='card-error.png'
					cardTitle='No voice routing details were discovered'
					cardDescription='Kindly be aware that no voice routing details were found.'
					isActive
					showButton={false}
				/>
			)}
		</>
	)
}

export default VoiceRoutingDetailsDisplay
