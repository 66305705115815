import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'
import { SvgIconComponent } from '@mui/icons-material'
import { styled } from '@mui/material'

interface IconLoadingButtonProps {
	disabled?: boolean
	loading: boolean
	icon: React.ReactElement<SvgIconComponent>
	onClick: () => void
}

const StyledIconLoadingButton = styled(LoadingButton)(({ theme }) => ({
	borderRadius: 0,
	'&.Mui-disabled': {
		color: 'rgba(0, 0, 0, 0.26)!important',
		svg: {
			color: 'rgba(0, 0, 0, 0.26)!important',
		},
	},
}))

const IconLoadingButton: React.FC<IconLoadingButtonProps> = ({
	disabled,
	loading,
	icon,
	onClick,
}) => {
	return (
		<StyledIconLoadingButton
			disabled={disabled}
			loading={loading}
			loadingIndicator={<CircularProgress size={24} />}
			variant='outlined'
			sx={{ padding: 1, minWidth: 'unset' }} // Ensure no extra padding
			onClick={onClick}>
			{icon}
		</StyledIconLoadingButton>
	)
}

export default IconLoadingButton
