import { AddonLicenseResponse } from '../../../../../../../utils/interfaces/APIModels'
import { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid-pro'
import { StyledDataGrid } from '../../../../../../../styles/styledComponents/displays/StyledDataGrid'
import { ModalForm } from '../../../../../../shared/inputs/ModalForm/ModalForm'
import GradingIcon from '@mui/icons-material/Grading'

const TUMAddonLicenseDisplay = ({
	addonLicenses,
	handleCloseModal,
}: {
	addonLicenses: AddonLicenseResponse[]
	handleCloseModal: any
}) => {
	// Display constants
	const [addonLicenseList, setAddonLicenseList] = useState(
		[] as AddonLicenseResponse[]
	)

	// Column Definition
	const addonLicenseColumns: GridColDef[] = [
		{
			field: 'addonLicenseName',
			headerName: 'Add-on License Name',
			hideable: false,
			flex: 1,
		},
		{
			field: 'numberPurchased',
			headerName: 'Total',
			hideable: false,
			flex: 1,
		},
		{
			field: 'numberAssigned',
			headerName: 'Assigned',
			hideable: false,
			flex: 1,
		},
	]

	useEffect(() => {
		// Assign list
		if (addonLicenses) {
			setAddonLicenseList(addonLicenses)
		}
	}, [addonLicenses])

	return (
		<>
			<ModalForm
				icon={<GradingIcon />}
				title='Add-on Licenses'
				description='View your add-on licenses list below'
				closeModal={handleCloseModal}>
				{/* Datagrid */}
				<StyledDataGrid
					rows={addonLicenseList}
					columns={addonLicenseColumns}
					editMode='row'
					checkboxSelection={false}
					pagination={false}
					getRowId={(row) => row.addonLicenseSKUID}
					autoHeight
					rowSelection={false}
					hideFooter
					hideFooterPagination
					hideFooterRowCount
				/>
			</ModalForm>
		</>
	)
}

export default TUMAddonLicenseDisplay
