import { Box, Typography } from '@mui/material'

const SDAFAQAnswer2 = () => {
	return (
		<>
			<Box className='documentation-answer-container'>
				{/* Question - What are the licenses used for in the Base Provisioning Process?*/}
				<Typography component='ul'>
					<Typography component='li'>
						To allow voice traffic to route from your Tenant to the Direct
						Routing Voice services, it <b>requires PSTN Gateways </b>to be
						created in the different <b>regions</b> your company is active in.
					</Typography>
					<Typography component='li'>
						In <b>each</b> region, <b>two gateways</b> are available to provide
						service resilience.
					</Typography>
					<Typography component='li'>
						A Custom Domain is created in your Tenant to represent each gateway
						required, with a <b>unique name </b>for each.
					</Typography>
					<Typography component='li'>
						For direct routing to work, these Custom Domains and the associated
						gateways need to be <b>active</b> for <b>Teams Voice</b>.
					</Typography>
					<Typography component='li'>
						To facilitate this, a <b>user account</b> must be present in each{' '}
						<b>Custom Domain</b> and be
						<b> licensed</b> with a capable <b>Teams Voice</b> license.
					</Typography>
					<Typography component='li'>
						During the process you are asked to select which Licenses to use to
						enable this service.
					</Typography>
					<Typography component='li'>
						Those licenses are <b>assigned</b> to the User accounts that are
						created in the Custom Domains to enable them for Teams Voice.
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export default SDAFAQAnswer2
