import './Preloader.scss'

const Preloader = () => {
  return (
    <>
      <div className='preloader-container'>
        <div className='preloader-content'>
          <div className='📦'></div>
          <div className='📦'></div>
          <div className='📦'></div>
          <div className='📦'></div>
          <div className='📦'></div>
        </div>
      </div>
    </>
  )
}

export default Preloader
