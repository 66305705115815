import {
	Box,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Typography,
	useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { SideMenuItems } from '../../../../utils/interfaces/ComponentModels'
import './Sidebar.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { Permissions, Roles } from '../../../../utils/enums/enums'

const Sidebar = () => {
	const partner = 'lmn'
	const navigate = useNavigate()
	const location = useLocation()
	const theme = useTheme()
	const [sideMenuItems, setSideMenuItems] = useState([] as SideMenuItems[])
	const loggedInRoleAndPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	var showLeads = process.env.REACT_APP_SHOW_LEADS === 'true'

	useEffect(() => {
		var _isDashboard = false
		var _isUserManagement = false
		var _isCustomerManagement = false
		var _isDDIManagement = false
		var _isPoc = false
		var _isSupport = false
		var _isOrderManagement = false
		var _isPriceBookManagement = false
		var _isCustomerOverview = false
		var _isTeamsOverview = false
		var _isLeadManagement = false
		//var _isAccessCompliance = false

		switch (location.pathname) {
			case '/': {
				_isDashboard = true
				break
			}
			case '/dashboard': {
				_isDashboard = true
				break
			}

			case '/dashboard/teams-user-management': {
				_isUserManagement = true
				break
			}

			case '/dashboard/customer-management': {
				_isCustomerManagement = true
				break
			}

			case '/dashboard/trial': {
				_isPoc = true
				break
			}

			case '/dashboard/tn-management': {
				_isDDIManagement = true
				break
			}

			case '/dashboard/order-management': {
				_isOrderManagement = true
				break
			}

			case '/dashboard/pricebook-management': {
				_isPriceBookManagement = true
				break
			}

			case '/knowledge-base': {
				_isSupport = true
				break
			}

			case `/dashboard/overview/${loggedInUser.customerID}`: {
				_isCustomerOverview = true
				break
			}

			case `/dashboard/teams-overview/${loggedInUser.customerID}`: {
				_isTeamsOverview = true
				break
			}

			case `/dashboard/leads`: {
				_isLeadManagement = true
				break
			}

			// case `/dashboard/CustomerCompliance`: {
			// 	_isAccessCompliance = true
			// 	break
			// }
		}

		var _sideMenuItems: SideMenuItems[] = []
		_sideMenuItems.push({
			MenuItemTitle: 'Dashboard',
			MenuItemLink: '/dashboard',
			MenuItemIcon: 'dashboard.png',
			IsActive: _isDashboard,
		})
		if (loggedInRoleAndPermissions) {
			// Teams User Management
			if (
				loggedInRoleAndPermissions.find(
					(rolePermission) =>
						Number(rolePermission.PermissionID) ===
						Number(Permissions.ManageTeamsUsers)
				)
			) {
				_sideMenuItems.push({
					MenuItemTitle: 'Teams User Management',
					MenuItemLink: '/dashboard/teams-user-management',
					MenuItemIcon: 'user-mgt.png',
					IsActive: _isUserManagement,
				})
			}
			// POC Order
			if (
				loggedInRoleAndPermissions.find(
					(rolePermission) =>
						Number(rolePermission.PermissionID) ===
						Number(Permissions.AddOrders)
				)
			) {
				_sideMenuItems.push({
					MenuItemTitle: 'Trial Order',
					MenuItemLink: '/dashboard/trial',
					MenuItemIcon: 'orders.png',
					IsActive: _isPoc,
				})
			}
			// Customer Management
			if (
				loggedInRoleAndPermissions.find(
					(rolePermission) =>
						Number(rolePermission.PermissionID) ===
						Number(Permissions.AddCustomer)
				)
			) {
				_sideMenuItems.push({
					MenuItemTitle: 'Customer Management',
					MenuItemLink: '/dashboard/customer-management',
					MenuItemIcon: 'cust-mgt.png',
					IsActive: _isCustomerManagement,
				})
			}
			// DDI Management
			if (
				loggedInRoleAndPermissions.find(
					(rolePermission) =>
						Number(rolePermission.PermissionID) === Permissions.UpdateDDI &&
						Number(rolePermission.RoleID) === Roles.PartnerAdmin
				)
			) {
				_sideMenuItems.push({
					MenuItemTitle: 'TN Management',
					MenuItemLink: '/dashboard/tn-management',
					MenuItemIcon: 'ddi-mgt.png',
					IsActive: _isDDIManagement,
				})
			}
			// Order Management
			if (
				loggedInRoleAndPermissions.find(
					(rolePermission) =>
						Number(rolePermission.PermissionID) ===
						Number(Permissions.AddOrders)
				)
			) {
				_sideMenuItems.push({
					MenuItemTitle: 'Order Management',
					MenuItemLink: '/dashboard/order-management',
					MenuItemIcon: 'order-mgt.png',
					IsActive: _isOrderManagement,
				})
			}
			// Pricebook Management
			if (
				loggedInRoleAndPermissions.find(
					(rolePermission) =>
						Number(rolePermission.PermissionID) ===
						Number(Permissions.ViewPriceBooks)
				)
			) {
				_sideMenuItems.push({
					MenuItemTitle: 'Price Books',
					MenuItemLink: '/dashboard/pricebook-management',
					MenuItemIcon: 'pricebook-mgt.png',
					IsActive: _isPriceBookManagement,
				})
			}
			// Customer Overview
			if (
				loggedInRoleAndPermissions.find(
					(rolePermission) =>
						Number(rolePermission.PermissionID) ===
							Number(Permissions.ViewCustomer) &&
						Number(rolePermission.RoleID) === Number(Roles.CustomerAdmin)
				) &&
				loggedInUser.customerID != null
			) {
				_sideMenuItems.push({
					MenuItemTitle: 'Customer Overview',
					MenuItemLink: `/dashboard/overview/${loggedInUser.customerID}`,
					MenuItemIcon: 'cust-mgt.png',
					IsActive: _isCustomerOverview,
				})
			}

			// Teams Overview
			if (
				loggedInRoleAndPermissions.find(
					(rolePermission) =>
						rolePermission.PermissionID === Permissions.ViewCustomer &&
						Number(rolePermission.RoleID) === Number(Roles.CustomerAdmin)
				) &&
				loggedInUser.customerID != null
			) {
				_sideMenuItems.push({
					MenuItemTitle: 'Teams Overview',
					MenuItemLink: `/dashboard/teams-overview/${loggedInUser.customerID}`,
					MenuItemIcon: 'teams-overview.png',
					IsActive: _isTeamsOverview,
				})
			}

			// Lead Management
			if (
				loggedInRoleAndPermissions.find(
					(rolePermission) =>
						Number(rolePermission.PermissionID) === Permissions.AddCustomer &&
						Number(rolePermission.RoleID) === Roles.PartnerAdmin
				) &&
				showLeads
			) {
				_sideMenuItems.push({
					MenuItemTitle: 'Lead Management',
					MenuItemLink: `/dashboard/leads`,
					MenuItemIcon: 'lead-mgt.png',
					IsActive: _isLeadManagement,
				})
			}

			//Compliance
			// if (
			// 	loggedInRoleAndPermissions.find(
			// 		(rolePermission) =>
			// 			Number(rolePermission.RoleID) === Roles.CustomerAdmin
			// 	)
			// ) {
			// 	_sideMenuItems.push({
			// 		MenuItemTitle: 'Compliance',
			// 		MenuItemLink: `/dashboard/CustomerCompliance`,
			// 		MenuItemIcon: 'compliance-icon.png',
			// 		IsActive: _isAccessCompliance,
			// 	})
			// }

			if (
				loggedInRoleAndPermissions.find(
					(rolePermission) =>
						Number(rolePermission.PermissionID) ===
						Number(Permissions.ViewOrders)
				)
			) {
				// Support
				_sideMenuItems.push({
					MenuItemTitle: 'Knowledge Base',
					MenuItemLink: '/knowledge-base',
					MenuItemIcon: 'support.png',
					IsActive: _isSupport,
				})
			}
		}

		setSideMenuItems(_sideMenuItems)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, loggedInRoleAndPermissions, loggedInUser])

	return (
		<>
			<Box
				id='side-menu'
				className='side-menu-container'
				bgcolor='fixedElement.default'
				sx={{ borderRight: 2, borderRightColor: 'border.primary' }}>
				<MenuList sx={{ padding: 0 }}>
					{sideMenuItems.map((item, index) => (
						<MenuItem
							key={index}
							className='menu-item flex-box col'
							sx={
								item.IsActive
									? {
											borderLeft: 4,
											borderLeftColor: 'primary.main',
											backgroundColor: 'background.superLight',
									  }
									: {}
							}
							onClick={() => navigate(item.MenuItemLink)}>
							<ListItemIcon>
								<img
									className={
										theme.palette.mode === 'light' && !item.IsActive
											? 'menu-img inv'
											: 'menu-img'
									}
									src={
										item.IsActive
											? require(`../../../../assets/icons/sideMenu/${partner}/active/${item.MenuItemIcon}`)
											: require(`../../../../assets/icons/sideMenu/${partner}/${item.MenuItemIcon}`)
									}
									alt={item.MenuItemTitle}
								/>
							</ListItemIcon>
							<ListItemText>
								<Typography
									component='p'
									color={item.IsActive ? 'text.secondary' : 'text.header'}>
									{item.MenuItemTitle}
								</Typography>
							</ListItemText>
						</MenuItem>
					))}
				</MenuList>
			</Box>
		</>
	)
}

export default Sidebar
