import { useSelector } from 'react-redux'
import {
	usePostBetaObjectWithoutRefetchMutation,
	usePublishServiceBusMessageMutation,
} from '../../../services/proxyAPIData'
import { CustomerPartners, ProductActionStatus } from '../../enums/enums'
import {
	getEmptyAPIMutationObject,
	getUTCDateTimeNow,
	toAlphaString,
	toBetaString,
} from '../../helperFunctions/helperFunctions'
import { ActionLog, ProductActionMap } from '../../interfaces/DBModels'
import PostAndRetrieveDataHook from '../APICalls/PostAndRetrieveDataHook'
import ErrorLogging from '../ErrorLogging'
import { RootState } from '../../../store/store'
import {
	AddRequest,
	BetaObject,
	DataResponse,
	PublishServiceBusMessageModel,
} from '../../interfaces/APIModels'
import { BYOCActionForServiceBus } from '../../constants/constants'

const TrunkActionMGT = () => {
	// Global variables
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// Hooks
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()

	// Proxy calls
	const [postWithoutRefetch] = usePostBetaObjectWithoutRefetchMutation()
	const [publishServiceBusMessage] = usePublishServiceBusMessageMutation()

	// Error logging
	const { addErrorLog } = ErrorLogging()

	// Handle the action mgt for trunks
	const handleTrunkActionMGT = async (
		customerID: string,
		trunkGroupID: string,
		orderSKUID: number
	) => {
		// Ensure there are values passed in
		if (trunkGroupID.length > 0 && customerID.length > 0 && orderSKUID > 0) {
			// Check if action log ID exists for the manual one
			var actionLogIDToUse = await getActionLogID(trunkGroupID, orderSKUID)

			if (actionLogIDToUse === 0) {
				// If it does not exist, get the product maps and use it to insert into action log
				var byocProductActionMaps = await getProductActionMaps()
				if (byocProductActionMaps && byocProductActionMaps.length > 0) {
					// Get latest log number
					var actionLogNumber = await getLatestActionLogNumber()

					// Loop through and create action log list
					var actionLogs = [] as ActionLog[]
					for (
						let action = 0;
						action < byocProductActionMaps.length;
						action++
					) {
						// Check which step, if step 1 then make the status be in progress else not started
						var productActionStatusID =
							ProductActionStatus[ProductActionStatus['NOT-START']]
						if (
							byocProductActionMaps[action].ActionStepNumber &&
							byocProductActionMaps[action].ActionStepNumber === 1
						) {
							productActionStatusID =
								ProductActionStatus[ProductActionStatus['IN-PROG']]
						}

						// Push to list
						actionLogs.push({
							ActionLogNumber: actionLogNumber,
							ProductID: byocProductActionMaps[action].ProductID,
							ActionID: byocProductActionMaps[action].ActionID,
							ActionTypeID: byocProductActionMaps[action].ActionTypeID,
							ProductActionStatusID: productActionStatusID,
							CustomerPartnerID: CustomerPartners.Lumen,
							CustomerID: customerID,
							CreatedDate: getUTCDateTimeNow(),
							OrderSKUID: orderSKUID,
							Data: trunkGroupID,
							ActionStepNumber: byocProductActionMaps[action].ActionStepNumber,
						})
					}

					// Add the action logs to the DB and retrieve IDs
					var newActionID = await addActionLogs(actionLogs)
					if (newActionID) {
						actionLogIDToUse = newActionID
					}
				}
			}
			// Now use the trunkactionlogs to publish this message
			if (actionLogIDToUse > 0) {
				// Publish to service bus
				await publishActionMessage(actionLogIDToUse)
			}
		}
	}

	// GET: Retrieve Action log information based on trunkGroupID and orderSKUID
	const getActionLogID = async (trunkGroupID: string, orderSKUID: number) => {
		// Make hook call
		var dataResponse = await postAndRetrieveDataFromDB(
			'Action Log ID for Trunk',
			`ActionLog.First(ActionLog.OrderSKUID = '${orderSKUID}' & ActionLog.Data = '${trunkGroupID}' & ActionLog.ProductID = 'BYOC' & ActionLog.ActionID = '${BYOCActionForServiceBus}' & ActionLog.ProductActionStatusID = '${
				ProductActionStatus[ProductActionStatus['IN-PROG']]
			}')`,
			false,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// If it exists, we will then use the return to add to product action map
			var trunkActionLogResponse = dataResponse.Obj.ActionLog as ActionLog

			if (trunkActionLogResponse && trunkActionLogResponse.ActionLogID) {
				// Return response to add to DB
				return trunkActionLogResponse.ActionLogID
			}
		}

		// Return empty
		return 0
	}

	// GET: Retrieve latest product map record for ID
	const getProductActionMaps = async () => {
		// Make hook call
		var dataResponse = await postAndRetrieveDataFromDB(
			'Retrieve Product Mappings for BYOC',
			`ProductActionMap.Where(ProductActionMap.ProductID = 'BYOC')`,
			false,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// If it exists, we will then use the return to add to product action map
			var productActionMapResponse = dataResponse.Obj
				.ProductActionMapList as ProductActionMap[]

			if (productActionMapResponse && productActionMapResponse.length > 0) {
				return productActionMapResponse
			}
		}

		// Return empty
		return []
	}

	// GET: Retrieve latest action log number
	const getLatestActionLogNumber = async () => {
		// Make hook call
		var dataResponse = await postAndRetrieveDataFromDB(
			'Latest Action Log Record',
			`ActionLog.All().Pagination(NumberOfRows = '1' & PageNumber = '1' & Column = 'ActionLog.ActionLogID' & SortType = 'DESC')`,
			false,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var actionLogResponse = dataResponse.Obj.ActionLogList as ActionLog[]

			if (
				actionLogResponse &&
				actionLogResponse[0] &&
				actionLogResponse[0].ActionLogNumber
			) {
				// Return latest action log number incremented
				var newLogNumber = actionLogResponse[0].ActionLogNumber + 1
				return newLogNumber
			}
		}

		// Empty return
		return 1
	}

	// POST: Add action logs and retrieve IDs
	const addActionLogs = async (actionLogs: ActionLog[]) => {
		// Error handling
		var isError = false

		// Create object
		var addActionLogsObj = {
			ActionLogList: actionLogs,
		}

		// Create add request object
		var requestBody: AddRequest = {
			Obj: addActionLogsObj,
			UserID: loggedInUser.userID + '',
			UserName: loggedInUser.email + '',
		}

		// Encrypt and create mutation
		var betaObj: BetaObject = {
			Content: await toBetaString(JSON.stringify(requestBody)),
		}

		var apiMutation = getEmptyAPIMutationObject()
		apiMutation.QueryParam = 'AddV2?Params=ActionLog:list'
		apiMutation.BetaObject = betaObj

		return await postWithoutRefetch(apiMutation)
			.unwrap()
			.then(async (response) => {
				// Check response for IDs
				if (response && response.Content) {
					// Decrypt response and return IDs
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as DataResponse
					if (dataResponse && dataResponse.Obj) {
						// Assign and return list to use
						var actionLogResponse = dataResponse.Obj
							.ActionLogList as ActionLog[]
						if (actionLogResponse) {
							// Get the ID of the AD-SER-INFO-NO-SBC action log so we can use it to publish to service bus
							var actionToUse = actionLogResponse.find(
								(act) => act.ActionID + '' === BYOCActionForServiceBus
							)
							if (actionToUse && actionToUse.ActionLogID) {
								return actionToUse.ActionLogID
							}
						}
					}
				}
			})
			.catch(async (error) => {
				if (error) {
					isError = true
				}
			})
			.finally(() => {
				// If error, add error to DB
				if (isError) {
					addErrorLog(
						'Add Action Logs for BYOC',
						'An error occurred when adding action logs for BYOC product on Lumen Portal'
					)
				}

				return 0
			})
	}

	// POST: Publish action log message to service bus
	const publishActionMessage = async (actionLogID: number) => {
		// Create service bus message
		var publishActionToServiceBusObj: PublishServiceBusMessageModel = {
			Message: `{ActionLogID: ${actionLogID}, IsSuccess: true}`,
			QueueOrTopicName: process.env.REACT_APP_ACTION_COMPLETED_TOPIC_NAME + '',
			ConnectionStringSettingName:
				process.env.REACT_APP_SERVICE_BUS_CONNECTION_STRING_SETTING + '',
		}

		// Encrypt
		var betaObj: BetaObject = {
			Content: await toBetaString(JSON.stringify(publishActionToServiceBusObj)),
		}

		// Publish message
		await publishServiceBusMessage(betaObj)
			.unwrap()
			.catch(async (error) => {
				if (error) {
					var errorMessage =
						`An error occurred when publishing action log for trunks message. Request body: ` +
						JSON.stringify(publishActionToServiceBusObj) +
						` Error: ` +
						error?.data
					addErrorLog('Publish Service Bus Message:', errorMessage)
				}
			})
	}

	return { handleTrunkActionMGT }
}

export default TrunkActionMGT
