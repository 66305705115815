import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { StyledTableCell } from '../StyledTableComponents'

const TeamsDirectRouting = () => {
	// Display rows
	const tableDetailDisplay = [
		{
			Element: 'Number of PSTN Domains',
			Detail: 'Two Per Region (Maximum 6 if active Globally)',
		},
		{
			Element: 'PSTN Domain Prefix',
			Detail: 'HALOCustomerID + OneNumber + RegionCode',
		},
		{
			Element: 'PSTN Domain Suffix',
			Detail: 'halo.sipcom.cloud',
		},
		{
			Element: 'Number of Users',
			Detail: 'One Per PSTN Domain (Maximum 6 if active Globally)',
		},
		{
			Element: 'Number of Licenses',
			Detail: 'One Per User (Maximum 6 if active Globally)',
		},
		{
			Element: 'AD Users Name',
			Detail: 'Name: HALO.EnterpriseVoice',
		},
		{
			Element: 'AD Users Display Name',
			Detail: 'Name: HALO.EnterpriseVoice DO NOT DELETE',
		},
		{
			Element: 'AD Users Permissions',
			Detail: 'None - Base Account Only',
		},
		{
			Element: 'AD Users Password',
			Detail: 'Randomly generated complex password - Not saved (cache only)',
		},
		{
			Element: 'National Call Policy Identity',
			Detail: 'CountryISO2Code + ” National Calls”',
		},
		{
			Element: 'International Call Policy Identity',
			Detail: 'CountryISO2Code + ” International Calls”',
		},
		{
			Element: 'Emergency Call Policy Identity',
			Detail: 'CountryISO2Code + ” Emergency Calls”',
		},
		{
			Element: 'Domestic Voice Routing Policy Identity ',
			Detail: '“VRP_” + CountryISO2Code + ” National_Calling”',
		},
		{
			Element: 'International Voice Routing Policy Identity',
			Detail: '“VRP_” + CountryISO2Code + ” International_Calling”',
		},
	]

	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					{/* Header */}
					<TableHead>
						<TableRow>
							<StyledTableCell>Element</StyledTableCell>
							<StyledTableCell>Details</StyledTableCell>
						</TableRow>
					</TableHead>
					{/* Body */}
					<TableBody>
						{tableDetailDisplay.map((row, index) => (
							<TableRow key={index}>
								<StyledTableCell>{row.Element}</StyledTableCell>
								<StyledTableCell>{row.Detail}</StyledTableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}

export default TeamsDirectRouting
