import {
	Box,
	Button,
	Divider,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined'
import PhoneDisabledOutlinedIcon from '@mui/icons-material/PhoneDisabledOutlined'
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined'
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined'
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { TUMUpdateList } from '../../../../../../../utils/interfaces/ComponentModels'
import {
	TUMConfigurationTypes,
	TUMUpdateStatuses,
} from '../../../../../../../utils/enums/enums'

const TUMUpdateRow = ({
	row,
	handleRemoveUpdateStatus,
	handleSearchForUser,
}: {
	row: TUMUpdateList
	handleRemoveUpdateStatus: any
	handleSearchForUser: any
}) => {
	// Theme
	const theme = useTheme()

	// Handle functions
	const getTimeDifference = (date: Date | string): string => {
		const now = new Date()
		const logDate = new Date(date) // Convert to Date object if necessary
		const diffInSeconds = Math.floor((now.getTime() - logDate.getTime()) / 1000)

		const minutes = Math.floor(diffInSeconds / 60)
		const hours = Math.floor(diffInSeconds / 3600)
		const days = Math.floor(diffInSeconds / 86400)

		if (days > 0) {
			return `${days} day${days > 1 ? 's' : ''} ago`
		} else if (hours > 0) {
			return `${hours} hour${hours > 1 ? 's' : ''} ago`
		} else if (minutes > 0) {
			return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
		} else {
			return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`
		}
	}

	return (
		<>
			<Box className='tum-update-row-container'>
				{/* Icon */}
				<Box className='tum-update-icon'>
					<Box className='icon-box' bgcolor={theme.palette.primary.main}>
						{Number(row.ActionPerformed) ===
						TUMConfigurationTypes['Add License'] ? (
							<PostAddOutlinedIcon
								sx={{
									color: theme.palette.primary.contrastText,
								}}
							/>
						) : Number(row.ActionPerformed) ===
						  TUMConfigurationTypes['Add TN'] ? (
							<AddIcCallOutlinedIcon
								sx={{
									color: theme.palette.primary.contrastText,
								}}
							/>
						) : Number(row.ActionPerformed) ===
						  TUMConfigurationTypes['Remove License'] ? (
							<DoNotDisturbAltOutlinedIcon
								sx={{
									color: theme.palette.primary.contrastText,
								}}
							/>
						) : (
							Number(row.ActionPerformed) ===
								TUMConfigurationTypes['Remove TN'] && (
								<PhoneDisabledOutlinedIcon
									sx={{
										color: theme.palette.primary.contrastText,
									}}
								/>
							)
						)}
					</Box>
				</Box>
				{/* Text */}
				<Box className='information'>
					{/* UPN */}
					<Typography component='p'>
						<strong>UPN:</strong> {row.UPN}
					</Typography>
					{/* Action Performed */}
					<Typography component='p'>
						<strong>Action Performed:</strong>{' '}
						{TUMConfigurationTypes[Number(row.ActionPerformed)]}
					</Typography>
					{/* Status */}
					<Typography className='status' component='p'>
						<strong>Status:</strong>{' '}
						{Number(row.Status) === TUMUpdateStatuses.Pending ? (
							<PendingOutlinedIcon color='info' />
						) : Number(row.Status) === TUMUpdateStatuses.Success ? (
							<CheckCircleOutlineOutlinedIcon color='success' />
						) : (
							Number(row.Status) === TUMUpdateStatuses.Failure && (
								<ErrorOutlineOutlinedIcon color='error' />
							)
						)}{' '}
						{TUMUpdateStatuses[Number(row.Status)]}
					</Typography>
					{/* Reason */}
					{Number(row.Status) === TUMUpdateStatuses.Failure && (
						<>
							<Typography component='p'>
								<strong>Reason:</strong> {row.Reason}
							</Typography>
						</>
					)}
					{/* Box - Buttons and Time */}
					<Box className='actions'>
						{/* Time difference */}
						<Typography className='time-diff' component='p'>
							{getTimeDifference(row.LogTime as Date)}
						</Typography>
						{/* Button group */}
						<Box className='tum-status-buttons'>
							{/* View */}
							<Tooltip title={`View this user's configuration`}>
								<Button
									variant='contained'
									onClick={() => handleSearchForUser(row.UPN + '')}>
									View User
								</Button>
							</Tooltip>
							{/* Delete  */}
							<Tooltip title={`Remove this update status`}>
								<Button
									variant='outlined'
									onClick={() => handleRemoveUpdateStatus(row)}>
									Delete Notification
								</Button>
							</Tooltip>
						</Box>
					</Box>
				</Box>
			</Box>
			{/* Divider */}
			<Divider />
		</>
	)
}

export default TUMUpdateRow
