import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Box,
	Typography,
} from '@mui/material'
import './Landing.scss'
import LoginButton from '../Login/LoginButton'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import EmailFAQAnswer1 from '../support/FAQs/EmailFAQs/Answer1'
import { FAQDisplay } from '../../../utils/interfaces/ComponentModels'
import { FAQTypes } from '../../../utils/enums/enums'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const Landing = ({ errorMessage }: { errorMessage: string }) => {
	const partner = useSelector(
		(state: RootState) => state.RootReducer.partnerReducer.value
	)

	// Build an array of possible FAQs
	const faqDisplay: FAQDisplay[] = [
		{
			FAQType: FAQTypes['Email Notifications'],
			Question: 'What will happen when you accept this invite?',
			Answer: <EmailFAQAnswer1 />,
		},
	]

	// Create my FAQ components for each category
	const faqs = (faqType: number) => (
		<Box className='faq-content'>
			{/* Heading */}
			<Typography color='primary.main' component='h2'>
				{FAQTypes[faqType]}
			</Typography>
			{/* Accordians for the FAQs */}
			{faqDisplay.map(
				(item, index) =>
					item.FAQType === faqType && (
						<Accordion
							key={index}
							sx={{
								border: 1,
								borderColor: 'primary.main',
							}}>
							<AccordionSummary
								className='faq-title'
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel1a-content'>
								{item.Question}
							</AccordionSummary>
							<AccordionDetails>{item.Answer}</AccordionDetails>
						</Accordion>
					)
			)}
		</Box>
	)

	return (
		<>
			<Box
				id='landing'
				className='landing-container'
				bgcolor='background.default'>
				<Box className='landing-content'>
					<Box className='landing-text'>
						<Typography component='h1' color='text.primary'>
							Lumen UC Platform
						</Typography>
						<Typography component='h4'>
							Welcome to the Lumen UC platform!
						</Typography>
						<Typography component='p'>
							Please click the Login button below to continue.
						</Typography>
						{errorMessage.length > 0 ? (
							<>
								<Alert severity='error' sx={{ maxWidth: 'max-content' }}>
									{errorMessage}
								</Alert>
							</>
						) : null}
						<Box className='landing-button-image'>
							<Box>
								<LoginButton />
								{faqs(FAQTypes['Email Notifications'])}
							</Box>
							<Box className='landing-image'>
								<img
									src={require(`../../../assets/images/home/${partner}/landing.png`)}
									alt='Landing'
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default Landing
